import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.TextColors.BodyText,
  Checked: Color.Primitive.Primary,
  Unchecked: Color.TextColors.BodyText,
  FocusOutline: Color.Primitive.Primary,
  Disabled: Color.Surface.Disabled.Foreground,
  DisabledText: Color.Surface.Disabled.Foreground,
  Error: Color.Signal.Danger,
  ErrorText: Color.Signal.Danger,
};
