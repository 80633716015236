import { isPast, parseISO } from 'date-fns';
import { useBonus } from '../BonusContext';

export const useMatchGameToFreespins = (familyName: string) => {
  const { freespins } = useBonus();

  const matchingOffer = freespins?.[familyName];

  if (matchingOffer == null) {
    return null;
  }

  if ('expiresAt' in matchingOffer && matchingOffer.expiresAt) {
    if (isPast(parseISO(matchingOffer.expiresAt))) {
      return null;
    }
  }

  if ('spinsRemaining' in matchingOffer && matchingOffer.spinsRemaining) {
    if (matchingOffer.game?.familyName) {
      return matchingOffer;
    }
  }

  return null;
};
