import type { BackendModule, ResourceLanguage } from 'i18next';
import { cookies, NEXT_LOCALE } from '@pafcloud/cookies';
import type { Site } from '@pafcloud/config';
import { getSiteProps } from '@pafcloud/locale';

type ClientBackend = BackendModule & {
  translations: ResourceLanguage;
  language: string;
  setTranslations(translations: ResourceLanguage, language: string): void;
};

export const translationClientPlugin: ClientBackend = {
  translations: {},
  language: '',
  type: 'backend',
  init() {},
  read(language, namespace, callback) {
    if (namespace in this.translations && language === this.language) {
      callback(null, this.translations[namespace]);
    } else {
      callback(`Namespace ${namespace} not found`, null);
    }
  },
  setTranslations(translations, language) {
    this.translations = translations;
    this.language = language;
  },
};

const lookupByPath = () => {
  if (typeof window !== 'undefined') {
    const match = /^\/([a-z]{2})(\/|$)/.exec(window.location.pathname);

    if (match) {
      return match[1];
    }
  }
};

export const detectClientLanguage = (languages: string[], defaultLocale: string) => {
  const pathLang = lookupByPath();
  if (pathLang && languages.includes(pathLang)) {
    return pathLang;
  }

  const cookieLang = cookies.get(NEXT_LOCALE);
  if (cookieLang && languages.includes(cookieLang)) {
    // We need to correct the URL or we will have a mismatch with server.
    // If there is a locale in the URL this function will have already returned.
    if (cookieLang !== defaultLocale && typeof window !== 'undefined') {
      // Avoid SSR cache worker returning a redirect to a sanitized URL with missing query params.
      const pathname = window.location.pathname.replace(/\/+$/, '');
      window.location.href = `/${cookieLang}${pathname}${window.location.search}`;
    }

    return cookieLang;
  }

  return defaultLocale;
};

let hasInit = false;
export const initClientTranslations = (site: Site, translations: Record<string, string>) => {
  if (!hasInit) {
    hasInit = true;
    const { locales, defaultLocale } = getSiteProps(site);
    const language = detectClientLanguage(locales, defaultLocale);
    translationClientPlugin.setTranslations(translations, language);
  }
};
