import { graphql, readInlineData } from 'relay-runtime';
import { isType } from '@pafcloud/collection-utils';
import type { bingoTickets_offer$data, bingoTickets_offer$key } from './__generated__/bingoTickets_offer.graphql';
import { getBonusOfferSteps } from './hooks/useBonusOfferSteps';

export type BingoTickets = ReturnType<typeof toBingoTickets>;

const bingoTicketsOfferFragment = graphql`
  fragment bingoTickets_offer on BonusOffer @inline {
    ...useBonusOfferSteps_offer
  }
`;

export const toBingoTickets = (key: bingoTickets_offer$key | null) => {
  const bingoTicketsOffer = readInlineData(bingoTicketsOfferFragment, key);
  const bingoTicketsSteps = getBonusOfferSteps(bingoTicketsOffer ?? null);

  if (bingoTicketsSteps == null) {
    return null;
  }

  // @ts-expect-error 'reward' does not exist on type '%other' and
  // Relay generates with %other as an option because reasons
  return bingoTicketsSteps.reduce<Record<string, NonNullable<bingoTickets_offer$data['steps']>[number]['reward']>>(
    (acc, step) => {
      if ('reward' in step && isType(step.reward, 'BingoTicketsOfferReward')) {
        if (
          step.reward?.game?.familyName != null &&
          step.reward.status === 'GIVEN' &&
          Boolean(step.reward.ticketsRemaining)
        ) {
          if (acc[step.reward.game.familyName] == null) {
            acc[step.reward.game.familyName] = step.reward;
          }
        }
      }

      return acc;
    },
    {},
  );
};
