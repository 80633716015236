import { buildContext } from '../buildContext';

export type TrackedGame = {
  game: {
    readonly category: string;
    readonly familyName: string;
    readonly gameId: string;
    readonly launchUrl: string;
    readonly name: string;
    readonly provider: string;
    readonly slug: string;
  };
  gameListPosition: number;
  gameListTitle: string;
  gameListId: string;
  recommendationGroupId: string;
  recommendationId: string;
  pageSlug: string;
  trackId: string;
  reported?: boolean;
  lastViewStarted?: number;
  totalTimeSeen?: number;
};

export type TrackedGames = Record<string, TrackedGame | undefined>;

export type TrackedViewedGame = TrackedGame & {
  entry: IntersectionObserverEntry;
};

type GameTrackingContext = {
  trackViewedGame: (props: TrackedViewedGame) => void;
  trackClickedGame: (props: TrackedGame) => void;
};

export const [gameTrackingContext, useGameTracking] = buildContext<GameTrackingContext>('GameTrackingContext');
