/**
 * @generated SignedSource<<e04d498101dfc897f62ad70e6dc67cd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIntervalReminders_data$data = {
  readonly player: {
    readonly reminder: {
      readonly interval: number | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useIntervalReminders_data";
};
export type useIntervalReminders_data$key = {
  readonly " $data"?: useIntervalReminders_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIntervalReminders_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useIntervalReminders_data"
};

(node as any).hash = "569e59da9b650334a56a3ccd5333fb15";

export default node;
