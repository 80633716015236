import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Disclosure, Icon, InternalLink } from '@pafcloud/base-components';
import { inArray, isType } from '@pafcloud/collection-utils';
import { getBonusOfferSteps } from '@pafcloud/contexts';
import { ExpandIconWrapper, SummaryText } from '../MainMenuGroup';
import type { BonusMenu_offers$data, BonusMenu_offers$key } from './__generated__/BonusMenu_offers.graphql';
import { GroupDetails } from './sharedStyles';
import {
  BonusBadge,
  BonusNavItem,
  BonusSubMenu,
  BonusGroupSummary,
  BonusSummaryContent,
  BonusNavItemIcon,
  BonusPageLink,
} from './styles';
import { BonusMenuItem } from './BonusMenuItem';
import { isActiveProductReward } from './BonusMenuItemContent';
import { BonusMenuColors } from './bonus-menu-colors';

const bonusMenuFragment = graphql`
  fragment BonusMenu_offers on Bonuses {
    offers {
      ...BonusMenuItem_offer
      ...useBonusOfferSteps_offer
      offerId
      status
    }
  }
`;

const ViewAllBonusesLink = styled(InternalLink)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  marginBottom: 16,
  padding: 8,
  textAlign: 'center',
  color: BonusMenuColors.AllBonusesLink,

  svg: {
    marginLeft: 8,
  },
});

const isFreespinsOrBettingOffer = (offer: NonNullable<BonusMenu_offers$data['offers']>[number]) => {
  if (offer.status === 'ACTIVE') {
    return false;
  }

  const steps = getBonusOfferSteps(offer);

  return (
    steps?.some((step) => {
      if (!('reward' in step)) {
        return false;
      }

      if (step.reward == null) {
        return false;
      }

      if (
        !isType(
          step.reward,
          'BingoTicketsOfferReward',
          'FreeBetOfferReward',
          'FreespinOfferReward',
          'OddsBoostOfferReward',
        )
      ) {
        return false;
      }

      return isActiveProductReward(step);
    }) ?? false
  );
};

const getOfferType = (offer: NonNullable<BonusMenu_offers$data['offers']>[number]): string | undefined => {
  const steps = getBonusOfferSteps(offer);

  if (steps == null) {
    return undefined;
  }

  // Group offer
  if (steps.length === 0) {
    return undefined;
  }

  if (offer.status === 'ACTIVE') {
    if (steps[1] != null && isType(steps[1], 'TurnoverOfferStep')) {
      return steps[1].__typename;
    }

    return steps[0].__typename;
  }

  const isActiveBingoTicketsOffer = steps.some(
    (step) =>
      'reward' in step && step.reward && isType(step.reward, 'BingoTicketsOfferReward') && isActiveProductReward(step),
  );

  const isActiveFreeBetOffer = steps.some(
    (step) =>
      'reward' in step && step.reward && isType(step.reward, 'FreeBetOfferReward') && isActiveProductReward(step),
  );

  const isActiveOddsBoostOffer = steps.some(
    (step) => 'reward' in step && step.reward && isType(step.reward, 'OddsBoostOfferReward'),
  );

  const activeSpinsStep = steps.find(
    (step) =>
      'reward' in step && step.reward && isType(step.reward, 'FreespinOfferReward') && isActiveProductReward(step),
  );

  if (isActiveBingoTicketsOffer) {
    return 'BingoTicketsOfferReward';
  }

  if (isActiveFreeBetOffer) {
    return 'FreeBetOfferReward';
  }

  if (isActiveOddsBoostOffer) {
    return 'OddsBoostOfferReward';
  }

  if (activeSpinsStep != null && 'reward' in activeSpinsStep) {
    return activeSpinsStep.reward?.__typename;
  }

  return undefined;
};

type BonusMenuProps = {
  offers: BonusMenu_offers$key | null;
};

export const BonusMenu: FC<BonusMenuProps> = (props) => {
  const bonusMenu = useFragment(bonusMenuFragment, props.offers);
  const { t } = useTranslation('common');

  if (bonusMenu?.offers == null) {
    return null;
  }

  const activeOffers = bonusMenu.offers.filter((offer) => {
    const steps = getBonusOfferSteps(offer);

    if (steps == null) {
      return false;
    }

    // Program groups would match this case
    if (steps.length === 0) {
      if (inArray(['ACTIVE', 'JOINED'] as const, offer.status)) {
        return true;
      }
    }

    if (offer.status !== 'ACTIVE') {
      return false;
    }

    const isCashbackOffer = steps.some(
      (step) => isType(step, 'ClaimOfferStep') && isType(step.reward, 'CashbackOfferReward'),
    );
    if (isCashbackOffer) {
      return false;
    }

    return steps.some((step) => 'status' in step && ['ACTIVE', 'REWARD_PENDING'].includes(step.status));
  });
  const inactiveOffersWithActiveFreeSpinsOrFreeBet = bonusMenu.offers.filter(isFreespinsOrBettingOffer);
  const offersToDisplay = [...activeOffers, ...inactiveOffersWithActiveFreeSpinsOrFreeBet];

  if (offersToDisplay.length === 0) {
    if ($buildEnv.market === 'sweden') {
      return null;
    }

    const addNavIcon = $buildEnv.theme === 'classic' || $buildEnv.theme === 'classic-red';

    return (
      <BonusNavItem>
        <BonusPageLink href="/profile/bonus" aria-label={t('bonus-menu-link.aria-label')} shallow>
          {t('bonus-menu.bonus-page-link.text')}
          {addNavIcon && <BonusNavItemIcon name="chevronRightFat" size="1rem" />}
        </BonusPageLink>
      </BonusNavItem>
    );
  }

  return (
    <BonusNavItem>
      <GroupDetails open={false}>
        <BonusGroupSummary as="summary">
          <BonusSummaryContent>
            <SummaryText>
              {t('bonus-menu.group-link.text')}
              <BonusBadge severity="low">{offersToDisplay.length}</BonusBadge>
              <ExpandIconWrapper>
                <Disclosure.SummaryIcon name="chevronRightFat" />
              </ExpandIconWrapper>
            </SummaryText>
          </BonusSummaryContent>
        </BonusGroupSummary>

        <BonusSubMenu>
          {offersToDisplay.map((offer) => {
            return <BonusMenuItem key={offer.offerId} offer={offer} type={getOfferType(offer)} />;
          })}

          {$buildEnv.market !== 'sweden' && (
            <li>
              <ViewAllBonusesLink href="/profile/bonus" shallow>
                {t('bonus-menu-link.aria-label')} <Icon name="chevronRightFat" size="0.85em" />
              </ViewAllBonusesLink>
            </li>
          )}
        </BonusSubMenu>
      </GroupDetails>
    </BonusNavItem>
  );
};
