import styled from '@emotion/styled';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FormInputProperties } from './form-input-style/properties';
import { FormInputStyle } from './form-input-style';
import { FormInputColors } from './form-input-colors';
import { FormInputMessage } from './FormInputMessage';
import { FormInputZIndex } from './FormInputZIndex';

export const Wrapper = styled.div<{ floatingLabel?: boolean }>(
  {
    height: FormInputStyle.Height,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: FormInputStyle.FontFamily,
    fontSize: FormInputStyle.FontSize,
    fontWeight: FormInputStyle.FontWeight,
    zIndex: FormInputZIndex.Wrapper,

    [`&:focus-within > ${FormInputMessage}`]: {
      display: 'flex',
    },

    '::after':
      $buildEnv.theme === 'goldenV2' ||
      $buildEnv.theme === 'golden' ||
      $buildEnv.theme === 'quirky' ||
      $buildEnv.theme === 'quirky-purple'
        ? undefined
        : {
            content: '""',
            display: 'block',
            flexShrink: 0,
            width: `calc(var(${FormInputProperties.TextIndent}) / 2)`,
            height: 20,
            pointerEvents: 'none',
          },

    [FormInputProperties.BackgroundColor]: FormInputColors.InputBackground,
    [FormInputProperties.BorderColor]: FormInputColors.BorderUntouched,
    [FormInputProperties.LabelColor]: FormInputColors.LabelUntouched,
    [FormInputProperties.TextColor]: FormInputColors.TextUntouched,
    [FormInputProperties.TextIndent]: FormInputStyle.TextIndent,
    [FormInputProperties.BorderWidth]: FormInputStyle.BorderWidthUntouched,
    [FormInputProperties.LabelWeight]: FormInputStyle.LabelWeight,
    [FormInputProperties.LabelWidth]: `var(${FormInputProperties.LabelWidth}, 100px)`,
  },
  ({ floatingLabel }) =>
    floatingLabel
      ? undefined
      : {
          [FormInputProperties.LabelWeight]: FormInputStyle.NoFloatLabelWeight,
        },
);
