import { useEffect, useState } from 'react';
import { fetchQuery, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks';
import type { GraphQLTaggedNode, OperationType } from 'relay-runtime';
import { useHandler } from '@pafcloud/react-hook-utils';
import { cookies, HAS_SESSION } from '@pafcloud/cookies';

type RelayDataProviderProps<T extends OperationType> = {
  query: GraphQLTaggedNode;
  queryArguments: T['variables'];
  children(props: { data: T['response']; isLoadingClientData: boolean }): JSX.Element;
};

export const RelayDataProvider = <T extends OperationType>(props: RelayDataProviderProps<T>) => {
  const environment = useRelayEnvironment();

  // Since SSR was anonymous we need to refetch all data on client to get the user specific data.
  const [isLoadingClientData, setIsLoadingClientData] = useState(true);

  const refetchQuery = useHandler(async () => {
    const couldBeLoggedIn = cookies.get(HAS_SESSION);

    if (couldBeLoggedIn) {
      await fetchQuery<T>(environment, props.query, props.queryArguments).toPromise();
    }

    setIsLoadingClientData(false);
  });

  useEffect(() => {
    void refetchQuery();
  }, [refetchQuery]);

  // Since we hydrate the relay store with the data from getIntialProps,
  // we can use store-only here to extract the data relevant for the query.
  const data = useLazyLoadQuery<T>(props.query, props.queryArguments, {
    fetchPolicy: 'store-only',
  });

  return props.children({
    isLoadingClientData,
    data,
  });
};
