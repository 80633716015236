import type { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import type { ConfigProviderProps } from '@pafcloud/contexts';
import { renderIntoRootContainer } from '@pafcloud/base-components';

import { $buildEnv } from '@pafcloud/config/buildEnv';
import { Reminder } from '@pafcloud/reminders';
import {
  SiteAlerts,
  Tracking,
  SyncAppData,
  GamePause,
  Header,
  CookieConsentModal,
  ToastNotificationContainer,
  SourceOfFundsReminder,
  FlowRouter,
  CountryRedirect,
  BrowseAppAccessGuard,
  Footer,
} from '@pafcloud/block-components';
import type { App_data$key } from './__generated__/App_data.graphql';

import { Contexts } from './Contexts';
import { useServiceWorker } from './useServiceWorker';
import { useSetAffiliateCookie } from './useSetAffiliateCookie';
import { RouteTransitionIndictor } from './RouteTransitionIndicator';
import { useEnsureCookies } from './useEnsureCookies';
import { useSyncRouterQueryWithBrowserUrl } from './useSyncRouterQueryWithBrowserUrl';

const dataFragment = graphql`
  fragment App_data on Query {
    ...Contexts_data
    ...SyncAppData_data
    ...Tracking_data
    ...SiteAlerts_data
    ...Header_content
    ...Footer_content
    player {
      sourceOfFunds @required(action: NONE) {
        ...SourceOfFundsReminder_data
      }
    }
    accessRequirements {
      browse {
        ...BrowseAppAccessGuard_requirements
      }
    }
    site {
      hasCountryRedirect
    }
  }
`;

type AppProps = {
  data: App_data$key;
  config: ConfigProviderProps['config'];
  isLoadingClientData: boolean;
  children: ReactNode;
};

export const App: FC<AppProps> = ({ config, children, isLoadingClientData, ...props }) => {
  const data = useFragment(dataFragment, props.data);

  useSyncRouterQueryWithBrowserUrl();
  useEnsureCookies();
  useSetAffiliateCookie();
  useServiceWorker();

  const { accessRequirements, player, site } = data;

  return (
    <>
      <Contexts isLoadingClientData={isLoadingClientData} config={config} data={data}>
        {$buildEnv.market != 'switzerland' && <GamePause />}

        <Header content={data} />

        <BrowseAppAccessGuard requirements={accessRequirements.browse}>
          {children}
          {!isLoadingClientData && renderIntoRootContainer(<FlowRouter />)}
        </BrowseAppAccessGuard>

        <Footer content={data} />

        {!isLoadingClientData && (
          <>
            <SyncAppData data={data} />
            <Tracking data={data} />
            {player && <SourceOfFundsReminder data={player.sourceOfFunds} />}
            <Reminder />
            <SiteAlerts data={data} />
            <ToastNotificationContainer />
            <CookieConsentModal />
            {site.hasCountryRedirect && <CountryRedirect />}
          </>
        )}
      </Contexts>
      <RouteTransitionIndictor />
    </>
  );
};
