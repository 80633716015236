import type { FieldValues } from 'react-hook-form';
import { useTranslation } from '@pafcloud/i18n';
import { HookFormInput } from '../hook-form-inputs/HookFormInput';
import { getPhoneValidationPattern } from './getPhoneValidationPattern';
import type { PhoneInputProps } from './PhoneInput';

export const PhoneInput = <TValues extends FieldValues>({
  name,
  validate,
  register,
  children,
  marketAgnostic,
  ...props
}: PhoneInputProps<TValues>) => {
  const { t } = useTranslation('form');

  return (
    <HookFormInput
      label={t('phone-number.input.label')}
      info={t('phone-number.input.format-hint')}
      {...props}
      type="tel"
      {...register(name, {
        pattern: {
          value: getPhoneValidationPattern(marketAgnostic),
          message: t('phone-number.input.invalid-format'),
        },
        required: props['aria-required'] && t('phone-number.input.required'),
        validate,
      })}
    >
      {children}
    </HookFormInput>
  );
};
