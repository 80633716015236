import type { FC } from 'react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { intervalToDuration } from 'date-fns/intervalToDuration';
import type { Duration } from 'date-fns';
import { ZIndex, DynamicValues } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { InternalLink, ExternalLink } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useIsPokerClient } from '@pafcloud/react-hook-utils';
import { useIsLoggedIn, getRGSlug } from '@pafcloud/contexts';
import SelfTestSVG from './svg/Sjalvtest.svg';
import TimeSVG from './svg/Time.svg';
import GamingLimitSVG from './svg/Spelgranser.svg';
import GamingPauseSVG from './svg/Spelpaus.svg';
import { GamePauseGlobal } from './GamePauseGlobal';
import { GamePauseColor } from './game-pause-colors';
import { getGamePauseSession } from './gamePauseSession';

// This is to avoid adjusting for this space in every other component below.
const GamePauseBar = styled.div({
  height: DynamicValues.GamePauseHeight,
});

const GamePauseBarContent = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: ZIndex.GamePause,
  width: '100%',
  height: DynamicValues.GamePauseHeight,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: GamePauseColor.GamePauseBackground,
  color: GamePauseColor.GamePauseText,
  padding: '0 3px',
  fontFamily: 'verdana, sans-serif',
  fontSize: 11,
});

const TimeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 6,
  fontFamily: 'arial, sans-serif',
  color: GamePauseColor.ClockText,

  '@media (max-width: 320px)': {
    marginLeft: 0,
  },
});

const Icon = styled.span({
  width: 16,
  height: 16,
  position: 'relative',
});

const SelfTestIcon = styled(Icon)().withComponent(SelfTestSVG);
const GamePauseIcon = styled(Icon)().withComponent(GamingPauseSVG);
const TimeIcon = styled(Icon)({
  left: -1,
  top: -1,
}).withComponent(TimeSVG);

const GamingLimitIcon = styled(Icon)({
  top: -1,
}).withComponent(GamingLimitSVG);

const Time = styled.time({
  margin: '0 2px',
  fontSize: '1em',
  fontVariantNumeric: 'tabular-nums',
});

const ButtonsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

const linkStyles = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 2,
  padding: '1px 2px',
  border: `1px solid ${GamePauseColor.GamePauseLinkBorder}`,
  borderRadius: 1,
  fontWeight: 'normal',
  backgroundColor: GamePauseColor.GamePauseLinkBackground,
} as const;

const Link = styled(InternalLink)(linkStyles);

const SpelPausLink = styled(ExternalLink)({
  ...linkStyles,
  color: 'inherit',
});

const LinkText = styled.span({
  fontSize: '1em',
  marginLeft: 3,
  whiteSpace: 'nowrap',
});

const formatTime = (time: Duration) => {
  const seconds = String(time.seconds ?? 0).padStart(2, '0');
  const minutes = String(time.minutes ?? 0).padStart(2, '0');
  const hours = time.hours ? `${String(time.hours).padStart(2, '0')}:` : '';

  return `${hours}${minutes}:${seconds}`;
};

const SessionTime: FC = () => {
  const [time, setTime] = useState<string | null>(null);

  useEffect(() => {
    const start = getGamePauseSession();

    setTime(formatTime(intervalToDuration({ start, end: Date.now() })));

    const intervalId = setInterval(() => {
      setTime(formatTime(intervalToDuration({ start, end: Date.now() })));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{time}</>;
};

export const GamePause: FC = () => {
  const { t } = useTranslation('game-pause');
  const isLoggedIn = useIsLoggedIn();
  const isPokerClient = useIsPokerClient();

  if (isPokerClient) {
    return null;
  }

  return (
    <GamePauseBar>
      <GamePauseBarContent>
        {isLoggedIn && (
          <TimeWrapper>
            <TimeIcon url="" />
            <Time aria-label={t('time.aria-label')}>
              <SessionTime />
            </Time>
          </TimeWrapper>
        )}

        {$buildEnv.market === 'sweden' && (
          <ButtonsWrapper>
            <Link aria-label={t('self-test.aria-label')} href={`/${getRGSlug()}/self-test`} prefetch={false}>
              <SelfTestIcon role="img" />
              <LinkText>{t('self-test.text')}</LinkText>
            </Link>
            <Link aria-label={t('limits.aria-label')} href="/profile/limits" prefetch={false}>
              <GamingLimitIcon role="img" />
              <LinkText>{t('limits.text')}</LinkText>
            </Link>
            <SpelPausLink href="https://www.spelpaus.se/?scos=true" aria-label={t('game-pause.aria-label')}>
              <GamePauseIcon role="img" />
              <LinkText>{t('game-pause.text')}</LinkText>
            </SpelPausLink>
          </ButtonsWrapper>
        )}

        <GamePauseGlobal />
      </GamePauseBarContent>
    </GamePauseBar>
  );
};
