import { useId, forwardRef } from 'react';
import { useFormState } from 'react-hook-form';
import { FormInput } from '../form-input';
import type { FormInputProps } from '../form-input';
import type { Alignment } from './HookFormMessage';
import { HookFormMessage } from './HookFormMessage';

export type HookFormInputProps = Omit<FormInputProps, 'defaultValue' | 'touched' | 'dirty' | 'valid' | 'ref'> & {
  name: string;
  info?: string;
  messageAlignment?: Alignment;
};

export const HookFormInput = forwardRef<HTMLInputElement, HookFormInputProps>(
  ({ info, children, messageAlignment, ...props }, ref) => {
    const messageId = useId();
    const state = useFormState<Record<string, string>>();

    const touched = state.touchedFields[props.name];
    const dirty = state.dirtyFields[props.name];
    const error = state.errors[props.name];

    return (
      <FormInput
        {...props}
        touched={touched}
        dirty={dirty}
        error={Boolean(error)}
        aria-describedby={messageId}
        ref={ref}
      >
        <HookFormMessage id={messageId} info={info} error={error?.message} alignment={messageAlignment} />
        {children}
      </FormInput>
    );
  },
);

HookFormInput.displayName = 'HookFormInput';
