import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useUpdatePhoneNumberMutation } from './__generated__/useUpdatePhoneNumberMutation.graphql';

const createUseUpdatePhoneNumber = createUseMutation<useUpdatePhoneNumberMutation>(graphql`
  mutation useUpdatePhoneNumberMutation($input: UpdatePhoneNumberInput!) {
    updatePhoneNumber(input: $input) {
      __typename
      success
    }
  }
`);

export const useUpdatePhoneNumber = createUseUpdatePhoneNumber((result) => {
  return result.updatePhoneNumber.success;
});
