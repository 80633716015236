import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { LoadingIndicator, Link, Heading } from '@pafcloud/base-components';
import { Font, FontHeadingSize, FontTextSize } from '@pafcloud/style';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { useWallet } from '@pafcloud/contexts';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { WalletComponent_session$key } from './__generated__/WalletComponent_session.graphql';
import { WalletComponentColors } from './wallet-component-colors';
import { WalletBalanceDetails } from './WalletBalanceDetails';

const sessionFragment = graphql`
  fragment WalletComponent_session on Session {
    balance
  }
`;

const WalletContainer = styled.div({
  margin: '32px auto 16px',
  textAlign: 'center',
  color: `var(--wallet-text, ${WalletComponentColors.Text})`,
  textTransform: 'uppercase',
});

const WalletHeading = styled(Heading)({
  margin: 0,
  fontSize: FontTextSize.Big,
  color: `var(--wallet-text, ${WalletComponentColors.Text})`,
});

const WalletTotalAmount = styled.span({
  display: 'block',
  fontFamily: Font.Heading,
  fontSize: FontHeadingSize.Normal,
  fontWeight: $buildEnv.theme === 'momentum' || $buildEnv.theme === 'quirky-purple' ? 900 : 'bold',

  color: `var(--wallet-text, ${WalletComponentColors.TotalText})`,
  lineHeight: 1,
});

type Props = {
  className?: string;
  session: WalletComponent_session$key | null;
};

export const WalletComponent: FC<Props> = ({ className, ...props }) => {
  const session = useFragment(sessionFragment, props.session);
  const { t } = useTranslation('wallet');
  const { balance } = useWallet();

  const formatAmount = useFormatAmount();

  return (
    <WalletContainer className={className}>
      <WalletHeading>{t('heading')}</WalletHeading>
      <WalletTotalAmount aria-label={t('total-aria-label')}>
        <Link href="/profile">{balance == null ? <LoadingIndicator /> : formatAmount(balance)}</Link>
      </WalletTotalAmount>
      <WalletBalanceDetails sessionBalance={session?.balance} />
    </WalletContainer>
  );
};
