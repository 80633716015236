import { useEffect, useRef } from 'react';
import { graphql } from 'relay-runtime';
import { useQuery } from '@pafcloud/relay-helpers';
import { useHandler, usePoller } from '@pafcloud/react-hook-utils';
import { usePlayerStatus } from '@pafcloud/contexts';
import type { useSyncPersonalDataQuery } from './__generated__/useSyncPersonalDataQuery.graphql';

const RETRY_DELAY = 1000;
const SYNC_DELAY = 5000;

const sleep = (timeInMs: number) => new Promise((resolve) => setTimeout(resolve, timeInMs));

const isNth = (currentValue: number, nth: number) => currentValue % nth === 0;

const syncPersonalDataQuery = graphql`
  query useSyncPersonalDataQuery($includeInbox: Boolean!) {
    ...PlayerContext_data
    inbox @include(if: $includeInbox) {
      unreadCount
    }
  }
`;

const useSyncData = () => {
  const queryData = useQuery<useSyncPersonalDataQuery>(syncPersonalDataQuery);
  const requestNumber = useRef(0);

  return async function syncPlayer(retries = 0): Promise<void> {
    try {
      await queryData({
        includeInbox: isNth(requestNumber.current, 2),
      });
    } catch (error) {
      if (retries < 4) {
        await sleep(RETRY_DELAY);
        await syncPlayer(retries + 1);
      }
    }

    requestNumber.current += 1;
  };
};

export const useSyncPersonalData = () => {
  const poller = usePoller({
    interval: SYNC_DELAY,
    onError: (error) => console.error('Polling failed with error', { error }),
  });
  const syncData = useSyncData();
  const playerStatus = usePlayerStatus();

  const startPersonalDataSync = useHandler(() => {
    poller.startPolling(async () => {
      await syncData();
      return true;
    });

    return poller.stopPolling;
  });

  useEffect(() => {
    if (playerStatus === 'LOGGED_IN') {
      const stopPersonalDataSync = startPersonalDataSync();
      return stopPersonalDataSync;
    }
  }, [startPersonalDataSync, playerStatus]);
};
