import type { SanityReference } from '@sanity/asset-utils';
import type { PortableTextMarkComponent } from '@portabletext/react';
import { isReference, parseFileAssetId } from '@sanity/asset-utils';
import { ExternalLink } from '@pafcloud/base-components';

type RichTextFileMarkProps = SanityReference & {
  _type: 'file';
};

export const RichTextFileMark: PortableTextMarkComponent<RichTextFileMarkProps> = ({ value: sanityRef, children }) => {
  if (!isReference(sanityRef)) {
    return <span>{children}</span>;
  }

  const { assetId, extension } = parseFileAssetId(sanityRef._ref);
  const fileName = `${assetId}.${extension}`;

  return <ExternalLink href={`/assets/files/${fileName}`}>{children}</ExternalLink>;
};
