import type {
  Theme,
  Site,
  Market,
  SiteName,
  LanguageCode,
  CountryCode,
  Feature,
  GameCategory,
  BettingProvider,
} from './market';

export type SiteProps = {
  theme: Theme;
  marketId: string;
  site: Site;
  market: Market;
  siteName: SiteName;
  companyName: string;
  defaultLocale: `${LanguageCode}`;
  locales: `${LanguageCode}`[];
  countryCode: `${CountryCode}`;
  baseUrl: string;
  gameOfChanceAgeRequirement?: number;
  bettingProviders: BettingProvider[];
  categories: GameCategory[];
  features: Feature[];
  maxBetValue?: number;
};

export const sitesMap = {
  'paf.com': {
    theme: 'classic',
    marketId: '1',
    site: 'paf.com',
    market: 'finland',
    siteName: 'PAF',
    companyName: 'Paf',
    defaultLocale: 'fi',
    locales: ['fi', 'sv', 'en'],
    countryCode: 'fi',
    baseUrl: 'https://www.paf.com',
    categories: ['slots', 'bingo', 'table', 'poker', 'betting'],
    bettingProviders: ['kambi', 'scoutgaming', 'colossusbets'],
    features: ['SIGNICAT_LOGIN'],
  },
  'paf.se': {
    theme: 'classic',
    marketId: '6',
    site: 'paf.se',
    market: 'sweden',
    siteName: 'SWEDEN',
    companyName: 'Paf International Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.paf.se',
    categories: ['slots', 'bingo', 'table', 'poker', 'betting'],
    bettingProviders: ['kambi', 'colossusbets'],
    features: ['SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'paf.ee': {
    theme: 'classic',
    marketId: '4',
    site: 'paf.ee',
    market: 'estonia',
    siteName: 'ESTONIA',
    companyName: 'A/S Pafer',
    defaultLocale: 'et',
    locales: ['et', 'ru', 'en'],
    countryCode: 'ee',
    baseUrl: 'https://www.paf.ee',
    gameOfChanceAgeRequirement: 21,
    categories: ['slots', 'bingo', 'table', 'poker', 'betting'],
    bettingProviders: ['kambi', 'scoutgaming', 'colossusbets'],
    features: ['SIGNICAT_LOGIN'],
  },
  'pafbet.lv': {
    theme: 'classic',
    marketId: '7',
    site: 'pafbet.lv',
    market: 'latvia',
    siteName: 'LATVIA',
    companyName: 'SIA Paf Latvija',
    defaultLocale: 'lv',
    locales: ['lv', 'ru', 'en'],
    countryCode: 'lv',
    baseUrl: 'https://www.pafbet.lv',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi', 'colossusbets'],
    features: ['SIGNICAT_LOGIN'],
  },
  'paf.es': {
    theme: 'classic',
    marketId: '3',
    site: 'paf.es',
    market: 'spain',
    siteName: 'SPAIN',
    companyName: 'PAF International PLC',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.paf.es',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi', 'colossusbets'],
    features: [],
  },
  'noaccountcasino.se': {
    theme: 'golden',
    marketId: '10',
    site: 'noaccountcasino.se',
    market: 'sweden',
    siteName: 'SWEDEN_NAC',
    companyName: 'Paf Multibrand Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.noaccountcasino.se',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'goldenbull.es': {
    theme: 'goldenV2',
    marketId: '18',
    site: 'goldenbull.es',
    market: 'spain',
    siteName: 'SPAIN_GOLDEN_BULL',
    companyName: 'PAF International PLC',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.goldenbull.es',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: [],
  },
  'goldenbull.se': {
    theme: 'goldenV2',
    marketId: '21',
    site: 'goldenbull.se',
    market: 'sweden',
    siteName: 'SWEDEN_GOLDEN_BULL',
    companyName: 'Paf New Tech Ltd (PNT)',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.goldenbull.se',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'casinoepic.se': {
    theme: 'goldenV2',
    marketId: '22',
    site: 'casinoepic.se',
    market: 'sweden',
    siteName: 'SWEDEN_CASINO_EPIC',
    companyName: 'Paf MT Ltd (PMT)',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.casinoepic.se',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'casinogami.se': {
    theme: 'samurai',
    marketId: '23',
    site: 'casinogami.se',
    market: 'sweden',
    siteName: 'SWEDEN_CASINO_GAMI',
    companyName: 'Paf MT Ltd (PMT)',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.casinogami.se',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'lokefreja.se': {
    theme: 'goldenV2',
    marketId: '24',
    site: 'lokefreja.se',
    market: 'sweden',
    siteName: 'SWEDEN_LOKE_FREJA',
    companyName: 'Paf MT Ltd (PMT)',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.lokefreja.se',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'noaccountbet.se': {
    theme: 'golden',
    marketId: '11',
    site: 'noaccountbet.se',
    market: 'sweden',
    siteName: 'SWEDEN_NAB',
    companyName: 'Paf Multibrand Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.noaccountbet.se',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'prankcasino.se': {
    theme: 'quirky',
    marketId: '12',
    site: 'prankcasino.se',
    market: 'sweden',
    siteName: 'SWEDEN_PRANK',
    companyName: 'Paf Multibrand Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.prankcasino.se',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'pinatacasino.es': {
    theme: 'quirky-purple',
    marketId: '19',
    site: 'pinatacasino.es',
    market: 'spain',
    siteName: 'SPAIN_PINATA_CASINO',
    companyName: 'PAF International PLC',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.pinatacasino.es',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: [],
  },
  'speedycasino.com': {
    theme: 'clean',
    marketId: '13',
    site: 'speedycasino.com',
    market: 'sweden',
    siteName: 'SWEDEN_SPEEDY_CASINO',
    companyName: 'Speedy Originals Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.speedycasino.com',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'speedybet.com': {
    theme: 'clean-blue',
    marketId: '14',
    site: 'speedybet.com',
    market: 'sweden',
    siteName: 'SWEDEN_SPEEDY_BET',
    companyName: 'Speedy Originals Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.speedybet.com',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'speedybet.es': {
    theme: 'momentum-neon',
    marketId: '20',
    site: 'speedybet.es',
    market: 'spain',
    siteName: 'SPAIN_SPEEDY_BET',
    companyName: 'PAF International PLC',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.speedybet.es',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: [],
  },
  'x3000.com': {
    theme: 'dreams',
    marketId: '15',
    site: 'x3000.com',
    market: 'sweden',
    siteName: 'SWEDEN_SPEEDY_SPEL',
    companyName: 'Speedy Ltd.',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.x3000.com',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['PAY_AND_PLAY', 'SIGNICAT_LOGIN'],
    maxBetValue: 50,
  },
  'x3000.ee': {
    theme: 'dreams',
    marketId: '9',
    site: 'x3000.ee',
    market: 'estonia',
    siteName: 'ESTONIA_NAB',
    companyName: 'Pafer AS',
    defaultLocale: 'et',
    locales: ['et', 'ru', 'en'],
    countryCode: 'ee',
    baseUrl: 'https://www.x3000.ee',
    gameOfChanceAgeRequirement: 21,
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['SIGNICAT_LOGIN'],
  },
  'x3000.lv': {
    theme: 'dreams',
    marketId: '16',
    site: 'x3000.lv',
    market: 'latvia',
    siteName: 'LATVIA_X3000',
    companyName: 'SIA X3000',
    defaultLocale: 'lv',
    locales: ['lv', 'ru', 'en'],
    countryCode: 'lv',
    baseUrl: 'https://www.x3000.lv',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['SIGNICAT_LOGIN'],
  },
  '11.lv': {
    theme: 'momentum',
    marketId: '17',
    site: '11.lv',
    market: 'latvia',
    siteName: 'LATVIA_11',
    companyName: 'SA Viensviens',
    defaultLocale: 'lv',
    locales: ['lv', 'ru', 'en'],
    countryCode: 'lv',
    baseUrl: 'https://www.11.lv',
    categories: ['slots', 'table', 'betting'],
    bettingProviders: ['kambi'],
    features: ['SIGNICAT_LOGIN'],
  },
  'mycasino.ch': {
    theme: 'classic-red',
    marketId: '8',
    site: 'mycasino.ch',
    market: 'switzerland',
    siteName: 'SWITZERLAND',
    companyName: 'Grand Casino Luzern AG',
    defaultLocale: 'de',
    locales: ['de', 'fr', 'it', 'en'],
    countryCode: 'ch',
    baseUrl: 'https://www.mycasino.ch',
    categories: ['slots', 'table'],
    bettingProviders: [],
    features: [],
  },
} as const satisfies Record<Site, SiteProps>;
