import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { LanguageCode } from '@pafcloud/config';
import { LanguageCodeToLocaleMap as LanguageCodeToLocaleMap_sweden } from './locales.sweden';
import { LanguageCodeToLocaleMap as LanguageCodeToLocaleMap_finland } from './locales.finland';
import { LanguageCodeToLocaleMap as LanguageCodeToLocaleMap_latvia } from './locales.latvia';
import { LanguageCodeToLocaleMap as LanguageCodeToLocaleMap_spain } from './locales.spain';
import { LanguageCodeToLocaleMap as LanguageCodeToLocaleMap_estonia } from './locales.estonia';
import { LanguageCodeToLocaleMap as LanguageCodeToLocaleMap_switzerland } from './locales.switzerland';

export const getLocale = (language: LanguageCode) => {
  // PAF
  if ($buildEnv.site === 'paf.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'paf.ee') {
    return LanguageCodeToLocaleMap_estonia[language];
  }
  if ($buildEnv.site === 'paf.com') {
    return LanguageCodeToLocaleMap_finland[language];
  }
  if ($buildEnv.site === 'pafbet.lv') {
    return LanguageCodeToLocaleMap_latvia[language];
  }
  if ($buildEnv.site === 'paf.es') {
    return LanguageCodeToLocaleMap_spain[language];
  }

  // PML
  if ($buildEnv.site === 'noaccountbet.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'noaccountcasino.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'goldenbull.es') {
    return LanguageCodeToLocaleMap_spain[language];
  }
  if ($buildEnv.site === 'goldenbull.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'casinoepic.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'casinogami.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'lokefreja.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'prankcasino.se') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'pinatacasino.es') {
    return LanguageCodeToLocaleMap_spain[language];
  }

  // Speedy
  if ($buildEnv.site === 'speedybet.com') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'speedybet.es') {
    return LanguageCodeToLocaleMap_spain[language];
  }
  if ($buildEnv.site === 'speedycasino.com') {
    return LanguageCodeToLocaleMap_sweden[language];
  }

  // X3000
  if ($buildEnv.site === 'x3000.com') {
    return LanguageCodeToLocaleMap_sweden[language];
  }
  if ($buildEnv.site === 'x3000.ee') {
    return LanguageCodeToLocaleMap_estonia[language];
  }
  if ($buildEnv.site === 'x3000.lv') {
    return LanguageCodeToLocaleMap_latvia[language];
  }

  // 11
  if ($buildEnv.site === '11.lv') {
    return LanguageCodeToLocaleMap_latvia[language];
  }

  // MyCasino
  if ($buildEnv.site === 'mycasino.ch') {
    return LanguageCodeToLocaleMap_switzerland[language];
  }

  console.warn('LanguageCodeToLocaleMap not specified!');
  return LanguageCodeToLocaleMap_finland[language];
};
