import { graphql, readInlineData } from 'relay-runtime';
import { isType } from '@pafcloud/collection-utils';
import type { cashbackReward_offer$key } from './__generated__/cashbackReward_offer.graphql';
import { getBonusOfferSteps } from './hooks/useBonusOfferSteps';

export type CashbackReward = ReturnType<typeof toCashbackReward>;

const cashbackRewardFragment = graphql`
  fragment cashbackReward_offer on BonusOffer @inline {
    expires
    status
    ...useBonusOfferSteps_offer
  }
`;

export const toCashbackReward = (key: cashbackReward_offer$key | null) => {
  const offer = readInlineData(cashbackRewardFragment, key);

  if (offer == null || offer.status !== 'ACTIVE') {
    return null;
  }

  const steps = getBonusOfferSteps(offer);

  if (steps == null) {
    return null;
  }

  for (const step of steps) {
    if (step.stepId == null) {
      continue;
    }

    if (isType(step, 'ClaimOfferStep') && isType(step.reward, 'CashbackOfferReward')) {
      return {
        expires: offer.expires,
        playerStepId: step.stepId,
        realAmount: step.reward.realAmount,
      };
    }
  }

  return null;
};
