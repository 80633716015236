/**
 * @generated SignedSource<<57b21267b98f803cc7f0d9fce74f5016>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletComponent_session$data = {
  readonly balance: number | null;
  readonly " $fragmentType": "WalletComponent_session";
};
export type WalletComponent_session$key = {
  readonly " $data"?: WalletComponent_session$data;
  readonly " $fragmentSpreads": FragmentRefs<"WalletComponent_session">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletComponent_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    }
  ],
  "type": "Session",
  "abstractKey": null
};

(node as any).hash = "6e68a726eeea8b1f07547f74930cbad3";

export default node;
