import type { FC } from 'react';
import styled from '@emotion/styled';
import { Image } from '@pafcloud/base-components';
import { LogoLink, LogoImageWrapper } from './shared';

const StyledLogoLink = styled(LogoLink)({
  margin: '8px 12px',
  transition: 'all, .125s',

  '&:active, &:hover': {
    transform: 'scale(1.1)',
    transition: 'all, .125s',
  },
});

type LogoImageProps = {
  imageUrl: string;
  name: string;
  url: string | null;
  width: number;
  height: number;
};

export const LogoImage: FC<LogoImageProps> = ({ imageUrl, name, url, width, height }) => {
  if (url) {
    return (
      <StyledLogoLink href={url}>
        <Image src={imageUrl} height={height} width={width} alt={name} unoptimized />
      </StyledLogoLink>
    );
  }

  return (
    <LogoImageWrapper>
      <Image src={imageUrl} height={height} width={width} alt={name} unoptimized />
    </LogoImageWrapper>
  );
};
