import type { ComponentPropsWithoutRef, ComponentPropsWithRef, FC } from 'react';
import { ExternalLink } from './ExternalLink';
import { InternalLink } from './InternalLink';

type ExternalProps = ComponentPropsWithoutRef<typeof ExternalLink>;
type InternalProps = ComponentPropsWithRef<typeof InternalLink>;

export type LinkProps = ExternalProps | InternalProps;

export const Link: FC<LinkProps> = ({ href, ...props }) => {
  if (typeof href === 'string' && (href.startsWith('http') || href.startsWith('mailto:'))) {
    return <ExternalLink href={href} {...props} />;
  }
  return <InternalLink href={href} {...props} />;
};
