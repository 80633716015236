import { useEffect } from 'react';
import { cookies, CSRF_TOKEN } from '@pafcloud/cookies';

/**
 * Checks there is a CSRF cookie, otherwise makes a http request to set it.
 */
export const useEnsureCookies = () => {
  useEffect(() => {
    if (cookies.get(CSRF_TOKEN) != null) {
      return;
    }

    // Cookie will be set by server-response
    void fetch('/api/ensure-cookies');
  }, []);
};
