/**
 * @generated SignedSource<<99a7530b669a470612cb5f7a3d063357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FooterMenu_links$data = ReadonlyArray<{
  readonly ariaLabel: string | null;
  readonly text: string;
  readonly url: string;
  readonly " $fragmentType": "FooterMenu_links";
}>;
export type FooterMenu_links$key = ReadonlyArray<{
  readonly " $data"?: FooterMenu_links$data;
  readonly " $fragmentSpreads": FragmentRefs<"FooterMenu_links">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FooterMenu_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ariaLabel",
      "storageKey": null
    }
  ],
  "type": "FooterMenuLink",
  "abstractKey": null
};

(node as any).hash = "ca0a5c4003385c76388c41690179dd2e";

export default node;
