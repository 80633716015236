import type { RefObject } from 'react';
import { useEffect } from 'react';
import { useHandler } from './useHandler';

type UseOutsideClickProps = {
  content: RefObject<HTMLElement>;
  triggerButtonRef: RefObject<HTMLElement>;
  onOutsideClick?: () => void;
};

export const useOutsideClick = ({ content, triggerButtonRef, onOutsideClick }: UseOutsideClickProps) => {
  const handleClickOutside = useHandler((event: MouseEvent) => {
    if (content.current && onOutsideClick) {
      const composedPath = event.composedPath();

      if (composedPath.includes(content.current)) {
        return;
      }

      if (triggerButtonRef.current && composedPath.includes(triggerButtonRef.current)) {
        return;
      }

      onOutsideClick();
    }
  });

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleEsc = useHandler((event: KeyboardEvent) => {
    if (onOutsideClick && event.key === 'Escape') {
      onOutsideClick();
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [handleEsc]);

  useEffect(() => {
    if (content.current) {
      content.current.focus();
    }

    if (triggerButtonRef.current) {
      const previousFocusTarget = triggerButtonRef.current;
      return () => {
        previousFocusTarget.focus();
      };
    }
  });
};
