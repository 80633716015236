import type { CookieConsentType } from './utils/consent';

export type CookieOptions = {
  expires?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None';
  httpOnly?: boolean;
};

export const defaultCookieOptions: CookieOptions = {
  expires: 365,
  httpOnly: false,
  path: '/',
};

export const secureCookieOptions: CookieOptions = {
  ...defaultCookieOptions,
  expires: undefined,
  httpOnly: true,
  secure: true,
};

export type Cookie = CookieOptions & {
  name: string;
  thirdParty?: boolean; // The cookie is used by external code
  requiredConsent: CookieConsentType;
};
