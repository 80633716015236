import type { FC } from 'react';
import { useRef, useState } from 'react';
import { QueryRenderer } from 'react-relay/legacy';
import { graphql, useRelayEnvironment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { Button } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';

import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import { HeaderIconButton } from '../../header-icon-button';
import { Tray } from '../../../tray';
import { MessageList } from '../../../inbox/message-list';
import type { MessageMenuListQuery } from './__generated__/MessageMenuListQuery.graphql';
import type { MessageMenuQuery } from './__generated__/MessageMenuQuery.graphql';

import { MessageMenuIcon } from './MessageMenuIcon';
import { MessageMenuHeader } from './MessageMenuHeader';

const messageMenuListQuery = graphql`
  query MessageMenuListQuery {
    inbox {
      messagePreviews(size: 3, page: 1, includeOpened: false) @required(action: NONE) {
        ...MessageList_list
      }
    }
  }
`;

const MessageTray = styled(Tray)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 168,
  width: '100%',
  height: '100%',

  [Breakpoint.LaptopOrLarger]: {
    margin: '0 56px 0 auto',
  },
});

const CloseButton = styled(Button)({
  margin: 'auto 1rem 1rem 1rem',
  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
});

type Props = {
  count: number;
};

const MessageMenuContainer: FC<Props> = ({ count }) => {
  const { t } = useTranslation('inbox');
  const [isActive, setIsActive] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  const closeMessagePreview = () => {
    setIsActive(false);
  };

  const toggleMessagePreview = () => {
    setIsActive((active) => !active);
  };

  return (
    <>
      <HeaderIconButton
        onClick={toggleMessagePreview}
        aria-label={t('message-preview.button')}
        data-name="inbox-tray-button"
        ref={button}
        aria-expanded={isActive}
      >
        <MessageMenuIcon numberOfMessages={count} />
      </HeaderIconButton>
      {isActive && (
        <MessageTray triggerButtonRef={button} onOutsideClick={closeMessagePreview}>
          <MessageMenuHeader numberOfMessages={count} closeTray={closeMessagePreview} />
          <SafeQueryRenderer<MessageMenuListQuery>
            query={messageMenuListQuery}
            render={({ props }) => {
              if (props.inbox == null) {
                return null;
              }
              return <MessageList list={props.inbox.messagePreviews} unread />;
            }}
          />
          <CloseButton
            size="large"
            variant="secondary"
            type="button"
            onClick={closeMessagePreview}
            aria-label={t('close-message-button.aria-label')}
          >
            {t('close-message-button.text')}
          </CloseButton>
        </MessageTray>
      )}
    </>
  );
};

const messageMenuQuery = graphql`
  query MessageMenuQuery {
    inbox {
      unreadCount
    }
  }
`;

export const MessageMenu = () => {
  const environment = useRelayEnvironment();

  return (
    <QueryRenderer<MessageMenuQuery>
      environment={environment}
      query={messageMenuQuery}
      variables={{}}
      render={({ props }) => {
        if (props?.inbox == null || props.inbox.unreadCount === 0) {
          // Don't show the message previews on Golden & Quirky theme (NoAccountCasino, NoAccountBet and PrankCasino) if there are none.
          // TODO: Delete this check when when Quirky and Golden are removed.
          if ($buildEnv.theme === 'golden' || $buildEnv.theme === 'quirky') {
            return null;
          }

          return <MessageMenuContainer count={0} />;
        }

        return <MessageMenuContainer count={props.inbox.unreadCount} />;
      }}
    />
  );
};

export default MessageMenu;
