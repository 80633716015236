import { useTranslation } from './i18n';

export const useDateFormat = () => {
  const { t } = useTranslation('date-formats');

  return {
    tDate: (date: Date) => t('global-date-formatter', { date }),
    tDateTime: (date: Date) => t('global-datetime-formatter', { date }),
    tTime: (date: Date) => t('global-time-formatter', { date }),
  };
};
