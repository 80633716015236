import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { Market } from '@pafcloud/config';
import type { CurrencyCode } from './locale';
import { isLanguageCode, isCurrencyCode } from './locale';
import { getLanguageLocale } from './languageLocales';

export type FormatCurrencyProps = {
  amount?: number | null;
  currencyCode?: string | null;
  decimals?: boolean;
  withCurrency?: boolean;
  locale?: string;
};

export const getCurrencySymbol = (currencyCode: CurrencyCode) => {
  if (currencyCode === 'SEK') {
    return 'kr';
  }
  if (currencyCode === 'CHF') {
    return 'CHF';
  }
  return '€';
};

export const getCurrencyCode = (market: Market = $buildEnv.market) => {
  if (market === 'sweden') {
    return 'SEK';
  }
  if (market === 'switzerland') {
    return 'CHF';
  }
  return 'EUR';
};

export const formatCurrency = ({
  amount,
  decimals = true,
  currencyCode = getCurrencyCode(),
  withCurrency = true,
  locale,
}: FormatCurrencyProps) => {
  if (typeof amount !== 'number') {
    return null;
  }

  if (!isLanguageCode(locale)) {
    console.warn(`Invalid language code "${locale}"`);
    return null;
  }

  if (!isCurrencyCode(currencyCode)) {
    console.warn(`Invalid currency code "${currencyCode}"`);
    return null;
  }

  const digits = decimals ? 2 : 0;
  const formatterOpt: Intl.NumberFormatOptions = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };

  if (withCurrency) {
    formatterOpt.style = 'currency';
    formatterOpt.currency = currencyCode;
  }

  const languageLocale = getLanguageLocale(locale);
  const formatter = new Intl.NumberFormat(languageLocale, formatterOpt);
  const formattedAmount = formatter.format(amount);

  if (currencyCode === 'SEK') {
    // Swenglish format where 'kr' should be behind amount - but localized separators should be used.
    const amountWithoutCurrency = formattedAmount.replace(/SEK|kr/i, '').trim();
    return withCurrency ? `${amountWithoutCurrency}\xa0${getCurrencySymbol(currencyCode)}` : amountWithoutCurrency;
  }
  if (currencyCode === 'CHF') {
    // Make sure the currency cymbol is in front of the amount for all languages in Switzerland - even French.
    const amountWithoutCurrency = formattedAmount.replace(/CHF/i, '').trim();
    return withCurrency ? `${getCurrencySymbol(currencyCode)}\xa0${amountWithoutCurrency}` : amountWithoutCurrency;
  }

  return formattedAmount;
};
