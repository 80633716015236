import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { SectionTitle as SectionTitle_default } from './SectionTitle.default';
import { SectionTitle as SectionTitle_golden } from './SectionTitle.pml';

export const SectionTitle = withBuildEnv(() => {
  if (
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'quirky' ||
    $buildEnv.theme === 'quirky-purple'
  ) {
    return SectionTitle_golden;
  }
  return SectionTitle_default;
});
