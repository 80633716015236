import type { Logger } from './shared';

export const clientLogger = {
  debug: console.debug,
  error: console.error,
  warn: console.warn,
  info: console.info,
  child: () => {},
  close: () => {},
} as unknown as Logger;
