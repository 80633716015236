import { forwardRef, useId } from 'react';
import { useFormState } from 'react-hook-form';
import styled from '@emotion/styled';
import type { CheckboxInputProps } from '../checkbox-input';
import { CheckboxInput } from '../checkbox-input';
import { FormInputMessage, FormInputProperties, FormInputStyle } from '../form-input';
import type { Alignment } from './HookFormMessage';
import { HookFormMessage } from './HookFormMessage';

const StyledCheckboxInput = styled(CheckboxInput)({
  [`&:focus-within > ${FormInputMessage}`]: {
    display: 'flex',
  },
  [FormInputProperties.TextIndent]: FormInputStyle.TextIndent,
});

type HookCheckboxInputProps = Omit<CheckboxInputProps, 'defaultValue'> & {
  name: string;
  info?: string;
  messageAlignment?: Alignment;
};

export const HookCheckboxInput = forwardRef<HTMLInputElement, HookCheckboxInputProps>(
  ({ info, children, messageAlignment, ...props }, ref) => {
    const messageId = useId();
    const state = useFormState<Record<string, string>>();

    const error = state.errors[props.name];

    return (
      <StyledCheckboxInput {...props} error={Boolean(error)} aria-describedby={messageId} ref={ref}>
        <HookFormMessage id={messageId} info={info} error={error?.message} alignment={messageAlignment} />
        {children}
      </StyledCheckboxInput>
    );
  },
);

HookCheckboxInput.displayName = 'HookCheckboxInput';
