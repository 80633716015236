import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import * as styles_primary from './styles.primary';
import * as styles_alternate from './styles.alternate';

export const bonusMenuStyles = (() => {
  if (
    $buildEnv.theme === 'momentum' ||
    $buildEnv.theme === 'momentum-neon' ||
    $buildEnv.theme === 'classic' ||
    $buildEnv.theme === 'classic-red' ||
    $buildEnv.theme === 'quirky-purple' ||
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'dreams'
  ) {
    return styles_primary;
  }

  if (
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'quirky' ||
    $buildEnv.theme === 'clean' ||
    $buildEnv.theme === 'clean-blue'
  ) {
    return styles_alternate;
  }

  console.warn('BonusMenuStyles not specified!');
  return styles_primary;
})();
