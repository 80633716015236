// Taken from https://github.com/palmerhq/the-platform/blob/master/src/useMedia.tsx
import { useEffect, useState } from 'react';
import { useIsSSR } from './useIsSSR';

const getMediaQueryList = (query: string) => {
  return window.matchMedia(query.replace('@media', '').trim());
};

export const matchesMediaQuery = (query: string) => {
  return getMediaQueryList(query).matches;
};

export const useMedia = (query: string, defaultMatches = true) => {
  const [matches, setMatches] = useState(defaultMatches);

  useEffect(() => {
    const mediaQueryList = getMediaQueryList(query);

    setMatches(mediaQueryList.matches);

    const handleMatches = () => {
      setMatches(mediaQueryList.matches);
    };

    // This is done because iOS 12 wont support addEventListener on mediaQueryList
    if (typeof mediaQueryList.addEventListener !== 'function') {
      mediaQueryList.addListener(handleMatches);
      return () => {
        mediaQueryList.removeListener(handleMatches);
      };
    }

    mediaQueryList.addEventListener('change', handleMatches);
    return () => {
      mediaQueryList.removeEventListener('change', handleMatches);
    };
  }, [query]);

  const isSSR = useIsSSR();

  if (isSSR) {
    return defaultMatches;
  }

  return matches;
};
