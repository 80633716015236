/**
 * @generated SignedSource<<5d334868bdfe40b4fbf7e6694c0db15b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CountryRedirectQuery$variables = Record<PropertyKey, never>;
export type CountryRedirectQuery$data = {
  readonly countryRedirect: {
    readonly description: string;
    readonly link: string;
    readonly redirectButton: string;
    readonly stayButton: string;
    readonly title: string;
  };
} | null;
export type CountryRedirectQuery = {
  response: CountryRedirectQuery$data;
  variables: CountryRedirectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "CountryRedirect",
  "kind": "LinkedField",
  "name": "countryRedirect",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "redirectButton",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stayButton",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CountryRedirectQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v0/*: any*/),
        "action": "NONE",
        "path": "countryRedirect"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CountryRedirectQuery",
    "selections": [
      (v0/*: any*/)
    ]
  },
  "params": {
    "cacheID": "546d704f1825da376633a2f6518c22cb",
    "id": null,
    "metadata": {},
    "name": "CountryRedirectQuery",
    "operationKind": "query",
    "text": "query CountryRedirectQuery {\n  countryRedirect {\n    link\n    title\n    description\n    redirectButton\n    stayButton\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6b1d56463e4ae58b8e82b7c9b114f4c";

export default node;
