import type { Dispatch, Reducer } from 'react';
import { useReducer, useEffect, useRef } from 'react';
import { useHandler } from './useHandler';
import { useThrowToErrorBoundary } from './useThrowToErrorBoundary';
// adapted from Kent C. Dodds Epic React Course

type IdleState = {
  status: 'idle';
  data: null;
  error: null;
};

type PendingState = {
  status: 'pending';
  data: null;
  error: null;
};

type ResolvedState<T> = {
  status: 'resolved';
  data: T;
  error: null;
};

type RejectedState = {
  status: 'rejected';
  data: null;
  error: unknown;
};

type State<T> = IdleState | PendingState | ResolvedState<T> | RejectedState;

type RejectedAction = {
  type: 'rejected';
  error: unknown;
};

type PendingAction = {
  type: 'pending';
};

type ResolvedAction<DataType> = {
  type: 'resolved';
  data: DataType;
};

type Action<T> = RejectedAction | PendingAction | ResolvedAction<T>;

function asyncReducer<T>(_state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case 'pending': {
      return { status: 'pending', data: null, error: null };
    }
    case 'resolved': {
      return { status: 'resolved', data: action.data, error: null };
    }
    case 'rejected': {
      return { status: 'rejected', data: null, error: action.error };
    }
    default: {
      // @ts-expect-error - Typescript rightfully complains here, since this should never happen.
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/**
 * Takes care of disabling dispatch if the component has unmounted.
 */
function useSafeDispatch<ActionType>(dispatch: Dispatch<ActionType>) {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return useHandler<typeof dispatch>((action) => {
    if (mountedRef.current) {
      dispatch(action);
    }
  });
}

export type UseAsyncOptions<T, Args extends unknown[]> = {
  onStart: (...args: Args) => Promise<T>;
  onResult?: (data: T) => void;
  onError?: (error: unknown) => void;
};

/**
 * Generic useAsync hook for handling any asynchronous logic.
 *
 * @return The State including status, data, error, and a memoized run function.
 */
export function useAsync<T, Args extends unknown[]>(options: UseAsyncOptions<T, Args>) {
  type ReducerType = Reducer<State<T>, Action<T>>;

  const [state, unsafeDispatch] = useReducer<ReducerType>(asyncReducer, {
    status: 'idle',
    data: null,
    error: null,
  });

  const dispatch = useSafeDispatch(unsafeDispatch);
  const throwToErrorBoundry = useThrowToErrorBoundary();

  const latestStarted = useRef<Promise<void> | null>(null);

  const handleResult = useHandler((result: T) => {
    return options.onResult?.(result);
  });

  const handleError = useHandler((error: unknown) => {
    return options.onError?.(error);
  });

  const start = useHandler((...args: Args) => {
    dispatch({ type: 'pending' });

    const currentStarted = options.onStart(...args).then(
      (data) => {
        if (currentStarted === latestStarted.current) {
          dispatch({ type: 'resolved', data });
          throwToErrorBoundry(() => handleResult(data));
          latestStarted.current = null;
        }
      },
      (error) => {
        if (currentStarted === latestStarted.current) {
          dispatch({ type: 'rejected', error });
          throwToErrorBoundry(() => handleError(error));
          latestStarted.current = null;
        }
      },
    );

    latestStarted.current = currentStarted;
  });

  return [state, start] as const;
}
