import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Logos as Logos_paf } from './logos.paf';
import { Logos as Logos_paf_pafbet } from './logos.paf.pafbet';
import { Logos as Logos_paf_mycasino } from './logos.paf.mycasino';
import { Logos as Logos_speedy_sc } from './logos.speedy.sc';
import { Logos as Logos_speedy_sb } from './logos.speedy.sb';
import { Logos as Logos_pml_nab } from './logos.pml.nab';
import { Logos as Logos_pml_nac } from './logos.pml.nac';
import { Logos as Logos_pml_goldenbull } from './logos.pml.goldenbull';
import { Logos as Logos_pml_prank } from './logos.pml.prank';
import { Logos as Logos_pml_pinata } from './logos.pml.pinata';
import { Logos as Logos_x3000 } from './logos.x3000';
import { Logos as Logos_oneOne_sb } from './logos.oneOne.sb';
import { Logos as Logos_oneOne } from './logos.oneOne';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = (() => {
  /* Speedy */
  if ($buildEnv.site === 'speedycasino.com') {
    return Logos_speedy_sc;
  }
  if ($buildEnv.site === 'speedybet.com') {
    return Logos_speedy_sb;
  }

  /* PML */
  if ($buildEnv.site === 'noaccountbet.se') {
    return Logos_pml_nab;
  }
  if ($buildEnv.site === 'noaccountcasino.se') {
    return Logos_pml_nac;
  }
  if ($buildEnv.site === 'goldenbull.es') {
    return Logos_pml_goldenbull;
  }
  if ($buildEnv.site === 'goldenbull.se') {
    return Logos_pml_goldenbull;
  }
  if ($buildEnv.site === 'prankcasino.se') {
    return Logos_pml_prank;
  }
  if ($buildEnv.site === 'pinatacasino.es') {
    return Logos_pml_pinata;
  }

  /* X3000 */
  if ($buildEnv.site === 'x3000.com') {
    return Logos_x3000;
  }
  if ($buildEnv.site === 'x3000.ee') {
    return Logos_x3000;
  }
  if ($buildEnv.site === 'x3000.lv') {
    return Logos_x3000;
  }

  /* 11 */
  if ($buildEnv.site === 'speedybet.es') {
    return Logos_oneOne_sb;
  }
  if ($buildEnv.site === '11.lv') {
    return Logos_oneOne;
  }

  /* PAF */
  if ($buildEnv.site === 'mycasino.ch') {
    return Logos_paf_mycasino;
  }
  if ($buildEnv.site === 'pafbet.lv') {
    return Logos_paf_pafbet;
  }
  return Logos_paf;
})();
