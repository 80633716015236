import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { graphql, useFragment } from 'react-relay/hooks';
import { ExternalLink, InternalLink } from '@pafcloud/base-components';
import { camelCase } from '@pafcloud/util';
import type { MainMenuLink_content$key } from './__generated__/MainMenuLink_content.graphql';
import { MainMenuColors } from './main-menu-colors';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuExternalLinkIcon, MainMenuItemIcon } from './MainMenuIcons';
import { menuLinkStyle } from './menuLinkStyle';
import { showMainMenuItemIcon } from './showMainMenuItemIcon';

const contentFragment = graphql`
  fragment MainMenuLink_content on MenuLink {
    text
    iconName
    url
  }
`;

const MenuInternalLink = styled(InternalLink)(menuLinkStyle);
const MenuExternalLink = styled(ExternalLink)(menuLinkStyle);

type MenuLinkProps = {
  url: string;
  text: string;
  tabIndex?: number;
  children?: ReactNode;
};

const MenuLink: FC<MenuLinkProps> = ({ url, text, children, ...props }) => {
  const isExternalLink = url.startsWith('http');

  return isExternalLink ? (
    <MenuExternalLink href={url} tabIndex={props.tabIndex}>
      {children}
      <MainMenuExternalLinkIcon name="externalLink" size="12" />
    </MenuExternalLink>
  ) : (
    <MenuInternalLink href={url} tabIndex={props.tabIndex}>
      {children}
    </MenuInternalLink>
  );
};

type MainMenuLinkComponentProps = {
  tabIndex?: number;
  content: MainMenuLink_content$key;
};

export const MainMenuLink: FC<MainMenuLinkComponentProps> = (props) => {
  const { text, url, iconName } = useFragment(contentFragment, props.content);
  const router = useRouter();
  const [active, setActive] = useState(false);

  /**
   * Taken from NextJS example
   * @see https://github.com/vercel/next.js/blob/34e10f302a206c30cd233dc8e0798da5a56cbae8/examples/active-class-name/components/ActiveLink.tsx#L21-L32
   */
  useEffect(() => {
    // Check if the router fields are updated client-side
    if (!router.isReady) {
      return;
    }

    // External links can't be active
    if (url.startsWith('http')) {
      return;
    }

    const linkUrl = new URL(url, location.href);
    const activeUrl = new URL(router.asPath, location.href);
    setActive(linkUrl.pathname === activeUrl.pathname);
  }, [router.asPath, router.isReady, url]);

  return (
    <MainMenuItem active={active} aria-current={active ? 'location' : undefined} role="menuitem">
      <MenuLink url={url} text={text} tabIndex={props.tabIndex}>
        {showMainMenuItemIcon && iconName && (
          <MainMenuItemIcon
            name={camelCase(iconName)}
            size="1em"
            color={active ? MainMenuColors.IconActive : MainMenuColors.Icon}
          />
        )}
        {text}
      </MenuLink>
    </MainMenuItem>
  );
};
