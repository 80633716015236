import { useRef, useState } from 'react';
import { useHandler } from './useHandler';

type Options = {
  interval: number;
  onError?: (error: unknown) => void;
};

/**
 * @example
 * const {startPolling, stopPolling} = usePoller({ interval: 2000, onError: (error) => ... });
 * startPolling(async () => {
 *   // Polling continues until false is returned or stopPolling() is called.
 *   // Polling does not stop automatically when the `onError` callback is triggered.
 * });
 */
export const usePoller = (options: Options) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isPolling, setPolling] = useState(false);
  const interval = options.interval;

  const stopPolling = useHandler(() => {
    setPolling(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  });

  const startPolling = useHandler((callback: () => Promise<boolean>) => {
    const poll = async () => {
      if (document.hidden) {
        timeoutRef.current = setTimeout(poll, interval);
        return;
      }

      try {
        const shouldContinuePolling = await callback();
        if (shouldContinuePolling && timeoutRef.current !== null) {
          timeoutRef.current = setTimeout(poll, interval);
        } else {
          setPolling(false);
        }
      } catch (error) {
        options.onError?.(error);
        timeoutRef.current = setTimeout(poll, interval);
      }
    };

    stopPolling();
    setPolling(true);

    timeoutRef.current = setTimeout(poll, interval);
  });

  return {
    startPolling,
    stopPolling,
    isPolling,
  };
};
