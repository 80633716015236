import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_golden } from './colors.golden';
import { Colors as Colors_quirky } from './colors.quirky';

export const Colors = (() => {
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }
  if ($buildEnv.theme === 'golden') {
    return Colors_golden;
  }

  return Colors_default;
})();
