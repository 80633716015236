/**
 * @generated SignedSource<<276c334159a87263e940067aafbed4a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type IdentificationStatus = "COMPLETED" | "IN_PROGRESS" | "POSSIBLE" | "REQUIRED";
export type SourceOfFundsStatus = "COMPLETED" | "IN_PROGRESS" | "NOT_REQUIRED" | "REJECTED" | "REQUIRED";
import { FragmentRefs } from "relay-runtime";
export type HeaderContentGolden_content$data = {
  readonly player: {
    readonly identification: {
      readonly status: IdentificationStatus | null;
    };
    readonly sourceOfFunds: {
      readonly status: SourceOfFundsStatus | null;
    };
  } | null;
  readonly " $fragmentType": "HeaderContentGolden_content";
};
export type HeaderContentGolden_content$key = {
  readonly " $data"?: HeaderContentGolden_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentGolden_content">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContentGolden_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Player",
      "kind": "LinkedField",
      "name": "player",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "SourceOfFunds",
            "kind": "LinkedField",
            "name": "sourceOfFunds",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          "action": "NONE",
          "path": "player.sourceOfFunds"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "Identification",
            "kind": "LinkedField",
            "name": "identification",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          "action": "NONE",
          "path": "player.identification"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fe688d2245b17d25a9846734704fc0d7";

export default node;
