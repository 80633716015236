/**
 * @generated SignedSource<<f25f76aa8f9ebee33bc05aaea53d6f26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdatePlayerLanguageInput = {
  language: string;
};
export type useUpdatePlayerLanguageMutation$variables = {
  input: UpdatePlayerLanguageInput;
};
export type useUpdatePlayerLanguageMutation$data = {
  readonly updatePlayerLanguage: {
    readonly player: {
      readonly language: string | null;
    };
    readonly success: boolean;
  };
};
export type useUpdatePlayerLanguageMutation = {
  response: useUpdatePlayerLanguageMutation$data;
  variables: useUpdatePlayerLanguageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdatePlayerLanguageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlayerLanguagePayload",
        "kind": "LinkedField",
        "name": "updatePlayerLanguage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "player",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdatePlayerLanguageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlayerLanguagePayload",
        "kind": "LinkedField",
        "name": "updatePlayerLanguage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "player",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f596c8b63db8a63581a8c58fc42216ff",
    "id": null,
    "metadata": {},
    "name": "useUpdatePlayerLanguageMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdatePlayerLanguageMutation(\n  $input: UpdatePlayerLanguageInput!\n) {\n  updatePlayerLanguage(input: $input) {\n    success\n    player {\n      language\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a684202d6fa19df458c08aa701c4b9d6";

export default node;
