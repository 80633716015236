import type { ComponentProps, ElementType, KeyboardEvent, MouseEvent } from 'react';
import { inArray } from '@pafcloud/collection-utils';

type CompatibleProps<E = Element> = {
  onClick?: (event: MouseEvent<E>) => void;
  onKeyDown?: (event: KeyboardEvent<E>) => void;
};

type ButtonizedProps<E = Element> = {
  disabled?: boolean;
  onClick?: (event: MouseEvent<E> | KeyboardEvent<E>) => void;
  onKeyDown?: (event: KeyboardEvent<E>) => void;
};

/**
 * Combines two prop types,
 * and overrides the type of props with the same name.
 */
type OverrideProps<Props, Overrides> = Omit<Props, keyof Overrides> & Overrides;

/**
 * Higher order component to turn anything into a button.
 *
 * https://www.w3.org/WAI/GL/WCAG20/WD-WCAG20-TECHS-20071102/SCR20.html
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
 */
export function asButton<C extends ElementType>(Component: C) {
  // We could pass this as an argument if needed.
  const activationKeys = ['Enter', 'Space'] as const;

  type OriginalProps = ComponentProps<C>;

  type EventElement = OriginalProps extends CompatibleProps<infer E> ? E : never;

  return function Buttonized(props: OverrideProps<OriginalProps, ButtonizedProps<EventElement>>) {
    const onKeyDown = (event: KeyboardEvent<EventElement>) => {
      if (inArray(activationKeys, event.code)) {
        // preventDefault is to stop "Enter" click from double-triggering
        event.preventDefault();
        props.onClick?.(event);
      }
      props.onKeyDown?.(event);
    };

    // Allow element focus if not disabled.
    const tabIndex = props.disabled ? -1 : 0;

    // @ts-expect-error https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34553
    return <Component role="button" tabIndex={tabIndex} {...props} onKeyDown={onKeyDown} />;
  };
}
