import styled from '@emotion/styled';
import { Icon } from '@pafcloud/base-components';

export const MainMenuItemIcon = styled(Icon)({
  width: '1.25em',
  margin: '0 1rem 0 0',
});

export const MainMenuExternalLinkIcon = styled(Icon)({
  margin: '-0.25rem 0 0 0.5rem',
});
