import type { Localized } from '../locale';

export const LanguageLocalesMap: Localized<string> = {
  en: 'en-GB',
  et: 'et-EE',
  sv: 'sv-SE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  lv: 'lv-LV',
  ru: 'ru-RU',
  es: 'es-ES',
};
