import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { cookies, IS_POKER_CLIENT } from '@pafcloud/cookies';

export const useIsPokerClient = () => {
  const [hasPokerClientCookie, setHasPokerClientCookie] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setHasPokerClientCookie(cookies.get(IS_POKER_CLIENT) === 'true');
  }, []);

  return hasPokerClientCookie || router.pathname.startsWith('/poker-client');
};
