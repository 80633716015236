import type { Context } from 'react';
import { createContext, useContext } from 'react';

const INITIAL_VALUE = Symbol('InitalContextValue');

export const buildContext = <ContextType>(contextName: string) => {
  const context = createContext<ContextType | typeof INITIAL_VALUE>(INITIAL_VALUE);
  context.displayName = contextName;

  const useContextValue = () => {
    const value = useContext(context);

    // We can chack if there is a provider above,
    // by checking the context value is the same reference as the initial value or not.
    // If we don't have a provider above, useContext falls back to the vaklue we passed into `createContext`,
    // which will be the INITIAL_VALUE object instance above.
    if (value === INITIAL_VALUE) {
      // At this point, we know that value is neither null or undefined
      throw new Error(`Tried to use context for ${context.displayName} outside of its provider.`);
    }

    return value;
  };

  return [context as Context<ContextType>, useContextValue] as const;
};
