import { useEffect, useState } from 'react';
import { useIsSSR } from '@pafcloud/react-hook-utils';

export const useStoredState = <T>(key: string, defaultValue: T) => {
  const [state, setState] = useState<T>(defaultValue);

  const isSSR = useIsSSR();

  useEffect(() => {
    // Ensure we don't use different values on server and client hydration.
    if (isSSR) {
      return;
    }

    const value = localStorage.getItem(key);

    if (value == null) {
      return;
    }

    setState(JSON.parse(value) as T);
  }, [key, isSSR]);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(state));
  }, [key, isSSR, state]);

  return [state, setState] as const;
};
