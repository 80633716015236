/**
 * @generated SignedSource<<406b26e6f0671ceca22c5dd4cb401164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAfterLoginReminder_data$data = {
  readonly player: {
    readonly reminder: {
      readonly afterLogin: boolean;
    } | null;
  } | null;
  readonly " $fragmentType": "useAfterLoginReminder_data";
};
export type useAfterLoginReminder_data$key = {
  readonly " $data"?: useAfterLoginReminder_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAfterLoginReminder_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useAfterLoginReminder_data"
};

(node as any).hash = "31da194215ce447474de71797136ecf2";

export default node;
