import styled from '@emotion/styled';
import { deviceWithHover } from '@pafcloud/style';
import { HeaderColors } from '../header-colors';

export const HeaderIconButton = styled.button({
  all: 'unset',
  position: 'relative',
  isolation: 'isolate',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  flexGrow: 0,
  width: 56,
  height: 56,
  fontSize: 22, // icon size
  color: HeaderColors.Icon,
  cursor: 'pointer',
  outline: 'transparent',
  WebkitTapHighlightColor: 'transparent',

  '&[aria-expanded="true"]': {
    color: HeaderColors.IconFocus,
  },

  [deviceWithHover]: {
    ':hover': {
      color: HeaderColors.IconFocus,
    },
  },

  ':focus-visible': {
    color: HeaderColors.IconFocus,

    '::before': {
      content: '""',
      position: 'absolute',
      width: 40,
      height: 40,

      zIndex: -1,
      borderRadius: HeaderColors.IconFocusCornerRadius,
      background: HeaderColors.IconFocusBackground,
    },
  },
});
