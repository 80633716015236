import styled from '@emotion/styled';
import { Breakpoint, Color, FontTextSize } from '@pafcloud/style';
import { Button } from '../button';
import { Disclosure } from '../disclosure';
import { Heading } from '../auto-heading';
import { ToggleSwitch } from '../toggle-switch';

export const CookieWrapper = styled.div({
  textAlign: 'center',
});

export const CookieHeading = styled(Heading)({
  margin: '0 0 16px',
  fontSize: FontTextSize.Big,
  fontWeight: 700,
  textAlign: 'center',
});

export const CookieParagraph = styled.p({
  marginTop: 0,
  marginBottom: 32,
  fontSize: FontTextSize.Normal,
});

export const CookieButtonWrapper = styled.div({
  display: 'grid',
  gridAutoColumns: 'auto',
  gap: 8,

  [Breakpoint.LandscapePhone]: {
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(2, auto)',
  },
});

export const CookieConsentButton = styled(Button)({
  [Breakpoint.LandscapePhone]: {
    ':first-of-type': {
      order: 2,
    },
  },
});

export const CookieFlowHeader = styled(Heading)({
  fontSize: FontTextSize.Normal,
  fontWeight: 700,
  marginTop: 0,
});

export const CookieSection = styled.section({
  position: 'relative',
  border: `2px solid ${Color.TextColors.BodyText}2e`,
  borderRadius: Color.CornerRadius.Small,

  '& + &': {
    marginTop: 8,
  },
});

export const CookieToggleSwitch = styled(ToggleSwitch)({
  position: 'absolute',
  top: 0,
  right: 8,
  marginTop: '0.688rem',
  zIndex: 1,
});

export const CookieToggleSummary = styled(Disclosure.Summary)({
  padding: '16px 8px',
});

export const CookieToggleTitle = styled.p({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  paddingLeft: 24,
  fontSize: FontTextSize.Normal,
  fontWeight: 'bold',
  textAlign: 'left',

  '::before, ::after': {
    content: '""',
    position: 'absolute',
    left: 8,
    width: 2,
    height: 12,
    borderRadius: 1,
    background: Color.TextColors.BodyText,
  },

  '::before': {
    transform: 'rotate(90deg)',
  },

  '[open] &::after': {
    display: 'none',
  },
});

export const CookieInfoText = styled.p({
  marginTop: 8,
  paddingLeft: 32,
  paddingRight: 16,
});

export const CookieSaveDivider = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '24px 0 48px',
});

export const CookieSaveButton = styled(Button)({
  maxWidth: 256,
  margin: '0 auto',
});

export const CookieSaveAllButton = styled(Button)({
  margin: 0,
});

export const CookieTextSection = styled.section({
  h1: {
    fontSize: '2rem',
  },
  h2: {
    fontSize: '1.5rem',
  },
  h3: {
    fontSize: '1.25rem',
  },
  'p, li': {
    fontSize: '1rem',
  },
});
