import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { LayoutProps } from '@pafcloud/style';
import type { GuardComponentProps } from '../src/access-guard/guards/guardComponentProps';

export const LoginGuard = dynamic<GuardComponentProps>(
  () => import('../src/access-guard/guards/login-guard/LoginGuard'),
);

export const GuardRenderComponent = styled.div({ position: 'absolute', inset: 0, top: LayoutProps.HeaderHeight });
