import { getCountryCode, phoneNumberPrefixes } from '@pafcloud/locale';
import { upperCase } from '@pafcloud/util';
import {
  MOBILE_PHONE_NUMBER_PATTERNS,
  LOOSE_NORDIC_AND_BALTIC_NUMBER_PATTERNS,
  PHONE_NUMBER_PATTERN,
  STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS,
} from '../form-input';

export const getPhoneValidationPattern = (marketAgnostic?: boolean) => {
  if (marketAgnostic) {
    return PHONE_NUMBER_PATTERN;
  }

  const patternCountryCode = upperCase(getCountryCode());
  if (patternCountryCode === 'ES' || patternCountryCode === 'CH') {
    return MOBILE_PHONE_NUMBER_PATTERNS[patternCountryCode];
  }

  return LOOSE_NORDIC_AND_BALTIC_NUMBER_PATTERNS;
};

export const getStrictPhoneValidationPattern = (prefix: number) => {
  switch (prefix) {
    case phoneNumberPrefixes.denmark:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.DK;
    case phoneNumberPrefixes.estonia:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.EE;
    case phoneNumberPrefixes.faroeIslands:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.FO;
    case phoneNumberPrefixes.finland:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.FI;
    case phoneNumberPrefixes.greenland:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.GL;
    case phoneNumberPrefixes.iceland:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.IS;
    case phoneNumberPrefixes.latvia:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.LV;
    case phoneNumberPrefixes.lithuania:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.LT;
    case phoneNumberPrefixes.norway:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.NO;
    case phoneNumberPrefixes.spain:
      return MOBILE_PHONE_NUMBER_PATTERNS.ES;
    case phoneNumberPrefixes.sweden:
      return STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS.SE;
    case phoneNumberPrefixes.switzerland:
      return MOBILE_PHONE_NUMBER_PATTERNS.CH;
    default:
      throw new Error('Tried to map prefix to country code but received invalid prefix: ', { cause: prefix });
  }
};
