import { graphql } from 'relay-runtime';
import { useRouter } from 'next/router';
import { useMutation } from '@pafcloud/relay-helpers';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { deleteDeferredLogin } from '../login-flow/deferredLogin';
import type { useLogoutMutation } from './__generated__/useLogoutMutation.graphql';

const mutation = graphql`
  mutation useLogoutMutation {
    logout {
      success
    }
  }
`;

const setLoggedOut = (redirectPath: string) => {
  deleteDeferredLogin();
  window.location.href = redirectPath;
};

export const useHandleSessionExpired = () => {
  const router = useRouter();

  return () => {
    // Remain on the current route with the login flow open
    const currentRoute = router.asPath.split('?')[0];
    const redirectUrl =
      router.defaultLocale === router.locale
        ? `${currentRoute}?flowType=login`
        : `/${router.locale}${currentRoute}?flowType=login`;

    setLoggedOut(redirectUrl);
  };
};

export const useLogout = () => {
  const logoutMutation = useMutation<useLogoutMutation>(mutation);
  const isLoggedIn = useIsLoggedIn();

  return async () => {
    if (!isLoggedIn) {
      return;
    }

    try {
      await logoutMutation();
    } catch {
      // The best behaviour here may be to simply swallow the error,
      // but we could potentially show some kind of indication to the user.
    }

    setLoggedOut('/');
  };
};
