import { memo, useMemo, useState } from 'react';
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { buildContext } from '@pafcloud/contexts';
import type { RecentSearch } from './useRecentSearch';

type RecentSearchResults = {
  recentSearchResults: Record<string, RecentSearch[]> | null;
  setRecentSearchResults: Dispatch<SetStateAction<Record<string, RecentSearch[]> | null>>;
};

export const [RecentSearchContext, useRecentSearchResults] = buildContext<RecentSearchResults>('RecentSearchContext');

type RecentSearchProviderProps = {
  children: ReactNode;
};

export const RecentSearchProvider: FC<RecentSearchProviderProps> = memo(({ children }) => {
  const [recentSearchResults, setRecentSearchResults] = useState<RecentSearchResults['recentSearchResults']>(null);

  const value = useMemo(() => {
    return {
      recentSearchResults,
      setRecentSearchResults,
    };
  }, [recentSearchResults]);

  return <RecentSearchContext.Provider value={value}>{children}</RecentSearchContext.Provider>;
});

RecentSearchProvider.displayName = 'RecentSearchProvider';
