import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useVerifyPhoneMutation } from './__generated__/useVerifyPhoneMutation.graphql';

const createUseVerifyPhone = createUseMutation<useVerifyPhoneMutation>(graphql`
  mutation useVerifyPhoneMutation($input: VerifyPhoneNumberInput!) {
    verifyPhoneNumber(input: $input) {
      __typename
      ... on VerifyPhoneNumberSuccess {
        success
        player {
          phoneNumber
          subscribeNewsSms
          subscribeOffers
        }
      }
      ... on VerificationError {
        error
      }
    }
  }
`);

export const useVerifyPhone = createUseVerifyPhone((result) => {
  return result.verifyPhoneNumber;
});
