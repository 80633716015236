import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { getCurrencyCode } from '@pafcloud/locale';

import { depositFormConfig as depositFormConfig_pml } from './DepositFormConfig.pml';
import { depositFormConfig as depositFormConfig_default } from './DepositFormConfig.default';

type DepositFormCurrencyConfig = {
  minAmount: number;
  maxAmount: number;
  minDepositButtonValue: number;
  maxDepositButtonValue: number;
  quickButtonValues: number[];
};

export const getDepositFormConfig = (): DepositFormCurrencyConfig => {
  const currencyCode = getCurrencyCode();
  if (
    $buildEnv.site === 'noaccountcasino.se' ||
    $buildEnv.site === 'noaccountbet.se' ||
    $buildEnv.site === 'prankcasino.se'
  ) {
    return depositFormConfig_pml[currencyCode];
  }
  return depositFormConfig_default[currencyCode];
};
