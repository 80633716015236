import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Color } from '@pafcloud/style';

const lineAnimation = keyframes({
  '0%': {
    transform: 'translateX(-100%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});

export const LoadingLine = styled.div({
  width: '100%',
  height: 2,
  marginBottom: -2,
  background: `linear-gradient(to right, ${Color.Primitive.Primary}00, ${Color.Primitive.Primary}, ${Color.Primitive.Primary}00)`,
  willChange: 'transform, opacity',
  animation: `${lineAnimation} 2s linear 0s infinite`,
});
