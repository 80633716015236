import type { FieldPath, FieldValues, UseFormRegister, Validate } from 'react-hook-form';
import { withBuildEnv } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { HookFormInputProps } from '../hook-form-inputs/HookFormInput';
import { PhoneInput as PhoneInput_default } from './PhoneInput.default';
import { PhoneInput as PhoneInput_alternate } from './PhoneInput.alternate';

export type PhoneInputProps<TValues extends FieldValues> = Omit<HookFormInputProps, 'type'> & {
  name: FieldPath<TValues>;
  register: UseFormRegister<TValues>;
  validate?: Validate<keyof TValues> | Record<string, Validate<keyof TValues>>;
  initialPrefix?: string;
  showPrefixDropdown?: boolean;
  marketAgnostic?: true;
};

export const PhoneInput = withBuildEnv(() => {
  if ($buildEnv.market === 'spain' || $buildEnv.market === 'switzerland') {
    return PhoneInput_alternate;
  }

  return PhoneInput_default;
});
