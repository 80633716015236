import { Color } from '@pafcloud/style';

export const Colors = {
  Border: Color.Surface.Floating.Dimmed,

  Focus: Color.Primitive.Primary,

  ProgressFill: Color.Primitive.Accent,
  ProgressBorder: Color.Surface.Floating.Dimmed,
  ProgressBackground: Color.Surface.Floating.Background,
  ProgressIcon: Color.Primitive.AccentContrast,
};
