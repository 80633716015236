import { safeParseJson } from '@pafcloud/util';
import type { Cookie } from '../cookie';

export type CookieConsentType = 'essential' | 'tracking' | 'marketing';
export type CookieConsent = Record<CookieConsentType, boolean>;

export const parseCookieConsent = (cookie: string | undefined) => {
  const cookieConsent = safeParseJson<CookieConsent>(cookie);
  return cookieConsent ?? null;
};

export const hasRequiredConsentForCookie = (cookieConsent: CookieConsent | null, cookie: Cookie) => {
  // Essential cookies are always allowed to be set
  if (cookie.requiredConsent === 'essential') {
    return true;
  }
  return cookieConsent?.[cookie.requiredConsent];
};
