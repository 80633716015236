import { Color } from '@pafcloud/style';

import type { ButtonSize, ButtonVariant } from '../ButtonProps';

export const getShadowAttributes = (props: ButtonSize & ButtonVariant) => {
  if (props.variant === 'secondary') {
    return undefined;
  }

  if (props.size === 'hero') {
    return {
      '--shadow-color': 'var(--button-shadow-tint)',
      boxShadow: Color.Elevation.Level3,
    };
  }

  return {
    boxShadow: Color.Elevation.Level1,
  };
};
