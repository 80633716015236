import styled from '@emotion/styled';
import { Color, Font, FontTextSize, getBrandBorderStyle, ZIndex } from '@pafcloud/style';
import { ModalBackgroundOverlay } from '../../modal';
import { NoStyleButton } from '../../button';

// Search input reset style
export const SearchInput = styled.input({
  appearance: 'none',
  flexBasis: '100%',
  minWidth: 0,
  padding: '16px 24px',
  border: 0,
  background: 'transparent',
  outline: 'none',
  color: 'currentColor',
  fontSize: FontTextSize.Big,
  fontFamily: Font.Button,

  '::placeholder': {
    color: `var(--search-input-placeholder-text, ${Color.TextColors.MutedText})`,
  },

  ':focus': {
    '&::placeholder': {
      opacity: 0.4,
    },
  },

  [`::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration`]: {
    WebkitAppearance: 'none',
  },
});

export const ResetButton = styled(NoStyleButton)({
  display: 'flex',
  cursor: 'pointer',
  lineHeight: 1,
  WebkitTapHighlightColor: 'transparent',

  ':focus, :hover': {
    outline: 'none',
  },
});

// Basic field shown in page content
export const DefaultSearchFieldWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  paddingRight: 24,
  boxShadow: Color.Elevation.Level1,
  borderRadius: Color.CornerRadius.Large,
  background: Color.Surface.Base.Background,
  color: Color.TextColors.HighlightedText,
  border: `1px solid ${Color.Surface.Base.Dimmed}`,

  ':focus-within': {
    boxShadow: Color.Elevation.Level2,
  },
});

export const DropdownContent = styled.div({
  display: 'none',

  position: 'absolute',
  zIndex: ZIndex.SearchContainer,
  top: 'calc(var(--scroll-offset) + 24px)',
  width: '100%',
  minWidth: 320,
  maxHeight: '50vh',
  marginTop: 8,

  overflowX: 'hidden',
  overflowY: 'auto',
  overscrollBehavior: 'contain',
  WebkitOverflowScrolling: 'touch',

  borderRadius: Color.CornerRadius.Base,
  boxShadow: Color.Elevation.Level3,
  background: Color.Surface.Base.Background,
});

export const Dropdown = styled.div({
  position: 'relative',

  [`&:focus-within ${DropdownContent}`]: {
    display: 'flex',
  },
});

// Fullscreen search
export const SearchBackgroundOverlay = styled(ModalBackgroundOverlay)({
  zIndex: ZIndex.SearchContainer,
  background: Color.Surface.Floating.Background,
});

export const SearchHeader = styled.header({
  position: 'sticky',
  top: 0,
  zIndex: Number(ZIndex.SearchContainer) + 1,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '6px 24px 0 16px',
  background: Color.Surface.Floating.Background,
  color: Color.TextColors.HighlightedText,
  borderBottom: `1px solid ${Color.Surface.Floating.Dimmed}`,

  '::before': {
    ...getBrandBorderStyle(),
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },

  [`${SearchInput}`]: {
    minHeight: 64,
  },
});

export const SearchContent = styled.aside({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  overscrollBehaviorY: 'contain',

  display: 'flex',
  flexDirection: 'column',
});
