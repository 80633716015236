import { graphql } from 'relay-runtime';
import { useCallback } from 'react';
import { useDebouncedMutation } from '@pafcloud/relay-helpers';
import { useIsLoggedIn } from '@pafcloud/contexts';
import type { useKeepAliveMutation } from './__generated__/useKeepAliveMutation.graphql';

const mutation = graphql`
  mutation useKeepAliveMutation {
    keepAlive {
      success
    }
  }
`;

export const useKeepAlive = () => {
  const isLoggedIn = useIsLoggedIn();
  const keepAliveMutation = useDebouncedMutation<useKeepAliveMutation>(mutation, 500);

  return useCallback(async () => {
    if (!isLoggedIn) {
      return false;
    }

    const { keepAlive } = await keepAliveMutation();
    return keepAlive.success;
  }, [isLoggedIn, keepAliveMutation]);
};
