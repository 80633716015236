import type { FieldValues, UseFormRegister, Path } from 'react-hook-form';
import { useTranslation } from '@pafcloud/i18n';
import type { HookFormInputProps } from '../hook-form-inputs';
import { HookFormInput } from '../hook-form-inputs';
import { useValidateDateOfBirth } from './useValidateDateOfBirth';

const FORMAT_REGEX = /^(\d{1,2})(\d{0,2})(\d{0,4})$/;

type Props<TValues extends FieldValues> = HookFormInputProps & {
  name: Path<TValues>;
  register: UseFormRegister<TValues>;
};

export function DateOfBirthInput<T extends FieldValues>({ name, register, ...props }: Props<T>) {
  const { t } = useTranslation('form');
  const validateDateOfBirth = useValidateDateOfBirth();

  return (
    <HookFormInput
      {...props}
      type="text"
      inputMode="numeric"
      info={t('date-of-birth-input.hint')}
      label={t('date-of-birth-input.label')}
      maxLength={10}
      {...register(name, {
        validate: validateDateOfBirth,
        required: props['aria-required'] && t('date-of-birth-input.required-hint'),
        onChange: (e) => {
          const cleaned = e.target.value.replace(/\D/g, '');
          const split = cleaned
            .split(FORMAT_REGEX)
            .slice(1)
            .filter((el: string | undefined) => el !== undefined && el !== '');
          const formatted = split.join('-');
          if (['.', '-', '/'].includes(e.target.value.slice(-1)) && [2, 5].includes(formatted.length)) {
            e.target.value = formatted + '-';
            return;
          }
          e.target.value = formatted;
        },
      })}
    />
  );
}
