export const depositFormConfig = {
  SEK: {
    minAmount: 100,
    maxAmount: 5000, // No need to allow this to be higher as the SGA weekly limit is 5000
    minDepositButtonValue: 250,
    maxDepositButtonValue: 5000,
    quickButtonValues: [250, 500, 1000],
  },
  EUR: {
    minAmount: 10,
    maxAmount: 50000,
    minDepositButtonValue: 25,
    maxDepositButtonValue: 500,
    quickButtonValues: [25, 50, 100],
  },
  CHF: {
    minAmount: 10,
    maxAmount: 50000,
    minDepositButtonValue: 25,
    maxDepositButtonValue: 500,
    quickButtonValues: [25, 50, 100],
  },
};
