import type { RefObject } from 'react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { RecentSearchResultChildren as RecentSearchResultChildren_default } from './RecentSearchResultChildren.default';
import { RecentSearchResultChildren as RecentSearchResultChildren_alternate } from './RecentSearchResultChildren.alternate';
import type { RecentSearch } from './useRecentSearch';

export type RecentSearchResultChildrenProps = {
  gameListId: string;
  search: RecentSearch;
  listRef: RefObject<HTMLOListElement>;
  onClickResult?: () => void;
};

export const RecentSearchResultChildren = withBuildEnv(() => {
  if ($buildEnv.theme === 'goldenV2') {
    return RecentSearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return RecentSearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'dreams') {
    return RecentSearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'momentum') {
    return RecentSearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return RecentSearchResultChildren_alternate;
  }

  return RecentSearchResultChildren_default;
});
