import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { FontTextSize } from '@pafcloud/style';

import { useFlowRouter } from '@pafcloud/flow-router';
import { MessageListItem } from '../message-list-item';
import { InboxColors } from '../inbox-colors';
import type { MessageList_list$key } from './__generated__/MessageList_list.graphql';

const listFragment = graphql`
  fragment MessageList_list on InboxMessagePreviews {
    items {
      messageId
      ...MessageListItem_item
    }
  }
`;

const NoResult = styled.div({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 14,
  fontSize: FontTextSize.Small,
  fontWeight: 'normal',
  color: InboxColors.Text,
});

type Props = {
  list: MessageList_list$key;
  unread?: boolean;
};

export const MessageList: FC<Props> = ({ unread, ...props }) => {
  const list = useFragment(listFragment, props.list);
  const { t } = useTranslation('inbox');
  const { openFlow } = useFlowRouter();

  if (list.items.length === 0) {
    return <NoResult>{unread ? t('no-unread-messages') : t('no-messages')}</NoResult>;
  }

  return (
    <>
      {list.items.map((item) => {
        if (item == null) {
          return null;
        }
        return (
          <MessageListItem
            key={item.messageId}
            item={item}
            onClick={(messageId) => openFlow('message', { messageId })}
          />
        );
      })}
    </>
  );
};
