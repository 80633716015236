export enum FormInputProperties {
  BackgroundColor = '--field-background-color',
  BorderWidth = '--field-border-width',
  BorderColor = '--field-border-color',
  LabelColor = '--field-label-color',
  LabelIconSize = '--field-label-icon-size',
  LabelWeight = '--field-label-weight',
  LabelWidth = '--field-label-width',
  TextColor = '--field-text-color',
  TextIndent = '--field-text-indent',
}
