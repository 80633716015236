import type { CSSObject } from '@emotion/react';
import { pxToRem } from '@pafcloud/style';
import type { ButtonSize, ButtonVariant } from '../ButtonProps';

export const getSizeAttributes = (props: ButtonSize & ButtonVariant) => {
  const width = props.fullWidth ? '100%' : 'unset';

  const heroButtonSize: CSSObject = {
    width,
    minHeight: pxToRem(56),
    padding: `${pxToRem(0)} ${pxToRem(24)}`,

    fontSize: 'var(--button-hero-font-size)',
    textTransform: 'uppercase',
  };

  const largeButtonSize: CSSObject = {
    width,
    minHeight: pxToRem(56),
    padding: `${pxToRem(4)} ${pxToRem(24)}`,
    fontSize: 'var(--button-large-font-size)',
  };

  const defaultButtonSize: CSSObject = {
    width,
    minHeight: pxToRem(40),
    padding: `${pxToRem(4)} ${pxToRem(16)}`,
    fontSize: 'var(--button-default-font-size)',
  };

  const smallButtonSize: CSSObject = {
    width,
    minHeight: pxToRem(32),
    padding: `${pxToRem(4)} ${pxToRem(12)}`,
    borderWidth: 1,
    fontSize: 'var(--button-small-font-size)',
  };

  switch (props.size) {
    case 'hero':
      return heroButtonSize;
    case 'large':
      return largeButtonSize;
    case 'default':
      return defaultButtonSize;
    case 'small':
      return smallButtonSize;
    default:
      return defaultButtonSize;
  }
};
