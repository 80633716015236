import { useRouter } from 'next/router';
import type { MutableRefObject } from 'react';
import { useEffect } from 'react';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import type { TrackedGame, TrackedGames } from './gameTrackingContext';
import type { ImpressionGame } from './utils';
import { impressionsToTrackedGames, mapTrackedGameToImpressionGame } from './utils';

const TRACK_BREAKPOINT_SECONDS = 0.5;
const TRACK_BREAKPOINT_MILLISECONDS = TRACK_BREAKPOINT_SECONDS * 1000;
const SEND_IMPRESSIONS_INTERVAL = 1 * 60 * 1000;

const isViewed = (viewedGame: TrackedGame) => {
  const currentTime = performance.now();
  const { lastViewStarted, totalTimeSeen = 0 } = viewedGame;
  const lastTimeSpan = lastViewStarted ? currentTime - lastViewStarted : 0;
  const totalTime = totalTimeSeen + lastTimeSpan;

  return totalTime >= TRACK_BREAKPOINT_MILLISECONDS;
};

export const useSendImpressions = (viewedGames: MutableRefObject<TrackedGames>) => {
  const { events } = useRouter();

  // Send game impressions on route to GTM and at interval
  useEffect(() => {
    const collectGameImpressions = () => {
      const impressionGames = Object.values(viewedGames.current).reduce<ImpressionGame[]>((impressions, viewedGame) => {
        if (viewedGame === undefined || viewedGame.reported) {
          return impressions;
        }

        if (isViewed(viewedGame)) {
          impressions.push(mapTrackedGameToImpressionGame(viewedGame));
        }

        return impressions;
      }, []);

      return impressionGames;
    };

    const sendTrackingEvents = (clear: boolean) => {
      const impressionGames = collectGameImpressions();

      if (impressionGames.length > 0) {
        dispatchTrackingEvent('gameList-impression', {
          gameList: {
            'impression.games': impressionGames,
          },
        });

        if (clear) {
          viewedGames.current = {};
        } else {
          const reportedGameElements = impressionsToTrackedGames(impressionGames, true);
          viewedGames.current = reportedGameElements;
        }
      }
    };

    const sendAndClearTrackingEvents = () => {
      sendTrackingEvents(true);
    };

    const intervalID = setInterval(() => sendTrackingEvents(false), SEND_IMPRESSIONS_INTERVAL);

    events.on('routeChangeStart', sendAndClearTrackingEvents);
    return () => {
      events.off('routeChangeStart', sendAndClearTrackingEvents);
      clearInterval(intervalID);
    };
  }, [events, viewedGames]);
};
