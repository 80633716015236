import type { ChangeEvent, FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { ActionList } from '@pafcloud/base-components';
import { FormInput } from '../form-input';
import type { FormInputProps } from '../form-input';
import { DropdownContent, DropDownIcon, DropDownListContainer, Wrapper } from './shared';

export type EditableDropdownOption = {
  value: unknown;
  label: string;
};

export type EditableDropDownSelectProps = Omit<FormInputProps, 'onChange' | 'type' | 'as'> & {
  name: string;
  openOnFocus?: boolean;
  options: EditableDropdownOption[];
  onChange: (value?: unknown) => void;
  onQueryChange?: (value: string) => void;
};

const InputWrapper: FC<{ openOnFocus?: boolean; children: ReactNode }> = ({ openOnFocus, children }) => {
  if (openOnFocus) {
    return <Combobox.Button as="span">{children}</Combobox.Button>;
  }

  return <span>{children}</span>;
};

export const EditableDropDownSelect: FC<EditableDropDownSelectProps> = ({
  name,
  disabled,
  value,
  defaultValue,
  children,
  options,
  onChange,
  onQueryChange,
  openOnFocus,
  ...props
}) => {
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(() => {
    if (query === '') {
      return options;
    }
    const foundOption = options.find((option) => option.label.toLowerCase() === query.toLowerCase());
    if (foundOption) {
      const index = options.indexOf(foundOption);
      return [foundOption, ...options.slice(0, index), ...options.slice(index + 1)];
    }

    return options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));
  }, [query, options]);

  const getDisplayValue = (selectedValue: unknown) => {
    if (selectedValue == null) {
      return query;
    }

    const selectedData = JSON.stringify(selectedValue);
    const matchingOption = filteredOptions.find((option) => JSON.stringify(option.value) === selectedData);

    return matchingOption?.label ?? query;
  };

  const updateSelection = (newQuery: string) => {
    const match = filteredOptions.find((option) => option.label.toLowerCase() === newQuery.toLowerCase());
    if (match) {
      onChange(match.value);
    }
  };

  return (
    <Combobox as={Wrapper} disabled={disabled} value={value} defaultValue={defaultValue} onChange={onChange}>
      {(comboboxProps) => (
        <>
          <InputWrapper openOnFocus={openOnFocus}>
            <Combobox.Input
              as={FormInput}
              {...props}
              displayValue={getDisplayValue}
              autoComplete="off"
              ref={null}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const newQuery = event.target.value;
                setQuery(newQuery);
                onQueryChange?.(newQuery);
                updateSelection(newQuery);
              }}
            >
              {children}
              {openOnFocus && <DropDownIcon name="chevronRight" />}
            </Combobox.Input>
          </InputWrapper>

          {comboboxProps.open && filteredOptions.length > 0 && (
            <DropDownListContainer>
              <DropdownContent>
                <Combobox.Options as={ActionList}>
                  {filteredOptions.map((option) => (
                    <Combobox.Option key={option.label} value={option.value}>
                      {option.label}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </DropdownContent>
            </DropDownListContainer>
          )}
        </>
      )}
    </Combobox>
  );
};
