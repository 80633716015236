/**
 * @generated SignedSource<<5088adf1c2a0378ee57128a869b951c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackGame_gameList$data = {
  readonly gameListId: string;
  readonly internalName: string;
  readonly " $fragmentType": "useTrackGame_gameList";
};
export type useTrackGame_gameList$key = {
  readonly " $data"?: useTrackGame_gameList$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_gameList">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTrackGame_gameList"
};

(node as any).hash = "5b92ab99750daf741564b1b3832b913a";

export default node;
