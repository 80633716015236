import type { FC } from 'react';
import styled from '@emotion/styled';
import type { Icon as IconNames } from '@pafcloud/icon';
import { Icon } from '../icon';

export const Details = styled.details({});

export const Summary = styled.summary({
  listStyle: 'none',
  cursor: 'pointer',

  '::marker': {
    content: '""',
  },

  '&::-webkit-details-marker': {
    display: 'none',
  },
});

const ToggleIcon = styled(Icon)({
  transform: 'rotate(90deg)',

  '[open] &': {
    transform: 'rotate(-90deg)',
  },
});

type ExpandIconProps = {
  className?: string;
  name?: IconNames;
};

export const SummaryIcon: FC<ExpandIconProps> = ({ className, name = 'chevronRightFat' }) => {
  return <ToggleIcon name={name} size="1em" className={className} />;
};

export const Disclosure = Object.assign(Details, {
  Summary,
  SummaryIcon,
});
