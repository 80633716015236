import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useHandler } from '@pafcloud/react-hook-utils';
import type { FlowParams, FlowType } from './flow';
import { removeFlowFromPath, createFlowUrl } from './flow';

const shallowRouterPush = (router: NextRouter, url: string) => {
  return router.push(url, undefined, { shallow: true, scroll: false });
};

export const useFlowRouter = () => {
  const router = useRouter();

  const getFlowUrlAtPath = useHandler(<T extends FlowType>(flow: T, path: string, params?: FlowParams<T>) => {
    return createFlowUrl<T>(flow, path, params);
  });

  const getFlowUrl = useCallback(
    <T extends FlowType>(flow: T, params?: FlowParams<T>) => {
      return getFlowUrlAtPath(flow, router.asPath, params);
    },
    [getFlowUrlAtPath, router.asPath],
  );

  const openFlow = useHandler(async <T extends FlowType>(flow: T, params?: FlowParams<T>) => {
    await shallowRouterPush(router, getFlowUrl(flow, params));
  });

  const openFlowAtPath = useHandler(async <T extends FlowType>(flow: T, path: string, params?: FlowParams<T>) => {
    await shallowRouterPush(router, getFlowUrlAtPath(flow, path, params));
  });

  const closeFlow = useHandler(async () => {
    await shallowRouterPush(router, removeFlowFromPath(router.asPath));
  });

  return {
    getFlowUrl,
    getFlowUrlAtPath,
    closeFlow,
    openFlow,
    openFlowAtPath,
  };
};
