import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { deviceWithHover } from '@pafcloud/style';

export const getVariableStyles = () => ({
  color: 'var(--button-text)',
  background: 'var(--button-background)',
  border: 'solid 2px var(--button-border)',
  backgroundPosition: '-2px -2px',
  backgroundSize: 'calc(100% + 4px) calc(100% + 4px)',
  transition: $buildEnv.theme === 'dreams' ? 'background-size 0.21s' : undefined,
  outline: 'none',
  outlineColor: 'transparent',

  ':hover': {
    color: 'var(--button-text--hover)',
    background: 'var(--button-background--hover)',
    borderColor: 'var(--button-border--hover)',
    backgroundPosition: '-2px -2px',
    backgroundSize: 'calc(100% + 4px) calc(100% + 8px)',
  },
  ':active': {
    color: 'var(--button-text--active)',
    background: 'var(--button-background--active)',
    borderColor: 'var(--button-border--active)',
    backgroundPosition: '-2px -4px',
    backgroundSize: 'calc(100% + 8px) calc(100% + 8px)',
  },
  [deviceWithHover]: {
    ':focus-visible': {
      outline: '2px solid var(--button-focus-ring)',
      outlineOffset: 1,
    },
  },
  ':focus:active': {
    boxShadow: 'none',
  },
});
