import type { FC } from 'react';
import { useId, useEffect, useState } from 'react';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { QueryRenderer } from 'react-relay/legacy';
import { graphql, useRelayEnvironment } from 'react-relay/hooks';
import {
  Button,
  ButtonLink,
  Heading,
  ModalBackgroundOverlay,
  ModalContent,
  renderIntoRootContainer,
} from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';
import { cookies, COUNTRY_REDIRECT } from '@pafcloud/cookies';
import { useAffiliateParameter } from '@pafcloud/react-hook-utils';
import { useCookieConsent } from '@pafcloud/contexts';
import type { CountryRedirectQuery, CountryRedirectQuery$data } from './__generated__/CountryRedirectQuery.graphql';

const Popup = styled(ModalContent)({
  margin: 16,
  marginTop: 32,
  padding: 16,
});

const PopupContent = styled.div({
  padding: '8px 0',
  textAlign: 'center',
});

const Header = styled(Heading)({
  margin: '0 0 16px',
  fontSize: FontTextSize.Big,
  fontWeight: 700,
  textAlign: 'center',
});

const Paragraph = styled.p({
  marginTop: 0,
  marginBottom: 32,
  fontSize: FontTextSize.Normal,
});

const ButtonWrapper = styled.div({
  display: 'grid',
  gridAutoColumns: 'auto',
  gap: 8,
});

const RedirectButtonLink = styled(ButtonLink)({
  fontWeight: 700,
});

const StayButton = styled(Button)({
  fontWeight: 700,
});

const countryRedirectQuery = graphql`
  query CountryRedirectQuery {
    countryRedirect @required(action: NONE) {
      link
      title
      description
      redirectButton
      stayButton
    }
  }
`;

type CountryRedirectPopupProps = {
  onRedirectRejected: () => void;
  data: NonNullable<CountryRedirectQuery$data>['countryRedirect'];
};

const CountryRedirectPopup: FC<CountryRedirectPopupProps> = ({ data, onRedirectRejected }) => {
  const affiliateParameter = useAffiliateParameter();
  const headerId = useId();
  const paragraphId = useId();

  const redirectUrl = new URL(data.link);
  if (affiliateParameter != null) {
    redirectUrl.searchParams.set(affiliateParameter.parameter, affiliateParameter.affiliateId);
  }

  const hasDescription = data.description.length > 0;

  return (
    <Popup
      role="alertdialog"
      aria-labelledby={headerId}
      aria-describedby={hasDescription ? paragraphId : undefined}
      aria-modal="true"
    >
      <PopupContent>
        <Global styles={{ body: { overflow: 'hidden !important' } }} />
        <Header id={headerId}>{data.title}</Header>
        {hasDescription && <Paragraph id={paragraphId}>{data.description}</Paragraph>}
        <ButtonWrapper>
          <RedirectButtonLink size="default" href={redirectUrl.toString()}>
            {data.redirectButton}
          </RedirectButtonLink>
          <StayButton type="button" size="default" variant="secondary" onClick={onRedirectRejected}>
            {data.stayButton}
          </StayButton>
        </ButtonWrapper>
      </PopupContent>
    </Popup>
  );
};

export const CountryRedirect: FC = () => {
  const environment = useRelayEnvironment();
  const cookieConsent = useCookieConsent();
  const [hasRejectedRedirect, setRejectedRedirect] = useState(true);

  useEffect(() => {
    setRejectedRedirect(!!cookies.get(COUNTRY_REDIRECT));
  }, []);

  if (cookieConsent == null || !cookieConsent.essential || hasRejectedRedirect) {
    return null;
  }

  return renderIntoRootContainer(
    <QueryRenderer<CountryRedirectQuery>
      query={countryRedirectQuery}
      environment={environment}
      variables={{}}
      fetchPolicy="store-or-network"
      render={({ props }) => {
        if (props == null) {
          return null;
        }

        const onRedirectRejected = () => {
          cookies.set(COUNTRY_REDIRECT, 'false');
          setRejectedRedirect(true);
        };

        return (
          <ModalBackgroundOverlay>
            <CountryRedirectPopup onRedirectRejected={onRedirectRejected} data={props.countryRedirect} />
          </ModalBackgroundOverlay>
        );
      }}
    />,
  );
};
