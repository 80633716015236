const DEFAULT_QUALITY = 85;
const DEFAULT_BLUR = 0; // No blur by default
const IMAGE_FORMAT = 'webp'; // https://developers.cloudflare.com/images/image-resizing/url-format/#format

const normalizeSrc = (src: string) => {
  if (typeof src === 'undefined') {
    console.error('image src is undefined');
    return '';
  }
  return src.startsWith('/') ? src.slice(1) : src;
};

// Handle query parameters on external sources
const getImageSrc = (src: string) => {
  if (src.startsWith('https://')) {
    const imageSource = new URL(src);
    // Make sure we get heights quality before Cloudflare compression
    imageSource.searchParams.set('q', '100');

    return imageSource.href;
  }

  return src;
};

type ImageLoaderProps = {
  src: string;
  width: number;
  quality?: number;
  blur?: number;
};

/**
 * Custom image loader that goes against cloudflare image resizer
 * @link https://developers.cloudflare.com/images/image-resizing/
 */
export const imageLoader = ({ src, width, quality = DEFAULT_QUALITY, blur = DEFAULT_BLUR }: ImageLoaderProps) => {
  const imageSource = getImageSrc(src);
  const params = `width=${width},quality=${quality},blur=${blur},format=${IMAGE_FORMAT}`;

  if (process.env.PLAIN_IMAGE_URL) {
    // For Storybook, we can't use cloudflare image resizer
    return normalizeSrc(src);
  }

  if (process.env.NODE_ENV === 'development' && imageSource.includes('://')) {
    // See issue https://github.com/vercel/next.js/issues/53438
    return `/cdn-cgi/image/${params}/${encodeURIComponent(normalizeSrc(imageSource))}`;
  }

  return `/cdn-cgi/image/${params}/${normalizeSrc(imageSource)}`;
};
