import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_classic_red } from './colors.classic-red';
import { Colors as Colors_golden } from './colors.golden';
import { Colors as Colors_goldenV2 } from './colors.goldenV2';
import { Colors as Colors_dreams } from './colors.dreams';
import { Colors as Colors_momentum_neon } from './colors.momentum-neon';

export const ToggleSwitchColors = (() => {
  if ($buildEnv.theme === 'classic-red') {
    return Colors_classic_red;
  }
  if ($buildEnv.theme === 'golden') {
    return Colors_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Colors_goldenV2;
  }
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Colors_momentum_neon;
  }

  return Colors_default;
})();
