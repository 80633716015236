import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { buildContext } from '../buildContext';
import type { PlayerContext_data$key } from './__generated__/PlayerContext_data.graphql';
import type { PlayerWallet } from './types';
import type { PlayerStatus } from './types';

const dataFragment = graphql`
  fragment PlayerContext_data on Query {
    player @required(action: NONE) {
      wallet {
        balance
        bonus
        cash
        currentTurnover
        totalTurnover
      }
    }
  }
`;

export const [PlayerStatusContext, usePlayerStatus] = buildContext<PlayerStatus>('PlayerStatusContext');
const [WalletContext, useWallet] = buildContext<PlayerWallet>('PlayerWalletContext');

export { useWallet };

export const useIsLoggedIn = () => {
  return usePlayerStatus() === 'LOGGED_IN';
};

type PlayerProviderProps = {
  data: PlayerContext_data$key | null;
  isLoadingClientData?: boolean;
  children: ReactNode;
};

export const PlayerProvider: FC<PlayerProviderProps> = ({ children, isLoadingClientData, ...props }) => {
  const data = useFragment(dataFragment, props.data);
  const status = useMemo((): PlayerStatus => {
    if (isLoadingClientData) {
      return 'LOADING';
    }

    if (data == null) {
      return 'LOGGED_OUT';
    }

    return 'LOGGED_IN';
  }, [isLoadingClientData, data]);

  const wallet = useMemo(() => {
    if (isLoadingClientData || data == null || data.player.wallet == null) {
      return {
        balance: null,
        currency: null,
        bonus: null,
        cash: null,
        currentTurnover: null,
        totalTurnover: null,
      };
    }

    return data.player.wallet;
  }, [isLoadingClientData, data]);

  return (
    <PlayerStatusContext.Provider value={status}>
      <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
    </PlayerStatusContext.Provider>
  );
};
