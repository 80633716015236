import Cookies from 'js-cookie';
import { getClientConfig } from '@pafcloud/config';
import { COOKIE_CONSENT } from '../applicationCookies';
import type { Cookie } from '../cookie';
import { defaultCookieOptions } from '../cookie';
import type { CookieConsent } from './consent';
import { hasRequiredConsentForCookie, parseCookieConsent } from './consent';

const config = getClientConfig();

export const cookieDomain =
  process.env.NODE_ENV === 'production' //
    ? config.domain
    : 'localhost';

const getCookieFromDocument = (cookie: Cookie) => {
  return Cookies.get(cookie.name);
};

const getCookieConsentFromDocument = () => {
  return parseCookieConsent(getCookieFromDocument(COOKIE_CONSENT));
};

const setCookieInDocument = (cookie: Cookie, value: string | object) => {
  const cookieConsent = getCookieConsentFromDocument();
  if (hasRequiredConsentForCookie(cookieConsent, cookie)) {
    const { name, ...options } = cookie;
    Cookies.set(name, value, { ...defaultCookieOptions, ...options });
  }
};

const removeCookieFromDocument = (cookie: Cookie) => {
  // Since removing a cookie is actually setting it with an expiration date in the past
  // we need to check for consent even when removing.
  const cookieConsent = getCookieConsentFromDocument();
  if (hasRequiredConsentForCookie(cookieConsent, cookie)) {
    Cookies.remove(cookie.name);
  }
};

const setCookieConsentInDocument = (cookieConsent: CookieConsent, domain = cookieDomain) => {
  setCookieInDocument({ ...COOKIE_CONSENT, domain }, cookieConsent);
};

export const cookies = {
  get: getCookieFromDocument,
  set: setCookieInDocument,
  remove: removeCookieFromDocument,
  getConsent: getCookieConsentFromDocument,
  setConsent: setCookieConsentInDocument,
};
