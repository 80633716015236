import type { Localized } from '../locale';

export const LanguageMap: Localized<string> = {
  en: 'en_CH',
  fr: 'fr_CH',
  de: 'de_CH',
  it: 'it_CH',

  // Not used
  et: '',
  sv: '',
  fi: '',
  lv: '',
  ru: '',
  es: '',
};
