import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDebounce } from '@pafcloud/react-hook-utils';
import type { SearchResponse } from './fetchSearchResults';
import { fetchSearchResults } from './fetchSearchResults';

export const useSearch = (gameListId: string) => {
  const { locale } = useRouter();
  const [response, setResponse] = useState<SearchResponse>({ items: [], total: 0 });
  const [isSearching, setIsSearching] = useState(false);
  const [value, setValue] = useState('');

  const fetchResults = async (input?: string) => {
    setIsSearching(true);
    try {
      setResponse(await fetchSearchResults(input, locale, gameListId));
    } finally {
      setIsSearching(false);
    }
  };

  const { isDebouncing, debouncedFn, cancel } = useDebounce(fetchResults, {
    wait: 500,
  });

  const isLoading = isDebouncing || isSearching;

  const search = (input: string) => {
    setValue(input);
    debouncedFn(input);
  };

  const reset = () => {
    search('');
  };

  useEffect(() => cancel, [cancel]);

  return { reset, search, isLoading, response, value };
};
