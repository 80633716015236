import type { FC, HTMLAttributes, ReactNode } from 'react';
import { createContext } from 'react';
import styled from '@emotion/styled';

// This is needed to serve as a default heading that we can return below.
const DefaultHeading = styled.h2({});

const Level = createContext<number>(2);

type HeadingProps = HTMLAttributes<HTMLHeadingElement>;

export const Heading: FC<HeadingProps> = ({ children, ...props }) => (
  <Level.Consumer>
    {(level) => {
      const as = `h${Math.min(level, 6)}` as `h${1 | 2 | 3 | 4 | 5 | 6}`;

      return (
        <DefaultHeading as={as} {...props}>
          {children}
        </DefaultHeading>
      );
    }}
  </Level.Consumer>
);

type SectionProps = {
  children: ReactNode;
};

export const Section: FC<SectionProps> = ({ children }) => (
  <Level.Consumer>{(level) => <Level.Provider value={level + 1}>{children}</Level.Provider>}</Level.Consumer>
);
