import type { Market } from '@pafcloud/config';

export const phoneNumberPrefixes = {
  denmark: 45,
  estonia: 372,
  faroeIslands: 298,
  finland: 358,
  iceland: 354,
  greenland: 299,
  latvia: 371,
  lithuania: 370,
  norway: 47,
  spain: 34,
  sweden: 46,
  switzerland: 41,
} as const;

export const getPhoneNumberPrefix = (market: Market) => {
  return phoneNumberPrefixes[market];
};
