/**
 * @generated SignedSource<<a0da6a12ae12d7d9446c6ba146a94ee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderContentDefault_content$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProfileMenu_data">;
  readonly " $fragmentType": "HeaderContentDefault_content";
};
export type HeaderContentDefault_content$key = {
  readonly " $data"?: HeaderContentDefault_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentDefault_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContentDefault_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileMenu_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0a62b69248052e7e14b6a03c903b7ae1";

export default node;
