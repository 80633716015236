import type { ReactNode } from 'react';
import { Suspense } from 'react';
import { QueryRenderer } from 'react-relay/legacy';
import { useRelayEnvironment } from 'react-relay/hooks';
import type { GraphQLTaggedNode, FetchPolicy, OperationType } from 'relay-runtime';
import styled from '@emotion/styled';
import { LoadingIndicator } from '@pafcloud/base-components/src/loading';
import { QueryError } from './QueryError';

const QueryLoadingIndicator = styled(LoadingIndicator)({
  display: 'block',
  margin: '32px auto',
});

type Props<T extends OperationType> = {
  query: GraphQLTaggedNode;
  variables?: T['variables'];
  fetchPolicy?: FetchPolicy;
} & (
  | { render: (renderProps: { props: T['response']; retry: (() => void) | null }) => ReactNode }
  | { children: (props: T['response']) => ReactNode }
) & { fallback?: ReactNode };

export const SafeQueryRenderer = <QueryType extends OperationType>({
  query,
  variables = {},
  fallback,
  fetchPolicy,
  ...misc
}: Props<QueryType>) => {
  const environment = useRelayEnvironment();

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={query}
      variables={variables}
      fetchPolicy={fetchPolicy}
      render={({ error, retry, props }) => {
        if (error) {
          return <QueryError error={error} retry={retry} />;
        }

        const Loading = fallback ?? <QueryLoadingIndicator />;

        if (props) {
          // TODO: Select one of these methods to use everywhere.
          if ('render' in misc) {
            return <Suspense fallback={Loading}>{misc.render({ props, retry })}</Suspense>;
          } else {
            return <Suspense fallback={Loading}>{misc.children(props)}</Suspense>;
          }
        }

        return Loading;
      }}
    />
  );
};
