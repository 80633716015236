import type { ComponentPropsWithoutRef, FC } from 'react';
import { useRouter } from 'next/router';
import { ExternalLink } from './ExternalLink';
import { getSupportEmail } from './getSupportEmail';

type Props = Omit<ComponentPropsWithoutRef<typeof ExternalLink>, 'href'>;
export const SupportEmailLink: FC<Props> = ({ children, ...props }) => {
  const { locale } = useRouter();
  const email = getSupportEmail(locale);

  return (
    <ExternalLink {...props} href={`mailto:${email}`}>
      {children ?? email}
    </ExternalLink>
  );
};
