import { Color } from '@pafcloud/style';
import { Colors as Color_default } from './colors.default';

export const Colors = {
  ...Color_default,
  Bullet: Color.Primitive.SecondaryTint,
  BulletDone: Color.Primitive.Secondary,
  TurnoverPercentage: Color.Primitive.Secondary,
  FallbackIconBackground: Color.Primitive.Primary,
  FallbackIcon: Color.Primitive.PrimaryContrast,
  CardBorder: `1px solid ${Color.Primitive.Secondary}40`,
};
