/**
 * @generated SignedSource<<533638f238f2138c1f0c5f2277b7c509>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type BonusOfferStatus = "ACTIVE" | "CANCELLED" | "COMPLETED" | "EXPIRED" | "JOINED" | "QUEUED";
import { FragmentRefs } from "relay-runtime";
export type cashbackReward_offer$data = {
  readonly expires: string;
  readonly status: BonusOfferStatus;
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
  readonly " $fragmentType": "cashbackReward_offer";
};
export type cashbackReward_offer$key = {
  readonly " $data"?: cashbackReward_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"cashbackReward_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "cashbackReward_offer"
};

(node as any).hash = "77060688432012d195cf5d9c55dbbd6e";

export default node;
