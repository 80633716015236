import type { FC } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { Button } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import { matchesMediaQuery, useIsSSR } from '@pafcloud/react-hook-utils';
import { useFlowRouter } from '@pafcloud/flow-router';
import { QuickButtons } from './QuickButtons';
import { getDepositFormConfig } from './DepositFormConfig';
import { DepositToPlayFormInput } from './DepositToPlayFormInput';

const Form = styled.form({
  width: '100%',
});

const SubmitButton = styled(Button)({
  margin: '0 auto',
  marginTop: 25,
  maxWidth: 220,
});

type DepositToPlayFormProps = {
  submitButtonText: string;
  className?: string;
  onHeroClick?: () => void;
};

export type DepositFormInput = {
  amount?: number;
};

export const DepositToPlayForm: FC<DepositToPlayFormProps> = ({ submitButtonText, className, onHeroClick }) => {
  const { openFlow } = useFlowRouter();
  const formConfig = getDepositFormConfig();
  const isSSR = useIsSSR();

  const { handleSubmit, register } = useForm<DepositFormInput>({
    defaultValues: {
      amount: formConfig.minDepositButtonValue,
    },
  });

  const onSubmit = handleSubmit(({ amount }) => {
    if (amount && !matchesMediaQuery(Breakpoint.Phone)) {
      openFlow('deposit', { initialAmount: amount });
    } else {
      openFlow('deposit');
    }
  });

  return (
    <div className={className}>
      <QuickButtons
        onSelect={(amount) => openFlow('deposit', { initialAmount: amount })}
        depositLimit={formConfig.maxDepositButtonValue}
      />
      <Form noValidate onSubmit={onSubmit}>
        <DepositToPlayFormInput register={register} />
        <SubmitButton
          variant="primary"
          colorstyle="accent"
          size="hero"
          type="submit"
          fullWidth
          ctaEffect
          onClick={onHeroClick}
          disabled={isSSR}
        >
          {submitButtonText}
        </SubmitButton>
      </Form>
    </div>
  );
};
