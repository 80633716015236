import styled from '@emotion/styled';
import { Heading } from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';

export const SectionTitle = styled(Heading)({
  display: 'flex',
  justifyContent: 'center',
  margin: '0 0 32px 0',
  fontSize: FontTextSize.Big,
});
