import { useEffect } from 'react';
import { cookies, AFFILIATE } from '@pafcloud/cookies';
import { useAffiliateParameter } from '@pafcloud/react-hook-utils';

/**
 * We must save the affiliate value we get as a parameter and send it to backend during registration.
 */
export const useSetAffiliateCookie = () => {
  const { affiliateId } = useAffiliateParameter() ?? {};

  useEffect(() => {
    if (affiliateId && cookies.get(AFFILIATE) !== affiliateId) {
      cookies.set(AFFILIATE, affiliateId);
    }
  }, [affiliateId]);
};
