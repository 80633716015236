import type { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Breakpoint, Color, getBrandBorderStyle } from '@pafcloud/style';
import { modalSurface } from './modalSurface';

const animateIn = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(-16px)',
  },
  to: {
    transform: 'translateY(0px)',
  },
});

export const ModalContent = styled.aside(modalSurface, {
  '::before': {
    ...getBrandBorderStyle(),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    content: '""',
  },

  minWidth: 'min(320px, 90%)',
  overflow: 'auto',
  overscrollBehaviorY: 'contain',
  animation: `${animateIn} 0.1s`,
  animationIterationCount: 1,
  borderRadius: Color.CornerRadius.Small,
  boxShadow: Color.Elevation.Level3,

  [Breakpoint.LaptopOrLarger]: {
    height: 'auto',
    width: 360,
  },
});

export type ModalContentProps = ComponentProps<typeof ModalContent>;
