import type { FC } from 'react';
import { ProgressBar } from '@pafcloud/base-components';
import { useFlowRouter } from '@pafcloud/flow-router';
import { BonusbarContainer, ProgressIcon } from './offerProgressBarStyles';

export type OfferProgressBarProps = {
  className?: string;
  disabledInOfferFlow?: boolean;
  hideTurnoverIcon?: boolean;
};

/**
 * @param currentValue
 * @param maxValue
 * currentValue and maxValue should not be null here, but TS will think so and by
 * accepting that in the props we can do ?? 0 in here instead of everywhere it's used.
 */
export const OfferProgressBar: FC<
  OfferProgressBarProps & {
    playerProgramId: number;
    currentValue: number | null;
    maxValue: number | null;
  }
> = ({ className, disabledInOfferFlow, hideTurnoverIcon, playerProgramId, currentValue, maxValue }) => {
  const { openFlow } = useFlowRouter();

  const launchBonusFlow = () => {
    return openFlow('bonus', {
      playerProgramId,
    });
  };

  return (
    <BonusbarContainer
      className={className}
      onClick={() => launchBonusFlow()}
      disabledInOfferFlow={disabledInOfferFlow}
      tabIndex={-1}
    >
      <ProgressBar
        aria-valuemax={maxValue ?? 0}
        aria-valuenow={currentValue ?? 0}
        max={maxValue ?? 0}
        value={currentValue ?? 0}
      />
      {hideTurnoverIcon ? null : (
        <ProgressIcon name="turnoverContribution" data-testid="bonus-progressbar-svg" size={20} />
      )}
    </BonusbarContainer>
  );
};
