import { Color, Font, FontTextSize } from '@pafcloud/style';
import { sharedStyle } from './sharedStyles';

export const Style = {
  ...sharedStyle,
  BorderRadius: Color.CornerRadius.Base,
  LabelFloatScale: '0.9',
  FontSize: FontTextSize.Big,
  FontFamily: Font.Button,
  FontWeight: 700,
  SuffixFontSize: FontTextSize.Normal,
};
