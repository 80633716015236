import type { Resources } from './resources';

export const namespaces: (keyof Resources)[] = [
  'app',
  'about-limits',
  'beneficial-ownership-form',
  'betting',
  'bingo',
  'blog',
  'bonus',
  'bonus-info',
  'bonus-terms',
  'common',
  'cookie-consent',
  'create-account',
  'date-formats',
  'deposit',
  'document-upload',
  'file-pond',
  'flow',
  'footer',
  'form',
  'game-list',
  'game-pause',
  'game-play-view',
  'gaming-overview',
  'guards',
  'header',
  'identification-veridas',
  'inbox',
  'jackpot',
  'jackpot-pools',
  'limits',
  'login',
  'loyalty-program',
  'manual-registration',
  'multi-site-registration',
  'pay-and-play-provider-screen',
  'poker',
  'privacy-policy',
  'profile',
  'register-phone',
  'reminders',
  'responsible-gaming-questionnaire',
  'return-to-player-summary',
  'search',
  'self-test',
  'slot-session',
  'source-of-funds',
  'source-of-wealth',
  'terms',
  'terms-and-conditions',
  'transaction-method-aplauz',
  'transaction-method-apple-pay',
  'transaction-method-bank-transfer',
  'transaction-method-brite',
  'transaction-method-card',
  'transaction-method-mobilepay',
  'transaction-method-muchbetter',
  'transaction-method-neteller',
  'transaction-method-pay-safe-card',
  'transaction-method-paypal',
  'transaction-method-siirto',
  'transaction-method-skrill',
  'transaction-method-swish',
  'transaction-method-trustly',
  'transaction-method-yapeal',
  'transaction-methods',
  'verification',
  'verify-phone',
  'wallet',
  'withdrawal',
];
