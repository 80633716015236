import type { ComponentProps, FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { Color } from '@pafcloud/style';

type LoadingIndicatorProps = ComponentProps<'svg'> & {
  size?: string | number;
  center?: boolean;
};

const LoadingIndicatorSvg = styled.svg<LoadingIndicatorProps>(
  ({ center }) =>
    center && {
      display: 'block',
      margin: 'auto',
    },
);

export const LoadingIndicator: FC<LoadingIndicatorProps> = (props) => {
  const { t } = useTranslation('common');

  return (
    <LoadingIndicatorSvg
      aria-label={t('loading-indicator.aria-label')}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : 24}
      height={props.size ? props.size : 24}
      viewBox="0 0 44 44"
      stroke={props.color ? props.color : Color.Primitive.Primary}
      {...props}
    >
      <g fill="none" fillRule="evenodd" strokeWidth="2">
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </LoadingIndicatorSvg>
  );
};
