import styled from '@emotion/styled';
import type { SanityImageSource } from '@sanity/asset-utils';
import { getImageAsset, isImageSource } from '@sanity/asset-utils';
import type { PortableTextTypeComponent } from '@portabletext/react';
import { Image } from '@pafcloud/base-components';
import { useConfig } from '@pafcloud/contexts';

type Layout = 'fill' | 'responsive' | 'intrinsic' | 'fixed';

const Figure = styled.div<{ aspectRatio?: number }>(
  {
    position: 'relative',
    margin: 0,
    textAlign: 'center',
    maxWidth: '100%', // prevent overscroll if image is wider than viewport
  },
  ({ aspectRatio }) =>
    aspectRatio && {
      // Aspect ratio is neeeded for 'fill' images.
      aspectRatio: aspectRatio.toFixed(3),

      // iOS 14 does not support aspect-ratio
      '@supports not (aspect-ratio: 1/1)': {
        '::before': {
          content: '""',
          float: 'left',
          height: 0,
          paddingTop: `${(1 / aspectRatio) * 100}%`,
        },
        '::after': {
          content: '""',
          display: 'table',
          clear: 'both',
        },
      },
    },
);

const StyledImage = styled(Image)({
  objectFit: 'contain',
  display: 'inline-flex', // remove extra space below image
  maxWidth: '100%',
  height: 'auto',
});

type RichTextImageTypeProps = {
  _type: 'richTextImage';
  asset: SanityImageSource;
  alt: string;
  width: number;
  height: number;
  imageLayout: Layout;
};

export const RichTextImageType: PortableTextTypeComponent<RichTextImageTypeProps> = (props) => {
  const { asset, alt, width, height, imageLayout } = props.value;
  const { sanityProjectId, sanityDataset } = useConfig();

  if (!isImageSource(asset)) {
    return null;
  }

  const imageAsset = getImageAsset(asset, { projectId: sanityProjectId, dataset: sanityDataset });

  const imageUrl = imageAsset.url;
  const imageSize = imageAsset.metadata.dimensions;

  if (imageLayout === 'fill') {
    return (
      <Figure aspectRatio={imageSize.aspectRatio}>
        <StyledImage
          src={imageUrl}
          alt={alt}
          fill
          sizes="
          (max-width: 500px) 91vw,
          (max-width: 1365px) 74vw,
          65vw"
        />
      </Figure>
    );
  }

  // Calculate height based on width and aspect ratio
  // If aspect ratio is not available, fallback to height set in Sanity
  const calculatedHeight = imageSize.aspectRatio ? Math.round(width / imageSize.aspectRatio) : height;

  return (
    <Figure>
      <StyledImage
        src={imageUrl}
        alt={alt}
        width={width}
        height={calculatedHeight}
        sizes={`
          (max-width: 500px) 91vw,
          ${width}px
        `}
      />
    </Figure>
  );
};
