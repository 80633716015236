import type { FC } from 'react';
import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import { useMedia } from '@pafcloud/react-hook-utils';
import { SearchOverlay } from './SearchOverlay';
import { SearchDropdown } from './SearchDropdown';
import { RecentSearchProvider } from './RecentSearchContext';

export type SearchResult = {
  id: string;
  supplier: string;
  slug: string;
  alternativeLink?: string;
  category?: string;
  launchUrl: string;
  rawSupplier?: string;
  rawTitle?: string;
  title: string;
  thumbnail: {
    src: string;
    height: number;
    width: number;
  };
  tags: string[];
  gameListId?: string;
};

export type SearchResultClickedInfo = {
  name: string;
  launchUrl: string;
  index: number;
  totalResults: number;
};

export const SearchContainer = styled.div({
  maxWidth: '100%',
  minWidth: 0,

  [Breakpoint.LaptopOrLarger]: {
    justifySelf: 'center',
    width: '50%',
  },
});

type Props = {
  gameListId: string;
  className?: string;
};

const TOUCH_DEVICE = 'screen and (max-width: 1024px) and (pointer: coarse)';

export const Search: FC<Props> = ({ gameListId, className }) => {
  const isTouchEnabled = useMedia(TOUCH_DEVICE);

  return (
    <RecentSearchProvider>
      {isTouchEnabled ? (
        <SearchOverlay className={className} gameListId={gameListId} />
      ) : (
        <SearchDropdown className={className} gameListId={gameListId} />
      )}
    </RecentSearchProvider>
  );
};
