import { isType } from '@pafcloud/collection-utils';
import { useBonus } from '../BonusContext';

export const useMatchGameToPlayAndGet = (familyName: string) => {
  const { playAndGet } = useBonus();

  if (playAndGet == null) {
    return null;
  }

  const matchingStep = playAndGet[familyName];

  const playRealMoneyStep = isType(matchingStep, 'PlayRealMoneyOfferStep') ? matchingStep : null;
  const playRealRoundsStep = isType(matchingStep, 'PlayRealRoundsOfferStep') ? matchingStep : null;

  if (playRealMoneyStep != null) {
    return {
      familyName: playRealMoneyStep.game?.name,
      remaining: playRealMoneyStep.remainingAmount,
      playAndGetType: playRealMoneyStep.__typename,
    };
  }

  if (playRealRoundsStep != null) {
    return {
      familyName: playRealRoundsStep.game?.name,
      remaining: playRealRoundsStep.remainingRounds,
      playAndGetType: playRealRoundsStep.__typename,
    };
  }

  return null;
};
