import { useCallback, useEffect, useRef, useState } from 'react';

export const useToggle = (initialOn = false) => {
  const mounted = useRef<boolean>();
  const [isOn, setIsOn] = useState(initialOn);

  // Without this we get errors in tests
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const toggle = useCallback(() => {
    if (mounted.current) {
      setIsOn((current) => !current);
    }
  }, []);

  return [isOn, toggle] as const;
};
