import type { HTMLProps } from 'react';
import { useId, forwardRef, useRef, useEffect } from 'react';

import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FormInputProperties } from './form-input-style/properties';
import { Wrapper } from './FormInputWrapper';
import { Label, Text, LabelIcon } from './FormInputLabel';
import { Input } from './FormInputField';

export type FormInputProps = Omit<HTMLProps<HTMLInputElement>, 'type' | 'as'> & {
  type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'date';
  label?: string;
  dirty?: boolean;
  touched?: boolean;
  error?: boolean;
  floatingLabel?: boolean;
};

export const enabledFloatingLabel = () => {
  if ($buildEnv.theme === 'golden') {
    return false;
  }

  return true;
};

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      children,
      floatingLabel = enabledFloatingLabel(),
      touched = false,
      dirty = false,
      error,
      className,
      ...props
    },
    ref,
  ) => {
    const id = useId();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      // update the gap in the border to cater for the label and icon
      if (floatingLabel && wrapperRef.current && labelRef.current) {
        const wrapperElement = wrapperRef.current;
        const labelElement = labelRef.current;

        wrapperElement.style.setProperty(FormInputProperties.LabelWidth, `${labelElement.offsetWidth}px`);
        const updateLabelWidth = () => {
          wrapperElement.style.setProperty(FormInputProperties.LabelWidth, `${labelElement.offsetWidth}px`);
        };

        labelElement.addEventListener('transitionstart', updateLabelWidth);
        return () => {
          labelElement.removeEventListener('transitionstart', updateLabelWidth);
        };
      }
    });

    return (
      <Wrapper floatingLabel={floatingLabel} ref={wrapperRef} className={className}>
        <Input
          error={error}
          dirty={dirty}
          touched={touched}
          floatingLabel={floatingLabel}
          {...props}
          id={id}
          ref={ref}
          placeholder="&nbsp;"
        />
        {children}
        <Label htmlFor={id}>
          <Text ref={labelRef}>
            <LabelIcon name={error ? 'closeFat' : 'check'} shown={floatingLabel && dirty && touched ? 1 : 0} />
            {label}
          </Text>
        </Label>
      </Wrapper>
    );
  },
);

FormInput.displayName = 'FormInput';
