import { useRouter } from 'next/router';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { ModalBackgroundOverlay, ModalContent, renderIntoRootContainer } from '@pafcloud/base-components';
import { LayoutProps, ZIndex } from '@pafcloud/style';
import { useCookieConsent } from '@pafcloud/contexts';
import { CookieConsent } from './CookieConsent';

const CookieContentOverlay = styled(ModalBackgroundOverlay)<{ useDefaultZIndex: boolean }>(({ useDefaultZIndex }) => ({
  zIndex: useDefaultZIndex ? undefined : ZIndex.CookieConsent,
}));

const CookieConsentContent = styled(ModalContent)({
  margin: 16,
  marginTop: LayoutProps.HeaderHeight,
  padding: '32px 16px',
});

export const CookieConsentModal = () => {
  const router = useRouter();
  const cookieConsent = useCookieConsent();

  if (cookieConsent != null) {
    return null;
  }

  const cookieSettingsIsOpen = router.query.flowType === 'cookieConsent';

  return renderIntoRootContainer(
    <CookieContentOverlay useDefaultZIndex={cookieSettingsIsOpen}>
      <CookieConsentContent>
        <Global styles={{ body: { overflow: 'hidden !important' } }} />
        <CookieConsent />
      </CookieConsentContent>
    </CookieContentOverlay>,
  );
};
