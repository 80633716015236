import type { NextIncomingMessage } from 'next/dist/server/request-meta';
import { getCookieFromRequest, IS_POKER_CLIENT, LAST_DEPOSIT_VALUE } from '@pafcloud/cookies';

export function getAllowedSSRCookie(request?: NextIncomingMessage) {
  // Cloudflare treats different values of these cookies as different caches.
  // If something is to be added to this list we need to change the cloudflare worker to adhere to the changes.
  // https://github.com/pafcloud/live-test-infra/blob/a30fe33dc4b032296c47b255c85650445bff8213/test/_global/cloudflare/worker-scripts/frontend-ssr-caching/src/index.js#L28
  const ssrAllowedCookies = {
    [LAST_DEPOSIT_VALUE.name]: Boolean(getCookieFromRequest(request, LAST_DEPOSIT_VALUE)),
    [IS_POKER_CLIENT.name]: Boolean(getCookieFromRequest(request, IS_POKER_CLIENT)),
  };

  const cookie = Object.entries(ssrAllowedCookies)
    .filter(([_key, value]) => value)
    .map(([key, _value]) => `${key}=true`)
    .join(';');

  return cookie;
}
