import styled from '@emotion/styled';

const SPACE_MULTIPLIER = 8;

type StackProps = {
  space?: number;
  centerText?: true;
};

export const Stack = styled.div<StackProps>(({ space = 1, centerText }) => ({
  textAlign: centerText && 'center',
  '> *:not(:last-child)': {
    marginBottom: SPACE_MULTIPLIER * space,
  },
}));
