import { Global } from '@emotion/react';
import { LayoutProps, CSSVariables } from '@pafcloud/style';

export const GamePauseGlobal = () => {
  return (
    <Global
      styles={{
        ':root': {
          [CSSVariables.GamePauseHeight]: LayoutProps.GamePauseHeight,
        },
      }}
    />
  );
};
