import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  InputBackground: Color.Surface.Base.Background,

  LabelUntouched: Color.Primitive.Accent,
  LabelValid: Color.Primitive.Accent,

  BorderUntouched: Color.Surface.Base.Dimmed,
  BorderValid: Color.Surface.Base.Dimmed,
  BorderFocused: Color.Primitive.Accent,

  TextUntouched: Color.Primitive.Accent,
  TextValid: Color.Primitive.Accent,
};
