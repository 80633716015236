import type { FC } from 'react';
import { useRouter } from 'next/router';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import {
  ResultImage,
  ResultLink,
  ResultSupplier,
  ResultTitle,
} from './search-result-styles/searchResultStyles.default';
import { useRecentSearch } from './useRecentSearch';
import type { SearchResultChildrenProps } from './SearchResultChildren';

export const SearchResultChildren: FC<SearchResultChildrenProps> = ({ gameListId, onClickResult, searchResult }) => {
  const { asPath } = useRouter();
  const { insertRecentSearchResult } = useRecentSearch(gameListId);

  return (
    <ResultLink
      onClick={(event) => {
        insertRecentSearchResult(searchResult);
        rememberGameHomeUrl(asPath);
        onClickResult?.();
        event.currentTarget.blur();
      }}
      href={searchResult.launchUrl}
    >
      <ResultImage
        src={searchResult.thumbnail.src}
        width={searchResult.thumbnail.width}
        height={searchResult.thumbnail.height}
      />
      <ResultTitle dangerouslySetInnerHTML={{ __html: searchResult.title }} />
      <ResultSupplier dangerouslySetInnerHTML={{ __html: searchResult.supplier }} />
    </ResultLink>
  );
};
