import { useId } from 'react';
import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { useFlowRouter } from '@pafcloud/flow-router';
import type { BonusMenuItem_offer$key } from './__generated__/BonusMenuItem_offer.graphql';
import { BonusNavSubItem } from './sharedStyles';
import { BonusMenuButton } from './styles';
import { BonusMenuItemContent } from './BonusMenuItemContent';

const offerFragment = graphql`
  fragment BonusMenuItem_offer on BonusOffer {
    offerId
    ...BonusMenuItemContent_offer
  }
`;

type BonusMenuItemProps = {
  offer: BonusMenuItem_offer$key;
  type: string | undefined;
};

export const BonusMenuItem: FC<BonusMenuItemProps> = (props) => {
  const offer = useFragment(offerFragment, props.offer);
  const { t } = useTranslation('common');
  const { openFlow } = useFlowRouter();
  const buttonElement = useId();

  const offerTypeHasProgressBar =
    typeof props.type === 'string' &&
    ['PlayRealMoneyOfferStep', 'PlayRealRoundsOfferStep', 'TurnoverOfferStep'].includes(props.type);

  return (
    <BonusNavSubItem
      /**
       * The aria-label is necessary here, but overrides the actual information in the element.
       * To include that information as well, we can use aria-describedby to concatenate it.
       */
      aria-label={t('bonus-menu.link.bonus.aria-label')}
      aria-describedby={buttonElement}
      data-testid={props.type}
    >
      <BonusMenuButton
        as="button"
        id={buttonElement}
        onClick={() => openFlow('bonus', { playerProgramId: offer.offerId })}
        singleRow={!offerTypeHasProgressBar}
      >
        <BonusMenuItemContent offer={offer} type={props.type} />
      </BonusMenuButton>
    </BonusNavSubItem>
  );
};
