import type { FC } from 'react';
import dynamic from 'next/dynamic';
import { renderIntoRootContainer } from '@pafcloud/base-components';
import { useReminders, useDispatchReminders, hideReminder } from '@pafcloud/contexts';

const Reminders = {
  AFTER_LOGIN: dynamic(() => import('./AfterLoginReminder')),
  AFTER_NEW_GAME: dynamic(() => import('./reality-check/RealityCheckReminder')),
  SESSION_LESS_THAN_10_MINUTES_LEFT: dynamic(() => import('./SessionExpiresReminder')),
  PLAY_FOR_REAL: dynamic(() => import('./DemoRealPlayReminder')),
  INTERVAL: dynamic(() => import('./reality-check/RealityCheckReminder')),
};

export const Reminder: FC = () => {
  const { reminders, status } = useReminders();
  const dispatchReminders = useDispatchReminders();

  const [reminder] = reminders;

  if (status !== 'OPEN') {
    return null;
  }

  const dismiss = () => dispatchReminders(hideReminder());
  const RemindersComponent = Reminders[reminder];

  return renderIntoRootContainer(<RemindersComponent onDismiss={dismiss} />);
};
