import type { CSSObject } from '@emotion/react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { ButtonVariant, ButtonProps } from '../ButtonProps';

const heroButtonGlow: CSSObject = {
  isolation: 'isolate',

  '::before, ::after': {
    content: '""',
    pointerEvents: 'none',
    position: 'absolute',
    bottom: '-50%',
    width: '100%',
    height: '70%',
    background: 'var(--button-background)',
    borderRadius: '20%',
    borderTopLeftRadius: '60% 100%',
    borderTopRightRadius: '60% 100%',

    transition: 'margin-bottom 150ms linear, opacity 50ms linear',
  },

  '::before': {
    opacity: 0.1,
    filter: 'blur(10px)',
  },

  '::after': {
    bottom: '-25%',
    width: '80%',
    height: '30%',
    filter: 'blur(8px)',
    opacity: 0.35,
  },

  ':focus::before': {
    opacity: 0.2,
    marginBottom: -4,
  },

  '@media (hover: hover)': {
    ':hover::before': {
      opacity: 0.12,
      marginBottom: -2,
    },
  },
};

export const getCallToActionEffect = (props: ButtonVariant & Pick<ButtonProps, 'ctaEffect'>) => {
  if (props.variant === 'secondary' || !props.ctaEffect) {
    return undefined;
  }

  if ($buildEnv.theme === 'golden' || $buildEnv.theme === 'dreams') {
    return heroButtonGlow;
  }

  return undefined;
};
