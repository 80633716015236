/**
 * @generated SignedSource<<c34d637b8104acf07d9938381f07b487>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type freespins_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
  readonly " $fragmentType": "freespins_offer";
};
export type freespins_offer$key = {
  readonly " $data"?: freespins_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"freespins_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "freespins_offer"
};

(node as any).hash = "8978389469b9b0097a2b887ce06657e7";

export default node;
