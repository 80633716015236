import { graphql, readInlineData } from 'relay-runtime';
import { isType } from '@pafcloud/collection-utils';
import type { freespins_offer$key } from './__generated__/freespins_offer.graphql';
import { getBonusOfferSteps } from './hooks/useBonusOfferSteps';
import type { BonusOfferReward } from './hooks/useBonusOfferSteps';

export type Freespins = ReturnType<typeof toFreespins>;

const freespinsOfferFragment = graphql`
  fragment freespins_offer on BonusOffer @inline {
    ...useBonusOfferSteps_offer
  }
`;

export const toFreespins = (key: freespins_offer$key | null) => {
  const freespinsOffer = readInlineData(freespinsOfferFragment, key);
  const freespinsSteps = getBonusOfferSteps(freespinsOffer ?? null);

  if (freespinsSteps == null) {
    return null;
  }

  return freespinsSteps.reduce<Record<string, BonusOfferReward>>((acc, step) => {
    if ('reward' in step && isType(step.reward, 'FreespinOfferReward')) {
      if (
        step.reward?.game?.familyName != null &&
        step.reward.status === 'GIVEN' &&
        Boolean(step.reward.spinsRemaining)
      ) {
        if (acc[step.reward.game.familyName] == null) {
          acc[step.reward.game.familyName] = step.reward;
        }
      }
    }

    return acc;
  }, {});
};
