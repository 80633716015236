import { Color } from '@pafcloud/style';
import type { ButtonColorsProps } from '../ButtonProps';

/**
 * State: Disabled
 * Variant: Primary
 */
const primaryDisabled = {
  TextDisabled: Color.Surface.Disabled.Foreground,
  BackgroundDisabled: Color.Surface.Disabled.Background,
  BorderDisabled: 'transparent',
};

/**
 * State: Disabled
 * Variant: Secondary
 */
const secondaryDisabled = {
  TextDisabled: Color.Surface.Disabled.Foreground,
  BackgroundDisabled: Color.Surface.Disabled.Background,
  BorderDisabled: Color.Surface.Disabled.Background,
};

/**
 * Color: Default
 * Variant: Primary
 */
const primaryDefault: ButtonColorsProps = {
  Text: Color.Primitive.PrimaryContrast,
  Background: Color.Primitive.Primary,
  Border: 'transparent',

  TextHover: Color.Primitive.PrimaryContrast,
  BackgroundHover: Color.Primitive.PrimaryTint,
  BorderHover: 'transparent',

  TextActive: Color.Primitive.PrimaryContrast,
  BackgroundActive: Color.Primitive.PrimaryTint,
  BorderActive: 'transparent',

  FocusRing: Color.Primitive.Primary + '80',
  ShadowTint: Color.Primitive.Primary + '30',

  ...primaryDisabled,
};

/**
 * Color: Accent
 * Variant: Primary
 */
const primaryAccent: ButtonColorsProps = {
  Text: Color.Primitive.AccentContrast,
  Background: Color.Primitive.Accent,
  Border: 'transparent',

  TextHover: Color.Primitive.AccentContrast,
  BackgroundHover: Color.Primitive.AccentTint,
  BorderHover: 'transparent',

  TextActive: Color.Primitive.AccentContrast,
  BackgroundActive: Color.Primitive.AccentTint,
  BorderActive: 'transparent',

  FocusRing: Color.Primitive.Accent + '80',
  ShadowTint: Color.Primitive.Accent + '30',

  ...primaryDisabled,
};

/**
 * Color: Default
 * Variant: Secondary
 */
const secondaryDefault: ButtonColorsProps = {
  Text: Color.Primitive.Primary,
  Background: 'transparent',
  Border: Color.Primitive.Primary,

  TextHover: Color.Primitive.PrimaryContrast,
  BackgroundHover: Color.Primitive.PrimaryTint,
  BorderHover: 'transparent',

  TextActive: Color.Primitive.PrimaryContrast,
  BackgroundActive: Color.Primitive.PrimaryTint,
  BorderActive: 'transparent',

  FocusRing: Color.Primitive.Primary + '80',
  ShadowTint: 'transparent',

  ...secondaryDisabled,
};

/**
 * Color: Accent
 * Variant: Secondary
 */
const secondaryAccent: ButtonColorsProps = {
  Text: Color.Primitive.Accent,
  Background: 'transparent',
  Border: Color.Primitive.Accent,

  TextHover: Color.Primitive.AccentContrast,
  BackgroundHover: Color.Primitive.AccentTint,
  BorderHover: 'transparent',

  TextActive: Color.Primitive.AccentContrast,
  BackgroundActive: Color.Primitive.AccentTint,
  BorderActive: 'transparent',

  FocusRing: Color.Primitive.Accent + '80',
  ShadowTint: 'transparent',

  ...secondaryDisabled,
};

export const Colors = {
  primaryDefault,
  primaryAccent,
  secondaryDefault,
  secondaryAccent,
};
