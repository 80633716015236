import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.Primitive.Primary,
  TextHover: Color.Primitive.PrimaryTint + 'C0',
  TextActive: Color.Primitive.PrimaryTint,
  TextShadow: undefined,

  Icon: Color.TextColors.BodyText,
  IconActive: Color.TextColors.BodyText,

  BackgroundHover: undefined,
  BackgroundActive: `radial-gradient(circle closest-corner at 10px center, ${Color.Primitive.PrimaryTint} 0, ${Color.Primitive.PrimaryTint} 3px, transparent 4px, transparent 100%)`,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: 'transparent',
  SubMenuRuler: Color.Surface.Base.Dimmed,
};

export const FontStyle = {
  TextTransform: 'uppercase',
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
