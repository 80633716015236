/**
 * @generated SignedSource<<38b1b9d02a5960c6a747d19c3bdc5c7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type BonusOfferStatus = "ACTIVE" | "CANCELLED" | "COMPLETED" | "EXPIRED" | "JOINED" | "QUEUED";
import { FragmentRefs } from "relay-runtime";
export type turnover_offer$data = {
  readonly expires: string;
  readonly offerId: number;
  readonly status: BonusOfferStatus;
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
  readonly " $fragmentType": "turnover_offer";
};
export type turnover_offer$key = {
  readonly " $data"?: turnover_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"turnover_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "turnover_offer"
};

(node as any).hash = "78aa5465844ee668832ce2744b13d0ee";

export default node;
