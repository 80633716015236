import styled from '@emotion/styled';
import type { FC, MouseEvent } from 'react';
import { BulletList, BulletListItem, Link } from '@pafcloud/base-components';
import { MainMenuColors } from '../main-menu-colors';
import type { MainMenuFooter_content$data } from '../__generated__/MainMenuFooter_content.graphql';

const List = styled(BulletList)({
  padding: 0,
  fontWeight: 'bold',
  width: 'calc(100% - 2.5em)',
});

const ListItem = styled(BulletListItem)({
  textShadow: `0 1px ${MainMenuColors.TextShadow}`,

  '::before, ::after': {
    background: 'currentColor',
  },
});

type Props = {
  content: MainMenuFooter_content$data;
  onLinkClick: (event: MouseEvent<HTMLElement>) => void;
};

export const MainMenuFooterContent: FC<Props> = ({ content: { footerLinks }, onLinkClick }) => {
  if (footerLinks.length > 0) {
    return (
      <List>
        {footerLinks.map(({ text, url }) => (
          <ListItem key={text} onClick={onLinkClick}>
            <Link href={url} aria-label={text}>
              {text}
            </Link>
          </ListItem>
        ))}
      </List>
    );
  }

  return null;
};
