import type { FC } from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { graphql, useFragment } from 'react-relay/hooks';
import { Icon, Link, Logo } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { BurgerComponent } from '../BurgerComponent';
import { HeaderIconButton } from '../header-icon-button';
import { LoginLink } from '../../LoginLink';
import type { HeaderContentDefault_content$key } from './__generated__/HeaderContentDefault_content.graphql';
import type { HeaderContentProps } from './shared';
import { StyledHeader } from './shared';

const ProfileMenu = dynamic(() => import('../menus/profile-menu/ProfileMenu'), { ssr: false });
const MessageMenu = dynamic(() => import('../menus/message-menu/MessageMenu'), { ssr: false });

const contentFragment = graphql`
  fragment HeaderContentDefault_content on Query {
    ...ProfileMenu_data
  }
`;

const LogoLink = styled(Link)(
  {
    display: 'flex',
    marginLeft: 8,
    height: 36,

    '> img, > svg': {
      height: '100%',
    },
  },
  () =>
    $buildEnv.site === 'mycasino.ch' && {
      height: 44,
    },
);

const RightSection = styled.div({
  marginLeft: 'auto',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
});

const LoginButton = styled(LoginLink)({
  marginRight: 8,
});

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentDefault_content$key>(contentFragment, props.content);
  const { t } = useTranslation('header');
  const isLoggedIn = useIsLoggedIn();

  const menuAriaLabel = isMainMenuOpen ? t('main-menu-button.close.aria-label') : t('main-menu-button.open.aria-label');

  return (
    <StyledHeader>
      <HeaderIconButton
        onClick={() => setMainMenuOpen(!isMainMenuOpen)}
        aria-label={menuAriaLabel}
        aria-expanded={isMainMenuOpen}
        data-name="main-menu-button"
      >
        <BurgerComponent open={isMainMenuOpen} />
      </HeaderIconButton>
      <LogoLink aria-label={t('logo.aria-label')} href="/" data-name="brand-logo">
        <Logo />
      </LogoLink>

      <RightSection onClick={() => setMainMenuOpen(false)}>
        {isLoggedIn ? (
          <>
            <MessageMenu />
            <ProfileMenu data={content} />
          </>
        ) : (
          <LoginButton data-testid="header-action">
            {t('login-button.text')} <Icon name="chevronRightFat" size="1rem" />
          </LoginButton>
        )}
      </RightSection>
    </StyledHeader>
  );
};
