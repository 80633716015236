import type { FC, HTMLAttributes } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { logger } from '@pafcloud/logging';
import { getNativeLanguageName, getSiteProps } from '@pafcloud/locale';
import { Icon } from '@pafcloud/base-components';
import { DropDownSelect, DropDownSelectValue, FormInputProperties } from '@pafcloud/form-components';
import type { DropDownSelectOpenDirection } from '@pafcloud/form-components';
import { useHandler } from '@pafcloud/react-hook-utils';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useUpdatePlayerLanguage } from './useUpdatePlayerLanguage';
import { LanguageSelectColors } from './language-select-colors';
import { setLocaleCookie } from './setLocaleCookie';

type LanguageSelectProps = {
  invertedTheme?: boolean;
  className?: string;
  tabIndex?: HTMLAttributes<HTMLDivElement>['tabIndex'];
  openDirection?: DropDownSelectOpenDirection;
};

const LanguageDropDownSelect = styled(DropDownSelect)<LanguageSelectProps>(
  {
    margin: '0 auto',
    maxWidth: 192,

    [`${DropDownSelectValue}`]: {
      fontWeight: 'bold',
      textIndent: 0,
    },
  },
  ({ invertedTheme }) =>
    invertedTheme && {
      '--lang-select-color': LanguageSelectColors.Inverted,
      color: 'var(--lang-select-color)',

      ':hover': {
        '--lang-select-color': LanguageSelectColors.InvertedHover,
      },
      ':active, :focus, :focus-within': {
        '--lang-select-color': LanguageSelectColors.InvertedHover,
      },

      [`${DropDownSelectValue}`]: {
        '--field-text-color': 'var(--lang-select-color) !important',
      },
      [`${DropDownSelectValue} ~ label`]: {
        '--field-border-color': 'var(--lang-select-color) !important',
        '--field-background-color': 'transparent',
        color: 'var(--lang-select-color) !important',
      },
    },
);

const GlobeIcon = styled(Icon)({
  marginLeft: `calc(var(${FormInputProperties.TextIndent}) / 2)`,
  marginRight: `calc(var(${FormInputProperties.TextIndent}) / 4)`,
});

export const LanguageSelect: FC<LanguageSelectProps> = ({
  invertedTheme = false,
  className,
  tabIndex,
  openDirection = 'down',
}) => {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const siteProps = getSiteProps();
  const updatePlayerLanguage = useUpdatePlayerLanguage();
  const { t, i18n } = useTranslation('common');

  const autoCorrectLocale = useHandler(() => {
    if (router.locale && router.locale !== i18n.language) {
      setLocaleCookie(router.locale);
      void i18n.changeLanguage(router.locale);
    }
  });

  useEffect(() => autoCorrectLocale(), [autoCorrectLocale]);

  const handleLanguageChange = async (language: string) => {
    // Updating player language while logged in needs to trigger a mutation
    if (isLoggedIn) {
      try {
        await updatePlayerLanguage({ language });
      } catch (error) {
        logger.error(`Could not update player language to "${language}"`, { error });
      }
    }

    setLocaleCookie(language);

    const isHomePath = router.asPath === '/';

    if (router.defaultLocale === language) {
      location.href = `${isHomePath ? '/' : router.asPath}`;
    } else {
      location.href = `/${language}${isHomePath ? '' : router.asPath}`;
    }
  };

  return (
    <LanguageDropDownSelect
      key={router.locale}
      invertedTheme={invertedTheme}
      className={className}
      label={t('language-select.title')}
      defaultValue={router.locale}
      onChange={handleLanguageChange}
      floatingLabel={false}
      options={siteProps.locales.map((language) => ({
        value: language,
        label: <span lang={language}>{getNativeLanguageName(language)}</span>,
      }))}
      tabIndex={tabIndex}
      openDirection={openDirection}
    >
      <GlobeIcon name="globe" size="1.5rem" />
    </LanguageDropDownSelect>
  );
};
