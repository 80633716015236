import type { FC } from 'react';
import { useRouter } from 'next/router';
import type { PortableTextTypeComponent } from '@portabletext/react';
import { ExternalLink, SupportEmailLink } from '@pafcloud/base-components';
import { useConfig } from '@pafcloud/contexts';

const HelpdeskLink: FC = () => {
  const router = useRouter();
  const { helpdeskUrl } = useConfig();

  const url = `${helpdeskUrl}/${router.locale}`;

  return <ExternalLink href={url}>{url}</ExternalLink>;
};

type RichTextPlaceholderTypeProps = {
  _type: 'placeholder';
  type: string;
};

export const RichTextPlaceholderType: PortableTextTypeComponent<RichTextPlaceholderTypeProps> = ({ value }) => {
  switch (value.type) {
    case 'supportEmail': {
      return <SupportEmailLink />;
    }
    case 'helpdeskLink': {
      return <HelpdeskLink />;
    }
    default:
      return null;
  }
};
