import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useSendPinCodeMutation } from './__generated__/useSendPinCodeMutation.graphql';

const createUseSendPinCode = createUseMutation<useSendPinCodeMutation>(graphql`
  mutation useSendPinCodeMutation($input: SendPinCodeInput!) {
    sendPinCode(input: $input) {
      __typename
      ... on SendPinCodeSuccess {
        ticketId
        identificationCode
      }
      ... on VerificationError {
        error
      }
    }
  }
`);

export const useSendPinCode = createUseSendPinCode((result) => {
  return result.sendPinCode;
});
