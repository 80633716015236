import type { FC } from 'react';
import { useState } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { useIsPokerClient } from '@pafcloud/react-hook-utils';
import { useLayoutVisibility } from '@pafcloud/contexts';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { MainMenu } from './menus';
import type { Header_content$key } from './__generated__/Header_content.graphql';
import { HeaderContent } from './header-content';

const contentFragment = graphql`
  fragment Header_content on Query {
    ...HeaderContentDefault_content
    ...HeaderContentSpeedy_content
    ...HeaderContentGolden_content
    ...HeaderContentGoldenV2_content
    ...HeaderContentX3000_content
    ...HeaderContentOneOne_content
    ...HeaderContentQuirky_content
    ...HeaderContentQuirkyPurple_content
    ...MainMenu_content
  }
`;

type HeaderProps = {
  content: Header_content$key;
};

export const Header: FC<HeaderProps> = (props) => {
  const content = useFragment(contentFragment, props.content);
  const [isMainMenuOpen, setMainMenuOpen] = useState(false);
  const isPokerClient = useIsPokerClient();
  const { headerVisible } = useLayoutVisibility();

  if (!headerVisible || isPokerClient) {
    return null;
  }

  return (
    <>
      <HeaderContent content={content} isMainMenuOpen={isMainMenuOpen} setMainMenuOpen={setMainMenuOpen} />

      {$buildEnv.theme === 'classic' ||
      $buildEnv.theme === 'classic-red' ||
      $buildEnv.theme === 'clean' ||
      $buildEnv.theme === 'clean-blue' ||
      $buildEnv.theme === 'golden' ||
      $buildEnv.theme === 'quirky' ||
      $buildEnv.theme === 'quirky-purple' ||
      $buildEnv.theme === 'momentum' ||
      $buildEnv.theme === 'momentum-neon' ? (
        <MainMenu content={content} isOpen={isMainMenuOpen} onClose={() => setMainMenuOpen(false)} />
      ) : null}
    </>
  );
};
