import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import type { FC } from 'react';
import { useRef } from 'react';
import { Global } from '@emotion/react';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import { Color, deviceWithBackdropFilter, DynamicValues } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useTopOfPageObserver } from '../utils/useTopOfPageObserver';
import { HeaderColors } from '../header-colors';

const animationSpeed = '0.3s';
const animationTiming = 'ease-in-out';

const backgroundStyle = () => {
  const addBackdropFilter = $buildEnv.theme === 'golden' || $buildEnv.theme === 'quirky';
  if (addBackdropFilter) {
    return {
      boxShadow: Color.Elevation.Level1,
      borderBottom: `1px solid ${Color.Primitive.Accent}80`,

      [deviceWithBackdropFilter]: {
        backdropFilter: 'blur(6px)',
        backgroundColor: HeaderColors.BackgroundBlur,
      },
    };
  }

  return {
    boxShadow: Color.Elevation.Level1,
  };
};

const HeaderBackgroundLayer = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 'var(--header-background-opacity, 0)',

  transition: `opacity ${animationSpeed} ${animationTiming}`,
  background: HeaderColors.Background,
  zIndex: -1,

  ...backgroundStyle(),
});

// Element to check scroll position
const ScrollPositionLayer = styled.div({
  height: 0,
  width: 0,
  position: 'absolute',
  visibility: 'hidden',
  top: DynamicValues.HeaderMaxHeight,
});

// Check if user is at the top of the page and sets transparent header background
const ScrollPosition: FC = () => {
  const scrollPositionElement = useRef<HTMLDivElement>(null);
  const topOfPage = useTopOfPageObserver(scrollPositionElement);
  const portalContainer = document.getElementById('__next') ?? document.body;

  return createPortal(
    <>
      <Global
        styles={{
          ':root': {
            '--header-background-opacity': topOfPage ? 0 : 1,
          },
        }}
      />

      <ScrollPositionLayer ref={scrollPositionElement} />
    </>,
    portalContainer,
  );
};

type HeaderBackgroundProps = {
  className?: string;
};

export const HeaderBackground: FC<HeaderBackgroundProps> = ({ className }) => {
  const isSSR = useIsSSR();

  return (
    <>
      {!isSSR && <ScrollPosition />}
      <HeaderBackgroundLayer className={className} />{' '}
    </>
  );
};
