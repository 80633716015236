import styled from '@emotion/styled';
import { CardColors } from './card-colors';

export const Card = styled.div({
  '--shadow-color': CardColors.ShadowColorTint,
  '--card-background': CardColors.Background,
  '--card-radius': CardColors.BorderRadius,
  '--card-border': CardColors.Border,

  boxShadow: CardColors.Shadow,
  background: 'var(--card-background)',
  borderRadius: 'var(--card-radius)',
  border: 'var(--card-border)',
});
