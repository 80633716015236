import styled from '@emotion/styled';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import { Font, Color } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';

const Text = styled.span({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  padding: '1em',
  fontWeight: 'bold',
  color: Color.Surface.Base.Foreground,
});

const ButtonGroupItem = styled.label({
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  fontFamily: Font.Button,
  textTransform:
    $buildEnv.theme === 'momentum' ||
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'quirky' ||
    $buildEnv.theme === 'quirky-purple'
      ? 'uppercase'
      : 'none',

  '& + &': {
    borderLeft: `1px solid ${Color.Surface.Base.Foreground}`,
  },

  [`&:first-of-type > ${Text}`]: {
    paddingLeft: 'calc(1em + 8px)',
    borderTopLeftRadius: 'var(--button-border-radius)',
    borderBottomLeftRadius: 'var(--button-border-radius)',
  },
  [`&:last-of-type > ${Text}`]: {
    paddingRight: 'calc(1em + 8px)',
    borderTopRightRadius: 'var(--button-border-radius)',
    borderBottomRightRadius: 'var(--button-border-radius)',
  },
});

const Input = styled.input({
  position: 'absolute',
  opacity: 0,
  appearance: 'none',

  [`&:hover + ${Text}`]: {
    color: Color.Primitive.Primary,
  },

  [`&:disabled + ${Text}`]: {
    color: Color.TextColors.DisabledText,
    cursor: 'auto',
  },

  // Selected item
  [`&:checked + ${Text}`]: {
    color: Color.Primitive.Primary,
    boxShadow: `0 0 0 1px ${Color.Primitive.Primary}, inset 0 0 0 1px ${Color.Primitive.Primary}`,
    backgroundColor: `${Color.Primitive.Primary}0D`,
  },

  [`&:focus:not(:checked) + ${Text}`]: {
    boxShadow: `0 0 0 1px ${Color.Surface.Disabled.Foreground}, inset 0 0 0 1px ${Color.Surface.Disabled.Foreground}`,
  },
});

type Props = ComponentProps<typeof Input> & {
  label: string;
  className?: string;
};

export const ButtonGroupInput = forwardRef<HTMLInputElement, Props>(({ label, className, ...props }, ref) => {
  return (
    <ButtonGroupItem className={className}>
      <Input {...props} type="radio" ref={ref} />
      <Text>{label}</Text>
    </ButtonGroupItem>
  );
});

ButtonGroupInput.displayName = 'ButtonGroupInput';
