import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';

export const LogoLink = styled(Link)({
  display: 'flex',
  margin: '8px 12px',
});

export const LogoImageWrapper = styled.div({
  display: 'flex',
  margin: '8px 12px',
});

export const LogoWrapper = styled.section({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 0,

  img: {
    display: 'inline-block',
    maxWidth: '100%',
  },
});
