/**
 * @generated SignedSource<<aa34b2f40bc9245798aa886a24113285>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Footer_content$data = {
  readonly site: {
    readonly footer: {
      readonly blocks: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"FooterBlocks_footerBlocks">;
      }>;
      readonly links: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"FooterMenu_links">;
      }>;
    } | null;
  };
  readonly " $fragmentType": "Footer_content";
};
export type Footer_content$key = {
  readonly " $data"?: Footer_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"Footer_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Footer_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Footer",
          "kind": "LinkedField",
          "name": "footer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FooterMenuLink",
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FooterMenu_links"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "blocks",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FooterBlocks_footerBlocks"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f46b3f2b2f7a1fb357e6b20cc6f94900";

export default node;
