import type { CSSObject } from '@emotion/react';
import type { ButtonColorsProps } from '..';
import { ButtonColors } from '../button-colors';
import type { ButtonVariant, ButtonColorStyle } from '../ButtonProps';

const getButtonProperties = (color: ButtonColorsProps) => ({
  '--button-text': color.Text,
  '--button-background': color.Background,
  '--button-border': color.Border,

  '--button-text--hover': color.TextHover,
  '--button-background--hover': color.BackgroundHover,
  '--button-border--hover': color.BorderHover,

  '--button-text--active': color.TextActive,
  '--button-background--active': color.BackgroundActive,
  '--button-border--active': color.BorderActive,

  '--button-focus-ring': color.FocusRing,
  '--button-shadow-tint': color.ShadowTint,

  ':disabled': {
    '--button-text': color.TextDisabled,
    '--button-background': color.BackgroundDisabled,
    '--button-border': color.BorderDisabled,
    '--button-text--hover': color.TextDisabled,
    '--button-background--hover': color.BackgroundDisabled,
    '--button-border--hover': color.BorderDisabled,
    '--button-text--active': color.TextDisabled,
    '--button-background--active': color.BackgroundDisabled,
    '--button-border--active': color.BorderDisabled,
    '--button-focus-ring': 'transparent',

    boxShadow: 'none',
    cursor: 'default',
  },
});

export const getColorAttributes = (props: ButtonVariant & ButtonColorStyle): CSSObject => {
  const styling = `${props.variant ?? 'primary'}-${props.colorstyle ?? 'default'}` as const;

  const color = {
    'primary-default': ButtonColors.primaryDefault,
    'primary-accent': ButtonColors.primaryAccent,
    'secondary-default': ButtonColors.secondaryDefault,
    'secondary-accent': ButtonColors.secondaryAccent,
  };

  return getButtonProperties(color[styling]);
};
