/**
 * @generated SignedSource<<472b39d268f305a9f89ec11900fb8e47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackGame_trackable$data = {
  readonly track: boolean;
  readonly trackId: string;
  readonly " $fragmentType": "useTrackGame_trackable";
};
export type useTrackGame_trackable$key = {
  readonly " $data"?: useTrackGame_trackable$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_trackable">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTrackGame_trackable"
};

(node as any).hash = "b3b13af685227f76e19a5c6f8d89b406";

export default node;
