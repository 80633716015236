import styled from '@emotion/styled';
import { InternalLink, NoStyleButton, NotificationBadge } from '@pafcloud/base-components';
import { MainMenuColors } from '../../main-menu-colors';
import { MainSubMenu, MenuGroupSummary, SummaryContent } from '../../MainMenuGroup';
import { MainMenuItemIcon } from '../../MainMenuIcons';
import { MainMenuItem } from '../../MainMenuItem';
import { BonusMenuColors } from '../bonus-menu-colors';
import { menuLinkStyle } from '../../menuLinkStyle';

export const BonusNavItem = styled(MainMenuItem)({
  background: 'none',

  ':focus-within': {
    color: MainMenuColors.Text,
    background: 'none',
  },
  'summary:focus-visible': {
    color: MainMenuColors.TextActive,
    background: MainMenuColors.BackgroundActive,
  },
});

export const BonusGroupSummary = styled(MenuGroupSummary)({
  display: 'flex',
  alignItems: 'center',
});

export const BonusSubMenu = styled(MainSubMenu)({
  paddingBottom: 16,
  color: MainMenuColors.Text,

  '::before': {
    display: 'none',
  },
});

export const BonusBadge = styled(NotificationBadge)({
  transform: 'translate(calc(0.2em + 4px), calc(-0.2em + 2px))',
  maxWidth: 'fit-content',
  color: BonusMenuColors.BadgeText,
  backgroundColor: BonusMenuColors.BadgeBackground,
});

export const BonusSummaryContent = styled(SummaryContent)({});

export const BonusNavItemIcon = styled(MainMenuItemIcon)({});

export const BonusMenuButton = styled(NoStyleButton)<{ singleRow?: boolean }>(({ singleRow }) => ({
  ...menuLinkStyle,
  display: 'grid',
  gridTemplateColumns: singleRow ? 'min-content auto' : undefined,
  width: '100%',
  paddingTop: 8,
  paddingBottom: 8,
  fontWeight: 'bold',

  ':focus-visible, :focus, :active, :hover': {
    outline: 'none',
    color: 'inherit',
  },

  '&:hover': {
    color: MainMenuColors.TextHover,
    background: MainMenuColors.BackgroundHover,
  },

  '&:focus-visible': {
    color: MainMenuColors.TextActive,
    background: MainMenuColors.BackgroundActive,
  },
}));

export const BonusPageLink = styled(InternalLink)(menuLinkStyle);
