import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Color, DynamicValues, LayoutProps, ZIndex } from '@pafcloud/style';
import { HeaderColors } from '../header-colors';

import type { HeaderContentSpeedy_content$key } from './__generated__/HeaderContentSpeedy_content.graphql';
import type { HeaderContentX3000_content$key } from './__generated__/HeaderContentX3000_content.graphql';
import type { HeaderContentOneOne_content$key } from './__generated__/HeaderContentOneOne_content.graphql';
import type { HeaderContentGolden_content$key } from './__generated__/HeaderContentGolden_content.graphql';
import type { HeaderContentGoldenV2_content$key } from './__generated__/HeaderContentGoldenV2_content.graphql';
import type { HeaderContentDefault_content$key } from './__generated__/HeaderContentDefault_content.graphql';
import type { HeaderContentQuirky_content$key } from './__generated__/HeaderContentQuirky_content.graphql';
import type { HeaderContentQuirkyPurple_content$key } from './__generated__/HeaderContentQuirkyPurple_content.graphql';

export type HeaderContentProps = {
  content: HeaderContentSpeedy_content$key &
    HeaderContentX3000_content$key &
    HeaderContentOneOne_content$key &
    HeaderContentGolden_content$key &
    HeaderContentGoldenV2_content$key &
    HeaderContentQuirky_content$key &
    HeaderContentQuirkyPurple_content$key &
    HeaderContentDefault_content$key;
  isMainMenuOpen: boolean;
  setMainMenuOpen: (value: boolean) => void;
};

const Header = styled.header({
  position: 'sticky',
  top: DynamicValues.GamePauseHeight,
  width: '100%',
  height: LayoutProps.HeaderHeight,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: ZIndex.Header,
  backgroundColor: HeaderColors.Background,
  boxShadow: Color.Elevation.Level1,
});

type HeaderProps = {
  className?: string;
  children?: ReactNode;
};

export const StyledHeader = forwardRef<HTMLElement, HeaderProps>(({ children, className }, ref) => {
  return (
    <Header data-name="header" className={className} ref={ref}>
      {children}
    </Header>
  );
});
StyledHeader.displayName = 'StyledHeader';
