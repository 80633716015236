import { createArrayValueValidator, createObjectValueValidator } from '@pafcloud/collection-utils';
import { CountryCode, LanguageCode } from '@pafcloud/config/market';

export type Localized<T> = Record<LanguageCode, T>;

export const allCurrencyCodes = ['SEK', 'EUR', 'CHF'] as const;

export type CurrencyCode = (typeof allCurrencyCodes)[number];

const languageCodeToNativeNameMap: Localized<string> = {
  en: 'English',
  sv: 'Svenska',
  fi: 'Suomi',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  et: 'Eesti',
  ru: 'Pусский',
  lv: 'Latviešu',
  es: 'Español',
};

export const getNativeLanguageName = (languageCode: `${LanguageCode}`) => {
  return languageCodeToNativeNameMap[languageCode];
};

export const isCurrencyCode = createArrayValueValidator(allCurrencyCodes);

export const isLanguageCode = createObjectValueValidator(LanguageCode);

export const isCountryCode = createObjectValueValidator(CountryCode);
