import { forwardRef } from 'react';
import { Icon, useToggle } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FormInputSuffix } from '../form-input';
import { HookFormInput } from '../hook-form-inputs';
import type { HookFormInputProps } from '../hook-form-inputs';

export const PasswordInput = forwardRef<HTMLInputElement, HookFormInputProps>(({ autoComplete, ...props }, ref) => {
  const [showPassword, toggleShowPassword] = useToggle(false);

  // Spain does not allow auto-filling password.
  if (autoComplete == null && $buildEnv.market === 'spain') {
    autoComplete = 'off';
  }

  return (
    <HookFormInput {...props} autoComplete={autoComplete} type={showPassword ? 'text' : 'password'} ref={ref}>
      <FormInputSuffix>
        <Icon name={showPassword ? 'toggleUnsee' : 'toggleSee'} onClick={toggleShowPassword} />
      </FormInputSuffix>
    </HookFormInput>
  );
});

PasswordInput.displayName = 'PasswordInput';
