import { Router } from 'next/router';
import { useEffect } from 'react';
import { useHandler } from './useHandler';

export const useRouteChange = (onChange: (url: string) => void) => {
  const handleChange = useHandler(onChange);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleChange);
    return () => {
      Router.events.off('routeChangeStart', handleChange);
    };
  }, [handleChange]);
};
