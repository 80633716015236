import type { ServerResponse } from 'node:http';
import { addDays } from 'date-fns/addDays';
import type { Cookie } from '../cookie';
import { COOKIE_CONSENT } from '../applicationCookies';
import { hasRequiredConsentForCookie, parseCookieConsent } from './consent';

type Headers = { [key: string]: string | string[] | undefined };
type Request = { headers?: Headers };

const getCookies = (cookies: string | undefined) => {
  return (
    cookies?.split('; ').reduce<Record<string, string>>((acc, cur) => {
      const parts = cur.split('=');
      if (parts[0] && parts[1]) {
        acc[parts[0]] = parts[1];
      }
      return acc;
    }, {}) ?? {}
  );
};

export const getCookieFromRequest = (request: Request | undefined, cookie: Cookie) => {
  const cookies = getCookies(request?.headers?.cookie as string | undefined);
  return cookies[cookie.name] as string | undefined;
};

export const createGetCookieFromRequest = (request: Request | undefined) => {
  return (cookie: Cookie) => getCookieFromRequest(request, cookie);
};

const getCookieConsentFromRequest = (request: Request | undefined) => {
  const cookieValue = getCookieFromRequest(request, COOKIE_CONSENT);
  if (!cookieValue) {
    return null;
  }
  return parseCookieConsent(decodeURIComponent(cookieValue));
};

const getCurrentSetCookies = (response: ServerResponse): string[] => {
  const cookies = response.getHeader('set-cookie');
  if (cookies == null || typeof cookies === 'number') {
    return [];
  }

  if (typeof cookies === 'string') {
    return [cookies];
  }

  return cookies;
};

const createSetCookieString = (name: string, value: string, props: Record<string, string | boolean | undefined>) => {
  const attributes = Object.entries(props)
    .map(([key, val]) => {
      if (!val) {
        return undefined;
      }

      return val === true ? key : `${key}=${val}`;
    })
    .filter(Boolean);

  return [`${name}=${value}`, ...attributes].join('; ');
};

export const createSetCookie = (cookie: Cookie, value: string) => {
  const { name, expires: daysToExpire, requiredConsent, ...options } = cookie;
  const expires = daysToExpire ? addDays(new Date(), daysToExpire).toUTCString() : undefined;

  return createSetCookieString(name, value, { ...options, expires });
};

export const setCookieInResponse = (response: ServerResponse, cookie: Cookie, value: string) => {
  const cookieConsent = getCookieConsentFromRequest(response.req);
  if (!hasRequiredConsentForCookie(cookieConsent, cookie)) {
    return;
  }

  const setCookieValue = createSetCookie(cookie, value);
  const current = getCurrentSetCookies(response);
  current.push(setCookieValue);

  response.setHeader('set-cookie', current);
};

export const removeCookieInResponse = (response: ServerResponse, cookie: Cookie) => {
  const expiredCookie = { ...cookie, expires: -1 };
  setCookieInResponse(response, expiredCookie, '');
};
