import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { objectWithBuildEnv } from '@pafcloud/base-components';
import * as searchResultStyles_default from './searchResultStyles.default';
import * as searchResultStyles_alternate from './searchResultStyles.alternate';

export const searchResultStyles = objectWithBuildEnv(() => {
  if ($buildEnv.theme === 'goldenV2') {
    return searchResultStyles_alternate;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return searchResultStyles_alternate;
  }
  if ($buildEnv.theme === 'dreams') {
    return searchResultStyles_alternate;
  }
  if ($buildEnv.theme === 'momentum') {
    return searchResultStyles_alternate;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return searchResultStyles_alternate;
  }

  return searchResultStyles_default;
});
