import type { ComponentType, FC, ReactNode } from 'react';
import Head from 'next/head';
import type { AppContext, AppProps } from 'next/app';
import { fetchQuery, graphql } from 'react-relay/hooks';
import type { Variables } from 'react-relay/hooks';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';

import {
  getI18n,
  getInitiatedI18n,
  I18nextProvider,
  initClientTranslations,
  namespaces,
  useTranslation,
} from '@pafcloud/i18n';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import { getClientConfig } from '@pafcloud/config';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { ConfigProvider } from '@pafcloud/contexts';
import { logger } from '@pafcloud/logging';

import { config } from '../lib/config';
import { useCanonical } from '../lib/useCanonical';
import { initRelayEnvironment } from '../lib/initRelayEnvironment';
import { getAllowedSSRCookie } from '../lib/getCookie';
import { HydratedRelayEnvironmentProvider } from '../lib/HydratedRelayEnvironmentProvider';
import { RelayDataProvider } from '../lib/RelayDataProvider';
import type { WithPage } from '../lib/PageWithData';
import { ErrorPage } from '../lib/ErrorPage';
import { NotFoundPage } from '../lib/NotFoundPage';
import { App as PafApp } from '../lib/app/App';
import type { AppQuery } from './__generated__/AppQuery.graphql';

// We need to force the app-assets to be served by nextjs.
// Without it they get tree-shaken since they are only used on the server.
import '@pafcloud/app-assets';

const isServer = typeof window === 'undefined';

const appQuery = graphql`
  query AppQuery {
    ...App_data
  }
`;

const appQueryArguments: AppQuery['variables'] = {};

type AppWrapperProps = {
  children: ReactNode;
  queryData: AppQuery['response'];
  isLoadingClientData: boolean;
};

const AppWrapper: FC<AppWrapperProps> = ({ children, queryData, isLoadingClientData }) => {
  const { t } = useTranslation('common');
  const canonicalURL = useCanonical(config.baseUrl);
  const isSSR = useIsSSR();

  if (config.disableSSR && isSSR) {
    return null;
  }

  return (
    <>
      <Head>
        {canonicalURL && <link rel="canonical" key="canonical" href={canonicalURL} />}
        <title>{t('default-title')}</title>
      </Head>
      <PafApp config={config} data={queryData} isLoadingClientData={isLoadingClientData}>
        {children}
      </PafApp>
    </>
  );
};

type InitialProps = {
  statusCode: number;
  records?: RecordMap;
  queryArguments: Variables;
  translations: Record<string, string>;
  initialProps: Record<string, unknown>;
};

type WithDataComponent = ComponentType<WithPage<AppProps> & InitialProps> & {
  getInitialProps(context: WithPage<AppContext>): Promise<InitialProps>;
};

const WithDataComponent: WithDataComponent = (props) => {
  if (!isServer) {
    initClientTranslations($buildEnv.site, props.translations);
  }

  const i18n = getI18n($buildEnv.site, props.router.locale);

  return (
    <I18nextProvider i18n={i18n}>
      {(() => {
        if (props.statusCode === 404) {
          return (
            <ConfigProvider config={getClientConfig()}>
              <NotFoundPage />
            </ConfigProvider>
          );
        }

        if (props.statusCode === 500 || props.records == null) {
          return (
            <ConfigProvider config={getClientConfig()}>
              <ErrorPage />
            </ConfigProvider>
          );
        }

        return (
          <HydratedRelayEnvironmentProvider records={props.records}>
            <RelayDataProvider<AppQuery> query={appQuery} queryArguments={appQueryArguments}>
              {(app) => (
                <AppWrapper queryData={app.data} isLoadingClientData={app.isLoadingClientData}>
                  {(() => {
                    if (props.Component.query == null) {
                      return <props.Component pageData={{}} {...props.initialProps} />;
                    }

                    // If the page has a query, we need to read it,
                    // and possibly refetch it with personalized data.
                    return (
                      <RelayDataProvider query={props.Component.query} queryArguments={props.queryArguments}>
                        {(page) => <props.Component pageData={page.data} {...props.initialProps} />}
                      </RelayDataProvider>
                    );
                  })()}
                </AppWrapper>
              )}
            </RelayDataProvider>
          </HydratedRelayEnvironmentProvider>
        );
      })()}
    </I18nextProvider>
  );
};

WithDataComponent.getInitialProps = async ({ ctx, router, Component }) => {
  const initialPageProps = await Component.getInitialProps?.(ctx);

  const { queryArguments = {}, postQuery, ...initialProps } = initialPageProps ?? {};

  const translations: Record<string, string> = {};

  if (isServer) {
    const i18n = await getInitiatedI18n($buildEnv.site, router.locale);

    // Pass translations to the client - we are currently unable to only pass relevant namespaces.
    namespaces.forEach((namespace) => {
      translations[namespace] = i18n.getResourceBundle(i18n.language, namespace);
    });
  }

  try {
    // This must know nothing about the client.
    // Don't pass in any other headers, cookies, or anything here that is specific to the player.
    const environment = initRelayEnvironment({
      headers: {
        cookie: getAllowedSSRCookie(ctx.req),
      },
    });

    if (isServer) {
      // We need to fetch the app query on the server to hydrate the relay store.
      // But subsequent page changes that happens on the client doesn't need to refetch it.
      await new Promise<void>((resolve, reject) => {
        fetchQuery(environment, appQuery, appQueryArguments, {
          networkCacheConfig: {
            metadata: {
              language: router.locale ?? router.defaultLocale,
            },
          },
        }).subscribe({
          error(reason: unknown) {
            logger.error('Could not load app query', { error: reason });
            reject();
          },
          complete() {
            resolve();
          },
        });
      });
    }

    if (Component.query) {
      const pageQuery = Component.query;

      await new Promise<void>((resolve, reject) => {
        fetchQuery(environment, pageQuery, queryArguments, {
          networkCacheConfig: {
            metadata: {
              language: router.locale ?? router.defaultLocale,
            },
          },
        }).subscribe({
          next: postQuery,
          error(reason: unknown) {
            logger.error('Could not load page query', { error: reason });
            reject();
          },
          complete() {
            resolve();
          },
        });
      });
    }

    return {
      initialProps,
      queryArguments,
      records: environment.getStore().getSource().toJSON(),
      statusCode: ctx.res?.statusCode ?? 200,
      translations,
    };
  } catch {
    if (ctx.res) {
      ctx.res.statusCode = 500;
    }

    return {
      initialProps,
      queryArguments,
      statusCode: 500,
      translations,
    };
  }
};

export default WithDataComponent;
