import type { FC } from 'react';
import styled from '@emotion/styled';
import { Heading, MarkdownParser, Section } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint, Color, FontHeadingSize, FontTextSize } from '@pafcloud/style';
import { LinkToCustomerService } from '../link-to-customer-service';
import { Search } from '../search';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: 32,
});

const SearchContainer = styled.div({
  width: '100%',
  marginTop: 32,
});

const FullWidthSearch = styled(Search)({
  width: '100%',
});

const Article = styled.article({
  display: 'flex',
  flexWrap: 'wrap-reverse',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'stretch',
});

const StyledSection = styled.section({
  width: '100%',
  fontSize: FontTextSize.Normal,

  [Breakpoint.LaptopOrLarger]: {
    width: '50%',
    paddingRight: 32,
  },
});

const Header = styled(Heading)({
  fontSize: 'min(max(100px, 48vw), 200px)',
  lineHeight: '.7',
  margin: '32px 0 0',
});

const SubHeader = styled(Heading)({
  fontSize: FontHeadingSize.Small,
  margin: '8px 0',
});

const Paragraph = styled(MarkdownParser)({
  fontSize: FontTextSize.Big,
  color: Color.TextColors.BodyText,
});

const SearchHeader = styled(Heading)({
  fontSize: FontHeadingSize.Tiny,
  margin: '16px 0',
});

const LinkContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 32,
  fontSize: FontTextSize.Big,
  width: '100%',

  [Breakpoint.LaptopOrLarger]: {
    width: '50%',
    gap: 64,
  },
});

const StyledLink = styled.a({
  display: 'block',
  margin: '8px 0',
  color: Color.TextColors.LinkText,
  fontWeight: 600,
});

const SupportLink = styled(LinkToCustomerService)({
  color: Color.TextColors.LinkText,
  fontWeight: 700,
});

export const NotFound: FC = () => {
  const { t } = useTranslation('common');
  const gameListId =
    $buildEnv.site === 'mycasino.ch' ? '5eac0f0b-c2ae-4156-ae4a-7388b4d9fe5e' : 'de2ec797-1055-426f-a32d-13c99cdcc9de';

  return (
    <Section>
      <Container>
        <Article>
          <StyledSection>
            <Header>{t('404.header')}</Header>
            <SubHeader>{t('404.subheader')}</SubHeader>
            <Paragraph>{t('404.text')}</Paragraph>

            {/* TODO: Add a "feature flag" for "header search". Header search result style breaks this search. */}
            {$buildEnv.site === 'paf.se' ||
            $buildEnv.site === 'paf.com' ||
            $buildEnv.site === 'pafbet.lv' ||
            $buildEnv.site === 'paf.ee' ||
            $buildEnv.site === 'paf.es' ||
            $buildEnv.site === 'mycasino.ch' ||
            $buildEnv.site === 'noaccountbet.se' ||
            $buildEnv.site === 'noaccountcasino.se' ||
            $buildEnv.site === 'prankcasino.se' ||
            $buildEnv.site === 'speedybet.com' ||
            $buildEnv.site === 'speedycasino.com' ? (
              <SearchContainer>
                <SearchHeader>{t('404.search.header')}</SearchHeader>
                <FullWidthSearch gameListId={gameListId} />
              </SearchContainer>
            ) : null}
          </StyledSection>
        </Article>
        <LinkContainer>
          <StyledLink href="/" aria-label={t('404.link.start-page')}>
            {t('404.link.start-page')}
          </StyledLink>
          <SupportLink />
        </LinkContainer>
      </Container>
    </Section>
  );
};
