import type { FC } from 'react';
import { FormInput, FormInputSuffix } from '@pafcloud/form-components';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint } from '@pafcloud/style';
import { getCurrencyCode } from '@pafcloud/locale';
import { matchesMediaQuery } from '@pafcloud/react-hook-utils';
import { useFlowRouter } from '@pafcloud/flow-router';
import { InputContainer } from './shared';
import type { DepositToPlayFormInputProps } from './shared';

export const DepositToPlayFormInput: FC<DepositToPlayFormInputProps> = ({ register }) => {
  const { t } = useTranslation('deposit');
  const { openFlow } = useFlowRouter();

  return (
    <InputContainer>
      <FormInput
        autoComplete="off"
        floatingLabel={false}
        type="number"
        step="any"
        min="0"
        label={t('choose-amount.label')}
        onClick={() => {
          if (matchesMediaQuery(Breakpoint.Phone)) {
            openFlow('deposit');
          }
        }}
        {...register('amount')}
      >
        <FormInputSuffix>{getCurrencyCode()}</FormInputSuffix>
      </FormInput>
    </InputContainer>
  );
};
