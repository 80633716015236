import { useMemo, useState } from 'react';
export function useAccessRequirements<TAccessRequirement>(requirements: Readonly<TAccessRequirement[]>) {
  const [currentRequirementIndex, setCurrentRequirementIndex] = useState(0);

  const requirement = useMemo(() => {
    if (currentRequirementIndex < requirements.length) {
      return requirements[currentRequirementIndex];
    }
    return null;
  }, [currentRequirementIndex, requirements]);

  const complete = () => {
    setCurrentRequirementIndex((current) => current + 1);
  };

  return { requirement, complete };
}
