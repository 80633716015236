/**
 * @generated SignedSource<<407535376d68f6e67d4daa405560abf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteAlerts_data$data = {
  readonly site: {
    readonly alerts: ReadonlyArray<{
      readonly content: {
        readonly json: string | null;
      };
      readonly id: string;
    } | null>;
  };
  readonly " $fragmentType": "SiteAlerts_data";
};
export type SiteAlerts_data$key = {
  readonly " $data"?: SiteAlerts_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteAlerts_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteAlerts_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteAlert",
          "kind": "LinkedField",
          "name": "alerts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Json",
              "kind": "LinkedField",
              "name": "content",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "json",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "aefb4c05ff1446025086cf024244d24d";

export default node;
