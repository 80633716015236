import styled from '@emotion/styled';
import type { FC } from 'react';
import { isToday } from 'date-fns/isToday';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { useFragment, graphql } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { Font, FontTextSize } from '@pafcloud/style';
import { Icon, NoStyleButton } from '@pafcloud/base-components';
import type { Icon as IconName } from '@pafcloud/icon';
import { kebabCase } from '@pafcloud/util';
import { InboxColors } from '../inbox-colors';
import type { MessageListItem_item$key, InboxMessageSubject } from './__generated__/MessageListItem_item.graphql';

const itemFragment = graphql`
  fragment MessageListItem_item on InboxMessagePreview {
    messageId
    category
    subject
    publishedAt
    opened
  }
`;

const MessageItem = styled(NoStyleButton)({
  width: '100%',
  padding: 16,
  textAlign: 'left',
  fontSize: FontTextSize.Normal,
  color: InboxColors.Text,

  '& + &': {
    borderTop: `1px solid ${InboxColors.BorderColor}`,
  },

  ':focus,:hover': {
    backgroundColor: InboxColors.Highlight,
    color: InboxColors.Text,
    outlineColor: InboxColors.HighlightBorder,
  },

  '&[data-read="true"]': {
    color: InboxColors.MessageSeenText,
  },
});

const MessageHeader = styled.div({
  fontFamily: Font.Body,
  fontSize: FontTextSize.Small,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 12,
});

const MessageIcon = styled(Icon)({
  marginRight: 12,
});

const Category = styled.span({
  textTransform: 'uppercase',
});

const Timestamp = styled.time({
  marginLeft: 'auto',
  opacity: 0.5,
});

const Subject = styled.div<{ opened?: boolean }>(({ opened }) => ({
  fontWeight: opened ? 'normal' : 'bold',
  fontSize: FontTextSize.Big,
}));

const nonStandardIcons: Partial<Record<InboxMessageSubject, IconName>> = {
  PAYMENT: 'wallet',
  PROMOTION: 'trophy',
  WINNER: 'gift',
};

type Props = {
  item: MessageListItem_item$key;
  onClick: (messageId: string) => void;
};

export const MessageListItem: FC<Props> = ({ item, onClick }) => {
  const { t } = useTranslation('inbox');
  const { subject, publishedAt, opened, category, messageId } = useFragment(itemFragment, item);

  const getFormattedMessageTime = () => {
    const dateTime = new Date(publishedAt);
    const distanceInMinutes = differenceInMinutes(new Date(), dateTime);

    if (distanceInMinutes < 30) {
      return t('just-now');
    }

    if (isToday(dateTime)) {
      return t('time-today-formatter', { date: dateTime });
    }

    return t('date-formatter', { date: dateTime });
  };

  return (
    <MessageItem onClick={() => onClick(messageId)} data-read={opened}>
      <MessageHeader>
        <MessageIcon size="24" name={nonStandardIcons[category] ?? 'message'} />
        <Category>{t(`message.type.${kebabCase(category)}`)}</Category>
        <Timestamp dateTime={publishedAt}>{getFormattedMessageTime()}</Timestamp>
      </MessageHeader>
      <Subject opened={opened}>{subject}</Subject>
    </MessageItem>
  );
};
