import { useRef, useState } from 'react';
import { useHandler } from './useHandler';

type Options = {
  /** wait time in milliseconds */
  wait: number;
};

type SetTimeoutType = ReturnType<typeof setTimeout> | null;

/**
 * Hook used for throttling calls to a function.
 *
 * @param fn The function to throttle
 * @param options Options object for configuring the throttling
 * @returns The throttled function
 */
export const useThrottle = <FN extends (...args: unknown[]) => unknown>(fn: FN, options: Options) => {
  const timeout = useRef<SetTimeoutType>(null);
  const [isThrottling, setIsThrottling] = useState(false);

  const cancel = useHandler(() => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
      timeout.current = null;
      setIsThrottling(false);
    }
  });

  const throttledFn = useHandler((...args: Parameters<FN>) => {
    if (timeout.current == null) {
      setIsThrottling(true);
      fn(...args);
      timeout.current = setTimeout(cancel, options.wait);
    }
  });

  return { throttledFn, cancel, isThrottling };
};
