import type { FC } from 'react';
import { FormInputMessage } from '../form-input';

export type Alignment = Parameters<typeof FormInputMessage>[0]['alignment'];

type Props = {
  id: string;
  info?: string;
  error?: string;
  alignment?: Alignment;
};

export const HookFormMessage: FC<Props> = ({ info, error, id, alignment }) => {
  if (error) {
    return (
      <FormInputMessage type="error" id={id} alignment={alignment}>
        {error}
      </FormInputMessage>
    );
  }

  if (info) {
    return (
      <FormInputMessage type="info" id={id} alignment={alignment}>
        {info}
      </FormInputMessage>
    );
  }

  return null;
};
