import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { SidebarOverlay as SidebarOverlay_default } from './SidebarOverlay.default';
import { SidebarOverlay as SidebarOverlay_classic } from './SidebarOverlay.classic';

export const SidebarOverlay = withBuildEnv(() => {
  if ($buildEnv.theme === 'classic') {
    return SidebarOverlay_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return SidebarOverlay_classic;
  }
  if ($buildEnv.theme === 'golden') {
    return () => null;
  }
  if ($buildEnv.theme === 'quirky') {
    return () => null;
  }

  return SidebarOverlay_default;
});
