export const safeParseJson = <T = unknown>(str: string | undefined | null) => {
  if (str) {
    try {
      return JSON.parse(str) as T;
    } catch (err) {
      const error = new Error('Json parse failed with error', { cause: err });
      console.error(error);
    }
  }

  return undefined;
};
