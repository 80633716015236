import type { FC, ReactNode } from 'react';
import type { ClientConfig } from '@pafcloud/config';
import { buildContext } from '../buildContext';

const [ConfigContext, useConfig] = buildContext<ClientConfig>('ConfigContext');

export { useConfig };

export type ConfigProviderProps = {
  config: ClientConfig;
  children: ReactNode;
};

export const ConfigProvider: FC<ConfigProviderProps> = ({ config, children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);
