import Router from 'next/router';
import { useEffect } from 'react';
import { dispatchTrackingEvent } from '@pafcloud/tracking';

export function usePageviewEvent() {
  useEffect(() => {
    // We're using the singleton "Router" here,
    // as "useRouter" will always point to
    // the current router state when the component renders,
    // and we need to know the state *after* has rendered the new route.

    const handleRouteChange = () => {
      dispatchTrackingEvent('pageview', {
        page: {
          path: document.location.pathname,
          title: document.title,
          type: Router.pathname,
        },
      });
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
}
