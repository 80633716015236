import styled from '@emotion/styled';
import type { FC } from 'react';
import type { LinkProps } from '../link';
import { Link } from '../link';
import type { ButtonProps, ButtonSize, ButtonVariant, ButtonColorStyle } from '../button';
import {
  getCallToActionEffect,
  getColorAttributes,
  getCommonStyles,
  getIconStyles,
  getShadowAttributes,
  getSizeAttributes,
  getVariableStyles,
} from '../button/get-attributes';
import { Icon } from '../icon';

export type StyledLinkProps = LinkProps &
  ButtonSize &
  ButtonVariant &
  ButtonColorStyle &
  Pick<ButtonProps, 'ctaEffect' | 'icon' | 'iconAlignment'>;

const shouldForwardProp = (propName: string) => {
  return !['variant', 'colorstyle', 'size', 'ctaEffect', 'fullWidth', 'isLoading', 'iconAlignment'].includes(propName);
};

const StyledLink = styled(Link, { shouldForwardProp })<StyledLinkProps>((props) => ({
  ...getCommonStyles(),
  ...getColorAttributes(props),
  ...getVariableStyles(),
  ...getSizeAttributes(props),
  ...getShadowAttributes(props),
  ...getCallToActionEffect(props),
  ...getIconStyles(props),

  '&:hover, &:active, &:focus': {
    textDecoration: 'none',
  },
}));

export const ButtonLink: FC<StyledLinkProps> = ({ children, ...props }) => (
  <StyledLink {...props}>
    {props.icon == null ? null : <Icon name={props.icon} size="0.85em" />}
    {children}
  </StyledLink>
);
