import type { FC } from 'react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { Heading } from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';
import { isType } from '@pafcloud/collection-utils';
import { RichText, RichTextJsonContent } from '@pafcloud/rich-text';
import type { FooterBlocks_footerBlocks$key } from './__generated__/FooterBlocks_footerBlocks.graphql';
import { LogoImage } from './LogoImage';
import { FooterColors } from './footer-colors';
import { LogoWrapper } from './shared';

const footerBlocksFragment = graphql`
  fragment FooterBlocks_footerBlocks on FooterBlock @relay(plural: true) {
    ... on FooterImageBlock {
      __typename
      title
      images {
        url
        name
        image {
          url
          width
          height
        }
      }
    }

    ... on FooterRichTextBlock {
      __typename
      text {
        textAlignment
        jsonContent {
          json
        }
      }
    }
  }
`;

const FooterLogoRowWrapper = styled.div({
  width: '100%',
  marginTop: 8,
  textAlign: 'center',
});

const LogoRowHeading = styled(Heading)({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 8,
  fontSize: FontTextSize.Normal,
  color: FooterColors.Text,
  fontWeight: 400,
  '::before, ::after': {
    content: '""',
    flex: '1 1',
    margin: 'auto',
  },
  ':before': {
    marginRight: '0.5rem',
  },
  ':after': {
    marginLeft: '0.5rem',
  },
});

const FooterRichText = styled(RichText)({
  display: 'inline-block',
  color: FooterColors.Text,
  fontSize: FontTextSize.Normal,
  margin: '8px auto',

  a: {
    color: FooterColors.Link,

    '&:hover': {
      color: 'inherit',
    },
  },
  p: {
    color: FooterColors.Text,
    fontSize: FontTextSize.Normal,
    lineHeight: 1.325,
    margin: '0 0 8px',

    ':last-child': {
      marginBottom: 0,
    },
  },
});

type FooterBlocksProps = {
  blocks: FooterBlocks_footerBlocks$key;
};

export const FooterBlocks: FC<FooterBlocksProps> = (props) => {
  const footerBlocks = useFragment(footerBlocksFragment, props.blocks);

  return (
    <>
      {footerBlocks.map((footerBlock, blockIndex) => (
        <FooterLogoRowWrapper key={blockIndex}>
          {'title' in footerBlock && footerBlock.title ? <LogoRowHeading>{footerBlock.title}</LogoRowHeading> : null}

          {isType(footerBlock, 'FooterRichTextBlock') && footerBlock.text != null && (
            <FooterRichText alignment={footerBlock.text.textAlignment}>
              <RichTextJsonContent content={footerBlock.text.jsonContent} />
            </FooterRichText>
          )}

          {isType(footerBlock, 'FooterImageBlock') && (
            <LogoWrapper>
              {footerBlock.images.map((footerImage, index) => (
                <LogoImage
                  key={index}
                  imageUrl={footerImage.image.url}
                  url={footerImage.url}
                  name={footerImage.name}
                  width={footerImage.image.width}
                  height={footerImage.image.height}
                />
              ))}
            </LogoWrapper>
          )}
        </FooterLogoRowWrapper>
      ))}
    </>
  );
};
