import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const GoldenPalette = {
  OceanicNoir: '#182F39',

  // Primary Colors
  Yellow: '#E5C64A',
  YellowLight: '#EED15F',
  YellowHighlight: '#EFD980',

  // Alternate Colors
  Turquoise: '#26EFD0',
  TurquoiseLight: '#64F5DF',
  TurquoiseHighlight: '#9CF3E6',
  TurquoiseMuted: '#a0d4d4',

  // Neutral Colors
  Dark: '#0a2028',
  Darker: '#020507',
  Neutral: '#787F83',
  Lighter: '#eaf0f3',
  White: '#ffffff',

  // Extra
  Aux: '#EF2656',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: GoldenPalette.Yellow,
  PrimaryTint: GoldenPalette.YellowLight,
  PrimaryContrast: GoldenPalette.Darker,

  Secondary: GoldenPalette.Turquoise,
  SecondaryTint: GoldenPalette.TurquoiseHighlight,
  SecondaryContrast: GoldenPalette.Darker,

  Accent: GoldenPalette.Turquoise,
  AccentTint: GoldenPalette.TurquoiseHighlight,
  AccentContrast: GoldenPalette.Darker,

  Gradient: GoldenPalette.Turquoise,
  GradientTint: GoldenPalette.TurquoiseHighlight,
  GradientContrast: GoldenPalette.Darker,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: GoldenPalette.Yellow,
  BodyText: GoldenPalette.White,
  MutedText: '#a0a8ac',
  HighlightedText: GoldenPalette.Yellow,
  LinkText: GoldenPalette.Turquoise,
  ErrorText: GoldenPalette.Aux,
  DisabledText: GoldenPalette.Neutral,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: '#0c1d24',
    Foreground: GoldenPalette.White,
    Dimmed: createTransparentColor(GoldenPalette.Turquoise, 0.15),
  },
  Nested: {
    Background: GoldenPalette.Darker,
    Foreground: GoldenPalette.White,
    Dimmed: createTransparentColor(GoldenPalette.Turquoise, 0.15),
  },
  Disabled: {
    Background: GoldenPalette.Neutral,
    Foreground: GoldenPalette.Darker + '80',
    Dimmed: createTransparentColor(GoldenPalette.Darker, 0.15),
  },
  Floating: {
    Background: GoldenPalette.OceanicNoir,
    Foreground: GoldenPalette.White,
    Dimmed: createTransparentColor(GoldenPalette.Turquoise, 0.15),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: GoldenPalette.Yellow,
  SuccessContrast: GoldenPalette.Darker,

  Info: GoldenPalette.TurquoiseMuted,
  InfoContrast: GoldenPalette.Darker,

  Attention: GoldenPalette.Yellow,
  AttentionContrast: GoldenPalette.Darker,

  Danger: GoldenPalette.Aux,
  DangerContrast: GoldenPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '8px',
  Large: '32px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: '0 1px 2px var(--shadow-color, rgba(0, 0, 0, 0.1))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: ColorDefinition['General'] = {
  Bonus: GoldenPalette.Turquoise,
  BonusContrast: GoldenPalette.Darker,
};

const Layout: ColorDefinition['Layout'] = {
  Background: GoldenPalette.OceanicNoir,
  BrandBorder: Primitive.Primary,
  BrowserTheme: GoldenPalette.OceanicNoir,
  LoadingColor: Primitive.Accent,
  Overlay: Surface.Base.Background + 'CC',
  Sidebar: {
    Background: GoldenPalette.OceanicNoir,
    Foreground: GoldenPalette.Lighter,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: Primitive.Primary,
  Text: GoldenPalette.White,
  TextHighlight: Primitive.Secondary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
