import { Color } from '@pafcloud/style';

export const Colors = {
  TrackBackground: `${Color.Surface.Base.Foreground}20`,
  TrackBackgroundChecked: Color.Primitive.Accent,
  TrackBorder: `inset ${Color.Elevation.Level2}, 0 0 0 1px ${Color.Surface.Base.Dimmed}`,
  TrackBorderFocus: `0 0 0 2px ${Color.Primitive.Accent}80`,

  ThumbBackground: Color.Primitive.AccentContrast,
  ThumbBackgroundChecked: Color.Primitive.AccentContrast,
  ThumbBorder: undefined,
  ThumbBorderFocus: undefined,
  ThumbBoxShadow: Color.Elevation.Level2,
};
