export { default as arrowLeftFat } from './icons/arrowLeftFat.svg';
export { default as chevronDoubleRight } from './icons/chevronDoubleRight.svg';
export { default as chevronRight } from './icons/chevronRight.svg';
export { default as chevronRightFat } from './icons/chevronRightFat.svg';
export { default as chevronRightThinRounded } from './icons/chevronRightThinRounded.svg';
export { default as closeFat } from './icons/closeFat.svg';
export { default as closeFatRounded } from './icons/closeFatRounded.svg';
export { default as closeThinRounded } from './icons/closeThinRounded.svg';
export { default as hamburger } from './icons/hamburger.svg';
export { default as search } from './icons/search.svg';
export { default as toggleSee } from './icons/toggleSee.svg';
export { default as toggleUnsee } from './icons/toggleUnsee.svg';
export { default as share } from './icons/share.svg';
