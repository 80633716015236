import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { buildContext } from '../buildContext';

export const NAVIGATION_FROM_SPORTS_PAGE = 'navigationFromSportsPage';

export type LayoutVisibilityState = {
  header: boolean;
  footer: boolean;
};

type State = {
  headerVisible: boolean;
  footerVisible: boolean;
  setLayoutVisibility: (value: LayoutVisibilityState) => void;
};

export const [LayoutVisibilityContext, useLayoutVisibility] = buildContext<State>('LayoutContext');

type Props = {
  children: ReactNode;
};

export const LayoutVisibilityProvider: FC<Props> = ({ children }) => {
  const { asPath } = useRouter();
  const [layoutVisibility, setLayoutVisibility] = useState<LayoutVisibilityState>({
    header: true,
    footer: true,
  });

  useEffect(() => {
    if ($buildEnv.site === 'x3000.ee') {
      if (window.sessionStorage.getItem(NAVIGATION_FROM_SPORTS_PAGE)) {
        setLayoutVisibility({
          header: false,
          footer: false,
        });
      }
    }
  }, [asPath]);

  const value = useMemo(
    () => ({
      headerVisible: layoutVisibility.header,
      footerVisible: layoutVisibility.footer,
      setLayoutVisibility,
    }),
    [layoutVisibility],
  );

  return <LayoutVisibilityContext.Provider value={value}>{children}</LayoutVisibilityContext.Provider>;
};

export const useHideLayout = (state: LayoutVisibilityState) => {
  const { setLayoutVisibility } = useLayoutVisibility();

  useEffect(() => {
    setLayoutVisibility({
      header: !state.header,
      footer: !state.footer,
    });
    return () =>
      setLayoutVisibility({
        header: true,
        footer: true,
      });
  }, [setLayoutVisibility, state.footer, state.header]);
};
