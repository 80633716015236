import styled from '@emotion/styled';
import type { UseFormRegister } from 'react-hook-form';
import { Breakpoint } from '@pafcloud/style';
import type { DepositFormInput } from './DepositToPlayForm';

export const InputContainer = styled.div({
  textAlign: 'center',
  margin: '16px auto',
  [Breakpoint.TabletOrLarger]: {
    maxWidth: '400px',
  },
});

export type DepositToPlayFormInputProps = {
  register: UseFormRegister<DepositFormInput>;
};
