import type { LogConfig } from './common';
import { logConfig } from './common';

export type ClientConfig = LogConfig & {
  applePayScriptUrl?: string;
  baseUrl: string;
  helpdeskUrl: string;
  domain: string;
  gtmId: string;
  newKycUploadBaseUrl: string;
  sentryDSN?: string;
  debugSentry?: string;
  serverLocalUrl: string;
  version?: string;
  sanityProjectId: string;
  sanityDataset: string;
  disableSSR: boolean;
  cloudflareSiteKey?: string;
  siteHasLoyaltyProgram: boolean;
  p3PaymentEnabled: boolean;
  multisiteRegistrationEnabled: boolean;
  kreditzBaseUrl?: string;
  verifyPhoneNumber: boolean;
  switchNetDepositLimit: boolean;

  // Temporary until we can use the dynamic slug for betting links.
  bettingPageSlug: string;
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const clientConfig: ClientConfig = {
  ...logConfig,
  baseUrl: process.env.BASE_URL!,
  helpdeskUrl: process.env.HELPDESK_URL!,
  domain: process.env.DOMAIN!,
  gtmId: process.env.GOOGLE_TAG_MANAGER_ID!,
  newKycUploadBaseUrl: process.env.NEW_KYC_UPLOAD_BASE_URL!,
  sentryDSN: process.env.SENTRY_DSN,
  debugSentry: process.env.DEBUG_SENTRY,
  serverLocalUrl: process.env.SERVER_LOCAL_URL!,
  version: process.env.VERSION,
  sanityProjectId: process.env.SANITY_PROJECT_ID!,
  sanityDataset: process.env.SANITY_DATASET!,
  applePayScriptUrl: 'https://static.paymentiq.io/applepay/merchant-createtoken.min.js',
  disableSSR: process.env.DISABLE_SSR === 'true',
  cloudflareSiteKey: process.env.CLOUDFLARE_SITEKEY,
  siteHasLoyaltyProgram: process.env.SITE_HAS_LOYALTY_PROGRAM === 'true',
  p3PaymentEnabled: process.env.P3_PAYMENT_ENABLED === 'true',
  multisiteRegistrationEnabled: process.env.MULTISITE_REGISTRATION_ENABLED === 'true',
  kreditzBaseUrl: 'https://vista.kreditz.com/connect_bank',
  verifyPhoneNumber: process.env.VERIFY_PHONE_NUMBER === 'true',
  switchNetDepositLimit: process.env.SWITCH_NET_DEPOSIT_LIMIT === 'true',
  bettingPageSlug: process.env.BETTING_PAGE_SLUG ?? 'betting',
};

declare global {
  interface Window {
    pafAppConfig: ClientConfig;
  }
}

export const getClientConfig = () => {
  const isServer = typeof window === 'undefined';
  return isServer ? clientConfig : window.pafAppConfig;
};
