import type { ComponentProps, FC } from 'react';
import styled from '@emotion/styled';
import { icons } from './icons';

/**
 * Make sure to crop the svg to it's content. No whitespace on the outside should be present.
 * @see https://svgcrop.com/
 */

export const IconBase = styled.span({
  margin: 0,
  display: 'inline-flex',
  alignItems: 'center',
});

type IconProps = ComponentProps<'svg'> & {
  name: keyof typeof icons;
  size?: string | number;
  color?: string;
};

export const Icon: FC<IconProps> = ({
  children,
  name,
  size = '1.5rem',
  color = 'currentColor',
  className,
  ...props
}) => {
  const Svg = name in icons ? icons[name] : 'svg';

  return (
    <IconBase className={className}>
      <Svg {...props} height={size} fill={color} preserveAspectRatio="xMidYMid meet" transform-origin="center center">
        {children}
      </Svg>
    </IconBase>
  );
};
