import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@pafcloud/i18n';
import { Icon } from '@pafcloud/base-components';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import {
  ResultDeleteButton,
  ResultImage,
  ResultLink,
  ResultSupplier,
  ResultTitle,
} from './search-result-styles/searchResultStyles.default';
import { useRecentSearch } from './useRecentSearch';
import type { RecentSearchResultChildrenProps } from './RecentSearchResultChildren';

export const RecentSearchResultChildren: FC<RecentSearchResultChildrenProps> = ({
  listRef,
  gameListId,
  onClickResult,
  search,
}) => {
  const { t } = useTranslation('search');
  const { asPath } = useRouter();
  const { deleteRecentSearchResult, insertRecentSearchResult } = useRecentSearch(gameListId);

  return (
    <>
      <ResultLink
        onClick={(event) => {
          insertRecentSearchResult(search);
          rememberGameHomeUrl(asPath);
          onClickResult?.();
          event.currentTarget.blur();
        }}
        href={search.launchUrl}
      >
        {search.thumbnail.src == null ? (
          <ResultImage
            // fallback for players with saved results in non-object format
            src={search.thumbnail}
            width={60}
            height={60}
          />
        ) : (
          <ResultImage src={search.thumbnail.src} width={search.thumbnail.width} height={search.thumbnail.height} />
        )}
        <ResultTitle>{search.rawTitle}</ResultTitle>
        <ResultSupplier>{search.rawSupplier}</ResultSupplier>
      </ResultLink>
      <ResultDeleteButton
        onClick={() => {
          listRef.current?.focus();
          deleteRecentSearchResult(search);
        }}
        aria-label={t('search-recent.delete', { gameTitle: search.rawTitle })}
      >
        <Icon name="trash" size="18" />
      </ResultDeleteButton>
    </>
  );
};
