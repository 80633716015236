import { $buildEnv } from '@pafcloud/config/src/buildEnv';

export const LayoutProps = {
  GamePauseHeight: $buildEnv.market === 'switzerland' ? 0 : 34,
  HeaderHeight: (() => {
    if ($buildEnv.theme === 'momentum') {
      return 64;
    }
    if ($buildEnv.theme === 'momentum-neon') {
      return 64;
    }
    if ($buildEnv.theme === 'quirky-purple') {
      return 64;
    }

    return 56;
  })(),
  SidebarWidthThin: 256,
};

export const CSSVariables = {
  GameListGap: '--game-list-gap',
  GamePauseHeight: '--game-pause-height',
  HeaderHeight: '--header-height',
  HeaderMaxHeight: '--header-max-height',
  TileBorderRadius: '--tile-border-radius',
  ViewportHeight: '--vh',
};

export const DynamicValues = {
  HeaderHeight: 'calc(var(--header-height, 0) * 1px)',
  HeaderMaxHeight: 'calc(var(--header-max-height, 0) * 1px)',
  GamePauseHeight: 'calc(var(--game-pause-height, 0) * 1px)',
  ViewportHeight: 'calc(var(--vh, 100vh) * 1px)',
};
