import type { FC } from 'react';
import styled from '@emotion/styled';
import { FontTextSize } from '@pafcloud/style';
import { RichText, RichTextJsonContent } from '@pafcloud/rich-text';

const StyledRichText = styled(RichText)({
  '&&&': {
    'h2, h3, h4, h5, h6': {
      marginTop: 8,
    },
    p: {
      fontSize: FontTextSize.Small,
      marginTop: 8,
    },
  },
});

type SiteAlertRichTextProps = {
  content: {
    json: string | null;
  };
};

export const SiteAlertRichText: FC<SiteAlertRichTextProps> = ({ content }) => (
  <StyledRichText>
    <RichTextJsonContent content={content} />
  </StyledRichText>
);
