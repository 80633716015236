import { useEffect, useRef } from 'react';
import { useThrottle, useRouteChange } from '@pafcloud/react-hook-utils';
import { useIsLoggedIn, usePlayerStatus } from '@pafcloud/contexts';
import { useHandleSessionExpired } from '../use-logout';
import { useKeepAlive } from './useKeepAlive';

const FOUR_MINUTES = 4 * 60 * 1000;

const useKeepAliveOnNavigation = () => {
  const keepAlive = useKeepAlive();
  const isLoggedIn = useIsLoggedIn();
  const { throttledFn: throttledKeepAlive, cancel } = useThrottle(keepAlive, { wait: FOUR_MINUTES });

  useRouteChange(() => {
    if (isLoggedIn) {
      throttledKeepAlive();
    }
  });

  useEffect(() => cancel, [cancel]);
};

export const useSyncPlayerSession = () => {
  const playerStatus = usePlayerStatus();
  const latestPlayerStatus = useRef(playerStatus);
  const handleSessionExpired = useHandleSessionExpired();

  useKeepAliveOnNavigation();

  useEffect(() => {
    const playerWasLoggedIn = latestPlayerStatus.current === 'LOGGED_IN';
    const playerIsLoggedOut = playerStatus === 'LOGGED_OUT';

    if (playerWasLoggedIn && playerIsLoggedOut) {
      void handleSessionExpired();
    }

    latestPlayerStatus.current = playerStatus;
  }, [playerStatus, handleSessionExpired]);
};
