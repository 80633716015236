import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useUpdatePlayerLanguageMutation } from './__generated__/useUpdatePlayerLanguageMutation.graphql';

const createUseUpdatePlayerLanguage = createUseMutation<useUpdatePlayerLanguageMutation>(graphql`
  mutation useUpdatePlayerLanguageMutation($input: UpdatePlayerLanguageInput!) {
    updatePlayerLanguage(input: $input) {
      success
      player {
        language
      }
    }
  }
`);

export const useUpdatePlayerLanguage = createUseUpdatePlayerLanguage(() => {
  return null;
});
