import type { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';

type ScrollShadowProps = {
  className?: string;
  children?: ReactNode;
};

const scrollShadowColor = {
  Background: `var(--scroll-shadow-background, ${Color.Surface.Floating.Background})`,
  Glow: `var(--scroll-shadow-glow, ${Color.Surface.Floating.Dimmed})`,
};

export const ScrollShadow: FC<ScrollShadowProps> = styled.div({
  width: ' 100%',
  overflow: 'auto',
  overscrollBehavior: 'contain',
  maxHeight: '100vh',
  background: `
    linear-gradient(${scrollShadowColor.Background} 33%, transparent),
    linear-gradient(transparent, ${scrollShadowColor.Background} 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, ${scrollShadowColor.Glow}, transparent),
    radial-gradient(farthest-side at 50% 100%, ${scrollShadowColor.Glow}, transparent) 0 100%`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'local, local, scroll, scroll',
  backgroundSize: '100% 72px, 100% 72px, 100% 24px, 100% 24px',
});

export const HorizontalScrollShadow: FC<ScrollShadowProps> = styled.div({
  overflow: 'auto',
  background: `
    linear-gradient(to right, ${scrollShadowColor.Background}, transparent),
    linear-gradient(to left, ${scrollShadowColor.Background}, transparent),
    linear-gradient(to right,  ${scrollShadowColor.Glow}, transparent),
    linear-gradient(to left, ${scrollShadowColor.Glow}, transparent)`,
  backgroundPosition: 'left center, right center, left center, right center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
  backgroundAttachment: 'local, local, scroll, scroll',
});
