import type { FC } from 'react';
import { LoadingLine } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import type { SearchResultClickedInfo } from './Search';
import { searchResultStyles } from './search-result-styles/searchResultStyles';
import { SearchResultChildren } from './SearchResultChildren';
import type { SearchResponse } from './fetchSearchResults';

const { ResultList, ResultListItem, ResultWrapper, ResultHeader, NoResultMessage } = searchResultStyles;

type SearchResultProps = {
  gameListId: string;
  isLoading: boolean;
  response: SearchResponse;
  onClickResult?: (result: SearchResultClickedInfo) => void;
};

export const SearchResult: FC<SearchResultProps> = (props) => {
  const { t } = useTranslation('search');
  const { isLoading, response, gameListId, onClickResult } = props;

  const hasResult = response.items.length > 0;

  return (
    <ResultWrapper>
      {isLoading && <LoadingLine />}

      {(() => {
        if (hasResult) {
          const numberOfResults = `${response.items.length}${response.total > response.items.length ? '+' : ''}`;

          return (
            <>
              <ResultHeader>{t('search-result.results', { numberOfResults })}</ResultHeader>;
              <ResultList tabIndex={-1}>
                {response.items.map((searchResult, index) => (
                  <ResultListItem key={`${searchResult.id}-${searchResult.supplier}`}>
                    <SearchResultChildren
                      searchResult={searchResult}
                      gameListId={gameListId}
                      onClickResult={() => {
                        onClickResult?.({
                          name: searchResult.rawTitle ?? searchResult.title,
                          launchUrl: searchResult.launchUrl,
                          index,
                          totalResults: response.total,
                        });
                      }}
                    />
                  </ResultListItem>
                ))}
              </ResultList>
            </>
          );
        }

        return <NoResultMessage>{t('search-result.no-results')}</NoResultMessage>;
      })()}
    </ResultWrapper>
  );
};
