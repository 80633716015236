import styled from '@emotion/styled';
import type { FC, MouseEvent } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import type { MainMenuFooter_content$key } from './__generated__/MainMenuFooter_content.graphql';
import { MainMenuColors } from './main-menu-colors';
import { MainMenuFooterContent } from './main-menu-footer-content';

const contentFragment = graphql`
  fragment MainMenuFooter_content on Menu {
    footerLinks {
      text
      url
    }
  }
`;

const MainMenuFooterWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,

  marginBottom: 32,
  padding: '0 24px',
  color: MainMenuColors.Text,
});

type Props = {
  content: MainMenuFooter_content$key;
  tabIndex?: number;
  onLinkClick: (event: MouseEvent<HTMLElement>) => void;
};

export const MainMenuFooter: FC<Props> = ({ onLinkClick, ...props }) => {
  const content = useFragment(contentFragment, props.content);

  return (
    <MainMenuFooterWrapper tabIndex={props.tabIndex}>
      <MainMenuFooterContent onLinkClick={onLinkClick} content={content} />
    </MainMenuFooterWrapper>
  );
};
