import { Color } from '@pafcloud/style';
import type { NotificationBadgeSeverity } from './shared';

export const getNotificationBadgeSeverityColor = (severity: NotificationBadgeSeverity) => {
  const SeverityColor = {
    low: {
      Text: Color.Signal.InfoContrast,
      Background: Color.Signal.Info,
    },
    medium: {
      Text: Color.Signal.AttentionContrast,
      Background: Color.Signal.Attention,
    },
    high: {
      Text: Color.Signal.DangerContrast,
      Background: Color.Signal.Danger,
    },
  };

  return SeverityColor[severity];
};
