import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { SearchResultChildren as SearchResultChildren_default } from './SearchResultChildren.default';
import { SearchResultChildren as SearchResultChildren_alternate } from './SearchResultChildren.alternate';
import type { SearchResult as SearchResultType } from './Search';

export type SearchResultChildrenProps = {
  gameListId: string;
  searchResult: SearchResultType;
  onClickResult?: () => void;
};

export const SearchResultChildren = withBuildEnv(() => {
  if ($buildEnv.theme === 'goldenV2') {
    return SearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return SearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'dreams') {
    return SearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'momentum') {
    return SearchResultChildren_alternate;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return SearchResultChildren_alternate;
  }

  return SearchResultChildren_default;
});
