import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Stack } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useIsPokerClient } from '@pafcloud/react-hook-utils';
import { useLayoutVisibility } from '@pafcloud/contexts';
import type { Footer_content$key } from './__generated__/Footer_content.graphql';
import { FooterBlocks } from './FooterBlocks';
import { FooterMenu } from './FooterMenu';
import { FooterColors } from './footer-colors';
import { FooterLanguageSelect } from './FooterLanguageSelect';

const contentFragment = graphql`
  fragment Footer_content on Query {
    site {
      footer {
        links {
          ...FooterMenu_links
        }
        blocks {
          ...FooterBlocks_footerBlocks
        }
      }
    }
  }
`;

const Container = styled(Stack)({
  display: 'grid',
  gridTemplateColumns: 'var(--page-margin) auto var(--page-margin)',
  width: '100%',
  marginTop: 'auto',
  padding: '32px 0',
  background: FooterColors.Background,
  color: FooterColors.Text,
});

const FooterBlock = styled.div({
  gridColumn: 2,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

type FooterProps = {
  content: Footer_content$key;
};

export const Footer: FC<FooterProps> = (props) => {
  const content = useFragment(contentFragment, props.content);
  const { footerVisible } = useLayoutVisibility();
  const isPokerClient = useIsPokerClient();

  if (!footerVisible || isPokerClient || content.site.footer == null) {
    return null;
  }

  const { links, blocks } = content.site.footer;

  const addLangSelect =
    $buildEnv.site === 'x3000.com' ||
    $buildEnv.site === 'x3000.ee' ||
    $buildEnv.site === 'x3000.lv' ||
    $buildEnv.site === 'goldenbull.es' ||
    $buildEnv.site === 'goldenbull.se';

  return (
    <>
      <Container space={1} as="footer" data-name="footer">
        <FooterBlock>
          {links.length > 0 && <FooterMenu links={links} />}
          <FooterBlocks blocks={blocks} />
        </FooterBlock>
      </Container>
      {addLangSelect && <FooterLanguageSelect />}
    </>
  );
};
