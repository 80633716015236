import { graphql, readInlineData } from 'relay-runtime';
import type {
  useBonusOfferSteps_offer$data,
  useBonusOfferSteps_offer$key,
} from './__generated__/useBonusOfferSteps_offer.graphql';
import type { useBonusOfferSteps_reward$key } from './__generated__/useBonusOfferSteps_reward.graphql';

const stepFragment = graphql`
  fragment useBonusOfferSteps_offer on IBonusOffer @inline {
    steps {
      ... on TurnoverOfferStep {
        __typename
        status
        stepId
        turnoverTimes
        turnoverAmount
        turnoverCategories {
          category
          contributionPercentage
          minBetOdds
        }
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on ClaimOfferStep {
        __typename
        status
        stepId
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on ImmediateOfferStep {
        __typename
        status
        stepId
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on PlayRealMoneyOfferStep {
        __typename
        status
        stepId
        playAmount
        remainingAmount
        minBetOdds
        game {
          name
          familyName
        }
        sportsBet {
          games {
            name
          }
          minBetOdds
          channel
          isBetBuilder
          minCombinationSize
        }
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on PlayRealRoundsOfferStep {
        __typename
        status
        stepId
        playRounds
        remainingRounds
        minBetOdds
        game {
          name
          familyName
        }
        sportsBet {
          games {
            name
          }
          minBetOdds
          channel
          isBetBuilder
          minCombinationSize
        }
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on DepositOfferStep {
        __typename
        status
        stepId
        minDepositAmount
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on RecentDepositOfferStep {
        __typename
        status
        stepId
        mustDeposit
        reward {
          ...useBonusOfferSteps_reward
        }
      }

      ... on SafeBetOfferStep {
        __typename
        status
        stepId
        playAmount
        game {
          name
        }
        reward {
          ...useBonusOfferSteps_reward
        }
      }
    }
  }
`;

const rewardFragment = graphql`
  fragment useBonusOfferSteps_reward on BonusOfferStepReward @inline {
    ... on BingoTicketsOfferReward {
      __typename
      status
      tickets
      ticketsRemaining
      ticketValue
      daysToComplete
      expiresAt
      game {
        name
        familyName
      }
    }

    ... on BonusMoneyOfferReward {
      __typename
      status
      bonusAmount
    }

    ... on RealMoneyOfferReward {
      __typename
      status
      realAmount
    }

    ... on RefundRealMoneyOfferReward {
      __typename
      status
      realAmount
    }

    ... on CashbackOfferReward {
      __typename
      status
      realAmount
    }

    ... on MatchDepositBonusMoneyOfferReward {
      __typename
      status
      maxMatchAmount
      matchPercentage
    }

    ... on FreespinOfferReward {
      __typename
      status
      spins
      spinsRemaining
      spinValue
      daysToComplete
      expiresAt
      game {
        name
        familyName
      }
      monetaryType
    }

    ... on FreeBetOfferReward {
      __typename
      status
      daysToComplete
      expiresAt
      playAmount
      amount
      game {
        name
        familyName
      }
    }

    ... on RedeemBonusOfferReward {
      __typename
      status
    }

    ... on OddsBoostOfferReward {
      __typename
      status
      boostedOdds
      maxExtraWinnings
      maxStake
      outcomeIds
      daysToComplete
      expiresAt
      game {
        name
        familyName
      }
    }
  }
`;

type Step = useBonusOfferSteps_offer$data['steps'][number];

// This is a hack to circumvent type limitations of relay.
// You must select the common fields on each type, otherwise we don't get the discriminated union. And when you
// select the common fields on each type, you can't access them without checking you didn't get some unknown type.
const isKnownStep = (step: Step) => {
  // @ts-expect-error if this expected error don't occur, you've forgotten to add status to at least one step.
  return 'status' in step && step.__typename !== '%other';
};

export const getBonusOfferSteps = (offerKey: useBonusOfferSteps_offer$key | null) => {
  const offer = readInlineData(stepFragment, offerKey);
  return offer?.steps.filter(isKnownStep).map((step) => ({
    ...step,
    reward: readInlineData<useBonusOfferSteps_reward$key>(rewardFragment, step.reward),
  }));
};

export type BonusOfferStep = NonNullable<ReturnType<typeof getBonusOfferSteps>>[number];

export type BonusOfferReward = NonNullable<BonusOfferStep['reward']>;
