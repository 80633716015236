import type { Site, SiteProps } from '@pafcloud/config';
import { allSites, sitesMap } from '@pafcloud/config';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { createArrayValueValidator } from '@pafcloud/collection-utils';

export const getSiteProps = (site: Site = $buildEnv.site): SiteProps => {
  const siteProps = sitesMap[site];

  if (typeof siteProps === 'undefined') {
    throw new Error(`Could not get props for site: ${site}`);
  }

  return siteProps;
};

export const allSiteConfigs = Object.values(sitesMap);

export const isSite = createArrayValueValidator(allSites);

export const getCountryCode = (site?: Site) => getSiteProps(site).countryCode;
