import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { Breakpoint } from '@pafcloud/style';
import { ScrollShadow } from '@pafcloud/base-components';

const FlowContentContainer = styled.section<{ useOutsideScroll?: boolean }>(({ useOutsideScroll }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  '--top-margin': '32px',
  '--side-margin': '16px',
  padding: 'var(--top-margin) var(--side-margin) var(--bottom-margin, 96px)',
  overflowY: useOutsideScroll ? undefined : 'auto',
  overscrollBehavior: 'contain',
  wordBreak: 'break-word',
  isolation: 'isolate',
  flex: 1,
  flexGrow: 1,
  '&:focus': {
    outline: 'none',
  },

  [Breakpoint.LaptopOrLarger]: {
    '--side-margin': '32px',
  },

  [Breakpoint.LandscapePhone]: {
    '--side-margin': '16px',
    padding: 16,
  },
}));

const ScrollIndicator = styled(ScrollShadow)({
  flexGrow: 1,
});

type Props = {
  children: ReactNode;
  className?: string;
};

export const FlowContent: FC<Props> = ({ className, children }) => {
  return (
    <FlowContentContainer tabIndex={0} className={className}>
      {children}
    </FlowContentContainer>
  );
};

export const FlowScrolledContent: FC<Props> = ({ className, children }) => {
  return (
    <ScrollIndicator className={className}>
      <FlowContentContainer tabIndex={0} useOutsideScroll>
        {children}
      </FlowContentContainer>
    </ScrollIndicator>
  );
};
