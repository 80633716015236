import { bonusMenuStyles } from './styles';

export const {
  BonusBadge,
  BonusNavItem,
  BonusGroupSummary,
  BonusSummaryContent,
  BonusMenuButton,
  BonusNavItemIcon,
  BonusPageLink,
  BonusSubMenu,
} = bonusMenuStyles;
