import type {
  BeneficialOwnershipInfoFlowQueryParams,
  BonusRouterFlowQueryParams,
  BonusTermsFlowQueryParams,
  CalendarPromotionFlowQueryParams,
  DocumentUploadFlowQueryParams,
  LimitQuestionnaireFlowQueryParams,
  LoginFlowQueryParams,
  MessageFlowQueryParams,
  MessageHelperFlowQueryParams,
  PromotionFlowQueryParams,
  RegisterAndVerifyPhoneFlowQueryParams,
  SourceOfFundsQueryParams,
  TransactionFlowQueryParams,
  TransactionResultFlowQueryParams,
  VerifyIdentityVeridasFlowQueryParams,
} from './flowTypes';

/**
 * The URL query parameter used to tell which flow type is currently open.
 */
export const FLOW_QUERY_PARAM = 'flowType';

type Flows =
  | { flow: 'aboutDepositLimits' }
  | { flow: 'aboutLossLimits' }
  | { flow: 'aboutSessionLimits' }
  | { flow: 'aboutStakeLimits' }
  | { flow: 'aboutYearlyLimits' }
  | { flow: 'beneficialOwnershipInfo'; params?: BeneficialOwnershipInfoFlowQueryParams }
  | { flow: 'bonus'; params: BonusRouterFlowQueryParams }
  | { flow: 'bonusDeposit' }
  | { flow: 'bonusTerms'; params: BonusTermsFlowQueryParams }
  | { flow: 'changePhoneNumber'; params?: RegisterAndVerifyPhoneFlowQueryParams }
  | { flow: 'cookieConsent' }
  | { flow: 'deposit'; params?: TransactionFlowQueryParams }
  | { flow: 'documentUpload'; params: DocumentUploadFlowQueryParams }
  | { flow: 'gamingOverview' }
  | { flow: 'manualRegistration' }
  | { flow: 'message'; params: MessageFlowQueryParams }
  | { flow: 'messageHelper'; params: MessageHelperFlowQueryParams }
  | { flow: 'login'; params?: LoginFlowQueryParams }
  | { flow: 'limitQuestionnaire'; params: LimitQuestionnaireFlowQueryParams }
  | { flow: 'receipt'; params: { reference: string } }
  | { flow: 'sourceOfFundsInfo'; params?: SourceOfFundsQueryParams }
  | { flow: 'transaction-result'; params: TransactionResultFlowQueryParams }
  | { flow: 'verifyEmail' }
  | { flow: 'verifyIdentity' }
  | { flow: 'resetPassword' }
  | { flow: 'responsibleGaming' }
  | { flow: 'open-banking' }
  | { flow: 'promotion'; params: PromotionFlowQueryParams }
  | { flow: 'calendarPromotion'; params: CalendarPromotionFlowQueryParams }
  | { flow: 'verifyIdentityVeridas'; params?: VerifyIdentityVeridasFlowQueryParams }
  | { flow: 'withdrawal'; params?: TransactionFlowQueryParams };

type Flow<T extends FlowType> = Extract<Flows, { flow: T }>;

export type FlowType = Flows['flow'];

export type FlowParams<T extends FlowType> = Flow<T> extends { params?: infer P } ? P : never;

export function createFlowUrl<T extends FlowType>(flow: T, path: string, params?: FlowParams<T>): string {
  const query = new URLSearchParams();

  query.set(FLOW_QUERY_PARAM, flow);

  Object.entries(params ?? {}).forEach(([key, value]) => {
    if (value != null) {
      query.set(key, String(value));
    }
  });

  const [pathWithoutHash, hash] = path.split('#');
  const pathWithoutQuery = pathWithoutHash.split('?')[0];

  return `${pathWithoutQuery}?${query.toString()}${hash ? `#${hash}` : ''}`;
}

export function removeFlowFromPath(path: string): string {
  const [pathWithoutHash, hash] = path.split('#');
  const pathWithoutQuery = pathWithoutHash.split('?')[0];

  return `${pathWithoutQuery}${hash ? `#${hash}` : ''}`;
}
