import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Breakpoint, Color, ZIndex } from '@pafcloud/style';
import { ButtonReset, Icon, Logo } from '@pafcloud/base-components';
import type { SidebarProps } from './Sidebar';

const Nav = styled.nav<{ isOpen?: boolean }>(({ isOpen }) => ({
  position: 'fixed',
  width: '100%',
  top: 0,
  bottom: 0,
  left: 0,
  overflowY: 'auto',
  zIndex: ZIndex.Header + 1,
  paddingBottom: 32, // Safari hack to avoid jumpy scroll
  transition: 'transform 0.2s',
  transform: isOpen ? undefined : `translateX(-100%)`,
  background: Color.Layout.Sidebar.Background,
  color: Color.Layout.Sidebar.Foreground,
  boxShadow: Color.Elevation.Level3,

  display: 'flex',
  flexDirection: 'column',

  [Breakpoint.LaptopOrLarger]: {
    width: 300,
  },
}));

const Header = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 24,
  padding: 24,
});

const BrandLogo = styled(Logo)({
  maxWidth: '100%',
  maxHeight: 40,
});

const CloseButton = styled.button(ButtonReset, {
  flexGrow: 0,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  transform: 'translateX(8px)',
  padding: 8,
  borderRadius: '99vw',
  color: Color.Layout.Sidebar.Foreground + 'C0',

  lineHeight: 1,

  ':hover, :focus': {
    color: Color.Layout.Sidebar.Foreground,
    background: Color.Primitive.Primary + '20',
    outline: 'transparent',
  },

  '*': {
    display: 'block',
  },

  svg: {
    width: 'auto',
    height: '100%',
  },
});

export const Sidebar = forwardRef<HTMLElement, SidebarProps>(({ onClose, isOpen, children }, ref) => {
  return (
    <Nav isOpen={isOpen} ref={ref} data-testid="main-menu">
      <Header>
        <BrandLogo />
        <CloseButton onClick={onClose}>
          <Icon name="closeThinRounded" />
        </CloseButton>
      </Header>
      {children}
    </Nav>
  );
});

Sidebar.displayName = 'Sidebar';
