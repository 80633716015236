import type { FC } from 'react';
import styled from '@emotion/styled';
import { HeaderColors } from './header-colors';

const Burger = styled.span<BurgerProps>((props) => ({
  width: 24,
  display: 'grid',
  gridAutoColumns: '1fr',
  gridAutoRows: '1fr',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 'var(--burger-gap, 4px)',

  'span, span::after, ::after, ::before': {
    content: '""',
    display: 'block',
    height: 'var(--burger-stroke-width, 4px)',
    width: '100%',
    borderRadius: '99vw',
    background: props.open ? HeaderColors.IconFocus : 'currentColor',
    transition: 'transform 0.12s ease-in-out, opacity 0.1s ease-in-out',
  },

  span: {
    transform: props.open ? 'rotate(45deg)' : undefined,
    transformOrigin: 'center',

    '::after': {
      transform: props.open ? 'rotate(-90deg)' : undefined,
    },
  },
  '::before, ::after': {
    transform: props.open ? 'scale(0)' : undefined,
    transformOrigin: 'center',
    opacity: props.open ? 0 : 1,
  },
}));

type BurgerProps = {
  open: boolean;
  className?: string;
};

export const BurgerComponent: FC<BurgerProps> = ({ open, className }) => {
  return (
    <Burger className={className} open={open}>
      <span />
    </Burger>
  );
};
