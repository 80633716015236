import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { HeaderContent as HeaderContent_default } from './HeaderContent.default';
import { HeaderContent as HeaderContent_clean } from './HeaderContent.clean';
import { HeaderContent as HeaderContent_golden } from './HeaderContent.golden';
import { HeaderContent as HeaderContent_goldenV2 } from './HeaderContent.goldenV2';
import { HeaderContent as HeaderContent_quirky } from './HeaderContent.quirky';
import { HeaderContent as HeaderContent_quirky_purple } from './HeaderContent.quirky-purple';
import { HeaderContent as HeaderContent_dreams } from './HeaderContent.dreams';
import { HeaderContent as HeaderContent_momentum } from './HeaderContent.momentum';

export const HeaderContent = withBuildEnv(() => {
  if ($buildEnv.theme === 'clean') {
    return HeaderContent_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return HeaderContent_clean;
  }
  if ($buildEnv.theme === 'golden') {
    return HeaderContent_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return HeaderContent_goldenV2;
  }
  if ($buildEnv.theme === 'quirky') {
    return HeaderContent_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return HeaderContent_quirky_purple;
  }
  if ($buildEnv.theme === 'dreams') {
    return HeaderContent_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return HeaderContent_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return HeaderContent_momentum;
  }

  return HeaderContent_default;
});
