import type { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from '@emotion/styled';
import { Breakpoint, Color, DynamicValues } from '@pafcloud/style';
import 'react-toastify/dist/ReactToastify.min.css';

const ToastContainerWrapper = styled(ToastContainer)({
  '.Toastify__toast': {
    backgroundColor: Color.Surface.Base.Background,

    borderTop: `6px solid ${Color.Signal.Info}`,
    borderRadius: Color.CornerRadius.Small,
    padding: '0 6px 6px 6px',
    overflow: 'initial',

    '&:before': {
      backgroundSize: '16px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      height: 24,
      width: 24,
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translate(-50%, -50%)',
    },
  },
  '.Toastify__toast--default': {
    borderTop: `6px solid ${Color.Signal.Info}`,
    color: Color.Surface.Base.Foreground,
  },

  '.Toastify__toast--success': {
    borderTop: `6px solid ${Color.Signal.Success}`,
    color: Color.Surface.Base.Foreground,

    '&:before': {
      backgroundColor: Color.Signal.Success,
      backgroundImage: 'url(/static/images/ui-toast-success.svg)',
    },
  },

  '.Toastify__toast--error': {
    borderTop: `6px solid ${Color.Signal.Danger}`,
    color: Color.Surface.Base.Foreground,

    '&:before': {
      backgroundColor: Color.Signal.Danger,
      backgroundImage: 'url(/static/images/ui-toast-error.svg)',
    },
  },

  '.Toastify__close-button': {
    color: Color.TextColors.MutedText,
    opacity: 1,
    paddingTop: 10,
  },

  '.Toastify__progress-bar--default': {
    background: Color.Signal.Info,
  },
  '&.Toastify__toast-container--top-center': {
    [Breakpoint.LaptopOrLarger]: {
      left: '50%',
    },
  },
  '&.Toastify__toast-container--top-center, &.Toastify__toast-container--top-right': {
    padding: 0,
    top: `calc(${DynamicValues.HeaderHeight} + ${4}px)`,
  },
  '@media (max-width: 480px)': {
    '&.Toastify__toast-container--top-center': {
      width: '85%',
      left: '7.5%',
    },
  },
  '.Toastify__toast-body': {
    width: '100%',
  },
  '@keyframes fly-in': {
    '0%': {
      top: -100,
    },
    '100%': {
      top: 0,
    },
  },
  '.fly-in': {
    animation: 'fly-in 0.2s ease-out both',
  },
  '@keyframes fly-out': {
    '0%': {
      top: 0,
    },
    '100%': {
      top: -100,
    },
  },
  '.fly-out': {
    animation: 'fly-out 0.2s ease-out both',
  },
});

export const ToastNotificationContainer: FC = () => {
  return <ToastContainerWrapper autoClose={3000} hideProgressBar={true} position="top-center" />;
};
