/**
 * @generated SignedSource<<55bd90591052bc18a926a787f6eade83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type BonusOfferStepRewardStatus = "GIVEN" | "NOT_GIVEN";
import { FragmentRefs } from "relay-runtime";
export type useBonusOfferSteps_reward$data = {
  readonly __typename: "BingoTicketsOfferReward";
  readonly daysToComplete: number | null;
  readonly expiresAt: string | null;
  readonly game: {
    readonly familyName: string;
    readonly name: string;
  } | null;
  readonly status: BonusOfferStepRewardStatus;
  readonly ticketValue: number;
  readonly tickets: number;
  readonly ticketsRemaining: number;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "BonusMoneyOfferReward";
  readonly bonusAmount: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "CashbackOfferReward";
  readonly realAmount: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "FreeBetOfferReward";
  readonly amount: number;
  readonly daysToComplete: number | null;
  readonly expiresAt: string | null;
  readonly game: {
    readonly familyName: string;
    readonly name: string;
  } | null;
  readonly playAmount: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "FreespinOfferReward";
  readonly daysToComplete: number | null;
  readonly expiresAt: string | null;
  readonly game: {
    readonly familyName: string;
    readonly name: string;
  } | null;
  readonly monetaryType: string | null;
  readonly spinValue: number;
  readonly spins: number;
  readonly spinsRemaining: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "MatchDepositBonusMoneyOfferReward";
  readonly matchPercentage: number;
  readonly maxMatchAmount: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "OddsBoostOfferReward";
  readonly boostedOdds: number;
  readonly daysToComplete: number | null;
  readonly expiresAt: string | null;
  readonly game: {
    readonly familyName: string;
    readonly name: string;
  } | null;
  readonly maxExtraWinnings: number;
  readonly maxStake: number;
  readonly outcomeIds: ReadonlyArray<string>;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "RealMoneyOfferReward";
  readonly realAmount: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "RedeemBonusOfferReward";
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  readonly __typename: "RefundRealMoneyOfferReward";
  readonly realAmount: number;
  readonly status: BonusOfferStepRewardStatus;
  readonly " $fragmentType": "useBonusOfferSteps_reward";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "useBonusOfferSteps_reward";
};
export type useBonusOfferSteps_reward$key = {
  readonly " $data"?: useBonusOfferSteps_reward$data;
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useBonusOfferSteps_reward"
};

(node as any).hash = "78ccb09808859026b8621a42ef9c0465";

export default node;
