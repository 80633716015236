import type { FC } from 'react';
import { useState } from 'react';
import type { PinCodeSuccessData } from './VerifyPhoneNumberForm';
import { VerifyPhoneNumberForm } from './VerifyPhoneNumberForm';
import { ChoosePhoneNumberForm } from './ChoosePhoneNumberForm';

type StepProps = {
  phoneNumber?: string;
  onCompleted: () => void;
  onAbort?: () => void;
  abortButtonText?: string;
};

export const VerifyPhoneNumberGuard: FC<StepProps> = ({ phoneNumber, abortButtonText, onCompleted, onAbort }) => {
  const [pinCodeSuccessData, setPinCodeSuccessData] = useState<PinCodeSuccessData | null>(null);

  if (pinCodeSuccessData) {
    return (
      <VerifyPhoneNumberForm
        pinCodeSuccessData={pinCodeSuccessData}
        onReset={() => setPinCodeSuccessData(null)}
        onCompleted={onCompleted}
      />
    );
  }

  return (
    <ChoosePhoneNumberForm
      defaultValues={{ phoneNumber }}
      onCompleted={setPinCodeSuccessData}
      onAbort={onAbort}
      abortButtonText={abortButtonText}
    />
  );
};
export default VerifyPhoneNumberGuard;
