/**
 * @generated SignedSource<<4681658dc402b04f6f0dca7e509437fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdatePhoneNumberInput = {
  phoneNumber: string;
};
export type useUpdatePhoneNumberMutation$variables = {
  input: UpdatePhoneNumberInput;
};
export type useUpdatePhoneNumberMutation$data = {
  readonly updatePhoneNumber: {
    readonly __typename: "UpdatePhoneNumberPayload";
    readonly success: boolean;
  };
};
export type useUpdatePhoneNumberMutation = {
  response: useUpdatePhoneNumberMutation$data;
  variables: useUpdatePhoneNumberMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePhoneNumberPayload",
    "kind": "LinkedField",
    "name": "updatePhoneNumber",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdatePhoneNumberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdatePhoneNumberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7de3819a072b420c0121143ef7101e7f",
    "id": null,
    "metadata": {},
    "name": "useUpdatePhoneNumberMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdatePhoneNumberMutation(\n  $input: UpdatePhoneNumberInput!\n) {\n  updatePhoneNumber(input: $input) {\n    __typename\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd19ee4bbb962061b85d2427948e7095";

export default node;
