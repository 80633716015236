import type { TrackedGame, TrackedGames } from './gameTrackingContext';

export const getTrackedGameKey = (trackableGame: TrackedGame) => {
  return trackableGame.game.slug + trackableGame.trackId;
};

export type ImpressionGame = {
  'recommendation.id': string;
  'recommendation.groupId': string;
  gameName: string;
  gameSlug: string;
  gameId: string;
  gameFamily: string;
  gameUrl: string;
  price: number;
  gameProvider: string;
  gameCategory: string;
  gameList: string;
  gameListId: string;
  gameListPosition: number;
  pageSlug: string;
  trackId: string;
};

export const mapTrackedGameToImpressionGame = (trackedGame: TrackedGame): ImpressionGame => {
  const {
    game,
    recommendationId,
    recommendationGroupId,
    gameListPosition,
    gameListTitle,
    pageSlug,
    gameListId,
    trackId,
  } = trackedGame;

  return {
    'recommendation.id': recommendationId,
    'recommendation.groupId': recommendationGroupId,
    gameName: game.name,
    gameSlug: game.slug,
    gameId: game.gameId,
    gameFamily: game.familyName,
    gameUrl: game.launchUrl,
    price: 0, // Shall always be zero
    gameProvider: game.provider,
    gameCategory: game.category,
    gameList: gameListTitle,
    gameListId,
    gameListPosition,
    pageSlug,
    trackId,
  };
};

const mapImpressionGameToTrackedGame = (impressionGame: ImpressionGame, reported: boolean): TrackedGame => {
  return {
    recommendationId: impressionGame['recommendation.id'],
    recommendationGroupId: impressionGame['recommendation.groupId'],
    gameListPosition: impressionGame.gameListPosition,
    gameListTitle: impressionGame.gameList,
    pageSlug: impressionGame.pageSlug,
    gameListId: impressionGame.gameListId,
    game: {
      name: impressionGame.gameName,
      gameId: impressionGame.gameId,
      familyName: impressionGame.gameFamily,
      launchUrl: impressionGame.gameUrl,
      provider: impressionGame.gameProvider,
      category: impressionGame.gameCategory,
      slug: impressionGame.gameSlug,
    },
    reported,
    trackId: impressionGame.trackId,
  };
};

export const impressionsToTrackedGames = (impressions: ImpressionGame[], reported: boolean) => {
  const trackedGames = impressions.reduce<TrackedGames>((games, impression) => {
    const trackedGame = mapImpressionGameToTrackedGame(impression, reported);
    const key = getTrackedGameKey(trackedGame);
    games[key] = trackedGame;

    return games;
  }, {});

  return trackedGames;
};
