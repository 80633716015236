import type { FC, ReactNode, RefObject } from 'react';
import { useRef } from 'react';
import styled from '@emotion/styled';
import { ModalContent, ModalTransparentOverlay, renderIntoRootContainer } from '@pafcloud/base-components';
import { Breakpoint, FontTextSize, LayoutProps } from '@pafcloud/style';
import { useOutsideClick } from '@pafcloud/react-hook-utils';

const TrayOverlay = styled(ModalTransparentOverlay)({
  top: LayoutProps.HeaderHeight,

  [Breakpoint.LandscapePhone]: {
    justifyContent: 'flex-end',
  },
});

type Props = {
  onOutsideClick?: () => void;
  className?: string;
  /**
   * use this if the tray is opened using a button,
   * as buttons in IOS do not receive focus when clicked,
   * and thus will not be set to the activeElement of the document
   *  */
  triggerButtonRef: RefObject<HTMLElement>;
  children: ReactNode;
};

export const Tray: FC<Props> = ({ onOutsideClick, className, children, triggerButtonRef }) => {
  const content = useRef<HTMLElement>(null);
  useOutsideClick({ content, onOutsideClick, triggerButtonRef });

  return renderIntoRootContainer(
    <TrayOverlay>
      <ModalContent ref={content} tabIndex={0} className={className}>
        {children}
      </ModalContent>
    </TrayOverlay>,
  );
};

export const TrayHeader = styled.header({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 16px',
  fontSize: FontTextSize.Huge,
  fontWeight: 'bold',
  lineHeight: 1,
});
