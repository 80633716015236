import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { MainMenuFooterContent as MainMenuFooterContent_default } from './MainMenuFooterContent.default';
import { MainMenuFooterContent as MainMenuFooterContent_clean } from './MainMenuFooterContent.clean';
import { MainMenuFooterContent as MainMenuFooterContent_golden } from './MainMenuFooterContent.golden';
import { MainMenuFooterContent as MainMenuFooterContent_quirky } from './MainMenuFooterContent.quirky';

export const MainMenuFooterContent = withBuildEnv(() => {
  if ($buildEnv.theme === 'clean') {
    return MainMenuFooterContent_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return MainMenuFooterContent_clean;
  }
  if ($buildEnv.theme === 'golden') {
    return MainMenuFooterContent_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return MainMenuFooterContent_golden;
  }
  if ($buildEnv.theme === 'quirky') {
    return MainMenuFooterContent_quirky;
  }
  return MainMenuFooterContent_default;
});
