import styled from '@emotion/styled';
import type { CSSObject } from '@emotion/react';
import { Disclosure, ProgressBar } from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';
import { OfferProgressBar, BonusProgressInformation } from '../../../../bonus';
import { MenuGroupDetails } from '../MainMenuGroup';
import { BonusMenuColors } from './bonus-menu-colors';

export const GroupDetails = styled(MenuGroupDetails)({
  width: '100%',

  '&[open]': {
    background: BonusMenuColors.GroupBackground,
  },
});

export const ExpandableIcon = styled(Disclosure.SummaryIcon)({
  marginLeft: 'auto',
});

export const BonusNavSubItem = styled.li({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
  fontWeight: 'bold',
});

const commonBonusTextStyles: CSSObject = {
  fontSize: FontTextSize.Normal,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const BonusText = styled.span({
  ...commonBonusTextStyles,
  whiteSpace: 'nowrap',
});

export const MultiLineBonusText = styled.span({
  ...commonBonusTextStyles,
  gridColumn: '1 / -1',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

export const PlayAndGetProgressBar = styled(ProgressBar)({
  gridArea: '3 / 1 / 3 / 2',
});

export const TurnoverProgressBar = styled(OfferProgressBar)({
  gridArea: '3 / 1 / 3 / 2',
});

export const MenuProgressInformation = styled(BonusProgressInformation)({
  display: 'block',
  fontSize: FontTextSize.Small,
  color: BonusMenuColors.ProgressInformation,
  gridArea: '2 / 1 / 2 / 2',
});
