import type { FC } from 'react';
import { Icon } from '@pafcloud/base-components';
import { ButtonContent, IconNotificationBadge, IconWrapper } from '../../header-icon-button/';

type Props = {
  numberOfMessages: number;
  'aria-label'?: string;
};

export const MessageMenuIcon: FC<Props> = ({ numberOfMessages, 'aria-label': ariaLabel }) => {
  const hasMessages = numberOfMessages > 0;

  return (
    <ButtonContent>
      {hasMessages ? <IconNotificationBadge severity="low">{numberOfMessages}</IconNotificationBadge> : null}
      <IconWrapper>
        <Icon name="notification" size="1em" aria-label={ariaLabel} />
      </IconWrapper>
    </ButtonContent>
  );
};
