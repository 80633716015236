import { useRouter } from 'next/router';

const KNOWN_PARAMETERS = ['tag'];

export const useCanonical = (baseUrl: string) => {
  const router = useRouter();
  const isDefaultLanguage = router.defaultLocale === router.locale;
  const languagePath = isDefaultLanguage ? '' : `/${router.locale}`;
  const url = new URL(baseUrl + router.asPath);

  // We must exclude all parameters that renders the same server content, since those cause duplicate content.
  // Check the cache code which parameters are allowed to reach the server.
  const searchParams = new URLSearchParams();
  for (const [key, value] of url.searchParams.entries()) {
    if (KNOWN_PARAMETERS.includes(key)) {
      searchParams.set(key, value);
    }
  }

  const path = url.pathname.replace(/\/$/, '');
  const parameters = searchParams.toString();
  const queryString = parameters.length ? `?${parameters}` : '';
  return `${baseUrl}${languagePath}${path}${queryString}`;
};
