/**
 * @generated SignedSource<<4576e737d44fce5105bc00074d3a0c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGeneratedBonusTitleQuery$data = {
  readonly isProgramGroup: boolean | null;
  readonly recurrence: {
    readonly availableMaxTimes: number | null;
  } | null;
  readonly steps: ReadonlyArray<{
    readonly __typename: "ClaimOfferStep";
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "DepositOfferStep";
    readonly minDepositAmount: number;
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "ImmediateOfferStep";
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "PlayRealMoneyOfferStep";
    readonly game: {
      readonly name: string;
    } | null;
    readonly playAmount: number;
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "PlayRealRoundsOfferStep";
    readonly game: {
      readonly name: string;
    } | null;
    readonly playRounds: number;
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "RecentDepositOfferStep";
    readonly mustDeposit: boolean | null;
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "SafeBetOfferStep";
    readonly playAmount: number;
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    readonly __typename: "TurnoverOfferStep";
    readonly reward?: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly ticketValue: number;
      readonly tickets: number;
    } | {
      readonly __typename: "BonusMoneyOfferReward";
      readonly bonusAmount: number;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly playAmount: number;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly name: string;
      } | null;
      readonly monetaryType: string | null;
      readonly spinValue: number;
      readonly spins: number;
    } | {
      readonly __typename: "MatchDepositBonusMoneyOfferReward";
      readonly matchPercentage: number;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly boostedOdds: number;
      readonly game: {
        readonly name: string;
      } | null;
    } | {
      readonly __typename: "RealMoneyOfferReward";
      readonly realAmount: number;
    } | {
      readonly __typename: "RefundRealMoneyOfferReward";
      readonly __typename: "RefundRealMoneyOfferReward";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly " $fragmentType": "useGeneratedBonusTitleQuery";
};
export type useGeneratedBonusTitleQuery$key = {
  readonly " $data"?: useGeneratedBonusTitleQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGeneratedBonusTitleQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useGeneratedBonusTitleQuery"
};

(node as any).hash = "4425c455a1b24d3b16c0aa53389d2483";

export default node;
