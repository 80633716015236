/**
 * @generated SignedSource<<7f42eb7d280f43279d83b8fc0150cc88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VerificationErrorCode = "INVALID_INPUT" | "TICKET_EXPIRED" | "TICKET_NOT_FOUND" | "UNKNOWN";
export type SendPinCodeInput = {
  phoneNumber: string;
};
export type useSendPinCodeMutation$variables = {
  input: SendPinCodeInput;
};
export type useSendPinCodeMutation$data = {
  readonly sendPinCode: {
    readonly __typename: "SendPinCodeSuccess";
    readonly identificationCode: string;
    readonly ticketId: string;
  } | {
    readonly __typename: "VerificationError";
    readonly error: VerificationErrorCode | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useSendPinCodeMutation = {
  response: useSendPinCodeMutation$data;
  variables: useSendPinCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "sendPinCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ticketId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identificationCode",
            "storageKey": null
          }
        ],
        "type": "SendPinCodeSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "type": "VerificationError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSendPinCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSendPinCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c130c9c9181a127423d84df1900b5b3c",
    "id": null,
    "metadata": {},
    "name": "useSendPinCodeMutation",
    "operationKind": "mutation",
    "text": "mutation useSendPinCodeMutation(\n  $input: SendPinCodeInput!\n) {\n  sendPinCode(input: $input) {\n    __typename\n    ... on SendPinCodeSuccess {\n      ticketId\n      identificationCode\n    }\n    ... on VerificationError {\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5187cfdaf0a9b503744d7375423e61c4";

export default node;
