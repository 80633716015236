export const Styles = {
  MainHeadingWeight: 900,
  MainHeadingStyle: undefined,

  SubHeadingWeight: 900,
  SubHeadingStyle: undefined,

  DefaultHeadingWeight: undefined,
  DefaultHeadingStyle: undefined,
} as const;
