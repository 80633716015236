import type { CSSObject } from '@emotion/react';

export const visuallyHiddenStyle: CSSObject = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
};
