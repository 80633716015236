import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Color, Font } from '@pafcloud/style';
import type { ButtonProps, ButtonTypes } from '../ButtonProps';

type NoStyleButtonProps = Partial<Omit<ButtonProps, 'variant' | 'size' | 'ref'>> & {
  type?: ButtonTypes;
};

export const ButtonReset = css({
  padding: 0,
  border: 'none',
  fontFamily: Font.Button,
  textAlign: 'inherit',
  color: 'inherit',
  background: 'transparent',
  cursor: 'pointer',

  [':disabled']: {
    cursor: 'not-allowed',
  },
});

export const NoStyleButton = styled.button<NoStyleButtonProps>(ButtonReset, {
  '&:focus, &:active, &:hover': {
    color: Color.TextColors.LinkText,
    outline: `1px solid ${Color.TextColors.LinkText}`,
  },
});

NoStyleButton.defaultProps = {
  type: 'button',
};
