import type { FC } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { LogoVariants } from './logos';

type LogoVariant = 'vertical' | 'horizontal' | 'icon';

type LogoProps = {
  variant?: LogoVariant;
  inverse?: boolean;
};

const getLogoComponent = (variation: LogoVariant, inverse: boolean) => {
  if (variation === 'icon') {
    return inverse ? LogoVariants.IconInverse : LogoVariants.Icon;
  }

  if (variation === 'vertical') {
    return inverse ? LogoVariants.VerticalInverse : LogoVariants.Vertical;
  }

  return inverse ? LogoVariants.HorizontalInverse : LogoVariants.Horizontal;
};

const LogoMarkup = styled('svg', { shouldForwardProp: isPropValid })({
  width: 'auto',
  height: 'auto',
});

export const Logo: FC<LogoProps & { className?: string }> = ({
  variant: variation = 'horizontal',
  inverse = false,
  className,
}) => {
  return <LogoMarkup as={getLogoComponent(variation, inverse)} role="img" aria-hidden="true" className={className} />;
};
