import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Icon } from '../icon';
import { LoadingIndicator } from '../loading';
import type { ButtonProps } from './ButtonProps';
import { getButtonStyles } from './getButtonStyles';

const shouldForwardProp = (propName: string) => {
  return !['variant', 'colorstyle', 'size', 'ctaEffect', 'iconAlignment', 'fullWidth', 'isLoading', 'icon'].includes(
    propName,
  );
};

const StyledButton = styled('button', { shouldForwardProp })<ButtonProps>(getButtonStyles);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  if (props.isLoading) {
    props = { ...props, disabled: true };
  }

  return (
    <StyledButton {...props} ref={ref}>
      {props.icon == null ? null : <Icon name={props.icon} size="0.85em" />}
      {props.isLoading ? <LoadingIndicator color="currentColor" /> : props.children}
    </StyledButton>
  );
});

Button.displayName = 'Button';
