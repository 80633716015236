import Head from 'next/head';
import { PageContainer } from '@pafcloud/base-components';
import { NotFound } from '@pafcloud/block-components';
import { useTranslation } from '@pafcloud/i18n';
import { useIsSSR } from '@pafcloud/react-hook-utils';

export const NotFoundPage = () => {
  const { t } = useTranslation('common');
  const isSSR = useIsSSR();

  if (isSSR) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{t('404.page-title')}</title>
      </Head>
      <PageContainer>
        <NotFound />
      </PageContainer>
    </>
  );
};
