import { $buildEnv } from '@pafcloud/config/src/buildEnv';

export const showMainMenuItemIcon = (() => {
  if ($buildEnv.theme === 'golden') {
    return false;
  }
  if ($buildEnv.theme === 'quirky') {
    return false;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return false;
  }
  if ($buildEnv.theme === 'clean') {
    return false;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return false;
  }
  if ($buildEnv.theme === 'dreams') {
    return false;
  }

  return true;
})();
