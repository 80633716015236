import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.TextColors.BodyText,
  MessageSeenText: Color.TextColors.MutedText,
  PaginationText: Color.TextColors.BodyText,
  BorderColor: Color.Surface.Base.Dimmed,
  Highlight: Color.Primitive.Primary + '20',
  HighlightBorder: Color.Primitive.Primary,
};
