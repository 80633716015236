import type { JSX, RefAttributes } from 'react';
import { forwardRef } from 'react';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';

export type InternalLinkProps = JSX.IntrinsicElements['a'] &
  RefAttributes<HTMLAnchorElement> &
  Pick<LinkProps, 'prefetch' | 'scroll' | 'shallow'>;

export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(
  ({ href, prefetch = false, ...props }, ref) => {
    if (href == null) {
      // Invalid href value makes NextLink throw an error
      const error = new Error();
      console.error(`Invalid href`, { error, props: { href, prefetch, ...props } });
      return null;
    }

    return <NextLink href={href} prefetch={prefetch} {...props} ref={ref} />;
  },
);

InternalLink.displayName = 'InternalLink';
