import { useRouter } from 'next/router';

/**
 * We have two affiliate systems in use:
 *  - MyAffiliates uses the `affid` parameter.
 *  - Netrefer uses the `btag` parameter.
 */
export const useAffiliateParameter = () => {
  const { query } = useRouter();

  if ('btag' in query && typeof query.btag === 'string') {
    return { parameter: 'btag', affiliateId: query.btag };
  }

  if ('affid' in query && typeof query.affid === 'string') {
    return { parameter: 'affid', affiliateId: query.affid };
  }

  return null;
};
