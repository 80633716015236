import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next';
import type { ChainedBackendOptions } from 'i18next-chained-backend';
import I18NextChainedBackend from 'i18next-chained-backend';
import { formatDate, getSiteProps } from '@pafcloud/locale';
import { logger } from '@pafcloud/logging';
import type { Site } from '@pafcloud/config';
import { createTranslationBackendPlugin, translationStore } from './i18n.server';
import { translationClientPlugin } from './i18n.client';
import { namespaces } from './namespaces';

export { I18nextProvider, useTranslation };
export type TFunction = (typeof i18n)['t'];

const TRANSLATION_REFRESH_INTERVAL = 60_000;
const isServer = typeof window === 'undefined';

type StoredInstance = {
  i18n: typeof i18n;
  init: Promise<unknown>;
};

const instances = new Map<string, StoredInstance>();

// This only needs to be dynamic for Storybook
let loadPath = '/api/translations?language={{lng}}&namespace={{ns}}';
export const setLoadPath = (path: string) => (loadPath = path);

const createInstance = (site: Site, language?: string) => {
  const { locales, defaultLocale } = getSiteProps(site);
  language ??= defaultLocale;

  const instanceKey = `${site}-${language}`;
  const existingInstance = instances.get(instanceKey);

  if (existingInstance) {
    return existingInstance;
  }

  const instance = i18n.createInstance();

  instance.use(initReactI18next);

  let backendOptions: ChainedBackendOptions | undefined;

  if (isServer) {
    instance.use(createTranslationBackendPlugin(site));
  } else {
    instance.use(I18NextChainedBackend);
    backendOptions = {
      backends: [translationClientPlugin, HttpApi],
      backendOptions: [{}, { loadPath }],
    };
  }

  const storedInstance: StoredInstance = {
    i18n: instance,
    init: instance.init({
      supportedLngs: locales,
      lng: language,
      defaultNS: 'common',
      fallbackLng: language,
      load: 'languageOnly',
      preload: isServer ? locales : false,
      backend: backendOptions,
      ns: namespaces,
      interpolation: {
        escapeValue: false,
        format: (value, format, lang) => {
          if (format) {
            return formatDate(value, format, lang);
          }

          return value ?? '';
        },
      },
      appendNamespaceToMissingKey: true,
      parseMissingKeyHandler(keyWithNamespace, defaultValue) {
        if (typeof defaultValue === 'string') {
          return defaultValue;
        }
        const [namespace, key] = keyWithNamespace.split(':');
        logger.error(`Missing translation (${language}) for: ${namespace} ${key}`);
        return key;
      },
      returnedObjectHandler(key, value) {
        logger.error(`Unexpected translation object for key: ${key}`);
        return value;
      },
    }),
  };

  startRefreshingTranslations();

  instances.set(instanceKey, storedInstance);

  return storedInstance;
};

export const getI18n = (site: Site, language?: string) => {
  const instance = createInstance(site, language);
  return instance.i18n;
};

export const getInitiatedI18n = async (site: Site, language?: string) => {
  const instance = createInstance(site, language);
  await instance.init;
  return instance.i18n;
};

let isRefreshingTranslations = false;

export function startRefreshingTranslations() {
  if (isRefreshingTranslations || !isServer) {
    return;
  }

  isRefreshingTranslations = true;

  setInterval(async function refreshTranslations() {
    try {
      await translationStore.updateCache();
      for await (const instance of instances.values()) {
        await instance.i18n.reloadResources(instance.i18n.language);
      }
    } catch (error) {
      logger.error('Failed to refresh translations', { error });
    }
  }, TRANSLATION_REFRESH_INTERVAL);
}
