type Typed<T = string> = {
  __typename?: T;
};

/**
 * Can be used to determine the type of a graphql union.
 *
 * @template TypeNames - The names of all possible types the object could be.
 * @template TypeName - The name of the specific type we are looking for.
 */
export const isType = <TypeNames extends string, TypeName extends TypeNames>(
  object: Typed<TypeNames> | undefined | null,
  ...types: TypeName[]
): object is Typed<TypeName> => types.some((type) => object?.__typename === type);
