import type { FetchFunction, IEnvironment } from 'relay-runtime';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import Router from 'next/router';
import { cookies, CSRF_TOKEN } from '@pafcloud/cookies';
import { getClientConfig } from '@pafcloud/config';

const MAX_RETRIES = 2;

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

type Headers = Record<string, string | string[] | undefined>;

let relayEnvironment: Environment | null = null;

const getFetchQuery = (headers?: Headers, onResponse?: (response: Response) => void): FetchFunction => {
  const config = getClientConfig();
  const version = encodeURIComponent(config.version ?? 'unknown');

  // Define a function that fetches the results of an operation (query/mutation/etc)
  // and returns its results as a Promise:
  return async (operation, variables, cacheConfig = {}) => {
    const csrfToken = operation.operationKind === 'mutation' ? cookies.get(CSRF_TOKEN) : null;

    const baseUrl = typeof window === 'undefined' ? config.serverLocalUrl : '';
    const path = typeof window === 'undefined' ? '' : encodeURIComponent(location.pathname);

    const fetchQuery = async (retries = 0): Promise<Response> => {
      const language = cacheConfig.metadata?.language ?? Router.locale;
      const graphqlUrl = `${baseUrl}/api/graphql?version=${version}&language=${language}&path=${path}`;

      try {
        return await fetch(graphqlUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(csrfToken ? { 'x-csrf-token': csrfToken } : {}),
            ...headers,
          },
          body: JSON.stringify({
            query: operation.text,
            variables,
          }),
        });
      } catch (error) {
        if (operation.operationKind === 'query' && retries < MAX_RETRIES) {
          // Wait a bit longer each retry to void spamming the network.
          await sleep(500 + retries * 1000);
          return fetchQuery(retries + 1);
        }
        throw error;
      }
    };

    const response = await fetchQuery();

    onResponse?.(response);

    return response.json();
  };
};

type RelayEnvironmentParams = {
  records?: RecordMap;
  headers?: Headers;
  onResponse?: (response: Response) => void;
};

export const initRelayEnvironment = ({ records, headers, onResponse }: RelayEnvironmentParams): IEnvironment => {
  // Create a network layer from the fetch function
  // TODO: No need to do this setup every time when we use the cached environment.
  const fetchQuery = getFetchQuery(headers, onResponse);
  const network = Network.create(fetchQuery);
  const store = new Store(new RecordSource(records));

  // Make sure to create a new Relay environment for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return new Environment({
      network,
      store,
    });
  }

  // Reuse Relay environment on client-side
  if (!relayEnvironment) {
    relayEnvironment = new Environment({
      network,
      store,
    });
  }

  return relayEnvironment;
};
