import { useId, forwardRef } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import type { EditableDropDownSelectProps } from '../drop-down-select';
import { EditableDropDownSelect } from '../drop-down-select';
import type { Alignment } from './HookFormMessage';
import { HookFormMessage } from './HookFormMessage';

type HookEditableDropDownSelectProps = Omit<
  EditableDropDownSelectProps,
  'defaultValue' | 'onChange' | 'touched' | 'dirty' | 'valid'
> & {
  info?: string;
  onSelect?: (value: unknown) => void;
  messageAlignment?: Alignment;
};

export const HookEditableDropDownSelect = forwardRef<HTMLInputElement, HookEditableDropDownSelectProps>(
  ({ info, children, onSelect, messageAlignment, ...props }, _ref) => {
    const messageId = useId();
    const state = useFormState<Record<string, string>>();
    const { setValue } = useFormContext();
    const value = useWatch({ name: props.name });

    const touched = state.touchedFields[props.name];
    const dirty = state.dirtyFields[props.name];
    const error = state.errors[props.name];

    return (
      <EditableDropDownSelect
        {...props}
        touched={touched}
        dirty={dirty}
        error={Boolean(error)}
        aria-describedby={messageId}
        value={value}
        onChange={(newValue) => {
          setValue(props.name, newValue, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
          });
          onSelect?.(newValue);
        }}
      >
        <HookFormMessage id={messageId} info={info} error={error?.message} alignment={messageAlignment} />
        {children}
      </EditableDropDownSelect>
    );
  },
);

HookEditableDropDownSelect.displayName = 'HookEditableDropDownSelect';
