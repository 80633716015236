import { useEffect } from 'react';
import { graphql, readInlineData } from 'relay-runtime';
import { showReminder, useDispatchReminders } from '@pafcloud/contexts';
import type { useAfterLoginReminder_data$key } from './__generated__/useAfterLoginReminder_data.graphql';

export const LOGGED_IN_FLASH = 'loggedInFlash';

export const setAfterLoginFlash = () => {
  sessionStorage.setItem(LOGGED_IN_FLASH, '1');
};

const useAfterLoginReminderFragment = graphql`
  fragment useAfterLoginReminder_data on Query @inline {
    player {
      reminder {
        afterLogin
      }
    }
  }
`;

export const useAfterLoginReminder = (key: useAfterLoginReminder_data$key | null) => {
  const data = readInlineData(useAfterLoginReminderFragment, key);
  const dispatchReminders = useDispatchReminders();

  useEffect(() => {
    const showReminderAfterLogin = data?.player?.reminder?.afterLogin ?? false;

    if (sessionStorage.getItem(LOGGED_IN_FLASH) != null) {
      if (showReminderAfterLogin) {
        dispatchReminders(showReminder('AFTER_LOGIN'));
      }
      sessionStorage.removeItem(LOGGED_IN_FLASH);
    }
  }, [data?.player, dispatchReminders]);
};
