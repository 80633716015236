import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import type { FC } from 'react';
import { inArray } from '@pafcloud/collection-utils';
import { useTranslation } from '@pafcloud/i18n';
import { useFlowRouter } from '@pafcloud/flow-router';
import { Heading, Icon, InternalLink, NotificationBadge } from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';
import { SidebarVerificationSectionColors } from './sidebar-verification-section-colors';
import type { SidebarVerificationSection_content$key } from './__generated__/SidebarVerificationSection_content.graphql';

const sidebarVerificationSectionFragment = graphql`
  fragment SidebarVerificationSection_content on Query {
    player @required(action: NONE) {
      emailVerified
      identification @required(action: NONE) {
        status
      }
      sourceOfFunds @required(action: NONE) {
        status
      }
    }
  }
`;

const SidebarIdentification = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'self-start',
  gap: 16,
  borderTop: SidebarVerificationSectionColors.Border,
  textShadow: SidebarVerificationSectionColors.TextShadow,
  padding: '16px 24px',
});

const SidebarIdentificationHeading = styled(Heading)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  margin: 0,
  marginLeft: -2, // visually aligns the badge with the text
  fontSize: FontTextSize.Small,
  color: SidebarVerificationSectionColors.HeadingColor,
});

const NotificationLink = styled(InternalLink)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: FontTextSize.Normal,
  fontWeight: 'bold',

  ':hover': {
    color: SidebarVerificationSectionColors.LinkHover,
  },

  ':focus, :active': {
    outline: 'none',
    color: SidebarVerificationSectionColors.LinkHover,
  },
});

type Props = {
  content: SidebarVerificationSection_content$key;
};

export const SidebarVerificationSection: FC<Props> = (props) => {
  const { t } = useTranslation('profile');
  const { getFlowUrl } = useFlowRouter();
  const content = useFragment(sidebarVerificationSectionFragment, props.content);

  const emailRequired = !content?.player?.emailVerified;
  const identificationRequired = inArray(
    ['REQUIRED', 'IN_PROGRESS', 'POSSIBLE'],
    content?.player?.identification?.status,
  );
  const sofRequired = inArray(['REQUIRED', 'REJECTED'], content?.player?.sourceOfFunds?.status);

  if (!content || (!emailRequired && !identificationRequired && !sofRequired)) {
    return null;
  }

  const sourceOfFundsInfoUrl = getFlowUrl('sourceOfFundsInfo');
  const identificationFlowUrl = getFlowUrl('verifyIdentity');
  const verifyEmailFlowUrl = getFlowUrl('verifyEmail');

  return (
    <SidebarIdentification>
      <SidebarIdentificationHeading>
        <NotificationBadge severity="high">!</NotificationBadge>
        {t('dialog.complete-verification.text')}
      </SidebarIdentificationHeading>

      {identificationRequired && (
        <NotificationLink href={identificationFlowUrl} shallow>
          {t('dialog.identification.text')}
          <Icon name="chevronRightFat" size="1rem" />
        </NotificationLink>
      )}

      {sofRequired && (
        <NotificationLink href={sourceOfFundsInfoUrl} shallow>
          {t('dialog.source-of-funds.text')}
          <Icon name="chevronRightFat" size="1rem" />
        </NotificationLink>
      )}
      {emailRequired && (
        <NotificationLink href={verifyEmailFlowUrl} shallow>
          {t('dialog.verify-email.text')}
          <Icon name="chevronRightFat" size="1rem" />
        </NotificationLink>
      )}
    </SidebarIdentification>
  );
};
