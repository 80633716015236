import type { CSSObject } from '@emotion/react';
import type { ButtonColorStyle, ButtonProps, ButtonSize, ButtonVariant } from './ButtonProps';
import {
  getCallToActionEffect,
  getColorAttributes,
  getCommonStyles,
  getIconStyles,
  getShadowAttributes,
  getSizeAttributes,
  getVariableStyles,
} from './get-attributes';

export const getButtonStyles = (
  props: ButtonSize & ButtonVariant & ButtonColorStyle & Pick<ButtonProps, 'ctaEffect' | 'iconAlignment'>,
): CSSObject => ({
  ...getCommonStyles(),
  ...getColorAttributes(props),
  ...getVariableStyles(),
  ...getSizeAttributes(props),
  ...getShadowAttributes(props),
  ...getCallToActionEffect(props),
  ...getIconStyles(props),
});
