import type { FC } from 'react';
import type { PortableTextBlock } from '@portabletext/types';
import { PortableText } from '@portabletext/react';
import type {
  PortableTextBlockComponent,
  PortableTextComponents,
  PortableTextMarkComponent,
} from '@portabletext/react';
import { safeParseJson } from '@pafcloud/util';
import { RichTextPlaceholderType } from './RichTextPlaceholderType';
import { RichTextImageType } from './RichTextImageType';
import { RichTextFileMark } from './RichTextFileMark';
import { RichTextBreakMark } from './RichTextBreakMark';
import { RichTextLinkMark } from './RichTextLinkMark';

export type RichTextContent = { json: string | null };
export type RichTextComponents = PortableTextComponents;
export type RichTextMarkComponent = PortableTextMarkComponent;
export type RichTextBlockComponent = PortableTextBlockComponent;

type RichTextContentProps = {
  content: RichTextContent;
  overrides?: RichTextComponents;
};

export const RichTextJsonContent: FC<RichTextContentProps> = ({ content, overrides }) => {
  const blocks = safeParseJson<PortableTextBlock[]>(content.json);

  if (blocks == null || blocks.length === 0) {
    return null;
  }

  return (
    <PortableText
      value={blocks}
      components={{
        ...overrides,
        types: {
          richTextImage: RichTextImageType,
          placeholder: RichTextPlaceholderType,
          ...overrides?.types,
        },
        marks: {
          link: RichTextLinkMark,
          break: RichTextBreakMark,
          file: RichTextFileMark,
          ...overrides?.marks,
        },
      }}
    />
  );
};
