import { useRouter } from 'next/router';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { Market } from '@pafcloud/config';
import { useConfig } from './ConfigContext';

const localeConfig: Record<string, Record<string, string>> = {
  finland: { en: 'en-us', fi: 'fi-fi', sv: 'sv-fi' },
  estonia: { en: 'en-us', et: 'et', ru: 'ru' },
  latvia: { en: 'en-us', lv: 'lv-lv', ru: 'ru-lv' },
  spain: { en: 'en-us', es: 'es' },
  sweden: { en: 'en-us', sv: 'sv' },
};

const getLocale = (locale: string | undefined, market: Market) => {
  const siteLocales = localeConfig[market];
  return siteLocales[locale ?? 'en'] || locale;
};

const shouldGetLocaleFromRouter = () => {
  return $buildEnv.site === 'mycasino.ch';
};

export function useLocalisedHelpdeskUrl() {
  const { helpdeskUrl } = useConfig();
  const { locale } = useRouter();

  if (shouldGetLocaleFromRouter()) {
    return `${helpdeskUrl}/${locale}`;
  }

  const customizedLocale = getLocale(locale, $buildEnv.market);
  return `${helpdeskUrl}/hc/${customizedLocale}`;
}
