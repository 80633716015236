import { Color } from '@pafcloud/style';

export const Colors = {
  InputBackground: 'transparent',
  InputBackgroundDisabled: Color.Surface.Disabled.Background + '20',

  LabelUntouched: Color.TextColors.MutedText,
  LabelDisabled: Color.TextColors.DisabledText,
  LabelValid: Color.TextColors.BodyText,
  LabelInvalid: Color.Signal.Danger,
  LabelFocused: Color.TextColors.BodyText,
  LabelReadOnly: Color.TextColors.BodyText,

  BorderUntouched: Color.TextColors.MutedText,
  BorderDisabled: Color.TextColors.DisabledText,
  BorderValid: Color.TextColors.BodyText,
  BorderInvalid: Color.Signal.Danger,
  BorderFocused: Color.TextColors.BodyText,
  BorderReadOnly: Color.TextColors.DisabledText,

  TextUntouched: Color.TextColors.BodyText,
  TextDisabled: Color.TextColors.DisabledText,
  TextValid: Color.TextColors.BodyText,
  TextInvalid: Color.TextColors.BodyText,
  TextFocused: Color.TextColors.BodyText,
  TextReadOnly: Color.TextColors.BodyText,

  Caret: 'currentColor',
  Placeholder: Color.Surface.Nested.Dimmed,
  Suffix: Color.Primitive.Accent,

  MessageDropShadow: Color.Elevation.Level2,

  ErrorMessageBorder: Color.Signal.Danger,
  ErrorMessageBackground: Color.Signal.Danger,
  ErrorMessageTextColor: Color.Signal.DangerContrast,

  InfoMessageBorder: Color.Signal.Info,
  InfoMessageBackground: Color.Signal.Info,
  InfoMessageTextColor: Color.Signal.InfoContrast,
};
