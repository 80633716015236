import type { FC } from 'react';
import styled from '@emotion/styled';
import { Icon } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { Color, FontTextSize } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';

const Guideline = styled.div({
  width: '100%',
  minHeight: 56,
  padding: '8px 32px 12px',
  fontWeight: 'bold',
  fontSize: FontTextSize.Tiny,
  textAlign: 'left',
  color: Color.TextColors.BodyText,
});

const Ruleset = styled.div({
  marginBottom: 16,
});

const Rules = styled.ul({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
});

const Description = styled.li({
  marginBottom: 8,
});

const RuleEntry = styled.li({
  margin: '0 0 8px 16px',
  display: 'flex',
});

const shouldForwardIconProp = (propName: string): boolean => {
  if (propName === 'valid') {
    return false;
  }

  return true;
};

const ValidityIcon = styled(Icon, { shouldForwardProp: shouldForwardIconProp })<{ valid: boolean }>(({ valid }) => ({
  color: valid ? Color.Signal.Success : Color.TextColors.MutedText,
  width: 24,
  flexShrink: 0,
  marginTop: '0.25em',
}));

const Rule: FC<{ label: string; valid: boolean }> = ({ label, valid }) => {
  return (
    <RuleEntry aria-invalid={!valid}>
      <ValidityIcon valid={valid} name={valid ? 'check' : 'closeFat'} size={FontTextSize.Tiny} />
      {label}
    </RuleEntry>
  );
};

const checkContainsInvalidWords = (
  input: PasswordGuidelineProps['value'],
  formValues?: PasswordGuidelineProps['formValues'],
) => {
  if ($buildEnv.market !== 'latvia') {
    return false;
  }

  if (formValues == null) {
    return false;
  }

  if (formValues.firstName) {
    if (input.toLowerCase().includes(formValues.firstName.toLowerCase())) {
      return true;
    }
  }

  if (formValues.lastName) {
    if (input.toLowerCase().includes(formValues.lastName.toLowerCase())) {
      return true;
    }
  }

  if (formValues.dateOfBirth) {
    if (input.replace(/-/g, '').includes(formValues.dateOfBirth.replace(/-/g, ''))) {
      return true;
    }
  }

  return false;
};

const getValidationState = (
  input: PasswordGuidelineProps['value'],
  formValues?: PasswordGuidelineProps['formValues'],
) => {
  if (!input) {
    return {
      minLength: false,
      number: false,
      lowercase: false,
      uppercase: false,
      validWords: true,
    };
  }

  return {
    minLength: input.length >= 12,
    number: /\d/.test(input),
    lowercase: /[a-z]/.test(input),
    uppercase: /[A-Z]/.test(input),
    validWords: !checkContainsInvalidWords(input, formValues),
  };
};

type PasswordGuidelineProps = {
  value: string;
  formValues?: Record<'firstName' | 'lastName' | 'dateOfBirth', string | undefined>;
};

export const PasswordGuideline: FC<PasswordGuidelineProps> = ({ value, formValues }) => {
  const { t } = useTranslation('manual-registration');

  const validationState = getValidationState(value, formValues);

  return (
    <Guideline>
      <Ruleset>
        <Rules>
          <Description>{t('form.password.requirement.required')}</Description>
          <Rule label={t('form.password.rule.minimum-length')} valid={validationState.minLength} />
          <Rule label={t('form.password.rule.lowercase')} valid={validationState.lowercase} />
          <Rule label={t('form.password.rule.uppercase')} valid={validationState.uppercase} />
          <Rule label={t('form.password.rule.number')} valid={validationState.number} />
          {$buildEnv.market === 'latvia' && formValues != null && (
            <Rule label={t('form.password.rule.no-invalid-words')} valid={validationState.validWords} />
          )}
        </Rules>
      </Ruleset>
    </Guideline>
  );
};

export const validatePassword = (
  input: PasswordGuidelineProps['value'],
  formValues?: PasswordGuidelineProps['formValues'],
) => {
  const state = getValidationState(input, formValues);

  return Object.values(state).every((value) => value);
};
