import type { FC } from 'react';
import styled from '@emotion/styled';
import { FontTextSize } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { Button } from '@pafcloud/base-components/src/button';
import { Heading, Section } from '@pafcloud/base-components/src/auto-heading';

const Wrapper = styled.article({
  margin: 'auto',
  padding: 32,
  textAlign: 'center',
});

const Title = styled(Heading)({
  fontSize: FontTextSize.Big,
  fontWeight: 'bold',
});

const RetryButton = styled(Button)({
  margin: '32px auto 16px auto',
});

type Props = {
  error: Error;
  retry: null | (() => void);
};

export const QueryError: FC<Props> = ({ error, retry }) => {
  const { t } = useTranslation('common');

  return (
    <Section>
      <Wrapper>
        <Title>{error.message}</Title>
        {retry && (
          <RetryButton type="button" size="default" variant="primary" onClick={retry}>
            {t('loading-retry')}
          </RetryButton>
        )}
      </Wrapper>
    </Section>
  );
};
