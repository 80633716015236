/**
 * We must make sure that the clients are regularly updated to avoid having to
 * maintain backwards compatibility in our apis.
 *
 * Most visitors naturally get a new client because the browser refreshes during
 * logout, if the tab is closed and reopened etc. But some visitors have very
 * old clients, probably because they leave the tab open and then come back to it.
 *
 * To limit the problem with old clients we do a hard refresh:
 * - On any route change after a new version is detected
 * - If browser window is focused and a new version is detected after more than 1 hour of idling
 */
import { useEffect, useRef } from 'react';
import { Router } from 'next/router';
import { getClientConfig } from '@pafcloud/config';
import { logger } from '@pafcloud/logging';

const clientVersion = () => getClientConfig().version;

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;

type ServerState = {
  version: string;
  refreshed: Date;
};

export const useRefreshOnVersionChange = () => {
  const serverState = useRef<ServerState>({
    version: clientVersion() ?? '',
    refreshed: new Date(),
  });

  const setServerVersion = (version: string) => {
    if (version === serverState.current.version) {
      return;
    }

    serverState.current = {
      version,
      refreshed: new Date(),
    };
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const oneMinuteAgo = Date.now() - ONE_MINUTE;

      // Prevent refresh for 1 minute to prevent getting stale client from cloudflare cache.
      if (serverState.current.refreshed.getTime() >= oneMinuteAgo) {
        return;
      }

      if (serverState.current.version !== clientVersion()) {
        logger.info(
          `New version found (${clientVersion()} -> ${serverState.current.version}), refreshing on route change`,
        );
        window.location.href = url;
      }
    };

    Router.events.on('routeChangeStart', handleRouteChange);

    return () => Router.events.off('routeChangeStart', handleRouteChange);
  }, []);

  useEffect(() => {
    const refreshVersion = async () => {
      const response = await fetch('/api/version');
      const body = (await response.json()) as { version: string };
      setServerVersion(body.version);
    };

    const handleVisibilityChange = async () => {
      if (document.hidden) {
        return;
      }

      const anHourAgo = Date.now() - ONE_HOUR;
      if (serverState.current.refreshed.getTime() < anHourAgo) {
        await refreshVersion();

        if (serverState.current.version !== clientVersion()) {
          logger.info(`New version found (${clientVersion()} -> ${serverState.current.version}), refreshing`);
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return {
    setServerVersion,
  };
};
