import type { CSSObject } from '@emotion/react';
import { MainMenuFontStyles } from './main-menu-colors';

export const menuLinkStyle: CSSObject = {
  minHeight: 'inherit',
  padding: '0 24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'inherit',
  outline: 'none',
  fontWeight: MainMenuFontStyles.Weight,

  '@media (hover: hover)': {
    ':hover': {
      fontWeight: MainMenuFontStyles.WeightHover,
    },
  },

  '[aria-current="location"] &': {
    fontWeight: MainMenuFontStyles.WeightActive,
  },
};
