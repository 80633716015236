/**
 * @generated SignedSource<<1fb8adb6bb402f025ffcbb4dfaf833fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackGame_games$data = {
  readonly recommendationGroupId: string;
  readonly recommendationId: string;
  readonly " $fragmentType": "useTrackGame_games";
};
export type useTrackGame_games$key = {
  readonly " $data"?: useTrackGame_games$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_games">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTrackGame_games"
};

(node as any).hash = "dffd48f5451f39ec7b613ff1746d9d57";

export default node;
