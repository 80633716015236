import type { GraphQLTaggedNode, IEnvironment, OperationType } from 'relay-runtime';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { useRelayEnvironment } from 'react-relay/hooks';
import { useRef } from 'react';
import { useHandler } from '@pafcloud/react-hook-utils';

const queryRelay = <T extends OperationType>(
  environment: IEnvironment,
  query: GraphQLTaggedNode,
  variables: T['variables'] = {},
) => {
  return new Promise<T['response']>((resolve, reject) => {
    const operation = createOperationDescriptor(getRequest(query), variables);

    environment.execute({ operation }).subscribe({
      error: (error: Error) => reject(error),
      complete: () => resolve(environment.lookup(operation.fragment).data),
    });
  });
};

export const useQuery = <T extends OperationType>(query: GraphQLTaggedNode) => {
  const environment = useRelayEnvironment();

  return useHandler((variables: T['variables'] = {}) => {
    return queryRelay<T>(environment, query, variables);
  });
};

export const useDebouncedQuery = <T extends OperationType>(query: GraphQLTaggedNode, delay: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const makeQuery = useQuery<T>(query);

  return useHandler(async (variables: T['variables'] = {}) => {
    return new Promise<T['response']>((resolve, _reject) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      timeoutRef.current = setTimeout(() => {
        resolve(makeQuery(variables));
      }, delay);
    });
  });
};

export const createUseQuery = <T extends OperationType>(query: GraphQLTaggedNode) => {
  return <TResult>(onResult: (result: T['response']) => TResult) => {
    return () => {
      const triggerQuery = useQuery<T>(query);

      return useHandler((variables: T['variables'] = {}) => {
        return triggerQuery(variables).then(onResult);
      });
    };
  };
};
