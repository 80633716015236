import { useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns/differenceInMilliseconds';
import { addMinutes } from 'date-fns/addMinutes';
import { graphql, readInlineData } from 'relay-runtime';
import { cookies, REMINDER_INTERVAL_STARTED_AT } from '@pafcloud/cookies';
import { showReminder, useDispatchReminders } from '@pafcloud/contexts';
import type { useIntervalReminders_data$key } from './__generated__/useIntervalReminders_data.graphql';

export const resetIntervalReminder = () => {
  cookies.remove(REMINDER_INTERVAL_STARTED_AT);
};

const refreshIntervalReminder = () => {
  cookies.set(REMINDER_INTERVAL_STARTED_AT, String(Date.now()));
};

const getMsUntilInterval = (intervalStartedAt: Date, interval: number) => {
  const timeoutEndDate = addMinutes(intervalStartedAt, interval);

  return differenceInMilliseconds(timeoutEndDate, new Date());
};

const useIntervalRemindersFragment = graphql`
  fragment useIntervalReminders_data on Query @inline {
    player {
      reminder {
        interval
      }
    }
  }
`;

export const useIntervalReminders = (key: useIntervalReminders_data$key | null) => {
  const data = readInlineData(useIntervalRemindersFragment, key);
  const dispatchReminders = useDispatchReminders();
  const reminderInterval = data?.player?.reminder?.interval ?? null;

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const run = (interval: number, timerStartedAt: Date) => {
      // get how many ms it is until interval reached
      const msUntilInterval = getMsUntilInterval(timerStartedAt, interval);

      const triggerIntervalReminder = () => {
        dispatchReminders(showReminder('INTERVAL'));
        refreshIntervalReminder();
        run(interval, new Date());
      };

      if (msUntilInterval >= 0) {
        timeoutId = setTimeout(() => triggerIntervalReminder(), msUntilInterval);
      } else {
        triggerIntervalReminder();
      }
    };

    if (reminderInterval) {
      const intervalStartedAtCookie = cookies.get(REMINDER_INTERVAL_STARTED_AT);

      // get date from interval started cookie or current date
      const startedAt = intervalStartedAtCookie ? new Date(Number(intervalStartedAtCookie)) : new Date();

      // set a cookie with start date if we did not have one
      if (intervalStartedAtCookie == null) {
        refreshIntervalReminder();
      }

      run(reminderInterval, startedAt);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [reminderInterval, dispatchReminders]);
};
