import { $buildEnv } from '@pafcloud/config/src/buildEnv';

export const getRGSlug = () => {
  if ($buildEnv.market === 'spain') {
    return 'safer-gaming';
  }

  if ($buildEnv.market === 'switzerland') {
    return 'responsible-gaming';
  }

  return 'play-responsibly';
};
