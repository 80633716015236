import { useState } from 'react';
import { useHandler } from './useHandler';

/**
 * Captures errors and throws them back within the react render cycle,
 * allowing us to propagate the error to error boundaries.
 */
export const useThrowToErrorBoundary = () => {
  // We can use `setState` to handle errors in the react lifecycle.
  // This way we can catch them in error boundaries without having to use `useEffect`.
  // See: https://github.com/facebook/react/issues/14981#issuecomment-468460187
  const [, captureLifecycleError] = useState(null);

  return useHandler(<ReturnValue>(callback: () => ReturnValue) => {
    try {
      return callback();
    } catch (error) {
      captureLifecycleError(() => {
        throw error;
      });
    }
  });
};
