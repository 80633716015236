/**
 * @generated SignedSource<<1da2ac0415f23873aaf6405b032be199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_content$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentDefault_content" | "HeaderContentGoldenV2_content" | "HeaderContentGolden_content" | "HeaderContentOneOne_content" | "HeaderContentQuirkyPurple_content" | "HeaderContentQuirky_content" | "HeaderContentSpeedy_content" | "HeaderContentX3000_content" | "MainMenu_content">;
  readonly " $fragmentType": "Header_content";
};
export type Header_content$key = {
  readonly " $data"?: Header_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentDefault_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentSpeedy_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentGolden_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentGoldenV2_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentX3000_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentOneOne_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentQuirky_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderContentQuirkyPurple_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MainMenu_content"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b1609c80ba84c065013daa8d25cd810c";

export default node;
