import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { BurgerComponent as BurgerComponent_default } from './BurgerComponent.default';
import { BurgerComponent as BurgerComponent_momentum } from './BurgerComponent.momentum';

export const BurgerComponent = withBuildEnv(() => {
  if ($buildEnv.theme === 'momentum') {
    return BurgerComponent_momentum;
  }

  if ($buildEnv.theme === 'momentum-neon') {
    return BurgerComponent_momentum;
  }

  return BurgerComponent_default;
});
