import { subMinutes } from 'date-fns/subMinutes';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { useEffect, useRef } from 'react';
import { graphql, readInlineData } from 'relay-runtime';
import { showReminder, useDispatchReminders } from '@pafcloud/contexts';
import type { useSessionExpiresReminder_data$key } from './__generated__/useSessionExpiresReminder_data.graphql';

// setTimeout can't store values over max 32bit integer.
const MAX_TIMEOUT = 2147483647;

const minutesToMs = (minutes: number) => Math.min(minutes * 60 * 1000, MAX_TIMEOUT);

const useSessionExpiresReminderFragment = graphql`
  fragment useSessionExpiresReminder_data on Query @inline {
    player {
      session {
        expiresAt
      }
    }
  }
`;

export const useSessionExpiresReminder = (key: useSessionExpiresReminder_data$key | null) => {
  const data = readInlineData(useSessionExpiresReminderFragment, key);
  const dispatchReminders = useDispatchReminders();
  const discarded = useRef(true);
  const expiresAt = data?.player?.session?.expiresAt ?? null;

  useEffect(() => {
    discarded.current = false;
    let timeoutId: ReturnType<typeof setTimeout>;

    if (expiresAt) {
      const expiresAtDate = new Date(expiresAt);

      // get how many minutes there is until the session expires
      const minutesLeftOfSession = differenceInMinutes(expiresAtDate, Date.now());

      // get when ten minutes before session expiration is
      const tenMinutesBeforeExpire = subMinutes(expiresAtDate, 10);

      if (minutesLeftOfSession > 10) {
        // if time left is more than 10 minutes, we want to schedule the reminder

        const minutesLeftUntilReminder = differenceInMinutes(tenMinutesBeforeExpire, Date.now());

        timeoutId = setTimeout(() => {
          if (!discarded.current) {
            dispatchReminders(showReminder('SESSION_LESS_THAN_10_MINUTES_LEFT'));
          }
        }, minutesToMs(minutesLeftUntilReminder));
      } else if (minutesLeftOfSession > 0) {
        // if time left is larger than 0, show the reminder immediately
        dispatchReminders(showReminder('SESSION_LESS_THAN_10_MINUTES_LEFT'));
      }
    }

    return () => {
      discarded.current = true;

      clearTimeout(timeoutId);
    };
  }, [expiresAt, dispatchReminders]);
};
