import { cookies, GAME_PAUSE_START_TIME } from '@pafcloud/cookies';

export const getGamePauseSession = () => {
  const storedStartTime = cookies.get(GAME_PAUSE_START_TIME);

  if (storedStartTime == null) {
    const startTime = Date.now();
    cookies.set(GAME_PAUSE_START_TIME, String(startTime));
    return startTime;
  }

  return Number(storedStartTime);
};

export const resetGamePauseSession = () => {
  cookies.remove(GAME_PAUSE_START_TIME);
};
