import { useCallback, useEffect, useState } from 'react';
import { safeParseJson } from '@pafcloud/util';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import { useRecentSearchResults } from './RecentSearchContext';
import type { SearchResult } from './Search';

export type RecentSearch = Omit<SearchResult, 'supplier' | 'tags' | 'title'>;

type LocalStorageAction = 'insert' | 'delete';

const OLD_RECENT_SEARCHES_KEY = 'gamesearch.recentlySearchedGames';
const RECENT_SEARCHES_KEY = 'recentlySearchedGames';

export const useRecentSearch = (gameListId: string) => {
  const isSSR = useIsSSR();

  const getRecentSearches = useCallback(() => {
    if (isSSR) {
      return null;
    }

    const savedSearches = safeParseJson<Record<string, RecentSearch[]>>(localStorage.getItem(RECENT_SEARCHES_KEY));

    if (savedSearches == null) {
      return null;
    }

    return savedSearches;
  }, [isSSR]);

  const [recentSearchResult, insertRecentSearchResult] = useState<SearchResult | RecentSearch>();
  const [deletedSearchResult, deleteRecentSearchResult] = useState<RecentSearch>();
  const [recentSearches, setRecentSearches] = useState<Record<string, RecentSearch[]> | null>(getRecentSearches());
  const { setRecentSearchResults } = useRecentSearchResults();

  const updateLocalStorage = useCallback(
    (gameToUpdate: RecentSearch, localStorageAction: LocalStorageAction) => {
      if (gameToUpdate.gameListId && gameToUpdate.rawSupplier && gameToUpdate.rawTitle) {
        const savedSearches = getRecentSearches();
        const gameListToUpdate = gameToUpdate.gameListId;

        if (savedSearches == null) {
          localStorage.setItem(
            RECENT_SEARCHES_KEY,
            JSON.stringify({
              [gameListToUpdate]: [gameToUpdate],
            }),
          );
          setRecentSearches({
            [gameListToUpdate]: [gameToUpdate],
          });
          return;
        }

        const getUpdatedSearches = () => {
          if (localStorageAction === 'insert') {
            if (savedSearches[gameListToUpdate] == null) {
              savedSearches[gameListToUpdate] = [];
            }

            savedSearches[gameListToUpdate] = savedSearches[gameListToUpdate].filter(
              (game) => game.rawTitle !== gameToUpdate.rawTitle,
            );

            savedSearches[gameListToUpdate].unshift(gameToUpdate);

            return {
              ...savedSearches,
              [gameListToUpdate]: savedSearches[gameListToUpdate].slice(0, 6),
            };
          }

          return {
            ...savedSearches,
            [gameListToUpdate]: savedSearches[gameListToUpdate].filter(
              (game) => game.rawTitle !== gameToUpdate.rawTitle,
            ),
          };
        };

        const updatedSearches = getUpdatedSearches();
        localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches));
        setRecentSearches(updatedSearches);
      }
    },
    [getRecentSearches],
  );

  useEffect(() => {
    if (recentSearchResult?.category) {
      const {
        gameListId: recentGameListId,
        id,
        rawSupplier,
        rawTitle,
        slug,
        thumbnail,
        category,
        launchUrl,
      } = recentSearchResult;

      updateLocalStorage(
        {
          gameListId: recentGameListId,
          id,
          rawSupplier,
          rawTitle,
          slug,
          thumbnail,
          category,
          launchUrl,
        },
        'insert',
      );
    }
  }, [recentSearchResult, updateLocalStorage]);

  useEffect(() => {
    if (deletedSearchResult) {
      updateLocalStorage(deletedSearchResult, 'delete');
    }
  }, [deletedSearchResult, updateLocalStorage]);

  useEffect(() => {
    // Ensure backwards compatibility
    const oldRecentSearches = localStorage.getItem(OLD_RECENT_SEARCHES_KEY);

    if (oldRecentSearches) {
      const oldStoredSearches = safeParseJson<SearchResult[]>(oldRecentSearches);
      // The old local storage item only saved items from the same game list.
      const storedGameListId = oldStoredSearches?.[0]?.gameListId;

      if (oldStoredSearches != null && storedGameListId != null) {
        setRecentSearchResults((prev) => ({
          ...prev,
          [storedGameListId]: oldStoredSearches,
        }));

        const storedSearches = safeParseJson<Record<string, SearchResult[]>>(localStorage.getItem(RECENT_SEARCHES_KEY));

        if (storedSearches != null) {
          localStorage.setItem(
            RECENT_SEARCHES_KEY,
            JSON.stringify({
              ...storedSearches,
              [storedGameListId]: oldStoredSearches,
            }),
          );
        } else {
          localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(oldStoredSearches));
        }
      }

      localStorage.removeItem(OLD_RECENT_SEARCHES_KEY);
    }

    if (recentSearches != null) {
      setRecentSearchResults((prev) => ({
        ...prev,
        [gameListId]: recentSearches[gameListId],
      }));
    }
  }, [recentSearches, gameListId, setRecentSearchResults]);

  return {
    insertRecentSearchResult,
    deleteRecentSearchResult,
    recentSearches,
  };
};
