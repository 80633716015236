/**
 * @generated SignedSource<<27a8143f04c6129fe5a0aa3fce0d2caa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Contexts_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BonusContext_data" | "PlayerContext_data">;
  readonly " $fragmentType": "Contexts_data";
};
export type Contexts_data$key = {
  readonly " $data"?: Contexts_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Contexts_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Contexts_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusContext_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlayerContext_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f93f0a4d635d16ef9da7e904e3af7e12";

export default node;
