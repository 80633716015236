import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useHandler } from '@pafcloud/react-hook-utils';

export const useInViewTracking = (onView: (entry: IntersectionObserverEntry) => void) => {
  // If we need it there are polyfills
  const isMissingSupport = typeof IntersectionObserver === 'undefined';

  const { ref, entry } = useInView({
    threshold: 0.5,
    skip: isMissingSupport,
  });

  const latestOnView = useHandler(onView);

  useEffect(() => {
    if (entry) {
      latestOnView(entry);
    }
  }, [latestOnView, entry]);

  return ref;
};
