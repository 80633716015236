import type { FC, MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';
import { QueryRenderer } from 'react-relay/legacy';
import { useFragment, graphql, useRelayEnvironment } from 'react-relay/hooks';
import { toast } from 'react-toastify';

import { useHandler } from '@pafcloud/react-hook-utils';
import { useBonus } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import type { LiveMessageNotificationsQuery } from './__generated__/LiveMessageNotificationsQuery.graphql';
import type { LiveMessageNotificationsPreviewsQuery } from './__generated__/LiveMessageNotificationsPreviewsQuery.graphql';
import type {
  LiveMessageNotifications_messages$key,
  InboxMessageSubject,
} from './__generated__/LiveMessageNotifications_messages.graphql';
import { MessageListItem } from './message-list-item';

const messagesFragment = graphql`
  fragment LiveMessageNotifications_messages on InboxMessagePreviews {
    items {
      category
      publishedAt
      ...MessageListItem_item
    }
  }
`;

type Props = {
  messages: LiveMessageNotifications_messages$key;
  lastNotificationTime: MutableRefObject<Date>;
};

export const LiveMessageNotificationsContainer: FC<Props> = ({ messages, lastNotificationTime }) => {
  const { items } = useFragment(messagesFragment, messages);
  const { openFlow } = useFlowRouter();
  const { refetchBonusContextQuery } = useBonus();

  const updateBonus = useHandler((category: InboxMessageSubject) => {
    // This is mainly to update Turnover related things, but is not possible to limit further without
    // matching on the translations of literal strings (using item.subject), eg. "You have a new offer!"
    if (category === 'PROMOTION') {
      refetchBonusContextQuery();
    }
  });

  useEffect(() => {
    for (const item of items) {
      if (item == null) {
        continue;
      }

      const publishedTime = new Date(item.publishedAt);

      if (publishedTime > lastNotificationTime.current) {
        updateBonus(item.category);
        toast(<MessageListItem item={item} onClick={(messageId) => openFlow('message', { messageId })} />, {
          closeButton: false,
          closeOnClick: true,
          draggable: false,
        });
      }
    }

    lastNotificationTime.current = new Date();
  }, [items, openFlow, updateBonus, lastNotificationTime]);

  return null;
};

const liveMessageNotificationsQuery = graphql`
  query LiveMessageNotificationsQuery {
    inbox {
      unreadCount
    }
  }
`;

const liveMessageNotificationsPreviewsQuery = graphql`
  query LiveMessageNotificationsPreviewsQuery {
    inbox {
      messagePreviews(size: 1, page: 1, includeOpened: false) @required(action: NONE) {
        ...LiveMessageNotifications_messages
      }
    }
  }
`;

export const LiveMessageNotifications = () => {
  const environment = useRelayEnvironment();

  // We need to track the last notification time to only display newer messages.
  const lastNotificationTime = useRef(new Date());

  return (
    <QueryRenderer<LiveMessageNotificationsQuery>
      environment={environment}
      query={liveMessageNotificationsQuery}
      variables={{}}
      render={({ props }) => {
        if (props == null || props.inbox == null || props.inbox.unreadCount === 0) {
          return null;
        }

        return (
          <QueryRenderer<LiveMessageNotificationsPreviewsQuery>
            /*
             * By using a key here, we can re-run the query request when the count updates.
             * Otherwise this will only run once if the count is above 0.
             */
            key={props.inbox.unreadCount}
            environment={environment}
            query={liveMessageNotificationsPreviewsQuery}
            variables={{}}
            render={({ props: previewsResult }) => {
              if (previewsResult == null || previewsResult.inbox == null) {
                return null;
              }

              return (
                <LiveMessageNotificationsContainer
                  messages={previewsResult.inbox.messagePreviews}
                  lastNotificationTime={lastNotificationTime}
                />
              );
            }}
          />
        );
      }}
    />
  );
};
