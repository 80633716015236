import { formatDuration as formatDurationFn } from 'date-fns/formatDuration';
import { differenceInCalendarYears } from 'date-fns/differenceInCalendarYears';
import { format as formatFn } from 'date-fns/format';
import { formatISO } from 'date-fns/formatISO';
import { formatISODuration } from 'date-fns/formatISODuration';
import { intervalToDuration } from 'date-fns/intervalToDuration';
import { isValid } from 'date-fns/isValid';
import { parseISO } from 'date-fns/parseISO';
import { hoursToSeconds } from 'date-fns/hoursToSeconds';
import { minutesToSeconds } from 'date-fns/minutesToSeconds';

import type { Duration, Interval } from 'date-fns';
import { getHours, getMinutes } from 'date-fns';
import { isLanguageCode } from './locale';
import { getLanguageLocale } from './languageLocales';
import { getLocale } from './locales';

export type DateLike = Date | string | number;

const getDate = (value: DateLike) => {
  if (value instanceof Date) {
    return value;
  }
  return new Date(value);
};

const isValidDate = (date: Date) => {
  return !isNaN(date.getTime());
};

export const formatDate = (value: DateLike, format: string, lang = 'en') => {
  const date = getDate(value);

  if (!isValidDate(date) || !isLanguageCode(lang)) {
    return '';
  }

  return formatFn(date, format, {
    locale: getLocale(lang),
  });
};

export const formatDateByLocale = (value: DateLike, lang = 'en', options?: Intl.DateTimeFormatOptions) => {
  const date = getDate(value);

  if (!isValidDate(date) || !isLanguageCode(lang)) {
    return '';
  }

  const locale = getLanguageLocale(lang);

  return new Intl.DateTimeFormat(
    locale,
    options ?? {
      dateStyle: 'short',
      timeStyle: 'short',
    },
  ).format(date);
};

export const intervalToISODuration = (interval: Interval) => {
  return formatISODuration(intervalToDuration(interval));
};

export const toISOString = (value: Date | number | string) => {
  if (value instanceof Date) {
    return formatISO(value);
  }

  // value can be a numeric string like '1624452381045'
  const maybeNumber = Number(value);
  if (isValid(maybeNumber)) {
    return formatISO(maybeNumber);
  }

  return formatISO(parseISO(value as string));
};

export const getAge = (dateOfBirth: string) => {
  return differenceInCalendarYears(new Date(), new Date(dateOfBirth));
};

export const isoDurationToTime = (isoDuration: string) => {
  // Split period string into pairs combining number and denotation, eg. "P3W4D5H26M44S" is parsed into ["3W", "4D", "5H", "26M", "44S"]
  // The filter removes any letters that's not preceeded by a number, essentially just "P" (period)
  const values = isoDuration.split(/([\d]+\D)/).filter((value) => value.match(/\d/));

  type Units = {
    weeks?: string;
    days?: string;
    hours?: string;
    minutes?: string;
  };

  const units: Units = values.reduce((acc, unit) => {
    if (unit.includes('W')) {
      return { ...acc, weeks: unit.slice(0, -1) };
    }
    if (unit.includes('D')) {
      return { ...acc, days: unit.slice(0, -1) };
    }
    if (unit.includes('H')) {
      return { ...acc, hours: unit.slice(0, -1) };
    }
    if (unit.includes('M')) {
      return { ...acc, minutes: unit.slice(0, -1) };
    }

    return acc;
  }, {});

  // Convert any weeks to days
  const weeksInDays = units.weeks ? Number(units.weeks) * 7 : 0;
  // Convert any days to hours
  const daysInHours = units.days ? (Number(units.days) + weeksInDays) * 24 : 0;

  const hours = units.hours ? Number(units.hours) + daysInHours : 0;

  return hoursToSeconds(hours) + minutesToSeconds(Number(units.minutes) || 0);
};

/**
 * To get a readable date and time to be shown to the user
 *
 * Date returned as 'yyyy-mm-dd'
 *
 * Time returned as 'hh:mm'
 */
export const getDateAndTime = (date: DateLike, lang = 'en') => {
  const isoDate = formatDate(date, 'yyyy-MM-dd', lang);
  const isoTime = formatDate(date, 'HH:mm', lang);

  return { date: isoDate, time: isoTime };
};

export const formatDuration = (duration: Duration, lang = 'en', format?: (keyof Duration)[]) => {
  if (!isLanguageCode(lang)) {
    return '';
  }

  return formatDurationFn(duration, {
    locale: getLocale(lang),
    format,
  });
};

export const formatTime = (date: number | Date) => {
  const hours = getHours(date).toString().padStart(2, '0');
  const minutes = getMinutes(date).toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};
