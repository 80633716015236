import { useRouter } from 'next/router';
import { graphql, readInlineData } from 'react-relay/hooks';
import { useHandler } from '@pafcloud/react-hook-utils';
import { useIsLoggedIn } from '../player-context';
import type { useTrackGame_game$key } from './__generated__/useTrackGame_game.graphql';
import type { useTrackGame_games$key } from './__generated__/useTrackGame_games.graphql';
import type { useTrackGame_gameList$key } from './__generated__/useTrackGame_gameList.graphql';
import type { useTrackGame_trackable$key } from './__generated__/useTrackGame_trackable.graphql';
import { useGameTracking } from './gameTrackingContext';

const trackableFragment = graphql`
  fragment useTrackGame_trackable on Trackable @inline {
    track
    trackId
  }
`;

const gamesFragment = graphql`
  fragment useTrackGame_games on GameListGames_Connection @inline {
    recommendationId
    recommendationGroupId
  }
`;

const gameListFragment = graphql`
  fragment useTrackGame_gameList on GameList @inline {
    gameListId
    internalName
  }
`;

const gameFragment = graphql`
  fragment useTrackGame_game on Game @inline {
    category
    familyName
    gameId
    launchUrl
    name
    provider
    slug
  }
`;

type BlockDataProps = {
  games: useTrackGame_games$key;
  gameList: useTrackGame_gameList$key;
  trackable: useTrackGame_trackable$key | null;
};

export type GameClickedProps = {
  game: useTrackGame_game$key;
  gameListPosition: number;
};

export const useTrackClickedGame = (props: BlockDataProps) => {
  const isLoggedIn = useIsLoggedIn();
  const { query } = useRouter();
  const { trackClickedGame } = useGameTracking();

  return useHandler(({ game, gameListPosition }: GameClickedProps) => {
    if (isLoggedIn) {
      const trackable = readInlineData(trackableFragment, props.trackable);

      if (trackable?.track && trackable.trackId) {
        const games = readInlineData(gamesFragment, props.games);
        const gameList = readInlineData(gameListFragment, props.gameList);
        const gameData = readInlineData(gameFragment, game);

        trackClickedGame({
          gameListPosition,
          pageSlug: query.slug?.[0] ?? '',
          trackId: `${trackable.trackId}${query.tag ?? ''}`,
          recommendationGroupId: games.recommendationGroupId,
          recommendationId: games.recommendationId,
          gameListTitle: gameList.internalName,
          gameListId: gameList.gameListId,
          game: gameData,
        });
      }
    }
  });
};

export type GameViewedProps = {
  entry: IntersectionObserverEntry;
  game: useTrackGame_game$key;
  gameListPosition: number;
};

export const useTrackViewedGame = (props: BlockDataProps) => {
  const isLoggedIn = useIsLoggedIn();
  const { query } = useRouter();
  const { trackViewedGame } = useGameTracking();

  return useHandler(({ entry, game, gameListPosition }: GameViewedProps) => {
    if (isLoggedIn) {
      const trackable = readInlineData(trackableFragment, props.trackable);

      if (trackable?.track && trackable.trackId) {
        const games = readInlineData(gamesFragment, props.games);
        const gameList = readInlineData(gameListFragment, props.gameList);
        const gameData = readInlineData(gameFragment, game);

        trackViewedGame({
          entry,
          gameListPosition,
          pageSlug: query.slug?.[0] ?? '',
          trackId: `${trackable.trackId}${query.tag ?? ''}`,
          recommendationGroupId: games.recommendationGroupId,
          recommendationId: games.recommendationId,
          gameListTitle: gameList.internalName,
          gameListId: gameList.gameListId,
          game: gameData,
        });
      }
    }
  });
};
