import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  Text: Color.Primitive.Accent,
  Unchecked: Color.Primitive.Accent,
  FocusOutline: Color.Primitive.Accent,
  Checked: Color.Primitive.Accent,
};
