import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  InputBackground: Color.Surface.Nested.Background + '80',

  LabelValid: Color.TextColors.MutedText,

  BorderUntouched: Color.TextColors.MutedText + '80',
  BorderValid: Color.TextColors.MutedText + '80',
  BorderFocused: Color.Primitive.Accent + '80',

  TextUntouched: Color.TextColors.MutedText,

  ErrorMessageBackground: Color.Surface.Nested.Background,

  InfoMessageBackground: Color.Surface.Nested.Background,
  InfoMessageTextColor: Color.Surface.Nested.Foreground,
};
