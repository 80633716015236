import styled from '@emotion/styled';
import { Disclosure } from '@pafcloud/base-components';
import {
  NavItem,
  NavItemContent,
  NavItemIcon,
  NavItemLink,
  NavNotificationBadge,
  SubNavList,
} from '../../../../../profile/profile-dialog/ProfileDialogNav';
import { BonusMenuColors } from '../bonus-menu-colors';

export const BonusNavItem = styled(NavItem)({});

export const BonusGroupSummary = styled(Disclosure.Summary)({});

export const BonusSubMenu = styled(SubNavList)({});

export const BonusBadge = styled(NavNotificationBadge)({
  transform: 'translate(4px, -1px)',
  maxWidth: 'fit-content',
  color: BonusMenuColors.BadgeText,
  backgroundColor: BonusMenuColors.BadgeBackground,
});

export const BonusSummaryContent = styled(NavItemContent)({});

export const BonusNavItemIcon = styled(NavItemIcon)({});

export const BonusMenuButton = styled(NavItemContent)<{ singleRow?: boolean }>(({ singleRow }) => ({
  display: 'grid',
  gridTemplateColumns: singleRow ? 'min-content auto' : undefined,
}));

export const BonusPageLink = styled(NavItemLink)({});
