import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Router } from 'next/router';
import styled from '@emotion/styled';
import { LoadingLine } from '@pafcloud/base-components';
import { DynamicValues, ZIndex } from '@pafcloud/style';

const RouteLoadingLine = styled(LoadingLine)({
  position: 'fixed',
  top: DynamicValues.GamePauseHeight,
  zIndex: ZIndex.RouteTransitionIndicator,
});

export const RouteTransitionIndictor: FC = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => setIsActive(true);
    const handleRouteChangeComplete = () => setIsActive(false);

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeError', handleRouteChangeComplete);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeError', handleRouteChangeComplete);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  if (isActive) {
    return <RouteLoadingLine />;
  }

  return null;
};
