import type { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { FontTextSize } from '@pafcloud/style';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { useWallet } from '@pafcloud/contexts';
import { WalletComponentColors } from './wallet-component-colors';

const WalletSummary = styled.div({
  display: 'grid',
  gap: 8,
  textTransform: 'none',
  marginTop: 16,
});

const WalletFlex = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  ':last-of-type': {
    marginBottom: 16,
  },
});

const WalletFlexChild = styled.span({
  fontSize: FontTextSize.Normal,
  lineHeight: FontTextSize.Big,
});

const Label = styled(WalletFlexChild)({
  marginRight: '1em',
  color: `var(--wallet-text, ${WalletComponentColors.Text})`,
});

const Amount = styled(WalletFlexChild)({
  fontWeight: 'bold',
  fontVariant: 'proportional-nums',
  color: `var(--wallet-text, ${WalletComponentColors.BoldedText})`,
});

type Props = {
  sessionBalance?: number | null;
  className?: string;
};

export const WalletBalanceDetails: FC<Props> = ({ sessionBalance, className }) => {
  const { t } = useTranslation('wallet');
  const { bonus, cash } = useWallet();

  const formatAmount = useFormatAmount();

  if (bonus == null && sessionBalance == null) {
    return null;
  }

  return (
    <WalletSummary className={className}>
      {bonus ? (
        <>
          <WalletFlex aria-label={t('bonus-aria-label')}>
            <Label>{t('bonus')}</Label>
            <Amount>{formatAmount(bonus)}</Amount>
          </WalletFlex>
          <WalletFlex aria-label={t('withdrawable-aria-label')}>
            <Label>{t('withdrawable')}</Label>
            <Amount>{formatAmount(cash ?? 0)}</Amount>
          </WalletFlex>
        </>
      ) : null}
      {sessionBalance != null && (
        <WalletFlex aria-label={t('session-balance.aria-label')}>
          <Label>{t('session-balance.text')}</Label>
          <Amount>{formatAmount(sessionBalance)}</Amount>
        </WalletFlex>
      )}
    </WalletSummary>
  );
};
