import styled from '@emotion/styled';
import type { FC } from 'react';
import { Button } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useLogout } from '../../../../use-logout';

const FooterMenuButton = styled(Button)({
  position: 'relative',
  display: 'flex',
  width: '100%',
});

const LoggedInButtons: FC = () => {
  const { t } = useTranslation('common');
  const logout = useLogout();

  return (
    <FooterMenuButton
      aria-label={t('logout.aria-label')}
      variant="secondary"
      size="default"
      title="logout"
      type="button"
      onClick={() => logout()}
    >
      {t('logout.text')}
    </FooterMenuButton>
  );
};

export const MainMenuFooterContent: FC = () => {
  const isLoggedIn = useIsLoggedIn();

  return isLoggedIn ? <LoggedInButtons /> : null;
};
