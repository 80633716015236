/**
 * @generated SignedSource<<4a2a4b6b5c4a73aa9e3ba2e3b4eabe1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VerifyPhoneNumberGuardFlow_requirement$data = {
  readonly phoneNumber: string | null;
  readonly skippable: boolean;
  readonly " $fragmentType": "VerifyPhoneNumberGuardFlow_requirement";
};
export type VerifyPhoneNumberGuardFlow_requirement$key = {
  readonly " $data"?: VerifyPhoneNumberGuardFlow_requirement$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerifyPhoneNumberGuardFlow_requirement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerifyPhoneNumberGuardFlow_requirement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skippable",
      "storageKey": null
    }
  ],
  "type": "BrowseAppAccessPhoneVerificationRequirement",
  "abstractKey": null
};

(node as any).hash = "90eb9fc6daff5e9af2e5a94b097c9381";

export default node;
