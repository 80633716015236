import type { ComponentProps } from 'react';
import type { FieldPath, FieldValues, UseFormRegister } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { useTranslation } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Stack } from '@pafcloud/base-components';
import { PASSWORD_PATTERN } from '../form-input';
import { PasswordGuideline, validatePassword as validateGuidelines } from './PasswordGuideline';
import { PasswordInput } from './PasswordInput';
import { PasswordHelpText } from './PasswordHelpText';

type NewPasswordProps<TValues extends FieldValues> = ComponentProps<typeof PasswordInput> & {
  name: FieldPath<TValues>;
  register: UseFormRegister<TValues>;
  validate?: (value: string) => string | boolean;
  formValues?: Record<'firstName' | 'lastName' | 'dateOfBirth', string | undefined>;
};

export const NewPasswordInput = <TValues extends FieldValues>({
  name,
  register,
  validate,
  formValues,
  ...props
}: NewPasswordProps<TValues>) => {
  const { t } = useTranslation('profile');
  const value = useWatch({ name });

  if ($buildEnv.market === 'spain' || $buildEnv.market === 'latvia') {
    return (
      <Stack>
        <PasswordInput
          autoComplete="new-password"
          {...props}
          {...register(name, {
            required: props['aria-required'] && t('profile.form.required'),
            validate: (input) => {
              if (validate) {
                return validate(input);
              }
              return validateGuidelines(input, formValues);
            },
          })}
        />
        <PasswordGuideline value={value} formValues={formValues} />
      </Stack>
    );
  }

  return (
    <Stack>
      <PasswordInput
        autoComplete="new-password"
        {...props}
        {...register(name, {
          required: props['aria-required'] && t('profile.form.required'),
          pattern: PASSWORD_PATTERN,
          minLength: 12,
          maxLength: 50,
          validate: (input) => {
            if (validate) {
              return validate(input);
            }
            return true;
          },
        })}
      />
      <PasswordHelpText />
    </Stack>
  );
};
