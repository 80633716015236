import { graphql, readInlineData } from 'relay-runtime';
import { createArrayValueValidator, isType } from '@pafcloud/collection-utils';
import type { turnover_offer$key } from './__generated__/turnover_offer.graphql';
import { getBonusOfferSteps } from './hooks/useBonusOfferSteps';

const turnoverOfferFragment = graphql`
  fragment turnover_offer on BonusOffer @inline {
    offerId
    expires
    status
    ...useBonusOfferSteps_offer
  }
`;

export const toTurnover = (key: turnover_offer$key | null) => {
  const offer = readInlineData(turnoverOfferFragment, key);

  if (offer == null) {
    return null;
  }

  const steps = getBonusOfferSteps(offer);

  if (steps == null) {
    return null;
  }

  const isActiveTurnoverOffer = createArrayValueValidator(['ACTIVE', 'QUEUED']);
  if (!isActiveTurnoverOffer(offer.status)) {
    return null;
  }

  const turnoverStep = steps.find((step) => isType(step, 'TurnoverOfferStep'));

  if (offer == null || turnoverStep == null) {
    return null;
  }

  const offerHasBeenActivated = steps.some((step) => 'stepId' in step && step.stepId != null);

  return {
    expires: offer.expires,
    offerId: offer.offerId,
    step: turnoverStep,
    offerHasBeenActivated,
  };
};

export type Turnover = ReturnType<typeof toTurnover>;
