import styled from '@emotion/styled';
import { NotificationBadgeBase } from '@pafcloud/base-components';
import { HeaderColors } from '../header-colors';

export const ButtonContent = styled.div({
  '--badge-size': '19px',

  position: 'relative',
  width: 40,
  height: 40,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const IconWrapper = styled.div({
  width: '100%',
  height: '100%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const IconNotificationBadge = styled(NotificationBadgeBase)({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,

  background: HeaderColors.NotificationBackground,
  color: HeaderColors.NotificationText,

  [`& + ${IconWrapper}`]: {
    '--mask-offset': '2px',
    '--mask-size': 'calc(var(--badge-size) / 2 + var(--mask-offset))',
    maskImage: `radial-gradient(circle at top calc(var(--mask-size) - var(--mask-offset)) right calc(var(--mask-size) - var(--mask-offset)), rgba(0, 0, 0, 0) var(--mask-size), rgba(0, 0, 0, 1) calc(var(--mask-size) + 0.26px))`,
    maskPosition: 'center center',
    maskRepeat: 'no-repeat',
  },
});
