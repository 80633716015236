import type { FC } from 'react';
import { useEffect } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { toast } from 'react-toastify';
import { cookies, SITE_ALERT } from '@pafcloud/cookies';
import { useHandler } from '@pafcloud/react-hook-utils';
import type { SiteAlerts_data$key } from './__generated__/SiteAlerts_data.graphql';
import { SiteAlertRichText } from './SiteAlertRichText';

const dataFragment = graphql`
  fragment SiteAlerts_data on Query {
    site {
      alerts {
        id
        content {
          json
        }
      }
    }
  }
`;

type Props = {
  data: SiteAlerts_data$key;
};

export const SiteAlerts: FC<Props> = (props) => {
  const data = useFragment(dataFragment, props.data);

  const handleSiteAlerts = useHandler(() => {
    for (const alert of data.site.alerts) {
      if (alert == null) {
        continue;
      }

      // Skip if the user has already seen this alert.
      if (cookies.get({ ...SITE_ALERT, name: alert.id })) {
        continue;
      }

      toast(<SiteAlertRichText content={alert.content} />, {
        toastId: alert.id,
        draggable: false,
        autoClose: false,
        onClose: () => {
          cookies.set({ ...SITE_ALERT, name: alert.id }, 'true');
        },
      });
    }
  });

  useEffect(handleSiteAlerts, [handleSiteAlerts]);

  return null;
};
