import type { HideReminderAction, ShowReminderAction } from './RemindersActionType';

export const showReminder = (reminder: ShowReminderAction['reminder']): ShowReminderAction => ({
  type: 'SHOW_REMINDER',
  reminder,
});

export const hideReminder = (): HideReminderAction => ({
  type: 'HIDE_REMINDER',
});
