import type { HTMLProps } from 'react';
import styled from '@emotion/styled';
import { ProgressBarColors } from './progress-bar-colors';

type ProgressBarProps = HTMLProps<'progress'> & {
  'aria-valuenow': number;
  'aria-valuemin'?: number;
  'aria-valuemax': number;
};

export const ProgressBar = styled.progress<ProgressBarProps>({
  WebkitAppearance: 'none',
  appearance: 'none',
  display: 'inline-block',
  width: '100%',
  height: 7,
  border: 0,
  borderRadius: '99vw',

  '&[value]::-webkit-progress-value': {
    transition: 'width 0.3s ease-in-out',
  },

  '::-webkit-progress-bar': {
    background: `var(--progressbar-background-color, ${ProgressBarColors.Background})`,
    borderRadius: '99vw',
  },

  '::-webkit-progress-value': {
    background: `var(--progressbar-value-color, ${ProgressBarColors.Fill})`,
    borderRadius: '99vw',
  },
  '::-moz-progress-bar': {
    background: `var(--progressbar-value-color, ${ProgressBarColors.Fill})`,
    borderRadius: '99vw',
  },
});
