import { dispatchTrackingEvent } from '@pafcloud/tracking';
import type { SearchResultClickedInfo } from '../Search';

export const dispatchSearchResultClickedEvent = (searchResult: SearchResultClickedInfo & { term: string }) => {
  dispatchTrackingEvent('game-search', {
    game: {
      search: {
        action: 'result click',
        term: searchResult.term,
        resultName: searchResult.name,
        targetPath: searchResult.launchUrl,
        resultIndex: searchResult.index,
        resultCount: searchResult.totalResults,
      },
    },
  });
};
