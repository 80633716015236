import styled from '@emotion/styled';

export const PageContainer = styled.main({
  '--page-bottom-margin': '128px',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'var(--page-margin) auto var(--page-margin)',
  width: '100%',
  // For avoiding hero content clipping on iOS 16.4 and later:
  height: 'min-content',
  flex: 1,
  alignContent: 'start',
  paddingBottom: 'var(--page-bottom-margin)',

  // Use the center column for all children of main.
  ' > *': {
    gridColumn: 2,
    marginTop: 32,
  },

  ' > .full-width': {
    gridColumn: '1 / -1',
    minWidth: 0,
  },
});
