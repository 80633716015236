import type { ParsedUrlQuery } from 'querystring';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useHandler } from '@pafcloud/react-hook-utils';

export const hasQueryParamWithValue = (query: ParsedUrlQuery, key: string, value: string) => {
  const queryValue = query[key];

  if (Array.isArray(queryValue)) {
    return queryValue.includes(value);
  }
  return queryValue === value;
};

const routerIsMissingAnyQueryParameter = (router: NextRouter, currentUrl: URL) => {
  return Array.from(currentUrl.searchParams).some(([key, value]) => {
    if (Array.isArray(value)) {
      return value.some((v) => !hasQueryParamWithValue(router.query, key, v));
    }
    return !hasQueryParamWithValue(router.query, key, value);
  });
};

/**
 * As we only use whitelisted query params in the SSR our initial router
 * state will be missing any query params that are not whitelisted for server-rendering.
 * This hook will revalidate the router state after the initial render
 * to ensure that the router state is up to date with the browser URL.
 * See: https://github.com/vercel/next.js/discussions/17646
 */
export const useSyncRouterQueryWithBrowserUrl = () => {
  const router = useRouter();

  const syncRouterQueryWithBrowserUrl = useHandler(() => {
    const currentUrl = new URL(router.asPath, location.origin);

    if (routerIsMissingAnyQueryParameter(router, currentUrl)) {
      // Update router with the current URL to get in sync with the browser URL
      router.replace(currentUrl.toString(), undefined, { shallow: true });
    }
  });

  useEffect(() => {
    syncRouterQueryWithBrowserUrl();
  }, [syncRouterQueryWithBrowserUrl]);
};
