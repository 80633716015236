const isRecord = (value: unknown): value is object => {
  return typeof value === 'object' && value != null;
};

/**
 * Converts a deeply nested object into a flat one,
 * with the paths of each deep value as the keys.
 *
 * @example
 * deepFlattenObjectStructure({
 *   user: {
 *     name: "Robin"
 *   }
 * })
 * // {
 * //   user.name: "Robin"
 * // }
 */
export function deepFlattenObjectStructure(object: unknown) {
  const values: Record<string, unknown> = {};

  const flattenObjectStructure = (key: string, value: unknown) => {
    if (isRecord(value)) {
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        const flatKey = key === '' ? nestedKey : `${key}.${nestedKey}`;

        if (isRecord(nestedValue)) {
          flattenObjectStructure(flatKey, nestedValue);
        } else {
          values[flatKey] = nestedValue;
        }
      }
    }

    return value;
  };

  flattenObjectStructure('', object);
  return values;
}
