import { Router, useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { buildContext } from '../buildContext';

const [BackLinkContext, useBackLink] = buildContext<string>('BackLinkContext');

export { useBackLink };
export const NO_PREVIOUS_PATH = '#';

type Props = {
  children?: ReactNode;
};
export const BackLinkProvider: FC<Props> = ({ children }) => {
  const router = useRouter();
  const [previous, setPrevious] = useState<string>(NO_PREVIOUS_PATH);

  useEffect(() => {
    const getPathWithoutLanguage = (path: string) => {
      const match = /^(?:\/[a-z]{2}(?:\/|$)|\/)(.*)/i.exec(path);
      return `/${match?.[1] ?? ''}`;
    };
    const storePreviousPath = (url: string) => {
      // make sure we are on a new path (ignoring language).
      if (getPathWithoutLanguage(url) !== router.asPath) {
        setPrevious(router.asPath);
      }
    };
    Router.events.on('beforeHistoryChange', storePreviousPath);
    return () => {
      Router.events.off('beforeHistoryChange', storePreviousPath);
    };
  }, [router.asPath]);

  return <BackLinkContext.Provider value={previous}>{children}</BackLinkContext.Provider>;
};
