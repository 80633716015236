import type { ComponentProps, ReactNode } from 'react';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Font, FontTextSize } from '@pafcloud/style';
import { RadioInputColors } from './radio-input-colors';

const RadioBox = styled.span({
  position: 'relative',
  cursor: 'pointer',
  padding: 0,
  marginRight: 10,
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  verticalAlign: 'top',
  width: 24,
  height: 24,
  background: 'transparent',
  border: `2px solid ${RadioInputColors.Unchecked}`,
  borderRadius: '50%',
  color: RadioInputColors.Text,
});

const Label = styled.span({
  fontFamily: Font.Body,
  fontSize: FontTextSize.Big,
});

const RadioDot = styled.span({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'currentColor',
  width: 12,
  height: 12,
  borderRadius: '50%',
});

const Input = styled.input<{ error?: boolean }>(({ error }) => ({
  opacity: 0,
  position: 'absolute',
  [`:not(:checked) + span > *`]: {
    display: 'none',
  },
  [`& + span + span`]: {
    color: RadioInputColors.Text,
  },
  [`:checked + span`]: {
    color: RadioInputColors.Checked,
    borderColor: RadioInputColors.Checked,
  },
  [`:hover + span`]: {
    borderColor: RadioInputColors.Checked,
  },
  [`:focus + span::after`]: {
    content: "''",
    width: 24,
    height: 24,
    boxShadow: `0 0 2px 2px ${RadioInputColors.FocusOutline}`,
    borderRadius: '50%',
    position: 'absolute',
    top: -2,
    right: 0,
    left: -2,
    bottom: 0,
    opacity: 0.33,
  },
  [`:disabled + span,
  :disabled + span + span`]: {
    color: RadioInputColors.DisabledText,
    borderColor: RadioInputColors.Disabled,
    cursor: 'auto',
  },
  ...(error
    ? {
        [`+ span,
        + span + span`]: {
          color: RadioInputColors.ErrorText,
          borderColor: RadioInputColors.Error,
        },
      }
    : {}),
}));

type RadioInputProps = ComponentProps<typeof Input> & {
  className?: string;
  disabled?: boolean;
  label: ReactNode;
  name: string;
  value?: string | number;
  checked?: boolean;
  id?: string;
  error?: boolean;
};

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(({ label, className, ...props }, ref) => {
  return (
    <label className={className}>
      <Input {...props} type="radio" ref={ref} />
      <RadioBox>
        <RadioDot />
      </RadioBox>
      <Label>{label}</Label>
    </label>
  );
});

RadioInput.displayName = 'RadioInput';
