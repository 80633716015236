import type { FC } from 'react';
import { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Listbox } from '@headlessui/react';

import { ActionList } from '@pafcloud/base-components';
import { Wrapper as FormInputWrapper, enabledFloatingLabel, FormInputProperties } from '../form-input';
import { Input } from '../form-input/FormInputField';
import { Label, Text } from '../form-input/FormInputLabel';
import type { FormInputProps } from '../form-input';
import { DropDownIcon, DropDownListContainer, DropdownContent, Wrapper } from './shared';
import type { SelectOption } from './shared';

export type DropDownSelectOpenDirection = 'down' | 'up';

export type DropDownSelectProps = Omit<FormInputProps, 'onChange' | 'type' | 'as' | 'ref' | 'value'> & {
  label: string;
  options: SelectOption[];
  onChange: (value: string) => void;
  openDirection?: DropDownSelectOpenDirection;
  value?: FormInputProps['value'] | null;
};

export const DropDownValueWrapper = styled(FormInputWrapper)({
  height: 'var(--drop-down-height)',

  '::after': {
    content: 'none',
  },
});

export const DropDownSelectValue = styled(Input)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  textAlign: 'start',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 'var(--drop-down-height)',
  padding: 0,
});

export const DropDownSelect: FC<DropDownSelectProps> = ({
  value,
  label,
  defaultValue,
  children,
  disabled,
  options,
  onChange,
  className,
  floatingLabel = enabledFloatingLabel(),
  openDirection = 'down',
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (floatingLabel && buttonRef.current && labelRef.current) {
      // Update the gap in the border to cater for the label and icon.
      buttonRef.current.style.setProperty(FormInputProperties.LabelWidth, `${labelRef.current.offsetWidth}px`);
    }
  });

  const getDisplayValue = (selectedValue?: string) => {
    if (selectedValue == null) {
      return '';
    }
    const matchingOption = options.find((option) => option.value === selectedValue);
    return matchingOption?.label ?? '';
  };

  const autoScrollIntoView = (element: HTMLElement | null) => {
    if (element instanceof HTMLElement) {
      const elementBounds = element.getBoundingClientRect();
      if (elementBounds.bottom > window.innerHeight) {
        element.scrollIntoView({ block: 'end' });
      }
    }
  };

  return (
    <Listbox
      as={Wrapper}
      disabled={disabled}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      className={className}
    >
      {(listboxProps) => (
        <>
          <Listbox.Button as={DropDownValueWrapper} floatingLabel={floatingLabel} ref={buttonRef} role="button">
            {(selected) => (
              <>
                <DropDownSelectValue
                  as="span"
                  disabled={selected.disabled}
                  data-empty={selected.value == null}
                  data-active={selected.open}
                  floatingLabel={floatingLabel}
                  tabIndex={0}
                  {...props}
                >
                  {children}
                  {getDisplayValue(selected.value)}
                  <DropDownIcon name="chevronRight" />
                </DropDownSelectValue>
                <Listbox.Label as={Label}>
                  <Text ref={labelRef}>{label}</Text>
                </Listbox.Label>
              </>
            )}
          </Listbox.Button>

          {listboxProps.open && (
            <DropDownListContainer openDirection={openDirection}>
              <DropdownContent ref={autoScrollIntoView}>
                <Listbox.Options as={ActionList}>
                  {options.map((option) => (
                    <Listbox.Option key={option.value} value={option.value}>
                      {option.label}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </DropdownContent>
            </DropDownListContainer>
          )}
        </>
      )}
    </Listbox>
  );
};
