import { Color } from '@pafcloud/style';

export const Colors = {
  Text: 'currentColor',
  TextHover: 'currentColor',
  TextActive: Color.Primitive.Accent,
  TextShadow: undefined,

  Icon: Color.TextColors.MutedText + '80',
  IconActive: 'currentColor',

  BackgroundHover: Color.Primitive.Accent + '10',
  BackgroundActive: Color.Primitive.Accent + '10',

  ItemDivider: `1px solid ${Color.Surface.Base.Background}10`,
  ItemActiveShadow: undefined,

  SubMenuBackground: 'transparent',
  SubMenuRuler: Color.Surface.Base.Dimmed + '80',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
