import { useCallback, useEffect, useRef } from 'react';
import { graphql } from 'relay-runtime';
import { safeParseJson } from '@pafcloud/util';
import { logger } from '@pafcloud/logging';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import { useQuery } from '@pafcloud/relay-helpers';
import { usePlayerStatus } from '@pafcloud/contexts';
import type { useLoginTrackingEventQuery } from './__generated__/useLoginTrackingEventQuery.graphql';

export const PREVIOUS_LOGIN_INFO = 'previousLoginInfo';

export type PreviousLoginInfo = {
  loginMethod: string;
  firstLogin: boolean;
  withDeposit?: boolean;
};

const TRACKING_QUERY = graphql`
  query useLoginTrackingEventQuery {
    player {
      externalId @required(action: NONE)
      address {
        country
      }
      currency
      ageRange
      email
      phoneNumber
      allowsMarketingEmails
      allowsMarketingSms
      subscribeOffers
      depositLimit {
        daily {
          amount
        }
        weekly {
          amount
        }
        monthly {
          amount
        }
      }
      sessionLimit {
        daily {
          amount
        }
        weekly {
          amount
        }
        monthly {
          amount
        }
      }
      lossLimit {
        daily {
          amount
        }
        weekly {
          amount
        }
        monthly {
          amount
        }
      }
      gamingFreeze {
        all {
          freezeIssuer
        }
        betting {
          freezeIssuer
        }
        bingo {
          freezeIssuer
        }
        poker {
          freezeIssuer
        }
        slots {
          freezeIssuer
        }
        table {
          freezeIssuer
        }
      }
    }
  }
`;

export const useDispatchLoginTrackingEvent = () => {
  const query = useQuery<useLoginTrackingEventQuery>(TRACKING_QUERY);

  return useCallback(
    async (loginType: 'auto' | 'manual') => {
      const { player } = await query();

      if (player == null) {
        throw new Error('Player is not defined.');
      }

      const {
        externalId,
        email,
        phoneNumber,
        allowsMarketingSms,
        allowsMarketingEmails,
        subscribeOffers,
        currency,
        ageRange,
        address,
        depositLimit,
        lossLimit,
        sessionLimit,
        gamingFreeze,
      } = player;

      const getRestrictions = () => {
        const restrictions =
          gamingFreeze &&
          Object.entries(gamingFreeze)
            .filter(([, value]) => value)
            .map(([[firstLetter, ...otherLetters]]) => `Freeze ${firstLetter.toUpperCase()}${otherLetters.join('')}`);

        const addLimits = (name: string, limits: typeof depositLimit | typeof sessionLimit) => {
          if (limits && Object.values(limits).some((period) => period?.amount) && restrictions) {
            restrictions.push(`${name} Limits`);
          }
        };

        addLimits('Loss', lossLimit);
        addLimits('Deposit', depositLimit);
        addLimits('Session', sessionLimit);

        return restrictions ? restrictions.filter(Boolean).join(', ') : 'none';
      };

      const previousLoginInfo = safeParseJson<PreviousLoginInfo>(localStorage.getItem(PREVIOUS_LOGIN_INFO));

      dispatchTrackingEvent('login', {
        login: {
          type: loginType,
          method: previousLoginInfo?.loginMethod ?? '',
        },
        user: {
          firstLogin: previousLoginInfo?.firstLogin ? 'yes' : 'no',
          category: 'customer',
          loggedIn: 'yes',
          externalPlayerId: externalId,
          email: email ?? '',
          phone: phoneNumber ?? '',
          acceptsPhoneMarketing: allowsMarketingSms == null ? null : (`${allowsMarketingSms}` as const),
          acceptsEmailMarketing: allowsMarketingEmails == null ? null : (`${allowsMarketingEmails}` as const),
          acceptsPersonalizedMarketing: subscribeOffers == null ? null : (`${subscribeOffers}` as const),
          currency,
          age: ageRange,
          restrictions: getRestrictions(),
          country: address?.country ?? '',
        },
      });
    },
    [query],
  );
};

export const useLoginTrackingEvent = () => {
  const playerStatus = usePlayerStatus();
  const dispatchLoginTrackingEvent = useDispatchLoginTrackingEvent();
  const lastPlayerStatus = useRef(playerStatus);

  useEffect(() => {
    if (playerStatus === 'LOGGED_IN') {
      const type = lastPlayerStatus.current === 'LOGGED_OUT' ? 'manual' : 'auto';
      dispatchLoginTrackingEvent(type).catch((error) => {
        logger.error(`Could not dispatch ${type} login tracking event`, { error });
      });
    }
    lastPlayerStatus.current = playerStatus;
  }, [playerStatus, dispatchLoginTrackingEvent]);
};
