import type { ImageProps } from 'next/image';
import NextImage from 'next/image';
import { imageLoader } from './image-loader';

type Props = Omit<ImageProps, 'alt'> & {
  alt?: string;
};

export const Image = ({ alt = '', loader = imageLoader, ...props }: Props) => {
  return <NextImage {...props} alt={alt} loader={loader} />;
};
