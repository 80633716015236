import type { ElementType, FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { MarkdownParser } from '@pafcloud/base-components';
import type { GeneratedBonusTitle_offer$key } from './__generated__/GeneratedBonusTitle_offer.graphql';
import { useGeneratedBonusTitle } from './hooks';

const offerFragment = graphql`
  fragment GeneratedBonusTitle_offer on IBonusOffer {
    ...useGeneratedBonusTitleQuery
  }
`;

type Props = {
  offer: GeneratedBonusTitle_offer$key;
  styledComponent: ElementType;
};

export const GeneratedBonusTitle: FC<Props> = (props) => {
  const offerData = useFragment(offerFragment, props.offer);
  const title = useGeneratedBonusTitle(offerData);
  if (title == null) {
    return null;
  }

  return <MarkdownParser components={{ p: props.styledComponent }}>{title}</MarkdownParser>;
};
