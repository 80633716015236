import styled from '@emotion/styled';
import type { CSSObject } from '@emotion/react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FormInputStyle } from './form-input-style';
import { FormInputColors } from './form-input-colors';
import { FormInputProperties } from './form-input-style/properties';
import { FormInputZIndex } from './FormInputZIndex';

const commonStyle = () => {
  const PMLStyle: CSSObject = {
    minWidth: FormInputStyle.Height,
    borderLeft: `1px solid ${FormInputColors.BorderUntouched}`,
  };

  const styling: CSSObject = {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    position: 'relative',
    height: FormInputStyle.Height,
    margin: 0,
    padding: `calc(var(${FormInputProperties.TextIndent}) / 2)`,
    zIndex: FormInputZIndex.Helper,
    color: FormInputColors.Suffix,
    whiteSpace: 'nowrap',
    flexGrow: 1,
    flexShrink: 0,
  };

  if (
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'quirky' ||
    $buildEnv.theme === 'quirky-purple'
  ) {
    return {
      ...styling,
      ...PMLStyle,
    };
  }

  return styling;
};

/** Placeholder for e.g. password toggle button / currency symbols */
export const FormInputAuxiliary = styled.div({
  ...commonStyle(),
});

/** Placeholder for suffix text like "optional" */
export const FormInputSuffix = styled.div({
  ...commonStyle(),
  fontSize: FormInputStyle.SuffixFontSize,
});
