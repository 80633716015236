import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import FocusTrap from 'focus-trap-react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useIsSSR, useRouteChange } from '@pafcloud/react-hook-utils';
import { isType } from '@pafcloud/collection-utils';
import { SafeQueryRenderer } from '@pafcloud/relay-helpers';
import { LanguageSelect } from '../../../language-select';
import type { MainMenu_content$key } from './__generated__/MainMenu_content.graphql';
import type { MainMenuBonusQuery } from './__generated__/MainMenuBonusQuery.graphql';
import { MainMenuFooter } from './MainMenuFooter';
import { MainMenuLink } from './MainMenuLink';
import { MainMenuGroup } from './MainMenuGroup';
import { Sidebar, SidebarOverlay } from './sidebar';
import { BonusMenu } from './bonus-menu/BonusMenu';
import { MainMenuColors } from './main-menu-colors';

const mainMenuBonusQuery = graphql`
  query MainMenuBonusQuery {
    player {
      bonus {
        ...BonusMenu_offers
      }
    }
  }
`;

const contentFragment = graphql`
  fragment MainMenu_content on Query {
    ...SidebarGolden_content
    ...SidebarQuirky_content
    ...SidebarClean_content
    site {
      menu {
        links {
          __typename
          ... on MenuLink {
            ...MainMenuLink_content
          }
          ... on MenuLinkGroup {
            ...MainMenuGroup_content
          }
        }
        ...MainMenuFooter_content
      }
    }
  }
`;

const MenuList = styled.ul({
  padding: '0',
  listStyle: 'none',
  fontWeight: 'bold',
  borderTop: MainMenuColors.ItemDivider,
});

const SidebarLanguageSelect = styled(LanguageSelect)({
  maxWidth: 'unset',
  width: 'auto',
  margin: '0 24px',
  marginTop: 'auto',
});

const hasInvertedLanguageSelect = [
  'classic',
  'classic-red',
  'clean',
  'clean-blue',
  'golden',
  'quirky-purple',
  'quirky',
].includes($buildEnv.theme);

export type MainMenuProps = {
  content: MainMenu_content$key;
  isOpen: boolean;
  onClose: () => void;
};

export const MainMenu: FC<MainMenuProps> = ({ isOpen, onClose, ...props }) => {
  const content = useFragment(contentFragment, props.content);
  const isSSR = useIsSSR();

  useRouteChange(() => onClose());

  if (content.site.menu == null) {
    return null;
  }

  const tabIndex = isOpen || $buildEnv.theme === 'quirky' || $buildEnv.theme === 'golden' ? 0 : -1;

  return (
    <>
      <SidebarOverlay isOpen={isOpen} onClick={onClose} data-testid="sidebar-overlay" />
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          escapeDeactivates: true,
          allowOutsideClick: true,
          initialFocus: false,
          onDeactivate: onClose,
        }}
      >
        <Sidebar content={content} isOpen={isOpen} onClose={onClose}>
          <MenuList role="menu">
            {content.site.menu.links.map((link, index) => {
              if (isType(link, 'MenuLink')) {
                return <MainMenuLink key={index} content={link} tabIndex={tabIndex} />;
              }
              if (isType(link, 'MenuLinkGroup')) {
                return <MainMenuGroup key={index} content={link} tabIndex={tabIndex} />;
              }
              return null;
            })}
            {($buildEnv.site === 'speedybet.com' ||
              $buildEnv.site === 'speedycasino.com' ||
              $buildEnv.site === 'prankcasino.se' ||
              $buildEnv.site === 'noaccountbet.se' ||
              $buildEnv.site === 'noaccountcasino.se') &&
              !isSSR && (
                // This is used in the sidebar for PML, which causes the client to crash
                // when the data is updated, unless we have the isSSR flag.
                <SafeQueryRenderer<MainMenuBonusQuery>
                  query={mainMenuBonusQuery}
                  fetchPolicy="store-or-network"
                  render={({ props: queryProps }) => <BonusMenu offers={queryProps.player?.bonus ?? null} />}
                />
              )}
          </MenuList>

          <MainMenuFooter content={content.site.menu} onLinkClick={onClose} tabIndex={tabIndex} />
          <SidebarLanguageSelect tabIndex={tabIndex} openDirection="up" invertedTheme={hasInvertedLanguageSelect} />
        </Sidebar>
      </FocusTrap>
    </>
  );
};
