import type { FC, ReactNode } from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { initRelayEnvironment } from './initRelayEnvironment';
import { useRefreshOnVersionChange } from './useRefreshOnVersionChange';

type Props = {
  records: RecordMap;
  children: ReactNode;
};

/**
 * Creates and provides a relay environment
 * with a store hydrated by the passed records.
 */
export const HydratedRelayEnvironmentProvider: FC<Props> = ({ records, children }) => {
  const { setServerVersion } = useRefreshOnVersionChange();

  const environment = initRelayEnvironment({
    records,
    onResponse: (response) => {
      const version = response.headers.get('Server-Version');

      if (version) {
        setServerVersion(version);
      }
    },
  });

  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
};
