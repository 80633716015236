/**
 * @generated SignedSource<<e1d24bc2537615109f46349d687044de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LiveMessageNotificationsQuery$variables = Record<PropertyKey, never>;
export type LiveMessageNotificationsQuery$data = {
  readonly inbox: {
    readonly unreadCount: number;
  } | null;
};
export type LiveMessageNotificationsQuery = {
  response: LiveMessageNotificationsQuery$data;
  variables: LiveMessageNotificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Inbox",
    "kind": "LinkedField",
    "name": "inbox",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unreadCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveMessageNotificationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LiveMessageNotificationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c7020a284c77733103753652eb1c9248",
    "id": null,
    "metadata": {},
    "name": "LiveMessageNotificationsQuery",
    "operationKind": "query",
    "text": "query LiveMessageNotificationsQuery {\n  inbox {\n    unreadCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "63051a70e853c1f2748d14e74c07b424";

export default node;
