import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_clean } from './colors.clean';
import { Colors as Colors_goldenV2 } from './colors.goldenV2';
import { Colors as Colors_quirky } from './colors.quirky';
import { Colors as Colors_quirky_purple } from './colors.quirky-purple';
import { Colors as Colors_dreams } from './colors.dreams';
import { Colors as Colors_momentum_neon } from './colors.momentum-neon';
import { Colors as Colors_momentum } from './colors.momentum';

export const ButtonColors = (() => {
  if ($buildEnv.theme === 'clean') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Colors_goldenV2;
  }
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Colors_quirky_purple;
  }
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Colors_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Colors_momentum_neon;
  }

  return Colors_default;
})();
