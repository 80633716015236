/**
 * @generated SignedSource<<ef070b70aa5604406ffb1f2e93c77dd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainMenu_content$data = {
  readonly site: {
    readonly menu: {
      readonly links: ReadonlyArray<{
        readonly __typename: "MenuLink";
        readonly " $fragmentSpreads": FragmentRefs<"MainMenuLink_content">;
      } | {
        readonly __typename: "MenuLinkGroup";
        readonly " $fragmentSpreads": FragmentRefs<"MainMenuGroup_content">;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"MainMenuFooter_content">;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SidebarClean_content" | "SidebarGolden_content" | "SidebarQuirky_content">;
  readonly " $fragmentType": "MainMenu_content";
};
export type MainMenu_content$key = {
  readonly " $data"?: MainMenu_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainMenu_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainMenu_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarGolden_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarQuirky_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarClean_content"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Menu",
          "kind": "LinkedField",
          "name": "menu",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "links",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MainMenuLink_content"
                    }
                  ],
                  "type": "MenuLink",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MainMenuGroup_content"
                    }
                  ],
                  "type": "MenuLinkGroup",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MainMenuFooter_content"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "54cd9fe3b0d64ee4edc66ab99aac056c";

export default node;
