import type { CookieConsent } from '@pafcloud/cookies/src/utils/consent';
import { dispatchTrackingEvent } from '@pafcloud/tracking';

export const tagCookieConsent = ({ essential, tracking, marketing }: CookieConsent) => {
  dispatchTrackingEvent('cookieConsent', {
    cookieConsent: {
      essential: `${essential}` as const,
      tracking: `${tracking}` as const,
      marketing: `${marketing}` as const,
    },
  });
};
