/**
 * @generated SignedSource<<94b33a96fd2ae0b7cbb6ca7618b71e15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type IdentificationStatus = "COMPLETED" | "IN_PROGRESS" | "POSSIBLE" | "REQUIRED";
export type SourceOfFundsStatus = "COMPLETED" | "IN_PROGRESS" | "NOT_REQUIRED" | "REJECTED" | "REQUIRED";
import { FragmentRefs } from "relay-runtime";
export type SidebarVerificationSection_content$data = {
  readonly player: {
    readonly emailVerified: boolean | null;
    readonly identification: {
      readonly status: IdentificationStatus | null;
    };
    readonly sourceOfFunds: {
      readonly status: SourceOfFundsStatus | null;
    };
  };
  readonly " $fragmentType": "SidebarVerificationSection_content";
} | null;
export type SidebarVerificationSection_content$key = {
  readonly " $data"?: SidebarVerificationSection_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarVerificationSection_content">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarVerificationSection_content",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailVerified",
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Identification",
              "kind": "LinkedField",
              "name": "identification",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            "action": "NONE",
            "path": "player.identification"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "SourceOfFunds",
              "kind": "LinkedField",
              "name": "sourceOfFunds",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            "action": "NONE",
            "path": "player.sourceOfFunds"
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "player"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e4668615e15cab48a743d50cec366cc5";

export default node;
