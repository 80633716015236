import type { FC } from 'react';
import { useId, useRef } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { searchResultStyles } from './search-result-styles/searchResultStyles';
import { RecentSearchResultChildren } from './RecentSearchResultChildren';
import type { SearchResultClickedInfo } from './Search';
import { useRecentSearchResults } from './RecentSearchContext';

const { ResultList, ResultListItem, ResultWrapper, ResultHeader } = searchResultStyles;

type RecentSearchResultsProps = {
  gameListId: string;
  onClickResult?: (searchResult: SearchResultClickedInfo) => void;
};

export const RecentSearchResults: FC<RecentSearchResultsProps> = ({ gameListId, onClickResult }) => {
  const { t } = useTranslation('search');
  const recentSearchHeadingId = useId();
  const listRef = useRef<HTMLOListElement>(null);
  const { recentSearchResults } = useRecentSearchResults();
  const recentSearches = recentSearchResults?.[gameListId] ?? [];

  return (
    <ResultWrapper>
      {recentSearches.length > 0 && (
        <>
          <ResultHeader id={recentSearchHeadingId}>{t('search-recent.heading')}</ResultHeader>
          <ResultList ref={listRef} tabIndex={-1} aria-labelledby={recentSearchHeadingId}>
            {recentSearches.map((search, index) => (
              <ResultListItem key={`${search.id}-${search.rawSupplier}`}>
                <RecentSearchResultChildren
                  listRef={listRef}
                  search={search}
                  gameListId={gameListId}
                  onClickResult={() => {
                    onClickResult?.({
                      name: search.rawTitle ?? '',
                      launchUrl: search.launchUrl,
                      totalResults: recentSearches.length,
                      index,
                    });
                  }}
                />
              </ResultListItem>
            ))}
          </ResultList>
        </>
      )}
    </ResultWrapper>
  );
};
