import type { FC } from 'react';
import styled from '@emotion/styled';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Button, Heading, ToggleSwitch } from '@pafcloud/base-components';
import { FontTextSize } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { isType } from '@pafcloud/collection-utils';
import { PhoneInput } from '@pafcloud/form-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { phoneNumberPrefixes } from '@pafcloud/locale';
import { PhoneNumberGroup } from '../../../profile/profile-tab/shared';
import type { PinCodeSuccessData } from './VerifyPhoneNumberForm';
import { useSendPinCode } from './useSendPinCode';
import { useUpdatePhoneNumber } from './useUpdatePhoneNumber';

export const Form = styled.form({
  maxWidth: 320,
  margin: '0 auto',
});

const FormHeading = styled(Heading)({
  display: 'flex',
  justifyContent: 'center',
  margin: '32px 0',
  fontSize: FontTextSize.Big,
});

const SubmitButton = styled(Button)({
  fontWeight: 'bold',
  margin: '25px auto',
  minWidth: 185,
  div: {
    display: 'grid',
    justifyContent: 'center',
  },
});

const Group = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: 20,
});

const Label = styled.label({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const LabelText = styled.span({
  paddingRight: 8,
});

const findPhoneNumberPrefix = (phoneNumber: string | null) => {
  if (phoneNumber == null) {
    return undefined;
  }

  if ($buildEnv.market === 'spain' || $buildEnv.market === 'switzerland') {
    return phoneNumber;
  }

  const prefix = Object.values(phoneNumberPrefixes).find((prefix) => phoneNumber.startsWith('+' + prefix));

  if (prefix == null) {
    return undefined;
  }

  return prefix.toString();
};

const stripPrefixFromPhoneNumber = (phoneNumber: string | null) => {
  if ($buildEnv.market === 'spain' || $buildEnv.market === 'switzerland') {
    return phoneNumber ?? '';
  }

  const prefix = findPhoneNumberPrefix(phoneNumber);

  // Falsy check in the eventuality that the player phone number is an empty string
  if (!prefix || phoneNumber == null) {
    return '';
  }

  return phoneNumber.substring(('+' + prefix).length);
};

type RegisterPhoneNumberFormInputs = {
  phoneNumber: string;
  subscribeNewsSms: boolean;
  subscribeOffers: boolean;
};

type Props = {
  defaultValues?: Partial<RegisterPhoneNumberFormInputs>;
  onCompleted: (data: PinCodeSuccessData) => void;
  onAbort?: () => void;
  abortButtonText?: string;
};

export const ChoosePhoneNumberForm: FC<Props> = ({ defaultValues, onCompleted, onAbort, abortButtonText }) => {
  const { t } = useTranslation('register-phone');
  const router = useRouter();
  const updatePhoneNumber = useUpdatePhoneNumber();
  const sendPinCode = useSendPinCode();

  const hasToVerifyPhoneNumber = $buildEnv.market === 'spain' || $buildEnv.market === 'sweden';

  const form = useForm<RegisterPhoneNumberFormInputs>({
    mode: 'onChange',
    defaultValues: {
      subscribeNewsSms: true,
      subscribeOffers: true,
      ...defaultValues,
      phoneNumber: stripPrefixFromPhoneNumber(defaultValues?.phoneNumber ?? ''),
    },
  });

  const { control, register, handleSubmit, formState, setError } = form;
  const { isValid, isSubmitting, errors } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { phoneNumber } = data;

      if (!hasToVerifyPhoneNumber) {
        await updatePhoneNumber({
          phoneNumber,
        });

        // This is instead of closeFlow() as we need a non-shallow replace to update the profile form.
        return router.replace(router.pathname);
      }

      const response = await sendPinCode({ phoneNumber });

      if (isType(response, 'VerificationError')) {
        const message = t('form.error.unknown');
        setError('phoneNumber', { type: 'server', message }, { shouldFocus: true });
        return;
      }

      if (isType(response, 'SendPinCodeSuccess')) {
        const { ticketId, identificationCode } = response;
        onCompleted({
          ticketId,
          identificationCode,
          phoneNumber,
          subscribeNewsSms: data.subscribeNewsSms,
          subscribeOffers: data.subscribeOffers,
        });
      }
    } catch {
      const message = t('form.error.unknown');
      setError('phoneNumber', { type: 'server', message }, { shouldFocus: false });
    }
  });

  return (
    <Form method="POST" noValidate onSubmit={onSubmit} onReset={onAbort}>
      <FormHeading>{t('form.heading')}</FormHeading>
      <FormProvider {...form}>
        <PhoneNumberGroup phoneNumberCanBeEdited={$buildEnv.market === 'switzerland'}>
          <PhoneInput
            name="phoneNumber"
            initialPrefix={defaultValues?.phoneNumber ? findPhoneNumberPrefix(defaultValues.phoneNumber) : undefined}
            register={register}
            aria-required
            aria-invalid={errors.phoneNumber ? true : false}
          />
        </PhoneNumberGroup>
      </FormProvider>

      {hasToVerifyPhoneNumber && (
        <>
          <Group>
            <Label>
              <LabelText>{t('form.news.sms-toggle-label')}</LabelText>
              <Controller
                name="subscribeNewsSms"
                control={control}
                render={({ field }) => {
                  return <ToggleSwitch id={field.name} checked={field.value} onChange={field.onChange} />;
                }}
              />
            </Label>
          </Group>
          <Group>
            <Label>
              <LabelText>{t('form.offers-toggle-label')}</LabelText>
              <Controller
                name="subscribeOffers"
                control={control}
                render={({ field }) => {
                  return <ToggleSwitch id={field.name} checked={field.value} onChange={field.onChange} />;
                }}
              />
            </Label>
          </Group>
        </>
      )}

      <SubmitButton size="large" disabled={!isValid} type="submit" variant="primary" isLoading={isSubmitting}>
        {t('form.submit')}
      </SubmitButton>
      {onAbort != null && (
        <SubmitButton size="large" disabled={isSubmitting} type="reset" variant="secondary">
          {abortButtonText ?? t('form.cancel')}
        </SubmitButton>
      )}
    </Form>
  );
};
