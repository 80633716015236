import styled from '@emotion/styled';
import { Color, Font, FontTextSize } from '@pafcloud/style';

export const ActionList = styled.ul({
  width: '100%',
  margin: 0,
  padding: 0,

  listStyle: 'none',
  outline: 'transparent',
  background: Color.Surface.Base.Background,

  '> li': {
    ':not(:first-of-type)': {
      '&[role=option], > a, > button, > [role=button]': {
        borderTop: `1px solid ${Color.Surface.Base.Dimmed}`,
      },
    },

    '&[role=option], > a, > button, > [role=button]': {
      cursor: 'pointer',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      width: '100%',

      minHeight: 'var(--action-list-item-height, 3em)',
      padding: '0.75rem',

      fontFamily: Font.Button,
      fontSize: FontTextSize.Normal,
      fontWeight: 'bold',

      border: 'none',
      outline: 'transparent',
      background: 'transparent',
      color: Color.Surface.Base.Foreground,

      ':hover': {
        background: Color.Primitive.Accent + '20',
        color: Color.Primitive.Accent,
      },

      ':focus-visible, &[data-headlessui-state*="active"]': {
        background: Color.Primitive.Accent + '20',
        color: Color.Primitive.Accent,
      },

      '&[aria-selected="true"], &[data-headlessui-state*="selected"]': {
        color: Color.Primitive.Accent,
        fontWeight: 'bold',
      },

      ':disabled, &[aria-disabled="true"]': {
        background: Color.Surface.Base.Dimmed,
        cursor: 'not-allowed',
      },
    },
  },
});
