/**
 * @generated SignedSource<<f0659b84e6e1bc07e3e0ba4142ff12b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bingoTickets_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
  readonly " $fragmentType": "bingoTickets_offer";
};
export type bingoTickets_offer$key = {
  readonly " $data"?: bingoTickets_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"bingoTickets_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "bingoTickets_offer"
};

(node as any).hash = "fa3c997f67830c443096ef20630ec820";

export default node;
