import type { ComponentProps, ComponentType } from 'react';
import { forwardRef, createElement } from 'react';

/**
 * Use this instead of self executing function when using site specific components.
 *
 * In production this is basically a self executing function, but during tests it is run per render to allow
 * different build config per test.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withBuildEnv = <C extends ComponentType<any>, E>(fn: () => C) => {
  if (process.env.NODE_ENV !== 'test') {
    return fn();
  }

  return forwardRef<E, ComponentProps<C>>(function Wrapped(props, ref) {
    return createElement(fn(), { ...props, ref });
  }) as unknown as C;
};

/**
 * Use this instead of self executing function when using site specific functions.
 *
 * In production this is basically a self executing function, during tests it makes every call a call to the
 * function again to get the value for the current environment.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const callbackWithBuildEnv = <F extends (...args: any[]) => any>(fn: () => F) => {
  if (process.env.NODE_ENV !== 'test') {
    return fn();
  }

  return ((...args) => fn()(...args)) as F;
};

/**
 * Use this instead of self executing function when using site specific objects.
 *
 * In production this is basically a self executing function, during tests it makes every property a getter
 * that calls the function again to get the value for the current environment.
 */
export const objectWithBuildEnv = <O extends object>(fn: () => O): Readonly<O> => {
  if (process.env.NODE_ENV !== 'test') {
    return fn();
  }

  const target = {} as O;
  return new Proxy(target, {
    get(_target, property) {
      return fn()[property as keyof O];
    },
    has(_target, property) {
      return property in fn();
    },
  });
};
