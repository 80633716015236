import styled from '@emotion/styled';
import type { FC } from 'react';
import { LoadingIndicator } from '@pafcloud/base-components';
import { FlowContent } from './FlowContent';
import { FlowCloseButton, FlowHeader, FlowTitle } from './FlowHeader';

const CenteredLoadingIndicator = styled(LoadingIndicator)({
  display: 'block',
  margin: '32px auto',
});

type Props = {
  title?: string;
};

export const FlowLoadingStep: FC<Props> = ({ title = '' }) => {
  return (
    <>
      <FlowHeader>
        <FlowTitle>{title}</FlowTitle>
        <FlowCloseButton />
      </FlowHeader>

      <FlowContent>
        <CenteredLoadingIndicator />
      </FlowContent>
    </>
  );
};
