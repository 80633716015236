import { useSyncExternalStore } from 'react';

const subscribe = () => {
  return function unsubscribe() {
    // noop
  };
};

export function useIsSSR() {
  return useSyncExternalStore(
    subscribe,
    () => false,
    () => true,
  );
}
