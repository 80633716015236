import { enGB } from 'date-fns/locale/en-GB';
import { fi } from 'date-fns/locale/fi';
import { sv } from 'date-fns/locale/sv';
import type { Locale } from 'date-fns';
import type { Localized } from '../locale';

export const LanguageCodeToLocaleMap: Localized<Locale> = {
  en: enGB,
  fi: fi,
  sv: sv,

  // Not used
  de: enGB,
  fr: enGB,
  it: enGB,
  et: enGB,
  ru: enGB,
  lv: enGB,
  es: enGB,
};
