import type { MouseEvent } from 'react';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { toast } from 'react-toastify';
import { showReminder, useConfig, useDispatchReminders } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { GuardRenderComponent, LoginGuard } from './WindowTestUtils';

declare global {
  interface Window {
    testUtils: Record<string, () => void> | Record<string, (input: string) => void>;
  }
}

export const useWindowTestUtils = () => {
  const config = useConfig();
  const { openFlow } = useFlowRouter();
  const dispatchReminders = useDispatchReminders();
  const renderParent = useRef<HTMLDivElement>();

  useEffect(() => {
    if (config.env !== 'prod') {
      renderParent.current = document.createElement('div');
      const root = document.getElementById('__next');
      root?.appendChild(renderParent.current);

      window.testUtils = {
        intervalReminder: () => {
          dispatchReminders(showReminder('INTERVAL'));
        },

        afterLoginReminder: () => {
          dispatchReminders(showReminder('AFTER_LOGIN'));
        },

        afterNewGameReminder: () => {
          dispatchReminders(showReminder('AFTER_NEW_GAME'));
        },

        sessionExpiresReminder: () => {
          dispatchReminders(showReminder('SESSION_LESS_THAN_10_MINUTES_LEFT'));
        },

        playForRealReminder: () => {
          dispatchReminders(showReminder('PLAY_FOR_REAL'));
        },

        gamingOverview: () => {
          openFlow('gamingOverview');
        },

        defaultToast: (message: string) => {
          toast(message);
        },

        successToast: (message: string) => {
          toast.success(message);
        },

        errorToast: (message: string) => {
          toast.error(message);
        },

        cookieConsent: () => {
          openFlow('cookieConsent');
        },

        bonusFlow: (playerProgramId: string) => {
          void openFlow('bonus', { playerProgramId: Number(playerProgramId) });
        },

        loginGuard: () => {
          if (renderParent.current) {
            const parentRoot = createRoot(renderParent.current);

            parentRoot.render(
              <GuardRenderComponent
                style={{ backgroundColor: '#fff' }}
                onClick={(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
                  const element = event.target as HTMLDivElement;
                  element.style.display = 'none';
                  element.style.backgroundColor = 'transparent';
                }}
              >
                {/* @ts-expect-error Object does not match relay fragment */}
                <LoginGuard onSkip={() => null} onCompleted={() => null} requirement={{}} />
              </GuardRenderComponent>,
            );
          }
        },
      };
    }
  }, [dispatchReminders, config, openFlow]);
};
