import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  InputBackground: Color.Surface.Base.Background,

  LabelUntouched: Color.Primitive.AccentTint,
  LabelValid: Color.Primitive.Accent,
  LabelFocused: Color.Primitive.Accent,

  BorderUntouched: Color.Primitive.AccentTint,
  BorderValid: Color.Primitive.Accent,
  BorderFocused: Color.Primitive.Accent,
};
