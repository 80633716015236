import { Color } from '@pafcloud/style';

export const Colors = {
  BackgroundBlur: Color.Surface.Floating.Background + 'D0',
  Background: Color.Surface.Floating.Background,

  Icon: Color.TextColors.MutedText,
  IconFocus: Color.TextColors.HeadingText,
  IconFocusBackground: Color.TextColors.BodyText + '20',
  IconFocusCornerRadius: Color.CornerRadius.Small,

  NotificationBackground: Color.Primitive.Primary,
  NotificationText: Color.Primitive.PrimaryContrast,
};
