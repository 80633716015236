/**
 * @generated SignedSource<<dff88e4e86c5c1448e7d15e2e45b5bc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackGame_game$data = {
  readonly category: string;
  readonly familyName: string;
  readonly gameId: string;
  readonly launchUrl: string;
  readonly name: string;
  readonly provider: string;
  readonly slug: string;
  readonly " $fragmentType": "useTrackGame_game";
};
export type useTrackGame_game$key = {
  readonly " $data"?: useTrackGame_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackGame_game">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useTrackGame_game"
};

(node as any).hash = "b1945616f8c21cd50f01cca94a778435";

export default node;
