import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormInputAuxiliary, FormInputProperties } from '@pafcloud/form-components';
import { Breakpoint, Color } from '@pafcloud/style';
import { SectionTitle } from '../shared';

export const EditFieldButton = styled(FormInputAuxiliary)({
  width: 'auto',
  fontWeight: 'bold',
  color: Color.TextColors.LinkText,
  padding: `0 calc(var(${FormInputProperties.TextIndent}) / 2)`,
  alignContent: 'center',
});

export const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: 'auto',

  [Breakpoint.LaptopOrLarger]: {
    maxWidth: 500,
  },

  [`${SectionTitle}`]: {
    marginTop: 32,
  },
});

export const Paragraph = styled.p({
  marginTop: 0,
  marginBottom: 32,
  color: Color.TextColors.BodyText,
  [Breakpoint.LaptopOrLarger]: {
    marginInline: 'auto',
    textAlign: 'center',
    maxWidth: 500,
  },
});

const groupStyles = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: 24,

  [`&&& ${EditFieldButton}`]: {
    span: {
      transform: 'translateY(2px)',
    },
    ':hover, :focus, :active': {
      outline: 'none',
    },
    '::after': {
      content: '""',
      width: 0,
      height: 2,
      backgroundColor: 'currentColor',
      '@media (prefers-reduced-motion: no-preference)': {
        transition: 'width .2s',
      },
    },
    ':hover::after, :focus::after, :active::after': {
      width: '100%',
    },
  },
});

export const Group = styled.div(groupStyles);

export const PhoneNumberGroup = styled.div<{ phoneNumberCanBeEdited: boolean }>(
  groupStyles,
  {
    // If it has a prefix dropdown field it will have two fields and we can then target the even field
    'div:nth-of-type(even)': {
      marginTop: 24,
    },
  },
  ({ phoneNumberCanBeEdited }) => {
    if (!phoneNumberCanBeEdited) {
      return {
        'input[type="tel"] + p[role="alert"]': {
          display: 'none',
        },
      };
    }
  },
);
