import type { ChangeEvent, FC } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { SearchInput } from './search-styles/commonSearchStyles';

type SearchFieldProps = {
  className?: string;
  autoFocus?: boolean;
  reset: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  value?: string;
};

export const SearchField: FC<SearchFieldProps> = ({ className, autoFocus, value, onChange, onFocus }) => {
  const { t } = useTranslation('search');

  return (
    <SearchInput
      autoComplete="off"
      autoFocus={autoFocus}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)}
      onFocus={onFocus}
      value={value}
      type="search"
      placeholder={t('search-input.placeholder')}
      className={className}
    />
  );
};
