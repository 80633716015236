import type { PortableTextMarkComponent } from '@portabletext/react';
import type { PortableTextLink } from '@portabletext/types';
import { Link } from '@pafcloud/base-components';

export const RichTextLinkMark: PortableTextMarkComponent<PortableTextLink> = ({ value, text }) => {
  if (value?.href == null) {
    return <span>{text}</span>;
  }
  return <Link href={value.href}>{text}</Link>;
};
