import type { FC } from 'react';
import { useId, useEffect, useRef } from 'react';
import { Breakpoint } from '@pafcloud/style';
import {
  Dropdown,
  DropdownContent,
  DefaultSearchFieldWrapper,
  Icon,
  ResetButton,
  SearchField,
} from '@pafcloud/base-components';
import { matchesMediaQuery, useDebounce } from '@pafcloud/react-hook-utils';
import { SearchResult } from './SearchResult';
import { RecentSearchResults } from './RecentSearchResults';
import { useSearch } from './useSearch';
import { dispatchSearchResultClickedEvent } from './tracking/dispatchSearchResultTrackingEvent';
import { dispatchSearchStartedEvent } from './tracking/dispatchSearchStartedEvent';
import { useRecentSearch } from './useRecentSearch';

type SearchDropdownProps = {
  gameListId: string;
  className?: string;
};

export const SearchDropdown: FC<SearchDropdownProps> = ({ gameListId, className }) => {
  const searchOverlay = useRef<HTMLDivElement>(null);
  const { search, isLoading, reset, response, value } = useSearch(gameListId);
  const ownedDropdown = useId();
  // This needs to be called when the dropdown is opened in order to populate the recent search list
  useRecentSearch(gameListId);

  function scrollOffset() {
    if (searchOverlay.current) {
      const bounds = searchOverlay.current.getBoundingClientRect();
      searchOverlay.current.style.setProperty('--scroll-offset', `${bounds.top}px`);
    }
  }

  const { debouncedFn } = useDebounce(scrollOffset, { wait: 10 });

  function onFocus() {
    dispatchSearchStartedEvent();

    if (matchesMediaQuery(Breakpoint.LaptopOrLarger)) {
      return;
    }
    const input = searchOverlay.current?.querySelector('input');
    const navElement = document.querySelector('nav');
    if (input && navElement?.contains(input)) {
      input.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const navElement = document.querySelector('nav');

    if (navElement?.contains(searchOverlay.current)) {
      const element = navElement.querySelector('div');

      if (element) {
        element.addEventListener('scroll', debouncedFn, { passive: true });
        return () => {
          element.removeEventListener('scroll', debouncedFn);
        };
      }
    }
  }, [debouncedFn]);

  // TODO: Add aria-expanded and aria-activedescendant
  return (
    <Dropdown className={className} ref={searchOverlay}>
      <DefaultSearchFieldWrapper>
        <SearchField onChange={search} reset={reset} value={value} onFocus={onFocus} aria-owns={ownedDropdown} />

        {value && (
          <ResetButton onClick={() => reset()}>
            <Icon name="closeThinRounded" />
          </ResetButton>
        )}
        {!value && <Icon name="search" />}
      </DefaultSearchFieldWrapper>

      <DropdownContent id={ownedDropdown} role="listbox">
        {value ? (
          <SearchResult
            isLoading={isLoading}
            response={response}
            gameListId={gameListId}
            onClickResult={(searchResult) => {
              dispatchSearchResultClickedEvent({
                ...searchResult,
                term: value,
              });
            }}
          />
        ) : (
          <RecentSearchResults
            gameListId={gameListId}
            onClickResult={(searchResult) => {
              dispatchSearchResultClickedEvent({
                ...searchResult,
                term: '[recent searches]',
              });
            }}
          />
        )}
      </DropdownContent>
    </Dropdown>
  );
};
