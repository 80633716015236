import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { Font } from '@pafcloud/style';
import { BulletList, BulletListItem, Link } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useFlowRouter } from '@pafcloud/flow-router';
import type { FooterMenu_links$key } from './__generated__/FooterMenu_links.graphql';
import { FooterColors } from './footer-colors';

const linksFragment = graphql`
  fragment FooterMenu_links on FooterMenuLink @relay(plural: true) {
    text
    url
    ariaLabel
  }
`;

const NavList = styled(BulletList)({
  margin: '8px 0 0',
  padding: 0,
});

const NavItem = styled(BulletListItem)({
  padding: '4px 0',

  'a, button': {
    color: FooterColors.Link,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: Font.Body,
    whiteSpace: 'nowrap',

    '&:active, &:hover': {
      outline: 'unset',
      color: FooterColors.Link,
      textDecoration: 'underline',
    },
  },

  '::before, ::after': {
    background: FooterColors.Background,
    boxShadow: `inset 0 0 0 16px ${FooterColors.Bullets}`,
  },
});

type FooterMenuProps = {
  links: FooterMenu_links$key;
};

export const FooterMenu: FC<FooterMenuProps> = (props) => {
  const links = useFragment(linksFragment, props.links);
  const { t } = useTranslation('footer');
  const { getFlowUrl } = useFlowRouter();

  return (
    <NavList>
      {links.map((item, index) => (
        <NavItem key={index}>
          <Link href={item.url} aria-label={item.ariaLabel ?? ''}>
            {item.text}
          </Link>
        </NavItem>
      ))}
      <NavItem>
        <Link aria-label={t('cookie-settings.aria-label')} href={getFlowUrl('cookieConsent')}>
          {t('cookie-settings.link-text')}
        </Link>
      </NavItem>
    </NavList>
  );
};
