import type { Localized } from '../locale';

export const LanguageMap: Localized<string> = {
  en: 'en_GB',
  et: 'et_EE',
  sv: 'sv_SE',
  fi: 'fi_FI',
  fr: 'fr_FR',
  de: 'de_DE',
  it: 'it_IT',
  lv: 'lv_LV',
  ru: 'ru_RU',
  es: 'es_ES',
};
