/**
 * @generated SignedSource<<426218a3dc44d40fec3c03ac326f635c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VerificationErrorCode = "INVALID_INPUT" | "TICKET_EXPIRED" | "TICKET_NOT_FOUND" | "UNKNOWN";
export type VerifyPhoneNumberInput = {
  code: string;
  phoneNumber: string;
  subscribeNewsSms: boolean;
  subscribeOffers: boolean;
  ticketId: string;
};
export type useVerifyPhoneMutation$variables = {
  input: VerifyPhoneNumberInput;
};
export type useVerifyPhoneMutation$data = {
  readonly verifyPhoneNumber: {
    readonly __typename: "VerificationError";
    readonly error: VerificationErrorCode | null;
  } | {
    readonly __typename: "VerifyPhoneNumberSuccess";
    readonly player: {
      readonly phoneNumber: string | null;
      readonly subscribeNewsSms: boolean | null;
      readonly subscribeOffers: boolean | null;
    } | null;
    readonly success: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useVerifyPhoneMutation = {
  response: useVerifyPhoneMutation$data;
  variables: useVerifyPhoneMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscribeNewsSms",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscribeOffers",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "VerificationError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useVerifyPhoneMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "verifyPhoneNumber",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "player",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "VerifyPhoneNumberSuccess",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useVerifyPhoneMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "verifyPhoneNumber",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "player",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "VerifyPhoneNumberSuccess",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c860b3a3b94cad538f4af4fdcc3d8368",
    "id": null,
    "metadata": {},
    "name": "useVerifyPhoneMutation",
    "operationKind": "mutation",
    "text": "mutation useVerifyPhoneMutation(\n  $input: VerifyPhoneNumberInput!\n) {\n  verifyPhoneNumber(input: $input) {\n    __typename\n    ... on VerifyPhoneNumberSuccess {\n      success\n      player {\n        phoneNumber\n        subscribeNewsSms\n        subscribeOffers\n        id\n      }\n    }\n    ... on VerificationError {\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e0b05195292f8d1594e1598fc4a1406";

export default node;
