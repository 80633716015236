import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { isType } from '@pafcloud/collection-utils';
import { buildContext } from '../buildContext';
import type { BonusContext_data$key } from './__generated__/BonusContext_data.graphql';
import type { CashbackReward } from './cashbackReward';
import type { BingoTickets } from './bingoTickets';
import type { Freespins } from './freespins';
import type { PlayAndGet } from './playAndGet';
import type { Turnover } from './turnover';
import { toCashbackReward } from './cashbackReward';
import { toBingoTickets } from './bingoTickets';
import { toFreespins } from './freespins';
import { toPlayAndGet } from './playAndGet';
import { toTurnover } from './turnover';

type Bonus = {
  refetchBonusContextQuery: (onComplete?: () => void) => void;
  bingoTickets: BingoTickets;
  freespins: Freespins;
  cashbackReward: CashbackReward;
  playAndGet: { [key: string]: PlayAndGet };
  turnover: Turnover;
  publicOfferIds: string[];
};

const [BonusContext, useBonus] = buildContext<Bonus>('BonusContext');

export { useBonus };

const bonusContextFragment = graphql`
  fragment BonusContext_data on Query @refetchable(queryName: "BonusContextQuery") {
    player {
      bonus {
        offers {
          publicOfferId
          ...cashbackReward_offer
          ...bingoTickets_offer
          ...freespins_offer
          ...playAndGet_offer
          ...turnover_offer
        }
      }
    }
  }
`;

type BonusProviderProps = {
  data: BonusContext_data$key | null;
  children: ReactNode;
};

export const BonusProvider: FC<BonusProviderProps> = (props) => {
  const [data, refetch] = useRefetchableFragment(bonusContextFragment, props.data);

  const value: Bonus = useMemo(() => {
    const contextValues: {
      cashbackReward: Bonus['cashbackReward'];
      bingoTickets: Bonus['bingoTickets'];
      freespins: Bonus['freespins'];
      playAndGet: Bonus['playAndGet'];
      turnover: Bonus['turnover'];
      publicOfferIds: Bonus['publicOfferIds'];
    } = {
      cashbackReward: null,
      bingoTickets: {},
      freespins: {},
      playAndGet: {},
      turnover: null,
      publicOfferIds: [],
    };

    data?.player?.bonus?.offers?.forEach((offer) => {
      const activePlayAndGet = toPlayAndGet(offer);

      if (activePlayAndGet != null) {
        const game = isType(activePlayAndGet, 'PlayRealMoneyOfferStep', 'PlayRealRoundsOfferStep')
          ? activePlayAndGet.game
          : null;

        if (game != null) {
          contextValues.playAndGet[game.familyName] = activePlayAndGet;
        }
      }

      contextValues.bingoTickets = {
        ...contextValues.bingoTickets,
        ...toBingoTickets(offer),
      };

      contextValues.freespins = {
        ...contextValues.freespins,
        ...toFreespins(offer),
      };

      const turnover = toTurnover(offer);
      if (turnover?.offerHasBeenActivated) {
        contextValues.turnover = turnover;
      }

      const cashbackReward = toCashbackReward(offer);
      if (cashbackReward != null) {
        contextValues.cashbackReward = cashbackReward;
      }

      contextValues.publicOfferIds.push(offer.publicOfferId);
    });

    return {
      refetchBonusContextQuery: (onComplete) => {
        refetch({}, { fetchPolicy: 'store-and-network', onComplete });
      },
      cashbackReward: contextValues.cashbackReward,
      bingoTickets: contextValues.bingoTickets,
      freespins: contextValues.freespins,
      playAndGet: contextValues.playAndGet,
      turnover: contextValues.turnover,
      publicOfferIds: contextValues.publicOfferIds,
    };
  }, [data, refetch]);

  return <BonusContext.Provider value={value}>{props.children}</BonusContext.Provider>;
};
