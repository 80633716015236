import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  Icon: Color.Primitive.Primary,
  IconFocus: Color.Primitive.PrimaryTint,

  NotificationBackground: Color.Signal.Danger,
  NotificationText: Color.Signal.DangerContrast,
};
