import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Styles as Styles_default } from './styles.default';
import { Styles as Styles_classic } from './styles.classic';
import { Styles as Styles_dreams } from './styles.dreams';
import { Styles as Styles_momentum } from './styles.momentum';

export const RichTextStyles = (() => {
  if ($buildEnv.theme === 'classic-red') {
    return Styles_classic;
  }
  if ($buildEnv.theme === 'classic') {
    return Styles_classic;
  }
  if ($buildEnv.theme === 'dreams') {
    return Styles_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Styles_momentum;
  }

  return Styles_default;
})();
