import styled from '@emotion/styled';
import type { ComponentProps, FC } from 'react';
import { Suspense, useId } from 'react';
import FocusTrap from 'focus-trap-react';
import { keyframes } from '@emotion/react';
import { ModalBackgroundOverlay, modalSurface } from '@pafcloud/base-components';
import { Breakpoint, Color, getBrandBorderStyle, ZIndex } from '@pafcloud/style';
import { useFlowRouter } from '@pafcloud/flow-router';
import { FlowLoadingStep } from './FlowLoadingStep';

const flowAnimationDesktop = keyframes({
  from: {
    opacity: 0,
    transform: 'translate(-50%, -50%) scale(.8)',
  },
  to: {
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)',
  },
});

const flowAnimationMobile = keyframes({
  from: { transform: 'translateY(100vw) scale(.8)' },
  to: { transform: 'translateY(0vw) scale(1)' },
});

const overlayAnimation = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const FlowOverlay = styled(ModalBackgroundOverlay)({
  zIndex: ZIndex.FlowContainer,
  overflow: 'hidden',
  animation: `${overlayAnimation} .2s ease`,
  display: 'grid',
  justifyContent: 'stretch',
  alignContent: 'stretch',

  [Breakpoint.LaptopOrLarger]: {
    alignItems: 'center',
    justifyItems: 'center',
  },
});

const FlowModal = styled.div<{ dynamicHeight?: boolean }>(modalSurface, ({ dynamicHeight }) => ({
  '--flow-container-width': '100%',

  display: 'flex',
  flexDirection: 'column',
  width: 'var(--flow-container-width)',
  height: '100%',
  minWidth: 0,
  overflow: 'hidden',
  animation: `${flowAnimationMobile} .2s ease forwards`,

  '::before': {
    content: '""',
    top: 0,
    left: 0,
    right: 0,
    ...getBrandBorderStyle(),
  },

  [Breakpoint.LaptopOrLarger]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    '--flow-container-width': '656px',
    height: dynamicHeight ? 'unset' : `calc(100% - 100px)`,
    maxHeight: 838,
    marginTop: 0,
    boxShadow: Color.Elevation.Level3,
    borderRadius: Color.CornerRadius.Small,
    animation: `${flowAnimationDesktop} .15s ease forwards`,
  },
}));

export type FlowContainerProps = {
  dismissible?: boolean;
} & ComponentProps<typeof FlowModal>;

export const FlowContainer: FC<FlowContainerProps> = ({ children, dismissible, ...props }) => {
  const id = useId();
  const { closeFlow } = useFlowRouter();

  // This is mainly for unit testing purposes, but should also serve to eliminate the random
  // "[Error: Your focus-trap needs to have at least one focusable element]"-errors we get
  let fallbackFocus: HTMLElement | undefined;
  if (typeof document !== 'undefined') {
    fallbackFocus = document.querySelector<HTMLElement>('#__next') ?? document.body;
  }

  const close = () => {
    if (dismissible) {
      closeFlow();
    }
  };

  return (
    <FlowOverlay
      aria-labelledby={id}
      onMouseDown={() => {
        close();
      }}
    >
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus,
          allowOutsideClick: true,
          initialFocus: false,
        }}
      >
        <FlowModal
          role="dialog"
          {...props}
          id={id}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          <Suspense fallback={<FlowLoadingStep />}>{children}</Suspense>
        </FlowModal>
      </FocusTrap>
    </FlowOverlay>
  );
};
