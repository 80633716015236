import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useIntervalReminders, useAfterLoginReminder, useSessionExpiresReminder } from '@pafcloud/reminders';
import { useWindowTestUtils } from '../../utils/useWindowTestUtils';
import { LiveMessageNotifications } from '../inbox';
import type { SyncAppData_data$key } from './__generated__/SyncAppData_data.graphql';
import { useSyncPersonalData } from './useSyncPersonalData';
import { useSyncPlayerSession } from './useSyncPlayerSession';

const dataFragment = graphql`
  fragment SyncAppData_data on Query {
    ...useIntervalReminders_data
    ...useAfterLoginReminder_data
    ...useSessionExpiresReminder_data
  }
`;

type Props = {
  data: SyncAppData_data$key;
};

export const SyncAppData: FC<Props> = (props) => {
  const data = useFragment(dataFragment, props.data);
  useSyncPersonalData();
  useSyncPlayerSession();
  useIntervalReminders(data);
  useAfterLoginReminder(data);
  useSessionExpiresReminder(data);

  // Not an app hook per se, but needs to be called here so we have access to functions
  useWindowTestUtils();

  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return <LiveMessageNotifications />;
  }

  return null;
};
