import { useRef, useState } from 'react';
import { useHandler } from './useHandler';

type Options = {
  /** wait time in milliseconds */
  wait: number;
};

type SetTimeoutType = ReturnType<typeof setTimeout> | null;

/**
 * Hook used for debouncing calls to a function.
 *
 * @param fn The function to debounce
 * @param options Options object for configuring the debounce
 * @returns The debounced function and a method for cancelling any ongoing debounced calls
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <FN extends (...args: any[]) => any>(fn: FN, options: Options) => {
  const timeout = useRef<SetTimeoutType>(null);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const cancel = useHandler(() => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    setIsDebouncing(false);
    timeout.current = null;
  });

  const debouncedFn = useHandler((...args: Parameters<FN>) => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    setIsDebouncing(true);
    timeout.current = setTimeout(() => {
      cancel();
      fn(...args);
    }, options.wait);
  });

  return { debouncedFn, cancel, isDebouncing };
};
