import { $buildEnv } from '@pafcloud/config/buildEnv';

import type { ColorDefinition } from './color';
import type { FontHeadingSizeType, FontTextSizeType, FontType } from './font';

import { Color as Color_classic } from './color/color.classic';
import { Color as Color_classic_red } from './color/color.classic-red';
import { Color as Color_clean } from './color/color.clean';
import { Color as Color_clean_blue } from './color/color.clean-blue';
import { Color as Color_dreams } from './color/color.dreams';
import { Color as Color_golden } from './color/color.golden';
import { Color as Color_goldenV2 } from './color/color.goldenV2';
import { Color as Color_momentum } from './color/color.momentum';
import { Color as Color_momentum_neon } from './color/color.momentum-neon';
import { Color as Color_quirky } from './color/color.quirky';
import { Color as Color_quirky_purple } from './color/color.quirky-purple';
import { Color as Color_samurai } from './color/color.samurai';

import * as Font_classic from './font/font.classic';
import * as Font_classic_red from './font/font.classic-red';
import * as Font_clean from './font/font.clean';
import * as Font_dreams from './font/font.dreams';
import * as Font_golden from './font/font.golden';
import * as Font_momentum from './font/font.momentum';
import * as Font_quirky from './font/font.quirky';
import * as Font_quirky_purple from './font/font.quirky-purple';

type Theme = {
  Color: ColorDefinition;
  Font: FontType;
  FontHeadingSize: FontHeadingSizeType;
  FontTextSize: FontTextSizeType;
};

export const Theme = ((): Theme => {
  if ($buildEnv.theme === 'classic') {
    return {
      Color: Color_classic,
      Font: Font_classic.Font,
      FontHeadingSize: Font_classic.FontHeadingSize,
      FontTextSize: Font_classic.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'classic-red') {
    return {
      Color: Color_classic_red,
      Font: Font_classic_red.Font,
      FontHeadingSize: Font_classic_red.FontHeadingSize,
      FontTextSize: Font_classic_red.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'clean') {
    return {
      Color: Color_clean,
      Font: Font_clean.Font,
      FontHeadingSize: Font_clean.FontHeadingSize,
      FontTextSize: Font_clean.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'clean-blue') {
    return {
      Color: Color_clean_blue,
      Font: Font_clean.Font,
      FontHeadingSize: Font_clean.FontHeadingSize,
      FontTextSize: Font_clean.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'dreams') {
    return {
      Color: Color_dreams,
      Font: Font_dreams.Font,
      FontHeadingSize: Font_dreams.FontHeadingSize,
      FontTextSize: Font_dreams.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'golden') {
    return {
      Color: Color_golden,
      Font: Font_golden.Font,
      FontHeadingSize: Font_golden.FontHeadingSize,
      FontTextSize: Font_golden.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'goldenV2') {
    return {
      Color: Color_goldenV2,
      Font: Font_golden.Font,
      FontHeadingSize: Font_golden.FontHeadingSize,
      FontTextSize: Font_golden.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'momentum') {
    return {
      Color: Color_momentum,
      Font: Font_momentum.Font,
      FontHeadingSize: Font_momentum.FontHeadingSize,
      FontTextSize: Font_momentum.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'momentum-neon') {
    return {
      Color: Color_momentum_neon,
      Font: Font_clean.Font,
      FontHeadingSize: Font_clean.FontHeadingSize,
      FontTextSize: Font_clean.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'quirky') {
    return {
      Color: Color_quirky,
      Font: Font_quirky.Font,
      FontHeadingSize: Font_quirky.FontHeadingSize,
      FontTextSize: Font_quirky.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'quirky-purple') {
    return {
      Color: Color_quirky_purple,
      Font: Font_quirky_purple.Font,
      FontHeadingSize: Font_quirky_purple.FontHeadingSize,
      FontTextSize: Font_quirky_purple.FontTextSize,
    };
  }

  if ($buildEnv.theme === 'samurai') {
    return {
      Color: Color_samurai,
      Font: Font_classic.Font,
      FontHeadingSize: Font_classic.FontHeadingSize,
      FontTextSize: Font_classic.FontTextSize,
    };
  }

  throw new Error(`Theme ${$buildEnv.theme} is not implemented`);
})();

// We export the invididual parts of the theme for easier access.
export const { Color, Font, FontHeadingSize, FontTextSize } = Theme;
