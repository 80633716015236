import dynamic from 'next/dynamic';
import type { ComponentProps, ComponentType } from 'react';
import { useIsSSR } from '@pafcloud/react-hook-utils';
import * as essential from '@pafcloud/icon/src/essential';
import type { Icon } from '@pafcloud/icon';

type SvgComponent = ComponentType<ComponentProps<'svg'>>;

type IconModule<IconName extends string> =
  | {
      [P in IconName]: SvgComponent;
    }
  | {
      // Typescript thinks that the module sometimes has a default export instead of named exports,
      // but with our build process that should never happen, and if it does it's actually an error.
      default: unknown;
    };

function createIconModuleLoader<IconName extends string>(getIcons: () => Promise<IconModule<IconName>>) {
  function createIconLoader(icon: IconName) {
    const DynamicIcon = dynamic(
      () =>
        getIcons().then((module) => {
          if ('default' in module) {
            throw new Error(`Icon module does not have named export "${icon}"`);
          }
          return module[icon];
        }),
      { ssr: false },
    );

    return function Icon(props: ComponentProps<'svg'>) {
      const isSSR = useIsSSR();

      // Next crashes dynamically imported componetns with `ssr: false` on the server.
      if (isSSR) {
        return null;
      }

      return <DynamicIcon {...props} />;
    };
  }

  return function pickIcons<Name extends IconName>(...icons: Name[]) {
    return icons.reduce(
      (acc, icon) => {
        acc[icon] = createIconLoader(icon);
        return acc;
      },
      {} as { [K in Name]: SvgComponent },
    );
  };
}

export const icons: Record<Icon, SvgComponent> = {
  // ESSENTIAL
  ...essential,

  // GAME
  ...createIconModuleLoader(() => import('@pafcloud/icon/src/game'))(
    'bingoBall',
    'bomb',
    'bonus',
    'coin',
    'coins',
    'collection',
    'dice',
    'fullscreen',
    'fullscreenClose',
    'maintenance',
    'pokerHand',
    'roundedSquare',
    'sliders',
    'ticketSpecial',
    'turnoverContribution',
  ),

  // GAME CATEGORIES
  ...createIconModuleLoader(() => import('@pafcloud/icon/src/game-categories'))(
    'basketball',
    'betting',
    'bingo',
    'casinoLive',
    'fantasySport',
    'jackpotPools',
    'otherGames',
    'poker',
    'slots',
    'ticketGrid',
    'videoPoker',
  ),

  // INFORMATION
  ...createIconModuleLoader(() => import('@pafcloud/icon/src/information'))(
    'gift',
    'hot',
    'jackpot',
    'players',
    'starBadge',
    'trophy',
  ),

  // UI
  ...createIconModuleLoader(() => import('@pafcloud/icon/src/ui'))(
    'account',
    'download',
    'exclamation',
    'externalLink',
    'globe',
    'home',
    'info',
    'kebab',
    'logout',
    'message',
    'notification',
    'soundOff',
    'soundOn',
    'support',
    'wallet',
    'phone',
    'email',
    'upload',
    'openBanking',
  ),

  // MISC
  ...createIconModuleLoader(() => import('@pafcloud/icon/src/misc'))(
    'age',
    'award',
    'baccarat',
    'belt',
    'blackjack',
    'care',
    'chatBubbles',
    'check',
    'christmas',
    'clock',
    'graph',
    'history',
    'hotBalloon',
    'hourglass',
    'insurance',
    'lightbulb',
    'lock',
    'minus',
    'notes',
    'package',
    'pause',
    'play',
    'plus',
    'roof',
    'roulette',
    'rules',
    'settings',
    'shield',
    'slotMachine',
    'stopWatch',
    'supportChat',
    'supportHands',
    'tools',
    'trash',
  ),
};
