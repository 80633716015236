import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type { CookieConsent } from '@pafcloud/cookies';
import { cookies } from '@pafcloud/cookies';
import { useHandler } from '@pafcloud/react-hook-utils';
import { buildContext } from '../buildContext';
import { tagCookieConsent } from './gtm';

type SetCookieConsent = (consent: CookieConsent) => void;

const [CookieConsentContext, useCookieConsent] = buildContext<CookieConsent | null>('CookieConsentContext');
const [SetCookieConsentContext, useSetCookieConsent] = buildContext<SetCookieConsent>('SetCookieConsentContext');

export { useCookieConsent, useSetCookieConsent };

type Props = {
  children?: ReactNode;
};

export const CookieConsentProvider: FC<Props> = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState<CookieConsent | null>(null);

  useEffect(() => {
    setCookieConsent(cookies.getConsent());
  }, []);

  const submitCookieConsent = useHandler((consent: CookieConsent) => {
    cookies.setConsent(consent);
    tagCookieConsent(consent);
    setCookieConsent(consent);
  });

  return (
    <SetCookieConsentContext.Provider value={submitCookieConsent}>
      <CookieConsentContext.Provider value={cookieConsent}>{children}</CookieConsentContext.Provider>
    </SetCookieConsentContext.Provider>
  );
};
