import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const ROOT_ID = 'modal-portal';

export function renderIntoRootContainer(children: ReactNode) {
  if (typeof document === 'undefined') {
    return null;
  }

  const container = document.getElementById(ROOT_ID) ?? document.body;

  return createPortal(children, container);
}
