import { Color, FontTextSize } from '@pafcloud/style';
import { sharedStyle } from './sharedStyles';

export const Style = {
  ...sharedStyle,
  BorderRadius: Color.CornerRadius.Base,
  BorderWidthUntouched: '2px',
  TextIndent: '16px',
  SuffixFontSize: FontTextSize.Big,
};
