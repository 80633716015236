import type { CSSObject } from '@emotion/react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { IconBase } from '../../icon';
import type { ButtonProps } from '../ButtonProps';

export const getIconStyles = (props: Pick<ButtonProps, 'icon' | 'iconAlignment'>) => {
  if (!props.icon) {
    return undefined;
  }

  const alignment = props.iconAlignment ?? 'LEFT';
  return {
    flexDirection: alignment === 'RIGHT' ? 'row-reverse' : 'row',

    [`> ${IconBase}`]: {
      marginRight: alignment === 'LEFT' ? '0.4em' : undefined,
      marginLeft: alignment === 'RIGHT' ? '0.4em' : undefined,
      position: 'relative',
      minWidth: '0.95em',
      top: $buildEnv.theme === 'classic' ? -1 : undefined,
    },
  } as CSSObject;
};
