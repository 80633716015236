import type { Dispatch, FC, ReactNode } from 'react';
import { useReducer } from 'react';

import { buildContext } from '../buildContext';

import type { RemindersState } from './remindersReducer';
import { getInitialRemindersState, remindersReducer } from './remindersReducer';
import type { RemindersAction } from './RemindersActionType';

type DispatchReminders = Dispatch<RemindersAction>;

export const [RemindersContext, useReminders] = buildContext<RemindersState>('RemindersContext');
export const [DispatchRemindersContext, useDispatchReminders] =
  buildContext<DispatchReminders>('DispatchRemindersContext');

type Props = {
  children?: ReactNode;
};

export const RemindersProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(remindersReducer, getInitialRemindersState());

  return (
    <DispatchRemindersContext.Provider value={dispatch}>
      <RemindersContext.Provider value={state}>{children}</RemindersContext.Provider>
    </DispatchRemindersContext.Provider>
  );
};
