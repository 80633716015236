import type { FC } from 'react';
import { ExternalLink, Icon } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useLocalisedHelpdeskUrl } from '@pafcloud/contexts';

type Props = {
  className?: string;
};

export const LinkToCustomerService: FC<Props> = ({ className }) => {
  const { t } = useTranslation('common');
  const helpdeskUrl = useLocalisedHelpdeskUrl();

  return (
    <ExternalLink href={helpdeskUrl} className={className}>
      {t('customer-service')} <Icon name="externalLink" size="12" />
    </ExternalLink>
  );
};
