import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, NoStyleButton, Heading, MarkdownParser } from '@pafcloud/base-components';
import { PIN_CODE_PATTERN, HookFormInput } from '@pafcloud/form-components';
import { useTranslation } from '@pafcloud/i18n';
import { Color, FontTextSize } from '@pafcloud/style';
import { isType } from '@pafcloud/collection-utils';
import { Form } from './ChoosePhoneNumberForm';
import { useSendPinCode } from './useSendPinCode';
import { useVerifyPhone } from './useVerifyPhone';

const FormHeading = styled(Heading)({
  display: 'flex',
  justifyContent: 'center',
  margin: '32px 0',
  fontSize: FontTextSize.Big,
});

const IntroText = styled(MarkdownParser)({
  marginBottom: 0,
  textAlign: 'center',
});

const SubmitButton = styled(Button)({
  fontWeight: 'bold',
  margin: '25px auto',
  minWidth: 185,
  div: {
    display: 'grid',
    justifyContent: 'center',
  },
});

const Group = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: 20,
  marginTop: 40,
});

const ResendGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: 20,
  justifyContent: 'center',
  alignItems: 'baseline',
  paddingRight: '5px',
  fontSize: FontTextSize.Small,
});

const ResendText = styled.span({
  marginRight: '5px',
  marginTop: '10px',
});

const ResendButton = styled(NoStyleButton)({
  fontWeight: 'bold',
  color: Color.TextColors.LinkText,
});

type VerifyPhoneNumberType = {
  code: string;
};

export type PinCodeSuccessData = {
  ticketId: string;
  identificationCode: string;
  phoneNumber: string;
  subscribeNewsSms: boolean;
  subscribeOffers: boolean;
};

type Props = {
  pinCodeSuccessData: PinCodeSuccessData;
  onCompleted: () => void;
  onReset: () => void;
};

export const VerifyPhoneNumberForm: FC<Props> = ({ pinCodeSuccessData, onCompleted, onReset }) => {
  const { t } = useTranslation('verify-phone');
  const { ticketId, identificationCode, phoneNumber, subscribeNewsSms, subscribeOffers } = pinCodeSuccessData;
  const verifyPhone = useVerifyPhone();
  const sendPinCode = useSendPinCode();
  const [codePrefix, setCodePrefix] = useState(identificationCode);
  const [ticket, setTicket] = useState(ticketId);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const form = useForm<VerifyPhoneNumberType>({
    mode: 'onChange',
    defaultValues: { code: `${codePrefix}-` },
  });

  const { register, handleSubmit, formState, setError, setValue } = form;
  const { isDirty, isValid, isSubmitting } = formState;

  const sendNewPinCode = async () => {
    const request = await sendPinCode({ phoneNumber });
    if (isType(request, 'SendPinCodeSuccess')) {
      setCodePrefix(request.identificationCode);
      setTicket(request.ticketId);
      setValue('code', `${request.identificationCode}-`);
    } else if (isType(request, 'VerificationError')) {
      // TODO handle error
    }
  };

  const handleResendPinButton = () => {
    setIsResendDisabled(true);
    setTimeout(() => setIsResendDisabled(false), 2000);
    void sendNewPinCode();
  };

  const changeToUpperCase = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    form.setValue('code', event.target.value.toUpperCase());
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { code } = data;

      const response = await verifyPhone({ code, ticketId: ticket, phoneNumber, subscribeOffers, subscribeNewsSms });

      if (isType(response, 'VerifyPhoneNumberSuccess')) {
        onCompleted();
      } else if (isType(response, 'VerificationError')) {
        const message = (() => {
          if (response.error === 'INVALID_INPUT') {
            return t('form.error.invalid-input');
          }
          if (response.error === 'TICKET_EXPIRED') {
            return t('form.error.ticket-expired');
          }
          return t('form.error.unknown');
        })();

        setError('code', { type: 'server', message }, { shouldFocus: true });
      }
    } catch {
      const message = t('form.error.unknown');
      setError('code', { type: 'server', message }, { shouldFocus: false });
    }
  });

  return (
    <Form method="POST" noValidate onSubmit={onSubmit} onReset={() => onReset()}>
      <FormHeading>{t('form.heading')}</FormHeading>
      <IntroText>{t('form.intro', { phoneNumber })}</IntroText>
      <FormProvider {...form}>
        <Group>
          <HookFormInput
            type="text"
            label={t('form.label')}
            info={t('form.pin.format-hint')}
            aria-required
            {...register('code', {
              required: t('form.error.required'),
              pattern: {
                value: PIN_CODE_PATTERN,
                message: t('form.error.pattern'),
              },
              onChange: changeToUpperCase,
            })}
          />
        </Group>
      </FormProvider>
      <ResendGroup>
        <ResendText>{t('form.resend.text')}</ResendText>
        <ResendButton onClick={() => handleResendPinButton()} disabled={isResendDisabled} type="button">
          {t('form.resend.button')}
        </ResendButton>
      </ResendGroup>
      <SubmitButton
        size="large"
        disabled={!isDirty || !isValid}
        type="submit"
        variant="primary"
        isLoading={isSubmitting}
      >
        {t('form.submit')}
      </SubmitButton>
      <SubmitButton size="large" disabled={isSubmitting} type="reset" variant="secondary">
        {t('form.back')}
      </SubmitButton>
    </Form>
  );
};
