import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

// useLayoutEffect can not be used on server.
const useIsomorphicEffect = typeof window == 'undefined' ? useEffect : useLayoutEffect;

/**
 * Placeholder for the new useEvent RFC.
 * @see https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useHandler<Handler extends (...args: any[]) => any>(handler: Handler) {
  const latestHandler = useRef(handler);

  useIsomorphicEffect(() => {
    latestHandler.current = handler;
  });

  return useCallback((...args: Parameters<Handler>) => {
    return latestHandler.current(...args);
  }, []) as Handler;
}
