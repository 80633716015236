import { useRouter } from 'next/router';

export const useGetQueryParam = (key: string) => {
  const { query } = useRouter();

  const value = query[key];
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};
