import { cookies, DEFERRED_LOGIN } from '@pafcloud/cookies';
import type { LoginMethod } from './useUpdateLoginCookieStates';

type DeferredLogin = {
  signinTicket: string;
  loginMethod: LoginMethod;
};

export const deferLogin = (deferredLogin: DeferredLogin) => {
  cookies.set(DEFERRED_LOGIN, JSON.stringify(deferredLogin));
};

export const deleteDeferredLogin = () => {
  cookies.remove(DEFERRED_LOGIN);
};

export const getDeferredLogin = (): DeferredLogin | null => {
  const deferredLogin = cookies.get(DEFERRED_LOGIN);
  if (!deferredLogin) {
    return null;
  }

  return JSON.parse(deferredLogin);
};
