import { useTranslation } from '@pafcloud/i18n';
import { getAge } from '@pafcloud/locale';
import { DATE_BIRTH_PATTERN } from '../form-input';

export const formatDateString = (value: string) => {
  const day = value.slice(0, 2);
  const month = value.slice(3, 5);
  const year = value.slice(6, 10);
  return [year, month, day].join('-');
};

export const useValidateDateOfBirth = () => {
  const { t } = useTranslation('manual-registration');

  return (value?: string) => {
    if (!value) {
      return true;
    }

    if (DATE_BIRTH_PATTERN.test(value)) {
      const dateString = formatDateString(value);

      if (getAge(dateString) < 18) {
        return `${t('form.date-of-birth.underaged.error')} (${t('form.date-of-birth.format-hint')})`;
      }

      return true;
    }

    return `${t('form.date-of-birth.format.error')} (${t('form.date-of-birth.format-hint')})`;
  };
};
