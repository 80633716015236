import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { isFuture } from 'date-fns';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { getBonusOfferSteps, useWallet } from '@pafcloud/contexts';
import type { BonusOfferStep } from '@pafcloud/contexts';
import { useTranslation } from '@pafcloud/i18n';
import { inArray, isType } from '@pafcloud/collection-utils';
import { GeneratedBonusTitle } from '../../../../bonus/GeneratedBonusTitle';
import {
  BonusText,
  MenuProgressInformation,
  MultiLineBonusText,
  PlayAndGetProgressBar,
  TurnoverProgressBar,
} from './sharedStyles';
import { BonusBadge } from './styles';
import type { BonusMenuItemContent_offer$key } from './__generated__/BonusMenuItemContent_offer.graphql';

const offerFragment = graphql`
  fragment BonusMenuItemContent_offer on BonusOffer {
    offerId
    ...GeneratedBonusTitle_offer
    ...useBonusOfferSteps_offer
  }
`;

export const isActiveProductReward = (step: BonusOfferStep) => {
  if ('reward' in step) {
    if (step.reward == null) {
      return false;
    }

    if ('status' in step.reward && step.reward.status === 'NOT_GIVEN') {
      return false;
    }

    if (isType(step.reward, 'BingoTicketsOfferReward') && step.reward.ticketsRemaining === 0) {
      return false;
    }

    if (isType(step.reward, 'FreespinOfferReward') && step.reward.spinsRemaining === 0) {
      return false;
    }

    if (isType(step.reward, 'FreeBetOfferReward') && step.reward.amount === 0) {
      return false;
    }

    if ('expiresAt' in step.reward && step.reward.expiresAt != null) {
      if (!isFuture(new Date(step.reward.expiresAt))) {
        return false;
      }
    }

    return true;
  }
};

type BonusMenuItemContentProps = {
  offer: BonusMenuItemContent_offer$key;
  type: string | undefined;
};

export const BonusMenuItemContent: FC<BonusMenuItemContentProps> = (props) => {
  const { t } = useTranslation('common');
  const offer = useFragment(offerFragment, props.offer);
  const steps = getBonusOfferSteps(offer);
  const formatAmount = useFormatAmount();
  const { currentTurnover, totalTurnover } = useWallet();

  if (props.type != null && ['FreespinOfferReward', 'BingoTicketsOfferReward'].includes(props.type)) {
    const activeRewardStep = steps?.find(isActiveProductReward);

    const spinsReward =
      activeRewardStep != null && 'reward' in activeRewardStep && isType(activeRewardStep.reward, 'FreespinOfferReward')
        ? activeRewardStep.reward
        : null;

    const ticketsReward =
      activeRewardStep != null &&
      'reward' in activeRewardStep &&
      isType(activeRewardStep.reward, 'BingoTicketsOfferReward')
        ? activeRewardStep.reward
        : null;

    if (spinsReward == null && ticketsReward == null) {
      return null;
    }

    return (
      <>
        <BonusText>{spinsReward?.game?.name ?? ticketsReward?.game?.name}</BonusText>
        <BonusBadge severity="low">{spinsReward?.spinsRemaining ?? ticketsReward?.ticketsRemaining}</BonusBadge>
      </>
    );
  }

  if (props.type === 'TurnoverOfferStep') {
    return (
      <>
        <BonusText>{t('bonus-menu.link.text.turnover')}</BonusText>
        <MenuProgressInformation>
          <strong>{formatAmount(currentTurnover ?? 0)}</strong>
          {` / `}
          <span>{formatAmount(totalTurnover ?? 0)}</span>
        </MenuProgressInformation>
        <TurnoverProgressBar
          currentValue={currentTurnover}
          maxValue={totalTurnover}
          playerProgramId={offer.offerId}
          hideTurnoverIcon
        />
      </>
    );
  }

  if (inArray(['PlayRealMoneyOfferStep', 'PlayRealRoundsOfferStep'] as const, props.type)) {
    const activePlayAndGetStep = steps?.find(
      (step) => isType(step, 'PlayRealMoneyOfferStep', 'PlayRealRoundsOfferStep') && step.status === 'ACTIVE',
    );

    if (activePlayAndGetStep?.__typename === 'PlayRealMoneyOfferStep') {
      const { playAmount, remainingAmount } = activePlayAndGetStep;

      const actualRemainingAmount = playAmount - remainingAmount;

      return (
        <>
          <GeneratedBonusTitle offer={offer} styledComponent={MultiLineBonusText} />
          <MenuProgressInformation>
            <strong>{formatAmount(actualRemainingAmount)}</strong>
            {` / `}
            <span>{formatAmount(playAmount)}</span>
          </MenuProgressInformation>
          <PlayAndGetProgressBar
            aria-valuenow={actualRemainingAmount}
            aria-valuemax={playAmount}
            value={actualRemainingAmount}
            max={playAmount}
          />
        </>
      );
    }

    if (activePlayAndGetStep?.__typename === 'PlayRealRoundsOfferStep') {
      const { playRounds, remainingRounds } = activePlayAndGetStep;

      const actualRemainingRounds = playRounds - remainingRounds;

      return (
        <>
          <GeneratedBonusTitle offer={offer} styledComponent={MultiLineBonusText} />
          <MenuProgressInformation>
            <strong>{actualRemainingRounds}</strong>
            {` / `}
            <span>{playRounds}</span>
          </MenuProgressInformation>
          <PlayAndGetProgressBar
            aria-valuenow={actualRemainingRounds}
            aria-valuemax={playRounds}
            value={actualRemainingRounds}
            max={playRounds}
          />
        </>
      );
    }
  }

  return <GeneratedBonusTitle offer={offer} styledComponent={MultiLineBonusText} />;
};
