import styled from '@emotion/styled';
import { Color, ZIndex } from '@pafcloud/style';

export const SidebarOverlayBase = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'fixed',
  top: 0,
  width: isOpen ? '100%' : 0,
  height: '100%',
  opacity: isOpen ? 1 : 0,
  transition: 'opacity 0.15s ease-in',
  zIndex: ZIndex.Header - 1,
  background: Color.Layout.Overlay,
}));
