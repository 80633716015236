import type { FC } from 'react';
import styled from '@emotion/styled';
import { LanguageSelect } from '../language-select';

const LanguageWrapper = styled.div({
  marginTop: 16,
  padding: 16,
  background: 'rgba(0,0,0,0.2)',

  label: {
    '::before': {
      opacity: 0.2,
    },
  },
});

export const FooterLanguageSelect: FC = () => {
  return (
    <LanguageWrapper className="full-width">
      <LanguageSelect openDirection="up" />
    </LanguageWrapper>
  );
};
