import { $buildEnv } from '@pafcloud/config/src/buildEnv';

/**
 * Returns a support email based on the current context.
 * @see https://docs.google.com/document/d/1y8lcEIRSCw1ay6GvCYGh0-nTn9Xp213v989-B6K4YK8/edit#
 */
export function getSupportEmail(locale?: string) {
  if ($buildEnv.site === 'mycasino.ch') {
    return getMycasinoSupportEmail(locale);
  }

  if (locale === 'sv') {
    return `kundservice@${$buildEnv.site}`;
  }

  if (locale === 'fi') {
    return `asiakaspalvelu@${$buildEnv.site}`;
  }

  if (locale === 'et') {
    return `klienditugi@${$buildEnv.site}`;
  }

  if (locale === 'ru') {
    if ($buildEnv.site === 'pafbet.lv' || $buildEnv.site === 'x3000.lv' || $buildEnv.site === '11.lv') {
      return `support.rus@${$buildEnv.site}`;
    }
    return `support.ru@${$buildEnv.site}`;
  }

  return `support@${$buildEnv.site}`;
}

function getMycasinoSupportEmail(locale?: string) {
  if (locale === 'en') {
    return `customerservice@${$buildEnv.site}`;
  }

  if (locale === 'it') {
    return `servizioclienti@${$buildEnv.site}`;
  }

  if (locale === 'fr') {
    return `serviceclient@${$buildEnv.site}`;
  }

  return `kundenservice@${$buildEnv.site}`;
}
