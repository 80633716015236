import type { SearchResult } from './Search';

export type SearchResponse = {
  total: number;
  items: SearchResult[];
};

const normalizeQuery = (query?: string) => (query ?? '').trim().toLowerCase();

export const fetchSearchResults = async (
  input?: string,
  locale?: string,
  gameListId?: string,
): Promise<SearchResponse> => {
  const query = normalizeQuery(input);
  if (query.length === 0) {
    return { items: [], total: 0 };
  }

  const url = `/api/search?gameListId=${gameListId}&locale=${locale}&q=${encodeURIComponent(query)}`;
  const response = await fetch(url);

  return await response.json();
};
