import { Color } from '@pafcloud/style';

export const Colors = {
  Divider: Color.Surface.Floating.Dimmed,
  TurnoverPercentage: Color.Primitive.Primary,
  DisabledRow: Color.TextColors.MutedText,
  Link: Color.TextColors.LinkText,
  Bullet: Color.Surface.Floating.Foreground,
  BulletDone: Color.Signal.Success,
  CancelledIcon: Color.Signal.Danger,
  Heading: Color.TextColors.HeadingText,
  Text: Color.Surface.Floating.Foreground,
  TextMuted: Color.TextColors.MutedText,
  CardBorder: undefined,
  CardBackground: Color.Surface.Base.Background,
  CardShadow: Color.Elevation.Level1,
  FallbackIconBackground: Color.Primitive.Secondary,
  FallbackIcon: Color.Primitive.SecondaryContrast,
  TurnoverBorder: `1px solid ${Color.Surface.Nested.Dimmed}`,
  TurnoverBackground: Color.Surface.Nested.Background,
};
