import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_golden } from './colors.golden';
import { Colors as Colors_goldenV2 } from './colors.goldenV2';

export const ProfileColors = (() => {
  if ($buildEnv.theme === 'golden') {
    return Colors_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Colors_goldenV2;
  }
  return Colors_default;
})();
