import type { FC, ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import { getTrackedGameKey, mapTrackedGameToImpressionGame } from './utils';
import type { TrackedGame, TrackedGames, TrackedViewedGame } from './gameTrackingContext';
import { gameTrackingContext } from './gameTrackingContext';
import { useSendImpressions } from './useSendImpressions';

type Props = {
  children?: ReactNode;
};

export const GameTrackingProvider: FC<Props> = ({ children }) => {
  const trackedGames = useRef<TrackedGames>({});

  const value = useMemo(() => {
    // Keeps track of viewed games to later send them to GTM
    const trackViewedGame = (viewedGame: TrackedViewedGame) => {
      const trackedGameKey = getTrackedGameKey(viewedGame);

      const {
        entry,
        game,
        gameListTitle,
        gameListPosition,
        recommendationGroupId,
        recommendationId,
        pageSlug,
        gameListId,
        trackId,
      } = viewedGame;

      const existingTrackedGame = trackedGames.current[trackedGameKey];

      if ((!entry.isIntersecting && existingTrackedGame === undefined) || existingTrackedGame?.reported) {
        return;
      }

      // Keep track of when element goes in and out of view
      const currentTime = performance.now();
      const lastTimeSpan = existingTrackedGame?.lastViewStarted ? currentTime - existingTrackedGame.lastViewStarted : 0;
      const lastViewStarted = entry.isIntersecting ? entry.time : undefined;
      const previousTotalTime = existingTrackedGame?.totalTimeSeen ?? 0;
      const totalTimeSeen = previousTotalTime + lastTimeSpan;

      trackedGames.current[trackedGameKey] = {
        game,
        gameListPosition,
        gameListTitle,
        gameListId,
        lastViewStarted,
        recommendationGroupId,
        recommendationId,
        totalTimeSeen,
        pageSlug,
        trackId,
      };
    };

    const trackClickedGame = (trackableGame: TrackedGame) => {
      dispatchTrackingEvent('gameList-click', {
        gameList: {
          'click.games': [mapTrackedGameToImpressionGame(trackableGame)],
        },
      });
    };

    return { trackViewedGame, trackClickedGame };
  }, []);

  useSendImpressions(trackedGames);

  return <gameTrackingContext.Provider value={value}>{children}</gameTrackingContext.Provider>;
};
