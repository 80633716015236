import styled from '@emotion/styled';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Breakpoint, Color, FontTextSize, ZIndex } from '@pafcloud/style';
import { Icon, NoStyleButton } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { useFlowRouter } from '@pafcloud/flow-router';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { FlowColors } from './flow-colors';

export const FlowHeader = styled.div({
  display: 'flex',
  flexShrink: 0,
  position: 'relative',
  zIndex: Number(ZIndex.FlowContainer) + 1,
  height: 64,
  width: '100%',
  padding: 8,
  flexDirection: 'row',
  alignItems: 'center',
  background: 'inherit',
  color: 'inherit',
  borderBottom: `1px solid ${FlowColors.Border}`,

  [Breakpoint.LandscapePhone]: {
    height: 56,
  },
});

export const FlowTitle = styled.h2({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  margin: '0 8px',
  flexGrow: 2,
  fontSize: FontTextSize.Huge,
  fontWeight: $buildEnv.theme === 'momentum' ? 800 : 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'inherit',
});

export const FlowTitleIcon = styled(Icon)({
  '> svg': {
    display: 'block',
    borderRadius: Color.CornerRadius.Large,
    border: '2px solid currentColor',
    color: 'currentColor',
    height: '1em',
    width: '1em',
    padding: 2,
  },
});

const HeaderIconButton = styled(NoStyleButton)({
  minWidth: 48,
  minHeight: 48,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ':hover, :focus': {
    outline: 'none',
    svg: {
      outline: 'none',
      fill: FlowColors.Focus,
    },
  },

  [Breakpoint.LandscapePhone]: {
    minWidth: 32,
    minHeight: 32,
  },
});

type FlowButtonProps = {
  onClick: () => void;
  autoFocus?: boolean;
};

export const FlowBackButton: FC<FlowButtonProps> = ({ onClick, autoFocus }) => {
  const { t } = useTranslation('flow');

  return (
    <HeaderIconButton aria-label={t('aria-label.go-back')} onClick={onClick} autoFocus={autoFocus}>
      <Icon name="arrowLeftFat" />
    </HeaderIconButton>
  );
};

export const FlowCloseButton: FC<Partial<FlowButtonProps>> = ({ onClick, autoFocus }) => {
  const { t } = useTranslation('flow');
  const { closeFlow } = useFlowRouter();

  const handleClick = () => {
    if (onClick != null) {
      onClick();
      return;
    }

    void closeFlow();
  };

  useEffect(() => {
    const closeOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClick();
      }
    };

    document.addEventListener('keydown', closeOnEscape);

    return () => document.removeEventListener('keydown', closeOnEscape);
  });

  return (
    <HeaderIconButton aria-label={t('aria-label.close-flow')} onClick={handleClick} autoFocus={autoFocus}>
      <Icon name="closeThinRounded" />
    </HeaderIconButton>
  );
};
