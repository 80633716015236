import styled from '@emotion/styled';
import { Breakpoint, FontTextSize } from '@pafcloud/style';

export const BulletList = styled.ul({
  '--bullet-size': '0.25rem',
  listStyle: 'none',
  textAlign: 'center',
  transform: 'translateX(calc(var(--bullet-size)*2.5))',
  [Breakpoint.LandscapePhone]: {
    fontSize: FontTextSize.Small,
  },
});

export const BulletListItem = styled.li({
  display: 'inline-flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  marginLeft: 'calc(-1 * var(--bullet-size) * 5);',
  color: 'currentColor',

  '&:first': {
    marginLeft: 0,
  },

  /* Bullet between all items in a list */
  '::before, ::after': {
    content: '""',
    display: 'block',
    width: 'var(--bullet-size)',
    height: 'var(--bullet-size)',
    margin: '0 calc(var(--bullet-size) * 2)',
    borderRadius: '50%',
  },
});
