import { Color } from '@pafcloud/style';
import { sharedStyle } from './sharedStyles';

export const Style = {
  ...sharedStyle,
  BorderRadius: Color.CornerRadius.Base,
  BorderWidthUntouched: '2px',
  TextIndent: '16px',
  FontWeight: 700,
  LabelWeight: 400,
  LabelWeightHeavy: 400,
  NoFloatLabelWeight: 400,
};
