import { isPast, parseISO } from 'date-fns';
import { useBonus } from '../BonusContext';

type MatchGameToBingoTicketsReturnType = {
  __typename: string;
  familyName: string;
  ticketsRemaining: number;
} | null;

export const useMatchGameToBingoTickets = (familyName: string): MatchGameToBingoTicketsReturnType => {
  const { bingoTickets } = useBonus();

  const matchingOffer = bingoTickets?.[familyName];

  if (matchingOffer?.expiresAt != null && isPast(parseISO(matchingOffer.expiresAt))) {
    return null;
  }

  if (matchingOffer?.ticketsRemaining && matchingOffer.game?.familyName) {
    return {
      __typename: matchingOffer.__typename,
      familyName: matchingOffer.game.familyName,
      ticketsRemaining: matchingOffer.ticketsRemaining,
    };
  }

  return null;
};
