import type { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Color } from '@pafcloud/style';

const overlay = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

export const ModalBackgroundOverlay = styled.div(overlay, {
  background: Color.Layout.Overlay,
});

/** Used when a dialog need to have "click out side to close" but doesn't need a background. Example: Profile Menu */
export const ModalTransparentOverlay = styled.div(overlay);

export type ModalBackgroundOverlayProps = ComponentProps<typeof ModalBackgroundOverlay>;
