/**
 * @generated SignedSource<<24775713e0aaf3592bbf3fd0b7fb21ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSyncPersonalDataQuery$variables = {
  includeInbox: boolean;
};
export type useSyncPersonalDataQuery$data = {
  readonly inbox?: {
    readonly unreadCount: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PlayerContext_data">;
};
export type useSyncPersonalDataQuery = {
  response: useSyncPersonalDataQuery$data;
  variables: useSyncPersonalDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeInbox"
  }
],
v1 = {
  "condition": "includeInbox",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Inbox",
      "kind": "LinkedField",
      "name": "inbox",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSyncPersonalDataQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PlayerContext_data"
      },
      (v1/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSyncPersonalDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "balance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bonus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentTurnover",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalTurnover",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ]
  },
  "params": {
    "cacheID": "23399789263fb2a44eaa567657eee078",
    "id": null,
    "metadata": {},
    "name": "useSyncPersonalDataQuery",
    "operationKind": "query",
    "text": "query useSyncPersonalDataQuery(\n  $includeInbox: Boolean!\n) {\n  ...PlayerContext_data\n  inbox @include(if: $includeInbox) {\n    unreadCount\n  }\n}\n\nfragment PlayerContext_data on Query {\n  player {\n    wallet {\n      balance\n      bonus\n      cash\n      currentTurnover\n      totalTurnover\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad7e975b3fa9384f01b8a1df384f320b";

export default node;
