import { useEffect } from 'react';

export const useServiceWorker = () => {
  useEffect(() => {
    const registerServiceWorker = async () => {
      const registrations = await navigator.serviceWorker.getRegistrations();

      const faultyRegistration = registrations.find((registration) => {
        return !!registration.active?.scriptURL.includes('static/app_content/features/serviceworker');
      });
      await faultyRegistration?.unregister();
      await navigator.serviceWorker.register('/sw.js', { scope: '/' });
    };

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', registerServiceWorker);
    }
    return () => window.removeEventListener('load', registerServiceWorker);
  }, []);
};
