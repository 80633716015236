import { graphql, readInlineData } from 'relay-runtime';
import { isType } from '@pafcloud/collection-utils';
import type { playAndGet_offer$key } from './__generated__/playAndGet_offer.graphql';
import { getBonusOfferSteps } from './hooks/useBonusOfferSteps';

export type PlayAndGet = ReturnType<typeof toPlayAndGet>;

const playAndGetOfferFragment = graphql`
  fragment playAndGet_offer on BonusOffer @inline {
    offerId
    ...useBonusOfferSteps_offer
  }
`;

export const toPlayAndGet = (key: playAndGet_offer$key | null) => {
  const offer = readInlineData(playAndGetOfferFragment, key);

  if (offer == null) {
    return null;
  }

  const steps = getBonusOfferSteps(offer);

  if (steps == null) {
    return null;
  }

  const activePlayAndGetStep = steps.find((step) => {
    if (isType(step, 'PlayRealMoneyOfferStep', 'PlayRealRoundsOfferStep')) {
      return step.status === 'ACTIVE';
    }
  });

  if (activePlayAndGetStep == null) {
    return null;
  }
  return {
    offerId: offer.offerId,
    ...activePlayAndGetStep,
  };
};
