import type { Site, LanguageCode } from '@pafcloud/config';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { LanguageMap as LanguageMap_default } from './languages.default';
import { LanguageMap as LanguageMap_switzerland } from './languages.switzerland';

export const getLanguage = (language: LanguageCode, site: Site = $buildEnv.site) => {
  if (site === 'mycasino.ch') {
    return LanguageMap_switzerland[language];
  }

  return LanguageMap_default[language];
};
