import { useRef } from 'react';
import type { GraphQLTaggedNode, MutationParameters } from 'relay-runtime';
import { useRelayEnvironment, commitMutation } from 'react-relay/hooks';
import { useAsync, useHandler } from '@pafcloud/react-hook-utils';
import type { UseAsyncOptions } from '@pafcloud/react-hook-utils';

export const useMutation = <T extends MutationParameters>(mutation: GraphQLTaggedNode) => {
  const environment = useRelayEnvironment();

  return useHandler((variables: T['variables'] = {}) => {
    return new Promise<T['response']>((resolve, reject) => {
      return commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors?.length) {
            reject(errors);
          } else {
            resolve(response);
          }
        },
        onError: (error: Error | null) => {
          reject(error);
        },
      });
    });
  });
};

export const useDebouncedMutation = <T extends MutationParameters>(mutation: GraphQLTaggedNode, delay: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const makeMutation = useMutation<T>(mutation);

  return useHandler(async (variables: T['variables'] = {}) => {
    return new Promise<T['response']>((resolve, _reject) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      timeoutRef.current = setTimeout(() => {
        resolve(makeMutation(variables));
      }, delay);
    });
  });
};

export const createUseMutation = <T extends MutationParameters>(mutation: GraphQLTaggedNode) => {
  type TInput = T['variables'] extends { input: infer Input } ? Input : object;

  return <TResult>(onResult: (result: T['response']) => TResult) => {
    return () => {
      const triggerMutation = useMutation<T>(mutation);

      return useHandler((input: TInput) => {
        return triggerMutation({ input }).then(onResult);
      });
    };
  };
};

export const createUseAsyncMutation = <T extends MutationParameters>(mutation: GraphQLTaggedNode) => {
  type TInput = T['variables'] extends { input: infer Input } ? Input : object;

  return <TResult>(onResult: (result: T['response']) => TResult) => {
    type Mutate = (input: TInput) => Promise<TResult>;

    return <TArgs extends unknown[]>(getOptions: (mutate: Mutate) => UseAsyncOptions<TResult, TArgs>) => {
      const triggerMutation = useMutation<T>(mutation);

      const mutate: Mutate = (input) => {
        return triggerMutation({ input }).then(onResult);
      };

      return useAsync(getOptions(mutate));
    };
  };
};
