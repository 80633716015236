import { Font } from '@pafcloud/style';
import { sharedStyle } from './sharedStyles';

export const Style = {
  ...sharedStyle,
  BorderRadius: '8px',
  BorderWidthUntouched: '2px',
  TextIndent: '16px',
  FontFamily: Font.Body,
};
