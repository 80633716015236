import type { FC } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { Disclosure } from '@pafcloud/base-components';
import { camelCase } from '@pafcloud/util';
import type { MainMenuGroup_content$key } from './__generated__/MainMenuGroup_content.graphql';
import { MainMenuLink } from './MainMenuLink';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuItemIcon } from './MainMenuIcons';
import { MainMenuColors } from './main-menu-colors';
import { menuLinkStyle } from './menuLinkStyle';
import { showMainMenuItemIcon } from './showMainMenuItemIcon';

const contentFragment = graphql`
  fragment MainMenuGroup_content on MenuLinkGroup {
    text
    iconName
    links {
      url
      ...MainMenuLink_content
    }
  }
`;

export const MenuGroupDetails = styled(Disclosure)({
  // & is used to increase specificity
  '&[open]': {
    backgroundColor: MainMenuColors.SubMenuBackground,
  },
});

export const MenuGroupSummary = styled(Disclosure.Summary)({
  ...menuLinkStyle,
});

const SUB_MENU_INDENT = 24;

export const MainSubMenu = styled.ul({
  position: 'relative',
  margin: 0,
  padding: 0,
  paddingBottom: 8,
  listStyle: 'none',

  '::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 8,
    left: SUB_MENU_INDENT,
    width: 2,
    background: MainMenuColors.SubMenuRuler,
    zIndex: 1,
  },

  [`${MainMenuItem}`]: {
    minHeight: 48,
    marginLeft: SUB_MENU_INDENT - 1, // -1 is to align SUB_MENU_INDENT with the active effect
    fontSize: '0.85em',

    border: 'unset',
  },
});

export const SummaryText = styled.header({
  flexBasis: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const ExpandIconWrapper = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  transition: 'transform 0.15s',
  fontSize: '1rem',
});

export const SummaryContent = styled.div({
  display: 'flex',
  width: '100%',
  minHeight: 56,
});

type Props = {
  tabIndex?: number;
  content: MainMenuGroup_content$key;
};

export const MainMenuGroup: FC<Props> = (props) => {
  const { text, iconName, links } = useFragment(contentFragment, props.content);
  const router = useRouter();

  const isExpanded = links.some((link) => {
    return router.asPath && link.url && router.asPath.startsWith(link.url);
  });

  return (
    <MainMenuItem>
      <MenuGroupDetails open={isExpanded}>
        <MenuGroupSummary role="menuitem" tabIndex={props.tabIndex}>
          <SummaryContent>
            {showMainMenuItemIcon && iconName && (
              <MainMenuItemIcon name={camelCase(iconName)} color={MainMenuColors.Icon} />
            )}
            <SummaryText>
              {text}
              <ExpandIconWrapper>
                <Disclosure.SummaryIcon name="chevronRightFat" />
              </ExpandIconWrapper>
            </SummaryText>
          </SummaryContent>
        </MenuGroupSummary>

        <MainSubMenu role="menu" tabIndex={props.tabIndex}>
          {links.map((link, index) => (
            <MainMenuLink key={index} content={link} tabIndex={props.tabIndex} />
          ))}
        </MainSubMenu>
      </MenuGroupDetails>
    </MainMenuItem>
  );
};
