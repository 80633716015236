import { useTranslation } from '@pafcloud/i18n';
import {
  CookieButtonWrapper,
  CookieConsentButton,
  CookieHeading,
  CookieParagraph,
  CookieWrapper,
} from '@pafcloud/base-components';
import { useSetCookieConsent } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';

export const CookieConsent = () => {
  const { t } = useTranslation('common');
  const setCookieConsent = useSetCookieConsent();
  const { openFlow } = useFlowRouter();

  const handleAcceptAll = () => {
    setCookieConsent({
      marketing: true,
      tracking: true,
      essential: true,
    });
  };

  return (
    <CookieWrapper>
      <CookieHeading>{t('cookie-consent.header')}</CookieHeading>
      <CookieParagraph>{t('cookie-consent.sub-header')}</CookieParagraph>
      <CookieButtonWrapper>
        <CookieConsentButton type="button" variant="primary" onClick={handleAcceptAll}>
          {t('cookie-consent.accept-all-button')}
        </CookieConsentButton>
        <CookieConsentButton type="button" variant="secondary" onClick={() => openFlow('cookieConsent')}>
          {t('cookie-consent.settings-button')}
        </CookieConsentButton>
      </CookieButtonWrapper>
    </CookieWrapper>
  );
};
