import { Color, Font, FontTextSize } from '@pafcloud/style';

export const sharedStyle = {
  BorderRadius: Color.CornerRadius.Small,
  BorderWidth: '2px',
  BorderWidthUntouched: '1px',
  FontFamily: Font.Button,
  FontSize: FontTextSize.Big,
  FontWeight: 400,
  Height: '56px',
  LabelFloatScale: '0.778',
  LabelGap: '4px',
  LabelWeight: 400,
  LabelWeightHeavy: 700,
  NoFloatLabelWeight: 400,
  SuffixFontSize: FontTextSize.Small,
  TextIndent: '30px',
};
