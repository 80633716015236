/**
 * @generated SignedSource<<8b2b58776f03d133b9ada4b6824a6f58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Tracking_data$data = {
  readonly player: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "Tracking_data";
};
export type Tracking_data$key = {
  readonly " $data"?: Tracking_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tracking_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tracking_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Player",
      "kind": "LinkedField",
      "name": "player",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f38ca5c7225f5ea5928e214e6bb30be8";

export default node;
