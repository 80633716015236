import GTM from 'react-gtm-module';
import type { TrackingInfo } from './info';
import type { TrackingEvent } from './events';
import { deepFlattenObjectStructure } from './deepFlattenObjectStructure';

const trackingInfo: Partial<TrackingInfo> = {};

export const prepareTrackingInfo = <I extends keyof TrackingInfo>(info: I, payload: TrackingInfo[I]) => {
  trackingInfo[info] = payload;
};

export const initializeTracking = (id: string) => {
  GTM.initialize({
    gtmId: id,
    dataLayer: deepFlattenObjectStructure(trackingInfo),
  });
};

export const dispatchTrackingEvent = <E extends keyof TrackingEvent>(event: E, payload: TrackingEvent[E]) => {
  if (typeof window === 'undefined') {
    console.log('TRIGGER_EVENT_ON_SERVER');
    return;
  }

  GTM.dataLayer({
    dataLayer: {
      event,
      ...deepFlattenObjectStructure(payload),
    },
  });
};
