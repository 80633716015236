import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { FlowContainer, FlowContent, FlowTitle, FlowHeader } from '../../../flow-building-blocks';
import { useLogout } from '../../../use-logout';
import { VerifyPhoneNumberGuard } from './VerifyPhoneNumberGuard';
import type { VerifyPhoneNumberGuardFlow_requirement$key } from './__generated__/VerifyPhoneNumberGuardFlow_requirement.graphql';

const requirementFragment = graphql`
  fragment VerifyPhoneNumberGuardFlow_requirement on BrowseAppAccessPhoneVerificationRequirement {
    phoneNumber
    skippable
  }
`;

type Props = {
  requirement: VerifyPhoneNumberGuardFlow_requirement$key;
  onCompleted: () => void;
};

const VerifyPhoneNumberGuardFlow: FC<Props> = ({ onCompleted, ...props }) => {
  const { phoneNumber } = useFragment(requirementFragment, props.requirement);
  const { t } = useTranslation(['flow', 'common']);
  const logout = useLogout();

  return (
    <FlowContainer aria-label={t('aria-label.register-phone')}>
      <FlowHeader>
        <FlowTitle>{t('title.register-phone')}</FlowTitle>
      </FlowHeader>

      <FlowContent>
        <VerifyPhoneNumberGuard
          phoneNumber={phoneNumber ?? undefined}
          onCompleted={onCompleted}
          onAbort={logout}
          abortButtonText={t('common:logout.text')}
        />
      </FlowContent>
    </FlowContainer>
  );
};

export default VerifyPhoneNumberGuardFlow;
