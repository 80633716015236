import type { RefObject } from 'react';
import { useState, useEffect, useRef } from 'react';

export const useTopOfPageObserver = (intersectingElement: RefObject<HTMLDivElement>) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsTopOfPage(entry.isIntersecting);
      },
      {
        threshold: [1],
      },
    );

    const currentObserver = observerRef.current;
    const scrollElement = intersectingElement.current;

    if (scrollElement) {
      currentObserver.observe(scrollElement);
    }

    return () => {
      if (scrollElement) {
        currentObserver.unobserve(scrollElement);
      }
      currentObserver.disconnect();
    };
  }, [intersectingElement]);

  return isTopOfPage;
};
