import type { ReactNode } from 'react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import type { SidebarGolden_content$key } from './__generated__/SidebarGolden_content.graphql';
import { Sidebar as Sidebar_default } from './Sidebar.default';
import { Sidebar as Sidebar_classic } from './Sidebar.classic';
import { Sidebar as Sidebar_clean } from './Sidebar.clean';
import { Sidebar as Sidebar_golden } from './Sidebar.golden';
import { Sidebar as Sidebar_quirky } from './Sidebar.quirky';
import { Sidebar as Sidebar_quirky_purple } from './Sidebar.quirky-purple';
import { Sidebar as Sidebar_momentum } from './Sidebar.momentum';
import { Sidebar as Sidebar_momentum_neon } from './Sidebar.momentum-neon';

export const Sidebar = withBuildEnv(() => {
  if ($buildEnv.theme === 'classic') {
    return Sidebar_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Sidebar_classic;
  }
  if ($buildEnv.theme === 'clean') {
    return Sidebar_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Sidebar_clean;
  }
  if ($buildEnv.theme === 'golden') {
    return Sidebar_golden;
  }
  if ($buildEnv.theme === 'momentum') {
    return Sidebar_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Sidebar_momentum_neon;
  }
  if ($buildEnv.theme === 'quirky') {
    return Sidebar_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Sidebar_quirky_purple;
  }

  return Sidebar_default;
});

export type SidebarProps = {
  content: SidebarGolden_content$key;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};
