export const ZIndex = {
  KambiMobileNav: 320, // lower will make it go behind betslip in Kambi
  GroupedTab: 399, // higher will go above the header
  Header: 400,
  GPV: 450, // Above header
  Tray: 799,
  SearchContainer: 809, // Needs to be higher than header
  FlowContainer: 810, // Needs to be higher than header
  ProcessCard: 810,
  RouteTransitionIndicator: 810,
  Reminders: 811, // Above flow and below cookies, but the toast is at z-index: 9999 so ¯\_(ツ)_/¯
  CookieConsent: 899,
  GamePause: 900, // Nothing can be above this
};
