import type { RemindersAction, Reminder } from './RemindersActionType';

type RemindersStatus = 'CLOSED' | 'OPEN';

export type RemindersState = {
  status: RemindersStatus;
  reminders: Reminder[];
};

export const getInitialRemindersState = (): RemindersState => ({
  status: 'CLOSED',
  reminders: [],
});

export const remindersReducer = (state: RemindersState, action: RemindersAction): RemindersState => {
  switch (action.type) {
    case 'SHOW_REMINDER': {
      // if there already is a reminder of same type, don't add another
      if (state.reminders.some((reminder) => reminder === action.reminder)) {
        return state;
      }
      return {
        ...state,
        status: 'OPEN',
        reminders: [...state.reminders, action.reminder],
      };
    }

    case 'HIDE_REMINDER': {
      // get all but first reminder
      const reminders = state.reminders.slice(1);
      return {
        ...state,
        reminders,
        status: reminders.length > 0 ? 'OPEN' : 'CLOSED',
      };
    }
    default:
      return state;
  }
};
