import type { CSSObject } from '@emotion/react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Font } from '@pafcloud/style';

const getThemeStyle = (): CSSObject => {
  if (
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'quirky' ||
    $buildEnv.theme === 'quirky-purple'
  ) {
    return {
      textTransform: 'uppercase',
      fontWeight: 900,
    };
  }

  if ($buildEnv.theme === 'momentum') {
    return {
      fontWeight: 800,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    };
  }

  return {};
};

export const getCommonStyles = (): CSSObject => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    border: 'none',
    borderRadius: 'var(--button-border-radius)',
    fontFamily: Font.Button,
    fontWeight: 'bold',
    WebkitTapHighlightColor: 'transparent',

    ...getThemeStyle(),

    '& > span': {
      display: 'grid',
      gridAutoFlow: 'column dense',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: '0.5em',
    },
  };
};
