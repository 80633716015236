import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { useId } from 'react';
import { Color } from '@pafcloud/style';
import { FormInputMessage, FormInputProperties, FormInputStyle } from '../form-input';

const ButtonWrapper = styled.div<{ error?: boolean }>(({ error }) => ({
  position: 'relative',
  display: 'inline-grid',
  gridAutoFlow: 'column',
  gridAutoColumns: '1fr',
  borderRadius: 'var(--button-border-radius)',
  border: error ? `2px solid ${Color.TextColors.ErrorText}` : `1px solid ${Color.Surface.Base.Foreground}`,
  [FormInputProperties.TextIndent]: FormInputStyle.TextIndent,

  [`&:focus-within`]: {
    border: `1px solid ${Color.Surface.Base.Foreground}`,
  },

  [`&:focus-within > ${FormInputMessage}`]: {
    display: 'flex',
  },
}));

type Props = {
  children: ReactNode[];
  error?: string;
  className?: string;
};

export const ButtonGroup: FC<Props> = ({ children, error, className }) => {
  const messageId = useId();

  return (
    <ButtonWrapper className={className} error={Boolean(error)} aria-describedby={messageId}>
      {error && (
        <FormInputMessage type="error" id={messageId}>
          {error}
        </FormInputMessage>
      )}
      {children}
    </ButtonWrapper>
  );
};

ButtonGroup.displayName = 'ButtonGroup';
