import { dispatchTrackingEvent } from '@pafcloud/tracking';

export const dispatchSearchStartedEvent = () => {
  dispatchTrackingEvent('game-search', {
    game: {
      search: {
        action: 'start search',
      },
    },
  });
};
