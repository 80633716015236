export function createArrayValueValidator<T>(array: readonly T[]) {
  const values = new Set<unknown>(array);

  return (value: unknown): value is T => {
    return values.has(value);
  };
}

export function createObjectValueValidator<T>(object: Record<string, T>) {
  return createArrayValueValidator(Object.values(object));
}

export function createObjectKeyValidator<T extends string>(object: Record<T, unknown>) {
  return createArrayValueValidator(Object.keys(object) as T[]);
}

export function isObject(value: unknown): value is object {
  return value instanceof Object && value.constructor === Object;
}

export function isEmptyObject(value: Record<string, unknown>) {
  for (const _ in value) {
    return false;
  }
  return true;
}

/**
 * Type narrowing wrapper around {@link Array.prototype.includes}
 *
 * @see https://fettblog.eu/typescript-array-includes/#option-2%3A-a-helper-with-type-assertions
 */
export const inArray = <T extends U, U>(arr: readonly T[], value: U): value is T => {
  // @ts-expect-error - Use native `Array#includes` when TypeScript supports bivariance in `includes`
  return arr.includes(value);
};
