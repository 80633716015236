import type { ComponentProps, FC } from 'react';
import { ButtonLink } from '@pafcloud/base-components';
import { useFlowRouter } from '@pafcloud/flow-router';

type LoginLinkProps = Omit<ComponentProps<typeof ButtonLink>, 'size' | 'variant' | 'type'>;

export const LoginLink: FC<LoginLinkProps> = ({ children, ...props }) => {
  const { getFlowUrl } = useFlowRouter();

  return (
    <ButtonLink href={getFlowUrl('login')} {...props} size="default" variant="primary">
      <span>{children}</span>
    </ButtonLink>
  );
};
